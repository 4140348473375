import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';
import type { ApplicationKey } from '../../application-key';
import type { TimeZone } from '../../general';

/**
 * These types are based on the response shape of the Users and User Search API.
 *
 * See:
 * - https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-users
 * - https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-user-search
 * - https://developer.atlassian.com/cloud/jira/service-desk/rest/api-group-organization
 */

export const ATLASSIAN_ACCOUNT_USER = 'atlassian' as const;
export const CUSTOMER_USER = 'customer' as const;
export const APP_USER = 'app' as const;
export const UNKNOWN_USER = 'unknown' as const;

export type AccountType =
	| typeof ATLASSIAN_ACCOUNT_USER
	| typeof CUSTOMER_USER
	| typeof APP_USER
	| typeof UNKNOWN_USER;

export interface ApplicationRole {
	key: ApplicationKey;
	name: string;
}

export interface ApplicationRolesWrapper {
	items: ApplicationRole[];
	size?: number;
}

export interface GroupName {
	name: string;
	self: string;
}

export interface GroupNamesWrapper {
	items: GroupName[];
	size?: number;
}

export interface AvatarUrls {
	['16x16']: string;
	['24x24']: string;
	['32x32']: string;
	['48x48']: string;
}

interface BaseUser {
	accountId: string;
	displayName: string;

	/**
	 * Deprecated properties
	 *
	 * See: https://developer.atlassian.com/cloud/jira/platform/deprecation-notice-user-privacy-api-migration-guide/
	 */
	key?: string;
	name?: string;
}

/**
 * A user with details as permitted by the user's Atlassian Account privacy settings.
 *
 * See: https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-users
 */
export interface User extends BaseUser {
	active: boolean;
	avatarUrls: AvatarUrls;
	self?: string;

	/**
	 * Optional properies
	 *
	 * Other user responses like `UserBean` do not return these properties. If you require these, extend this interface.
	 *
	 * See: https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-dashboards/#api-rest-api-3-dashboard-search-get
	 */
	accountType?: AccountType;
	emailAddress?: string | null;
	locale?: Locale | null;
	timeZone?: TimeZone;

	/**
	 * Expanded properties
	 *
	 * These are additional user details based on the `expand` parameter
	 */
	expand?: 'groups,applicationRoles';
	applicationRoles?: ApplicationRolesWrapper;
	groups?: GroupNamesWrapper;
}

/**
 * A user found in search
 *
 * See: https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-user-search
 */
export interface UserPickerUser extends BaseUser {
	avatarUrl: string;
	html?: string;
}

/**
 * A user associated with a JSM organisation.
 *
 * See: https://developer.atlassian.com/cloud/jira/service-desk/rest/api-group-organization
 */
export interface UserDTO extends BaseUser {
	active?: boolean;
	emailAddress?: string | null;
	timeZone?: TimeZone;
	_links?: {
		jiraRest: string;
	};
}
