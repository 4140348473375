import log from '@atlassian/jira-common-util-logging/src/log';

const PREFIX = '[Queues]';

/** This function logs privacy safe error.
 * The content must not include any personally identifiable information (PII) or user generated content (UGC).
 * In Jira, UGC includes issue keys, project keys etc. Please see go/jira-ugc for more information.
 *
 * @param location the lower-case, '.' separated path to the file that you are logging from.
 * @param message the message to be logged.
 * @param event the error that is thrown.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const safeErrorWithoutCustomerData = (location: string, message: string, event?: any): void => {
	log.safeErrorWithoutCustomerData(location, `${PREFIX} ${message}`, event);
};

/** This function logs privacy safe warning.
 * The content must not include any personally identifiable information (PII) or user generated content (UGC).
 * In Jira, UGC includes issue keys, project keys etc. Please see go/jira-ugc for more information.
 *
 * @param location the lower-case, '.' separated path to the file that you are logging from.
 * @param message the message to be logged.
 * @param event the error that is thrown.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const safeWarnWithoutCustomerData = (location: string, message: string, event?: any): void => {
	log.safeWarnWithoutCustomerData(location, `${PREFIX} ${message}`, event);
};

/** This function logs privacy safe info.
 * The content must not include any personally identifiable information (PII) or user generated content (UGC).
 * In Jira, UGC includes issue keys, project keys etc. Please see go/jira-ugc for more information.
 *
 * @param location the lower-case, '.' separated path to the file that you are logging from.
 * @param message the message to be logged.
 * @param event the error that is thrown.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const safeInfoWithoutCustomerData = (location: string, message: string, event?: any): void => {
	log.safeInfoWithoutCustomerData(location, `${PREFIX} ${message}`, event);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	safeInfoWithoutCustomerData,
	safeWarnWithoutCustomerData,
	safeErrorWithoutCustomerData,
};
