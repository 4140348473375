// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import type { Store } from 'redux';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import { Provider } from '../../common/table-redux';
import { CELL_NAVIGATION } from '../../model/navigation';
import { setStateFromProps } from '../../ops/hydrate/set';
import { updateStateFromProps } from '../../ops/hydrate/update';
import { setVerticalScrollOffset } from '../../ops/scrolling/offset';
import { scrollToRow } from '../../ops/scrolling/to-row';
import { setRenderSidebarIcon } from '../../state/internal/actions';
import { initialInternalState } from '../../state/reducer';
import type { State } from '../../state/types';
import View from '../../view';
import { ThemeProvider } from '../context/theme-context';
import theme from './theme';
import type { TableProps as Props } from './types';
import createStore from './utils/create-store';
import transformPropsToState from './utils/transform-props-to-state';

// eslint-disable-next-line jira/react/no-class-components
export default class TableApp extends PureComponent<Props> {
	// go/jfe-eslint
	// eslint-disable-next-line react/sort-comp
	static defaultProps: Partial<Props> = {
		NonTemporaryCellWrapper: undefined,
		navigationMode: CELL_NAVIGATION,

		rowIds: [],
		rowsConfiguration: {},
		defaultRowHeight: Math.max(theme.row.height, theme.row.minHeight),
		autoRowHeight: false,
		draggableRows: false,

		columns: [],
		expandedColumnIds: [],
		columnWidths: {},
		hiddenColumnIds: [],

		onActiveItemChanged: noop,
		onRowListFocusFunctionChanged: undefined,

		contentKey: undefined,
	};

	static getInitialStoreState(props: Props) {
		return __SERVER__ && props.ssrTableSize
			? {
					internal: {
						...initialInternalState,
						tableSize: props.ssrTableSize,
					},
				}
			: undefined;
	}

	constructor(props: Props) {
		super(props);
		const initialStoreState = TableApp.getInitialStoreState(props);
		this.store = createStore(initialStoreState);

		const consumerState = transformPropsToState(props);
		this.store.dispatch(setStateFromProps(consumerState, theme));

		if (props.renderSidebarIcon) {
			this.store.dispatch(setRenderSidebarIcon(props.renderSidebarIcon));
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (!isEqual(this.props, nextProps)) {
			const consumerState = transformPropsToState(nextProps);
			this.store.dispatch(updateStateFromProps(consumerState));

			if (nextProps.renderSidebarIcon) {
				this.store.dispatch(setRenderSidebarIcon(nextProps.renderSidebarIcon));
			}
		}
	}

	scrollToRow(rowId: string) {
		this.store.dispatch(scrollToRow(rowId));
	}

	resetVerticalScrollOffset() {
		this.store.dispatch(setVerticalScrollOffset(0));
	}

	store: Store<State>;

	render() {
		const { defaultRowHeight, autoRowHeight, customScrollBarOffset } = this.props;
		return (
			<Provider store={this.store}>
				<ThemeProvider
					defaultRowHeight={defaultRowHeight}
					autoRowHeight={autoRowHeight}
					customScrollBarOffset={customScrollBarOffset}
				>
					<View />
				</ThemeProvider>
			</Provider>
		);
	}
}
