import { CREATED_DEMO_REQUESTS_PROPERTY_KEY } from '../../common/constants';
import { get, type GetCreatedRequestsResponse } from './project-property';

export type GetCreatedRequestsPayload = {
	baseUrl: string;
	projectId: number;
};

const service = async ({
	baseUrl,
	projectId,
}: GetCreatedRequestsPayload): Promise<GetCreatedRequestsResponse> => {
	try {
		const response = await get(baseUrl, projectId, CREATED_DEMO_REQUESTS_PROPERTY_KEY);
		return response.value || [];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return [];
	}
};

export default service;
