import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { JSMImporterSurvey, useIsJSMImporterSurveyEnabled } from '@atlassian/jira-importer-survey';
import { AsyncQueuesAutomationMenu } from '@atlassian/jira-servicedesk-queues-automation-menu/src/async';

type Props = {
	isManuallySorted: boolean;
	isFavoriteEnabled: boolean;
	canManageQueue: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ResetSortingButton: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ActionsMenu: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FavoriteButton: ComponentType<any>;
	showIssueFailureError: boolean;
	onDeleteComplete: (arg1: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	OpenInIssueNavButton: ComponentType<any>;
};

export default function HeaderActions({
	canManageQueue,
	isManuallySorted,
	isFavoriteEnabled,
	onDeleteComplete,
	showIssueFailureError,
	ActionsMenu,
	FavoriteButton,
	ResetSortingButton,
	OpenInIssueNavButton,
}: Props) {
	const shouldShowResetSortingButton =
		!showIssueFailureError && !canManageQueue && isManuallySorted;
	const hasMultipleActions = isFavoriteEnabled && (canManageQueue || shouldShowResetSortingButton);
	const { showJSMImporterSurvey } = useIsJSMImporterSurveyEnabled();

	return (
		<Wrapper>
			{showJSMImporterSurvey && (
				<>
					<JSMImporterSurvey />
					<Spacer />
				</>
			)}
			<AsyncQueuesAutomationMenu />
			<>
				<OpenInIssueNavButton />
				<Spacer />
			</>
			{isFavoriteEnabled && <FavoriteButton />}
			{hasMultipleActions && <Spacer />}
			{canManageQueue && <ActionsMenu onDeleteComplete={onDeleteComplete} />}
			{shouldShowResetSortingButton && <ResetSortingButton />}
		</Wrapper>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Spacer = styled.div({
	width: token('space.100', '8px'),
});
