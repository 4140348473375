import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
// eslint-disable-next-line jira/import/no-duplicates, import/no-duplicates
import srcDark from '../../assets/error-white.svg';
// eslint-disable-next-line import/no-duplicates
import srcLight from '../../assets/error-white.svg';

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

const viewBox: SVGViewBox = [0, 0, 164, 212];
export { srcLight, srcDark, viewBox };
