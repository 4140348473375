import React, { Component, createRef } from 'react';
import { Observable } from 'rxjs/Observable';
import type { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/fromPromise';
import Spinner from '@atlassian/jira-common-components-spinner';
import log from '@atlassian/jira-common-util-logging/src/log';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import {
	generatePageState,
	type UrlState,
} from '@atlassian/jira-servicedesk-legacy-bridges-common';
import { start, end } from '@atlassian/servicedesk-frontend-apdex';
import {
	getLegacyIssueView,
	type LegacyIssueView,
	type LegacyIssueViewClass,
	type Report,
} from './bridge';

type Props = {
	isInitialRender: boolean;
	issueKey: string;
	report: Report;
	pathname: string;
	onSubmitApdex: () => void;
};

type State = {
	loading: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class BridgedIssueView extends Component<Props, State> {
	static defaultProps = {
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onSubmitApdex: () => {},
	};

	state = {
		loading: true,
	};

	componentDidMount() {
		this.requestForBridgedView();
	}

	shouldComponentUpdate({ issueKey: nextKey }: Props, { loading: nextLoading }: State) {
		const { issueKey } = this.props;
		const { loading } = this.state;

		return nextKey !== issueKey || nextLoading !== loading;
	}

	componentDidUpdate({ issueKey: prevIssueKey }: Props) {
		const { issueView } = this;
		const { issueKey: key, report } = this.props;
		const hasIssueKeyChanged = prevIssueKey !== key;

		// guard in case of a force update
		if (hasIssueKeyChanged) {
			if (issueView) {
				issueView.render({ key, report });
			} else {
				this.bridgeSubscription.unsubscribe();
				this.requestForBridgedView();
			}
		}
	}

	componentWillUnmount() {
		const { bridgeSubscription, issueView } = this;

		if (bridgeSubscription) {
			bridgeSubscription.unsubscribe();
		}

		if (issueView) {
			issueView.dismiss();
		}
	}

	requestForBridgedView() {
		const { onSubmitApdex, pathname, isInitialRender } = this.props;
		this.bridgeSubscription = Observable.fromPromise(getLegacyIssueView()).subscribe(
			(IssueView: LegacyIssueViewClass) => {
				let generatedPageState: UrlState;
				if (!onSubmitApdex && pathname) {
					const jsdApdexSanitizedPathname = pathname.replace('/jira/servicedesk', '');
					generatedPageState = generatePageState({
						newRoute: jsdApdexSanitizedPathname,
						isSettings: false,
					});
					start(generatedPageState, isInitialRender);
				}

				this.setState({
					loading: false,
				});
				const { report, issueKey: key } = this.props;
				const { current: el } = this.wrapperRef;

				if (el) {
					// @ts-expect-error - TS7009 - 'new' expression, whose target lacks a construct signature, implicitly has an 'any' type.
					this.issueView = new IssueView();
					this.issueView
						.setElement(el)
						.render({ key, report })
						.then(() => {
							if (onSubmitApdex) {
								onSubmitApdex();
							} else {
								generatedPageState && end(generatedPageState);
							}
						});
				}
			},
			(error: string) => {
				const errMessage = `Reports Legacy issue view has failed to load: ${error}`;
				fireErrorAnalytics({
					meta: {
						id: 'legacyIssueViewLoad',
						packageName: 'jiraServicedeskReportsLegacyIssueView',
					},
					error: new Error(errMessage),
				});
				log.safeErrorWithoutCustomerData('servicedesk.reports.legacy-issue-view.view', errMessage);
			},
		);
	}

	// @ts-expect-error - TS2564 - Property 'bridgeSubscription' has no initializer and is not definitely assigned in the constructor.
	bridgeSubscription: Subscription;

	wrapperRef = createRef<Element>();

	// @ts-expect-error - TS2564 - Property 'issueView' has no initializer and is not definitely assigned in the constructor.
	issueView: LegacyIssueView;

	render() {
		const ReportsLegacyIssueView = (
			<>
				{this.state.loading && <Spinner />}
				{/* @ts-expect-error - TS2322 - Type 'RefObject<Element>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
				<div ref={this.wrapperRef} />
			</>
		);

		return (
			<JSErrorBoundary
				id="reportsLegacyIssueViewApp"
				packageName="jiraServicedeskReportsLegacyIssueView"
				fallback="flag"
			>
				{ReportsLegacyIssueView}
			</JSErrorBoundary>
		);
	}
}
