import type { CmdbObject } from '@atlassian/jira-servicedesk-insight-shared-types';

const baseKeys = [
	'id',
	'workspaceId',
	'objectId',
	'objectKey',
	'label',
	'avatar',
	'objectType',
	'links',
	'attributes',
];

/**
 * Filter for enriched Insight objects from the list of objects.
 * We need to do this because an Insight field's objects sometimes contains deleted or unenriched objects
 * that only has the object IDs.
 *
 * @param {CmdbObject[]} values
 * @returns Enriched cmdb objects that have all the keys
 */
export const filterEnrichedObjects = (values: CmdbObject[]): CmdbObject[] => {
	const isEnrichedObject = (value: CmdbObject): boolean => {
		const keys = Object.keys(value);
		return baseKeys.every((key) => keys.includes(key));
	};
	return values.filter(isEnrichedObject);
};

export const sortCmdbObjects = (unsortedObjects: CmdbObject[]): CmdbObject[] =>
	unsortedObjects.sort((objectOne, objectTwo) => {
		if (objectOne.label.toLowerCase() < objectTwo.label.toLowerCase()) {
			return -1;
		}
		if (objectOne.label.toLowerCase() > objectTwo.label.toLowerCase()) {
			return 1;
		}
		return 0;
	});
