import React from 'react';
import type { ColumnId } from '../../../../../model/columns';
import ColumnWrapper from '../common/column-wrapper';

type Props = {
	id: ColumnId;
};

export default function DynamicColumnHeader(props: Props) {
	const { id } = props;
	return <ColumnWrapper id={id} />;
}
