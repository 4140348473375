import { batchUpdateRowHeightMapping } from '../../../state/internal/actions';
import { defineTransaction } from '../../common/transactions';
import { BATCH_UPDATE_ROW_HEIGHT_MAPPING, type BatchUpdateRowHeightMappingAction } from './action';

const batchUpdateRowHeightMappingEpic = defineTransaction(
	BATCH_UPDATE_ROW_HEIGHT_MAPPING,
	(action: BatchUpdateRowHeightMappingAction) =>
		batchUpdateRowHeightMapping(action.payload.rowIds, action.payload.rowHeightMapping),
);

export default batchUpdateRowHeightMappingEpic;
