import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorHeader: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'servicedesk.queues.agent-view.layout.queues-error-boundary.error-header',
		defaultMessage: "We couldn't load your queue",
		description:
			'Header of error message shown to user when the queues page fails to properly load.',
	},
	errorMessage: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'servicedesk.queues.agent-view.layout.queues-error-boundary.error-message',
		defaultMessage: 'Check your network connection, then try loading the queue again.',
		description: 'Error message shown to user when the queues page fails to properly load.',
	},
	retry: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'servicedesk.queues.agent-view.layout.queues-error-boundary.retry',
		defaultMessage: 'Reload queue',
		description: 'Text shown on a button that will reload the current queues page when clicked.',
	},
});
