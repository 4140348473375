import React from 'react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	injectIntlV2 as injectIntl,
	type IntlShapeV2 as IntlShape,
	type MessageDescriptorV2 as MessageDescriptor,
} from '@atlassian/jira-intl';
import Alert from '../common/alert';
import messages from './messages';

type Props = {
	isLastColumn: boolean;
	message: string | MessageDescriptor;
	intl: IntlShape;
};

function Error(props: Props) {
	const {
		isLastColumn,
		message,
		intl: { formatMessage },
	} = props;

	const tooltipMessage = typeof message === 'string' ? message : formatMessage(message);

	return (
		<Alert
			isLastColumn={isLastColumn}
			tooltipMessage={tooltipMessage}
			Icon={ErrorIcon}
			color={token('color.icon.danger', colors.R400)}
			backgroundColor={token('color.background.danger', 'rgb(239, 239, 239)')}
		/>
	);
}

Error.defaultProps = {
	isLastColumn: false,
	message: messages.errorMessage,
};

export default injectIntl(Error);
