import { createSelector } from 'reselect';
import {
	getCloudId,
	getFabricPfDirUrl,
	getAtlassianAccountId,
} from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';

export const getIssueTableAppProps = createSelector(
	getCloudId,
	getFabricPfDirUrl,
	getAtlassianAccountId,
	(cloudId, fabricPfDirUrl, atlassianAccountId) => ({
		cloudId,
		fabricPfDirUrl,
		atlassianAccountId,
	}),
);
