import { connect } from '../../../common/table-redux';
import { isContentStale, shouldHideLoadingIndicator } from '../../../state/consumer/selectors';
import { hasHorizontalScrollbar } from '../../../state/selectors';
import type { State } from '../../../state/types';
import Content from './view';

export default connect(
	(state: State) => ({
		isHorizontalScrollVisible: hasHorizontalScrollbar(state),
		isContentStale: isContentStale(state),
		shouldHideLoadingIndicator: shouldHideLoadingIndicator(state),
	}),
	{},
)(Content);
