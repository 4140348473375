import {
	transformAggFieldToLegacyField,
	type AggField,
} from '@atlassian/jira-issue-agg-field-transformers';
import {
	DATE_PICKER_GQL_FIELD,
	PEOPLE_GQL_FIELD,
	NUMBER_GQL_FIELD,
	PRIORITY_GQL_FIELD,
	USER_PICKER_GQL_FIELD,
	SPRINT_GQL_FIELD,
	TEXT_GQL_FIELD,
	PARENT_GQL_FIELD,
	PROJECT_GQL_FIELD,
	DATE_TIME_GQL_FIELD,
	URL_GQL_FIELD,
	SECURITY_GQL_FIELD,
} from '@atlassian/jira-platform-field-config';
import type { AggFieldChangeProps, FieldValue } from '../../types';
import { transformAggParentFieldValueToLegacyFieldValue } from './parent';

type TransformAggProps = {
	fieldValue: FieldValue;
	aggFieldChangeProps: AggFieldChangeProps;
};

export const transformAggToLegacy = ({ fieldValue, aggFieldChangeProps }: TransformAggProps) => {
	if (!aggFieldChangeProps?.__typename) {
		throw new Error('node did not have __typename');
	}

	switch (aggFieldChangeProps.__typename) {
		// These fields require context for their transform in transformAggFieldToLegacyField so need to be manually implemented
		// This is not an exhaustive list, I did a quick check is all, so best to check the transform works as expected as you migrate fields to relay
		// These are commented out because they are not implemented and due to types can't just be added here until implemented
		// case JSM_PEOPLE_GQL_FIELD:
		// case ISSUE_RESTRICTION_GQL_FIELD:
		// case MULTI_USER_PICKER_GQL_FIELD:
		case PEOPLE_GQL_FIELD:
			throw new Error(
				'Requires manual implementation as the transformer package require context for this field aka all issue fields',
			);

		// otherwise we can default to the existing package
		case SECURITY_GQL_FIELD:
		case NUMBER_GQL_FIELD:
		case TEXT_GQL_FIELD:
		case USER_PICKER_GQL_FIELD:
		case PRIORITY_GQL_FIELD:
		case URL_GQL_FIELD:
		case DATE_PICKER_GQL_FIELD:
		case PROJECT_GQL_FIELD:
		case DATE_TIME_GQL_FIELD:
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return transformAggFieldToLegacyField({
				[getValueKey(aggFieldChangeProps.__typename)]: fieldValue,
				__typename: aggFieldChangeProps.__typename,
				type: aggFieldChangeProps.type,
			} as AggField)?.value;

		case PARENT_GQL_FIELD:
			return transformAggParentFieldValueToLegacyFieldValue(fieldValue);
		case SPRINT_GQL_FIELD:
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return transformAggFieldToLegacyField({
				__typename: aggFieldChangeProps.__typename,
				type: aggFieldChangeProps.type,
				...fieldValue,
			} as AggField)?.value;

		default:
			throw new Error('__typename not implemented');
	}
};

const getValueKey = (__typename: string) => {
	const VALUE_KEYS: { [key: string]: string } = {
		[TEXT_GQL_FIELD]: 'text',
		[NUMBER_GQL_FIELD]: 'number',
		[PRIORITY_GQL_FIELD]: 'priority',
		[USER_PICKER_GQL_FIELD]: 'user',
		[DATE_PICKER_GQL_FIELD]: 'date',
		[PROJECT_GQL_FIELD]: 'project',
		[DATE_TIME_GQL_FIELD]: 'dateTime',
		[URL_GQL_FIELD]: 'uri',
		[SECURITY_GQL_FIELD]: 'securityLevel',
	};
	const value = VALUE_KEYS[__typename];
	if (value) {
		return value;
	}
	throw new Error(
		`Did you forget to implement getValueKey for your field type? Couldn't find a value in the map for ${__typename}`,
	);
};
