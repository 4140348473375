import React, { type ComponentType, useState } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import {
	ASSIGNED_ISSUE_ACTION,
	VIEW_PROFILE_ACTION,
} from '@atlassian/jira-profilecard-next/src/async';
import type { ProfileCardProps } from '@atlassian/jira-profilecard-next/src/main';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller';
import type { UserProps } from '../../../../../model/fields/json-fields/common/types';
import messages from '../user-select/messages';

type Props = {
	isLastColumn: boolean;
	isButtonDisabled: boolean;
	assignee: UserProps | undefined;
	overrideDisabledAppearance: boolean;
	onClick: () => void;
	onMouseEnter: () => void;
	intl: IntlShape;
	ProfileCard?: ComponentType<ProfileCardProps>;
	accountId?: string;
};

const AssigneeButton = ({
	isLastColumn,
	assignee,
	intl,
	overrideDisabledAppearance,
	accountId,
	ProfileCard,
	...rest
}: Props) => {
	const [isHover, setIsHover] = useState<boolean>(false);
	const setIsHoverToTrue = () => setIsHover(true);
	const setIsHoverToFalse = () => setIsHover(false);
	const assigneeName = assignee
		? assignee.displayName || assignee.emailAddress
		: intl.formatMessage(messages.unassigned);
	return (
		<Box
			xcss={wrapperBaseStyles}
			paddingInlineEnd={isLastColumn ? 'space.100' : 'space.0'}
			onMouseEnter={setIsHoverToTrue}
			onMouseLeave={setIsHoverToFalse}
		>
			{ff('a11y-fission-queues-related-issues_giuuk') ? (
				<StyledButton {...rest} isHovered={isHover} aria-label={`${assigneeName} - edit Assignee`}>
					{accountId && ProfileCard ? (
						<ProfileCard
							accountId={accountId}
							TenantContextSubscriber={TenantContextSubscriber}
							actions={[VIEW_PROFILE_ACTION, ASSIGNED_ISSUE_ACTION]}
						>
							<Avatar
								src={assignee && assignee.avatarUrl}
								size="small"
								borderColor="transparent"
								isDisabled={!overrideDisabledAppearance && rest.isButtonDisabled}
							/>
						</ProfileCard>
					) : (
						<Avatar
							src={assignee && assignee.avatarUrl}
							size="small"
							borderColor="transparent"
							isDisabled={!overrideDisabledAppearance && rest.isButtonDisabled}
						/>
					)}

					<Text>{assigneeName}</Text>
				</StyledButton>
			) : (
				<StyledButton {...rest} isHovered={isHover}>
					<Avatar
						src={assignee && assignee.avatarUrl}
						size="small"
						borderColor="transparent"
						isDisabled={!overrideDisabledAppearance && rest.isButtonDisabled}
					/>
					<Text>
						{assignee
							? assignee.displayName || assignee.emailAddress
							: intl.formatMessage(messages.unassigned)}
					</Text>
				</StyledButton>
			)}
		</Box>
	);
};

AssigneeButton.defaultProps = {
	isLastColumn: false,
	isButtonDisabled: false,
	onClick: noop,
	onMouseEnter: noop,
	overrideDisabledAppearance: false,
};

export default AssigneeButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledButton = styled.button<{ isButtonDisabled: boolean; isHovered?: boolean }>({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isButtonDisabled, isHovered }) =>
		!isButtonDisabled && isHovered
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.neutral.hovered', colors.N30)
			: 'transparent',
	border: 0,
	borderRadius: 3,
	padding: `0 ${token('space.050', '4px')}`,
	width: '100%',
	height: token('space.400', '32px'),
	overflowX: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isButtonDisabled }) => (isButtonDisabled ? { cursor: 'default' } : 'pointer'),
	/* CSS reset to Atlaskit defaults */
	color: 'inherit',

	fontSize: token('font.body'),
	outline: 'none',
});

const wrapperBaseStyles = xcss({
	paddingTop: 'space.050',
	paddingRight: 'space.0',
	paddingBottom: 'space.050',
	paddingLeft: 'space.0',
	display: 'flex',
	textAlign: 'left',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.span({
	flex: 1,
	lineHeight: 1.4,
	paddingLeft: token('space.100', '8px'),
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	textAlign: 'left',
});
