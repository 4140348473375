import { createSelector } from 'reselect';
import { getSafeViewMode } from '@atlassian/jira-issue-view-common-utils';
import {
	applicationKeySelector,
	viewModeSelector,
	issueIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';

export const metricsStateSelector = createSelector(
	applicationKeySelector,
	viewModeSelector,
	issueIdSelector,
	(analyticsKey, viewMode, issueId) => ({
		analyticsKey,
		viewMode: getSafeViewMode(viewMode),
		issueId,
	}),
);
