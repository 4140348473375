import type { ComponentType, KeyboardEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ColumnId } from '../columns';
import type { Identifier } from '../identifiers';
import type { Optional } from '../optional';
import type { TreeNode } from '../tree';

export type RowId = Identifier;

export type Row = TreeNode<RowId> & {
	id: RowId;
	childrenIds: RowId[];
};

export type RowConfiguration = {
	isDraggable?: boolean;
	dragIndicatorColor?: string;
	canAddChildren?: boolean;
	canAddSibling?: boolean;
	isLoading?: boolean;
	isSelected?: boolean;
};

export type RowsConfiguration = Record<RowId, RowConfiguration>;

export type RowAddState = 'NEW' | 'PERSISTING' | 'PERSISTED';

export type RowTree = {
	rows: Record<RowId, Row>;
	rootIds: RowId[];
};

export type RowIdHash = Record<RowId, boolean>;

export const FIRST_POSITION = 'FIRST';
export const LAST_POSITION = 'LAST';
export const BEFORE_POSITION = 'BEFORE';
export const AFTER_POSITION = 'AFTER';
export const INSIDE_POSITION = 'INSIDE';

export type LoadingRowUnmountCallback = (
	arg1: number,
	arg2: number,
	arg3: RowId,
	arg4: number,
	arg5: UIAnalyticsEvent,
) => void;

export type RowAnalyticsData = {
	displayStart: number;
	displayEnd: number;
	mountBeginTime: number;
	mountEndTime: number;
	columns: ColumnId[];
	numberOfColumns: number;
	numberOfRows: number;
	analyticsEvent: UIAnalyticsEvent;
};
export type RowContentMountCallback = (
	arg1: number,
	arg2: number,
	arg3: ColumnId[],
	arg4: number,
	arg5: UIAnalyticsEvent,
) => void;

export type RowContentUpdateCallback = (arg1: RowId, arg2: number) => void;

export type OnFocusCallback = () => void;
export type OnClickCallback = (arg1: KeyboardEvent<HTMLDivElement>, arg2: UIAnalyticsEvent) => void;

export const IS_LOADING = 'IS_LOADING' as const;
export const DELAYED_RENDER = 'DELAYED_RENDER' as const;
export type LoadingRowRenderReasonType = typeof IS_LOADING | typeof DELAYED_RENDER;

export type LoadingRowProps = {
	id: RowId;
	rowIndex: number;
	reasonType: LoadingRowRenderReasonType;
	onRowContentUpdate: RowContentUpdateCallback;
	onUnmount: LoadingRowUnmountCallback;
	onFocusCallback: OnFocusCallback;
	onClick: OnClickCallback;
};
export type LoadingRowComponent = ComponentType<LoadingRowProps>;

export type LoadingRowAnalyticsActionPayload = {
	rowId: RowId;
	timeVisibleFor: number;
	numberOfLoadingRows: number;
	reasonType: LoadingRowRenderReasonType;
	analyticsEvent: UIAnalyticsEvent;
};

export type RelativePosition =
	| typeof BEFORE_POSITION
	| typeof AFTER_POSITION
	| typeof INSIDE_POSITION;
export type AbsolutePosition = typeof FIRST_POSITION | typeof LAST_POSITION;
export type AnchorPosition = RelativePosition | AbsolutePosition;
type Anchored = {
	anchorId?: RowId;
	position: AnchorPosition;
};

export type TemporaryAddedRow = {
	temporaryId: RowId;
	isPersisting?: boolean;
} & Anchored;

export type PersistedAddedRow = {
	temporaryId: RowId;
} & Anchored;

export type PersistedAddedRows = Record<RowId, PersistedAddedRow>;

export type AddedRows = {
	temporary: Optional<TemporaryAddedRow>;
	persisted: Optional<PersistedAddedRows>;
};

export type AddConfiguration = Anchored;

export type ExpandConfiguration = {
	rowId: RowId;
	shouldExpand: boolean;
};

export const sortedRowIdsHashRootId = 'ROOT' as const;

export type SortedRowIdsHash = Record<RowId, RowId[]>;

export type RowHeightMapping = Record<RowId, number>;
