// @ts-expect-error - TS2305 - Module '"monet"' has no exported member 'MaybeType'.
import { Maybe, type MaybeType } from 'monet';
import type { UpdateMetadata } from '../../reducers/persisted/update-metadata/types';
import type { State } from '../../reducers/types';
import { getPersisted } from '../common';

const maybeUpdateMetadata = (state: State): MaybeType<UpdateMetadata> =>
	Maybe.fromNull(getPersisted(state).updateMetadata);

export const getProjectStateHash = (state: State): string =>
	maybeUpdateMetadata(state)
		// @ts-expect-error - TS7006 - Parameter 'updateMetadata' implicitly has an 'any' type.
		.flatMap((updateMetadata) => Maybe.fromNull(updateMetadata.projectStateHash))
		.orSome('');

export const getIssueHash = (state: State): string =>
	maybeUpdateMetadata(state)
		// @ts-expect-error - TS7006 - Parameter 'updateMetadata' implicitly has an 'any' type.
		.flatMap((updateMetadata) => Maybe.fromNull(updateMetadata.issueHash))
		.orSome('');
