import type { IssueKey } from '@atlassian/jira-shared-types';

// FETCH_ISSUE_REMOTE_DATA_REQUEST
export const FETCH_ISSUE_REMOTE_DATA_REQUEST = 'FETCH_ISSUE_REMOTE_DATA_REQUEST' as const;

export const fetchIssueRemoteDataRequest = (issueKey: IssueKey) => ({
	type: FETCH_ISSUE_REMOTE_DATA_REQUEST,
	payload: issueKey,
});

// FETCH_ISSUE_REMOTE_DATA_SUCCESS
export const FETCH_ISSUE_REMOTE_DATA_SUCCESS = 'FETCH_ISSUE_REMOTE_DATA_SUCCESS' as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchIssueRemoteDataSuccess = (entities: any) => ({
	type: FETCH_ISSUE_REMOTE_DATA_SUCCESS,
	payload: entities,
});

export type FetchIssueRemoteDataSuccess = ReturnType<typeof fetchIssueRemoteDataSuccess>;

// FETCH_ISSUE_REMOTE_DATA_FAILURE
export const FETCH_ISSUE_REMOTE_DATA_FAILURE = 'FETCH_ISSUE_REMOTE_DATA_FAILURE' as const;

export const fetchIssueRemoteDataFailure = () => ({
	type: FETCH_ISSUE_REMOTE_DATA_FAILURE,
});

export type FetchIssueRemoteDataFailure = ReturnType<typeof fetchIssueRemoteDataFailure>;
