import React, { type ReactNode } from 'react';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing';
import {
	type QueueId,
	fromQueueIdString,
} from '@atlassian/jira-servicedesk-queues-common/src/model';
import { JIRA_SERVICE_DESK } from '@atlassian/jira-shared-types';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller';
import ConnectedTracker from '../tracker';

export type Props = {
	isQueueVisible: boolean;
	queueId: QueueId;
	children: ReactNode;
};

export default function Provider(props: Props) {
	const { queueId, children, isQueueVisible } = props;

	return (
		<TenantContextSubscriber>
			{({ tenantContext }) => (
				<ViewExperienceTrackingProvider
					analyticsSource="queues"
					experience="viewQueue"
					experienceId={fromQueueIdString(queueId)}
					application={JIRA_SERVICE_DESK}
					edition={tenantContext.appEditions.serviceDesk}
					isExperienceReady={isQueueVisible}
				>
					<ConnectedTracker />
					{children}
				</ViewExperienceTrackingProvider>
			)}
		</TenantContextSubscriber>
	);
}
