import getMeta from '@atlassian/jira-get-meta';
import { getAnalyticsWebClient } from '@atlassian/jira-product-analytics-web-client';
import type { IssueListResponse } from '@atlassian/jira-servicedesk-queues-common/src/rest/issue/types';

const STALENESS_THRESHOLD_FOR_FETCH = 60 * 60 * 1000;
const STALENESS_THRESHOLD_FOR_UPDATE = 3 * 60 * 1000;

// @ts-expect-error - TS7006 - Parameter 'payload' implicitly has an 'any' type.
const fireManualOperationalAnalytics = (payload) =>
	getAnalyticsWebClient().getInstance().sendOperationalEvent(payload);

export const sendIssuelistStalenessAnalytics = (
	result: IssueListResponse,
	isUpdate: boolean,
): void => {
	const { metadata } = result;
	const currentTimeInMs = Date.now();
	const delayInMs = currentTimeInMs - metadata.lastUpdatedTimeInMs;
	const thresholdInMs = isUpdate ? STALENESS_THRESHOLD_FOR_UPDATE : STALENESS_THRESHOLD_FOR_FETCH;
	const jiraShard = getMeta('ajs-shard') || '';

	fireManualOperationalAnalytics({
		source: 'viewQueueScreen',
		actionSubject: 'issuelist',
		action: isUpdate ? 'updated' : 'fetched',
		attributes: {
			issuelistStale: delayInMs > thresholdInMs,
			delayInMs,
			currentTimeInMs,
			thresholdInMs,
			browserTabVisibilityState: document.visibilityState,
			jiraShard,
			...metadata,
		},
	});
};
