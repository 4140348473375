import React, { type Context, type FC, createContext, useContext } from 'react';
import type { CompiledTheme } from '../../../model/themes';
import { compiledThemeWithProps } from '../../../view/themes';
import theme from '../../table/theme';
/*
 * This is to replace the styledComponent theme provider as part of the compiled
 * css migration at https://hello.jira.atlassian.cloud/browse/ENGHEALTH-4743.
 * Before the migration, styled-components/ThemeProvider was used at
 * jira/src/packages/servicedesk/virtual-table/src/app/table/index.tsx
 * to inject StyledTheme context for the styledComponents to consume and after
 * the migration below theme context is used instead.
 *
 * */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const ThemeContext: Context<CompiledTheme> = createContext({} as CompiledTheme);

interface Props {
	children: React.ReactNode;
	defaultRowHeight: number;
	autoRowHeight: boolean;
	customScrollBarOffset?: number;
}

// Custom hook to use the theme
export const useTheme = () => useContext(ThemeContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withTheme(Component: React.ComponentType<any>) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (props: any) => (
		<ThemeContext.Consumer>
			{(defaultTheme) => <Component {...props} theme={defaultTheme} />}
		</ThemeContext.Consumer>
	);
}

export const ThemeProvider: FC<Props> = ({
	children,
	defaultRowHeight,
	autoRowHeight,
	customScrollBarOffset,
}) => (
	<ThemeContext.Provider
		value={compiledThemeWithProps(theme, defaultRowHeight, autoRowHeight, customScrollBarOffset)}
	>
		{children}
	</ThemeContext.Provider>
);
