import { createSelector } from 'reselect';
import find from 'lodash/find';
// @ts-expect-error - TS2305 - Module '"monet"' has no exported member 'MaybeType'.
import { Maybe, type MaybeType } from 'monet';
import type { ColumnProps } from '../../../model';
import type { SortOrder } from '../../../model/fields/sort-order';
import type { State } from '../../reducers/types';
import { getPersisted } from '../common';

export const getColumns = (state: State): ColumnProps[] => getPersisted(state).columns;

export const maybeColumn = (state: State, columnId: string): MaybeType<ColumnProps> =>
	Maybe.fromNull(find(getColumns(state), { id: columnId }));

export const maybeColumnTitle = (state: State, columnId: string): MaybeType<string> =>
	// @ts-expect-error - TS7006 - Parameter 'column' implicitly has an 'any' type.
	maybeColumn(state, columnId).map((column) => column.title);

export const getColumnTitle = (state: State, columnId: string): string =>
	maybeColumnTitle(state, columnId).orSome('');

export const getCurrentSorting = (state: State, columnId: string): SortOrder | undefined =>
	maybeColumn(state, columnId)
		// @ts-expect-error - TS7006 - Parameter 'column' implicitly has an 'any' type.
		.flatMap((column) => Maybe.fromNull(column.currentSorting))
		.cata(
			() => undefined,
			// @ts-expect-error - TS7006 - Parameter 'order' implicitly has an 'any' type.
			(order) => order,
		);

export const isDisabled = (state: State, columnId: string): boolean =>
	maybeColumn(state, columnId)
		// @ts-expect-error - TS7006 - Parameter 'column' implicitly has an 'any' type.
		.flatMap((column) => Maybe.fromNull(column.isDisabled))
		.cata(
			() => false,
			// @ts-expect-error - TS7006 - Parameter 'disabled' implicitly has an 'any' type.
			(disabled) => disabled,
		);

export const getFieldType = (state: State, columnId: string): string =>
	maybeColumn(state, columnId)
		// @ts-expect-error - TS7006 - Parameter 'column' implicitly has an 'any' type.
		.map((column) => column.fieldType)
		.orSome('');

export const maybeOnSortOrderChanged = (
	state: State,
	columnId: string,
): MaybeType<(sortedBy: string, sortOrder: SortOrder) => void> =>
	// @ts-expect-error - TS7006 - Parameter 'column' implicitly has an 'any' type.
	maybeColumn(state, columnId).flatMap((column) => Maybe.fromNull(column.onSortOrderChanged));

export const getOnSortOrderChanged = (
	state: State,
	columnId: string,
): ((sortedBy: string, sortOrder: SortOrder) => void) | undefined =>
	maybeOnSortOrderChanged(state, columnId).cata(
		() => undefined,
		// @ts-expect-error - TS7006 - Parameter 'callback' implicitly has an 'any' type.
		(callback) => callback,
	);

export const isLastColumn = (state: State, columnId: string): boolean => {
	const columns = getColumns(state);
	return columns.length > 0 && columns[columns.length - 1].id === columnId;
};

const indexColumnIds = createSelector(
	getColumns,
	(columns) => new Set(columns.map((column) => column.id)),
);

export const isColumnKnown = (state: State, columnId: string): boolean =>
	indexColumnIds(state).has(columnId);
