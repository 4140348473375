import React from 'react';
import { styled } from '@compiled/react';
import { lazy } from 'react-loosely-lazy';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import type UserPicker from '@atlaskit/user-picker';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';

type UserPickerProps = React.ComponentProps<typeof UserPicker>;
type ErrorProps = { onError: () => void };

type CombinedProps = UserPickerProps & ErrorProps;

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyUserPicker = lazy<typeof UserPicker>(
	() => import(/* webpackChunkName: "async-issue-table-user-picker" */ '@atlaskit/user-picker'),
);

const AsyncUserPicker = (props: CombinedProps) => {
	const { onError, ...userPickerProps } = props;

	return (
		<JSErrorBoundary
			id="async-issue-table-user-picker"
			packageName="jiraIssueTable"
			onError={onError}
		>
			<Placeholder
				name="user-picker"
				fallback={
					<SpinnerWrapper>
						<Spinner size="small" />
					</SpinnerWrapper>
				}
			>
				<UserPickerWrapper>
					<LazyUserPicker {...userPickerProps} />
				</UserPickerWrapper>
			</Placeholder>
		</JSErrorBoundary>
	);
};

export { AsyncUserPicker as default };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	textAlign: 'center',
	position: 'relative',
	top: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UserPickerWrapper = styled.div({
	/* FIXME: this style override is required for the placeholder styling to be compatible with react-select v4.
    Once JFE resolves to react-select v5, this style override can be removed (aka when the resolution for @atlaskit/select
    in the root package.json will be removed: https://ecosystem-platform.atlassian.net/browse/UNBOX-685). */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fabric-user-picker__value-container': {
		display: 'grid',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.fabric-user-picker__placeholder': {
			position: 'relative',
			top: 'initial',
			left: 'initial',
			transform: 'initial',
		},
	},
});
