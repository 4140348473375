import React from 'react';
import ErrorState from '@atlassian/jira-common-components-error-state';
import { injectIntlV2 as injectIntl, type IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	isLoading: boolean;
	onRetry: () => void;
	intl: IntlShape;
};

function Error(props: Props) {
	const {
		isLoading,
		onRetry,
		intl,
		intl: { formatMessage },
	} = props;

	return (
		<ErrorState
			// @ts-expect-error - TS2322 - Type '{ intl: IntlShapeV2; isLoading: boolean; onRetry: () => void; header: string; description: string; }' is not assignable to type 'IntrinsicAttributes & Omit<Pick<Props, "description" | "intl" | "onRetry" | "imageWidth" | "maxImageHeight" | "errorImage"> & Partial<Pick<Props, "header" | ... 3 more ... | "isSubtle">> & Partial<...>, "intl"> & { ...; } & { ...; }'.
			intl={intl}
			isLoading={isLoading}
			onRetry={onRetry}
			header={formatMessage(messages.errorHeader)}
			description={formatMessage(messages.errorMessage)}
		/>
	);
}

Error.defaultProps = {
	isLoading: false,
};

export default injectIntl(Error);
