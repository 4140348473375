import { getConsumerState } from '../../../../state/consumer/selectors';
import { getAddedRows, getRowTree } from '../../../../state/consumer/selectors/rows';
import { setSortedRowIdsHash } from '../../../../state/internal/actions';
import {
	getSortedRowIdsHash,
	getActiveSortedColumnConfiguration,
} from '../../../../state/internal/selectors';
import getSortedInternalState from '../../../common/sorting';
import { defineTransaction } from '../../../common/transactions';
import { RE_SORT, type ReSortAction } from './action';

export default defineTransaction(RE_SORT, (action: ReSortAction, state) => {
	const consumerState = getConsumerState(state);

	const sortedRowIdsHash = getSortedInternalState(
		getAddedRows(state),
		getRowTree(state),
		undefined, // ignore last sort configuration - force sorting
		false, // sort override disabled
		getActiveSortedColumnConfiguration(state),
		getSortedRowIdsHash(state),

		consumerState.addedRows,
		consumerState.rowTree,
		consumerState.columnComparators,
		consumerState.defaultComparator,
	);

	consumerState.callbacks.onSortedRowIdsChanged &&
		consumerState.callbacks.onSortedRowIdsChanged(sortedRowIdsHash);

	return [setSortedRowIdsHash(sortedRowIdsHash)] as const;
});
