/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { createContext, type ReactNode, useContext } from 'react';

import { css, jsx } from '@compiled/react';
import invariant from 'tiny-invariant';

import { useId } from '@atlaskit/ds-lib/react-uid';
import { token } from '@atlaskit/tokens';

type MenuSectionProps = {
	children?: ReactNode;
};

const MenuSectionContext = createContext<string | null>(null);

const useMenuSectionContext = () => {
	const context = useContext(MenuSectionContext);
	invariant(context, 'useMenuSectionContext must be used within a MenuSection');
	return context;
};

/**
 * __MenuSection__
 *
 * A composable component for grouping menu items, along with a heading (`MenuSectionHeading`) and a divider (`Divider`).
 *
 * Usage example:
 * ```jsx
 * <MenuSection>
 *   <MenuSectionHeading>Section heading</MenuSectionHeading>
 *   <MenuList>
 *     <MenuItem>Item 1</MenuItem>
 *     <MenuItem>Item 2</MenuItem>
 *   </MenuList>
 *   <Divider />
 * </MenuSection>
 * ```
 */
export const MenuSection = ({ children }: MenuSectionProps): JSX.Element => {
	const id = useId();

	return (
		<MenuSectionContext.Provider value={id}>
			<div role="group" aria-labelledby={`${id}-heading`}>
				{children}
			</div>
		</MenuSectionContext.Provider>
	);
};

const headingStyles = css({
	color: token('color.text.subtlest'),
	font: token('font.heading.xxsmall'),
	paddingBlock: token('space.100'),
	paddingInlineStart: token('space.075'),
});

// TODO: Fill in the component {description} and ensure links point to the correct {packageName} location.
// Remove links that the component does not have (such as usage). If there are no links remove them all.
/**
 * __Menu section heading__
 *
 * A menu section heading {description}.
 *
 * - [Examples](https://atlassian.design/components/{packageName}/examples)
 * - [Code](https://atlassian.design/components/{packageName}/code)
 * - [Usage](https://atlassian.design/components/{packageName}/usage)
 */
export const MenuSectionHeading = ({ children }: { children: ReactNode }): JSX.Element => {
	const id = useMenuSectionContext();

	return (
		<h2 css={headingStyles} id={`${id}-heading`}>
			{children}
		</h2>
	);
};
