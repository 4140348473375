import React, { type ComponentType, type ReactNode } from 'react';
import { ViewExperienceSuccessTracker } from '@atlassian/jira-common-experience-tracking-viewing';
import { setMark } from '@atlassian/jira-common-performance';
import type {
	FieldDataSelectorChildrenProps,
	FieldDataSelectorCoordinates,
	FieldDataSelectorProps,
} from '@atlassian/jira-issue-table/src';
import type { QueueId } from '@atlassian/jira-servicedesk-queues-common/src/model';
import type { ChangeIssueFieldActionDispatcher } from '@atlassian/jira-servicedesk-queues-common/src/state/actions/issue/field';
import { SpaStatePageReadyOnUpdate } from '@atlassian/jira-spa-state-controller/src/components';
import { AgentTableApdexSubmitter } from '../apdex';

type ConnectedProps = {
	onFieldDataSelection: (
		arg1: FieldDataSelectorCoordinates,
		arg2: ChangeIssueFieldActionDispatcher,
	) => FieldDataSelectorChildrenProps;
	onFieldChange: ChangeIssueFieldActionDispatcher;
};

export type Props = {
	isQueueVisible: boolean;
	isFirstFieldInTable: boolean;
	isFieldToExperienceTrack: boolean;
} & FieldDataSelectorProps &
	ConnectedProps & {
		queueId: QueueId;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		ReadyForUser: ComponentType<any>;
	};

const renderWithExperienceTracker = (
	node: ReactNode,
	{ isFieldToExperienceTrack, fieldDescriptor: { fieldType } }: Props,
) =>
	isFieldToExperienceTrack ? (
		<ViewExperienceSuccessTracker location={`queue-cell-${fieldType}`}>
			{node}
		</ViewExperienceSuccessTracker>
	) : (
		node
	);

const renderWithReadyForUser = (
	node: ReactNode,
	{ queueId, isQueueVisible, isFirstFieldInTable }: Props,
) =>
	isQueueVisible && isFirstFieldInTable ? (
		<>
			<AgentTableApdexSubmitter />
			{node}
			<SpaStatePageReadyOnUpdate comparer={[queueId]} />
		</>
	) : (
		node
	);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const {
		onFieldChange,
		onFieldDataSelection,
		fieldDescriptor,
		issueKey,
		children,
		isQueueVisible,
		isFirstFieldInTable,
	} = props;
	const dataSelectorCoordinates = { fieldDescriptor, issueKey };
	if (isQueueVisible && isFirstFieldInTable) {
		setMark('jsd.performance.profile.queues.firstfield.render');
	}

	const childrenNode = children(onFieldDataSelection(dataSelectorCoordinates, onFieldChange));

	return <>{renderWithReadyForUser(renderWithExperienceTracker(childrenNode, props), props)}</>;
};
