import type { VirtualBoundaries } from '../../../../../../model';
import type { RowId } from '../../../../../../model/rows';

/**
 * Threshold for rows in the "buffered" region (i.e., difference of <display region> and <visible region>).
 *
 * Only the visible region will be rendered initially if more than `DEFERRED_BUFFERING_NEW_MOUNT_THRESHOLD`
 * of the "buffered" rows need to be mounted (because they were not previously rendered); rendering of the
 * buffer will be deferred.
 */
const DEFERRED_BUFFERING_NEW_MOUNT_THRESHOLD = 0.3;

export type RenderState = {
	virtualBoundaries: VirtualBoundaries;
	rowIds: RowId[];
};

const getRowsForBuffering = ({
	virtualBoundaries: { displayStart, visibleStart, visibleEnd, displayEnd },
	rowIds,
}: RenderState): RowId[] => [
	...rowIds.slice(displayStart, visibleStart),
	...rowIds.slice(visibleEnd + 1, displayEnd + 1),
];

const getPreviouslyRenderedRows = (prevRenderState: RenderState): RowId[] => {
	const {
		rowIds,
		virtualBoundaries: { displayStart, displayEnd },
	} = prevRenderState;
	return rowIds.slice(displayStart, displayEnd + 1);
};

const getBufferRowsNeedingMount = (
	nextRenderState: RenderState,
	prevRenderState: RenderState,
): RowId[] => {
	const previouslyRenderedRows = new Set(getPreviouslyRenderedRows(prevRenderState));
	return getRowsForBuffering(nextRenderState).filter((row) => !previouslyRenderedRows.has(row));
};

// Only allow deferred buffering when at the top of the row list for now.
const canDeferBuffering = (nextRenderState: RenderState): boolean => {
	const {
		virtualBoundaries: { visibleStart },
	} = nextRenderState;
	return visibleStart === 0;
};

/**
 * Predicate returning true if rendering beyond the visible area should be deferred.
 */
export const shouldDeferBuffering = (
	prevRenderState: RenderState,
	nextRenderState: RenderState,
) => {
	if (!canDeferBuffering(nextRenderState)) {
		return false;
	}

	const { visibleStart, displayStart, visibleEnd, displayEnd } = nextRenderState.virtualBoundaries;
	const requestedBufferSize = displayEnd - visibleEnd + (visibleStart - displayStart);

	if (requestedBufferSize === 0) {
		return false;
	}

	const rowsNeedingMount = getBufferRowsNeedingMount(nextRenderState, prevRenderState).length;
	return rowsNeedingMount > requestedBufferSize * DEFERRED_BUFFERING_NEW_MOUNT_THRESHOLD;
};
