import type { MiddlewareAPI } from 'redux';
import type { StandaloneCustomReporter } from '@atlassian/jira-browser-metrics/src/types';
import { ff } from '@atlassian/jira-feature-flagging';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service';
import type { ContextPanelItem } from '@atlassian/jira-issue-layout-common-constants';
import type { ContextPanel, Field } from '@atlassian/jira-issue-shared-types';
import { CONTEXT_GROUP_INITIAL_OPENED } from '@atlassian/jira-issue-view-common-constants/src/layout';
import { getChildIssuesLimitExceededAnalyticsCount } from '@atlassian/jira-issue-view-common-types/src/children-issues-type';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	EPIC_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
	SUBTASKS,
} from '@atlassian/jira-issue-view-configurations';
import {
	getGroupLocalStorageName,
	getGroupsLocalStorage,
} from '@atlassian/jira-issue-view-layout-group';
import { generateContextPanelId } from '@atlassian/jira-issue-view-layout-templates-services';
import { issueIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	childIssuesLimitUrlSelector,
	fieldSelector,
	fieldsSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { isSimplifiedProjectSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import {
	activityPanelsSelector,
	allContentPanelsSelector,
	contextPanelEntitiesSelector,
	glancesSelector,
	operationsSelector,
} from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector';
import { forgeContextPanelsSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector';
import { hierarchyLevelSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/hierarchy-level-selector';
import { ecosystemCustomFieldIdsSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/layout-items-selector';
import { issueTypeHierarchyLevelSelector } from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector';
import { totalCommentsSelector } from '@atlassian/jira-issue-view-store/src/selectors/comment-selector';
import { combinedLinkedIssueCountSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector';
import { issueTypesSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-types-selector';
import { isSubtaskTypesPresentSelector } from '@atlassian/jira-issue-view-store/src/selectors/subtask-types-selector';
import { subtaskEntitiesSelector } from '@atlassian/jira-issue-view-store/src/selectors/subtasks-selector';
import {
	CASCADING_SELECT_CF_TYPE,
	CHILDREN_ISSUES,
	PROFORMA_FORMS,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config';
import { getPermalinkTypeAndId } from '@atlassian/jira-platform-issue-permalinks/src';
import { COMMENTS } from '@atlassian/jira-platform-issue-permalinks/src/constants';

const getCascadingSelectFields = (state: State) =>
	Object.values(fieldsSelector(state)).filter(
		(fieldValues: Field) => fieldValues.schema.custom === CASCADING_SELECT_CF_TYPE,
	);

const getAtlassianTeamFields = (state: State) =>
	Object.values(fieldsSelector(state)).filter(
		(fieldValues: Field) => fieldValues.schema.custom === TEAMS_PLATFORM_CF_TYPE,
	);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCascadingSelectOptionChildrenCount = (cascadingSelectFieldValue: any[]): number => {
	if (cascadingSelectFieldValue.length === 0) {
		return 0;
	}
	return Object.values(cascadingSelectFieldValue).reduce(
		(accumulatedCount, parentValue) => accumulatedCount + (parentValue?.children?.length || 1),
		0,
	);
};

const getCascadingSelectOptionsTotalCount = (state: State): number => {
	const cascadingSelectFields = getCascadingSelectFields(state);
	if (cascadingSelectFields.length === 0) {
		return 0;
	}
	return cascadingSelectFields.reduce(
		(accumulatedCount, cascadingSelectField) =>
			accumulatedCount +
			(getCascadingSelectOptionChildrenCount(cascadingSelectField.allowedValues) || 1),
		0,
	);
};

/**
 * This calculates how many context panels are open and closed by accessing their state in local storage and returning
 * open and closed counts. It takes the provided ecosystem and forge installed plugins and removes them from the counts
 * to ensure we are only counting actual panels that are present.
 *
 * This is done so we can see if there is any correlation between TTI and number of panels open.
 *
 * @param projectKey The key of the current issue's project. Used to lookup the context panel states
 * @param ecosystem Installed ecosystem connect context panel plugins
 * @param forge Installed forge issue context panel layout items. This is a different object to ecosytem as all it cares about is ids
 */
export const calculatePanelCounts = ({
	projectKey,
	ecosystem,
	forge,
}: {
	projectKey?: string;
	ecosystem: ContextPanel[];
	forge: ContextPanelItem[];
}) => {
	let open = 0;
	let closed = 0;
	if (projectKey !== undefined) {
		// The context groups store the state of open / closed inside local storage, so we need to get access to that
		// here. It's not held in redux which is why we look it up this way.
		const groupLocalStorageName = getGroupLocalStorageName(projectKey, 'context-group');
		try {
			// It's possible panels are uninstalled and not removed from localstorage, so we need to check they are installed
			// otherwise the analytics may be incorrectly advising how many panels are open
			const installedPanels = [
				...ecosystem.map((item) => `${groupLocalStorageName}-${generateContextPanelId(item)}`),
				...forge.map((item) => `${groupLocalStorageName}-${item.id}`),
			];

			open = Object.values(
				getGroupsLocalStorage(installedPanels, CONTEXT_GROUP_INITIAL_OPENED),
			).filter(Boolean).length;

			// Panels may not exist in localStorage but may be installed, in which case they are closed as this is the default state
			closed = installedPanels.length - open;
		} catch {
			// No action required
		}
	}

	return { open, closed };
};

export const issueScaleabilityReporterFactory =
	(
		store: MiddlewareAPI<State>,
		issueContextActions?: IssueContextServiceActions,
	): StandaloneCustomReporter =>
	() => {
		const state = store.getState();
		const { permalinkType } = getPermalinkTypeAndId() || {};
		const childIssues = fieldSelector(CHILDREN_ISSUES)(state);
		const proformaHarmonisation = fieldSelector(PROFORMA_FORMS)(state);

		const { open: contextPanelOpenCount, closed: contextPanelClosedCount } = calculatePanelCounts({
			projectKey: state?.entities?.project?.projectKey,
			ecosystem: contextPanelEntitiesSelector(state),
			forge: forgeContextPanelsSelector(state),
		});

		const subtaskCount =
			childIssuesLimitUrlSelector(state)(SUBTASKS) && issueContextActions
				? getChildIssuesLimitExceededAnalyticsCount(issueContextActions.getChildIssuesLimit())
				: subtaskEntitiesSelector(state).length;

		const childIssuesCount =
			childIssuesLimitUrlSelector(state)(CHILDREN_ISSUES) && issueContextActions
				? getChildIssuesLimitExceededAnalyticsCount(issueContextActions.getChildIssuesLimit())
				: childIssues?.value.length ?? 0;

		// If a portfolio issue is over the limit we cannot tell because
		// only the subtasks panel is displayed. Once the portfolio issue
		// type hierarchy level is a value > 1 then we can set this to ">100".
		let portfolioChildIssueCount;
		let actualSubtaskCount;
		let actualChildIssueCount;
		let actualEpicIssueCount;
		let epicIssueCount;

		const subtasks = fieldSelector(SUBTASKS)(state);
		const epicIssues = fieldSelector(EPIC_ISSUES)(state);
		const portfolioChildIssues = fieldSelector(PORTFOLIO_CHILD_ISSUES)(state);

		const getChildIssuesLimitUrl = childIssuesLimitUrlSelector(state);
		const isEpicIssuesOverLimit = Boolean(getChildIssuesLimitUrl(EPIC_ISSUES));
		const isOverLimit =
			Boolean(getChildIssuesLimitUrl(SUBTASKS)) ||
			Boolean(getChildIssuesLimitUrl(CHILDREN_ISSUES)) ||
			isEpicIssuesOverLimit;

		if (isOverLimit) {
			actualChildIssueCount = childIssues?.originalContentLength ?? 0;
			actualEpicIssueCount = epicIssues?.originalContentLength ?? 0;
			// If epic issue is over the limit, we exclude the subtasks field
			// from the store. So we need to grab the data from the epicIssues field.
			actualSubtaskCount = isEpicIssuesOverLimit
				? epicIssues?.otherOriginalChildIssuesLength ?? 0
				: subtasks?.originalContentLength ?? 0;

			epicIssueCount =
				isEpicIssuesOverLimit && issueContextActions
					? getChildIssuesLimitExceededAnalyticsCount(issueContextActions.getChildIssuesLimit())
					: epicIssues?.originalContentLength ?? 0;

			// If portfolio issue is over the limit, we exclude the portfolio-child-issues field
			// from the store. So we need to grab the data from the subtasks field.
			portfolioChildIssueCount = subtasks?.otherOriginalChildIssuesLength ?? 0;
		} else {
			actualChildIssueCount = childIssues?.value.length ?? 0;
			actualSubtaskCount = subtasks?.value.length ?? 0;
			actualEpicIssueCount = epicIssues?.value.length ?? 0;
			portfolioChildIssueCount = portfolioChildIssues?.value.length ?? 0;
			epicIssueCount = actualEpicIssueCount;
		}

		// Properties added here show up in:
		// - jira.fe.custom.issue-view-on-page-load
		// - jira.fe.page-load.issue-view
		// - and jira.fe.custom.embedded-issue-view-load
		// Verify these in the BM3 tab of the Chrome Analytics extension.
		return {
			ecosystemGlances: glancesSelector(state).length,
			ecosystemContentPanels: allContentPanelsSelector(state).length,
			contextPanelOpenCount,
			contextPanelClosedCount,
			ecosystemActivityPanels: activityPanelsSelector(state).length,
			...(!ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w')
				? { ecosystemOperations: operationsSelector(state).length }
				: {}),

			ecosystemCustomsFields: ecosystemCustomFieldIdsSelector(state).length,
			childIssues: childIssuesCount,
			linkIssueCount: combinedLinkedIssueCountSelector(state),
			subtaskCount,
			actualChildIssueCount,
			actualSubtaskCount,
			actualEpicIssueCount,
			actualPortfolioChildIssueCount: portfolioChildIssueCount,
			epicIssueCount,
			portfolioChildIssueCount,
			issueId: issueIdSelector(state),
			commentsTotalCount: totalCommentsSelector(state),
			hasCommentPermalink: permalinkType === COMMENTS,
			cascadingSelectCount: getCascadingSelectFields(state).length,
			isAtlassianTeamFieldEnabled: getAtlassianTeamFields(state).length > 0,
			cascadingSelectOptionsTotalCount: getCascadingSelectOptionsTotalCount(state),
			proformaHarmonisationEnabled: proformaHarmonisation
				? proformaHarmonisation.value.isHarmonisationEnabled
				: false,
			hasSubtasksTypes: isSubtaskTypesPresentSelector(state),
			issueTypesCount: issueTypesSelector(state).length,
			issueTypeHierarchyLevel: !isSimplifiedProjectSelector(state)
				? issueTypeHierarchyLevelSelector(state)
				: hierarchyLevelSelector(state),
		};
	};
