// eslint-disable-next-line jira/restricted/react
import { PureComponent } from 'react';
import isEmpty from 'lodash/isEmpty';
import type { QueueId } from '@atlassian/jira-servicedesk-queues-common/src/model';
import type {
	OnQueueIssueCountUpdatedCallback,
	OnProjectStateHashUpdateCallback,
} from '../../../model';

export type Props = {
	issueCount: number;
	isCurrentDataFiltered: boolean;
	queueId: QueueId;
	projectStateHash: string | undefined;
	onQueueIssueCountUpdated: OnQueueIssueCountUpdatedCallback | undefined;
	onProjectStateHashUpdate: OnProjectStateHashUpdateCallback | undefined;
};

// eslint-disable-next-line jira/react/no-class-components
export default class NavUpdater extends PureComponent<Props> {
	static defaultProps = {
		onQueueIssueCountUpdated: undefined,
		onProjectStateHashUpdate: undefined,
	};

	componentDidMount() {
		this.updateIssueCount();
	}

	componentDidUpdate(prevProps: Props) {
		const { projectStateHash, onProjectStateHashUpdate, issueCount } = this.props;

		if (prevProps.issueCount !== issueCount) {
			this.updateIssueCount();
		}
		if (
			// Only call again if it isn't immediately following an initialization call (projectStateHash = null)
			!isEmpty(prevProps.projectStateHash) &&
			prevProps.projectStateHash !== projectStateHash &&
			onProjectStateHashUpdate
		) {
			// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
			onProjectStateHashUpdate();
		}
	}

	updateIssueCount() {
		const { queueId, issueCount, onQueueIssueCountUpdated, isCurrentDataFiltered } = this.props;
		!isCurrentDataFiltered &&
			onQueueIssueCountUpdated &&
			onQueueIssueCountUpdated(queueId, issueCount);
	}

	render() {
		return null;
	}
}
