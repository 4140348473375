import type { AggTransformerFunction } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/transformer-type.tsx';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type CONNECT_MULTI_SELECT_GQL_FIELD,
	ISSUE_FIELD_SINGLE_SELECT_CF_TYPE,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	toLegacyConnectOption,
	transformAggConnection,
	transformAggNodeToLegacyField,
} from './default-node-transformer';

export const transformAggConnectMultipleSelectToLegacyField: AggTransformerFunction<
	typeof CONNECT_MULTI_SELECT_GQL_FIELD
> = (node) => {
	const value = transformAggConnection(node.selectedOptions, toLegacyConnectOption);
	const allowedValues = transformAggConnection(
		node.connectMultiSelectFieldOptions,
		toLegacyConnectOption,
	);

	return {
		...transformAggNodeToLegacyField(node),
		value: value.length ? value : null,
		allowedValues,
		schema: {
			type: ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
			renderer: PLAIN_TEXT,
			custom: node.type,
			system: null,
			items: ISSUE_FIELD_SINGLE_SELECT_CF_TYPE,
			configuration: null,
		},
	};
};
