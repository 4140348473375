import type { ConsumerState } from './types';

export const SET_CONSUMER_STATE = 'state.consumer.SET_CONSUMER_STATE' as const;

export type SetConsumerStateAction = {
	type: typeof SET_CONSUMER_STATE;
	payload: ConsumerState;
};

export const setConsumerState = (payload: ConsumerState): SetConsumerStateAction => ({
	type: SET_CONSUMER_STATE,
	payload,
});
