// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	AsyncFlagsDispatcher,
	DISMISS_COMMAND,
	type FlagsMapperFactoryArguments,
	type FlagsMapperFactory,
} from '@atlassian/jira-flags/src/async';
import { MountEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import flagsMapperFactory, { type Action } from '../../../flags';
import type { State } from '../../../state/reducers/types';

// eslint-disable-next-line jira/react/no-class-components, @typescript-eslint/no-explicit-any
export default class Flags extends PureComponent<Record<any, any>> {
	onMount = (analyticsEvent: UIAnalyticsEvent) => {
		this.flagsAnalyticEvent = analyticsEvent;
	};

	getFlagsMapperFactory = memoizeOne(
		(factory: FlagsMapperFactory<Action, State>) => (args: FlagsMapperFactoryArguments) => {
			const mapper = factory(args);

			return (action: Action, state: State) => {
				const flagConfig = mapper(action, state);

				if (flagConfig) {
					const { type } = action;
					let analyticsAction = 'viewed';

					// Only type of DismissFlag has command field
					// @ts-expect-error - TS2339 - Property 'command' does not exist on type 'DismissFlag | Flag'.
					if (flagConfig.command) {
						analyticsAction = DISMISS_COMMAND.toLowerCase();
					}

					this.flagsAnalyticEvent.update({ action: analyticsAction });
					fireUIAnalytics(this.flagsAnalyticEvent, `flag ${analyticsAction}`, {
						actionType: type.split('.').pop(),
					});
				}

				return flagConfig;
			};
		},
	);

	// @ts-expect-error Property 'flagsAnalyticEvent' has no initializer and is not definitely assigned in the constructor.
	flagsAnalyticEvent: UIAnalyticsEvent;

	render() {
		return (
			<>
				<MountEvent onMount={this.onMount} />
				<AsyncFlagsDispatcher mapperFactory={this.getFlagsMapperFactory(flagsMapperFactory)} />
			</>
		);
	}
}
