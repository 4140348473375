import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src';
import { fromQueueId } from '@atlassian/jira-servicedesk-queues-common/src/model';
import {
	getAtlassianAccountId,
	getIsAdmin,
	getProjectId,
} from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';
import { TABLE_CHANGED } from '../../../state/actions/table';
import type { State } from '../../../state/reducers/types';
import { getIssueCountByAssignee, getIssueStatusIdsCounts } from '../../../state/selectors/issues';
import { getIssueKeysForPage } from '../../../state/selectors/pagination';
import { getEnabledPractices, isItsmProject } from '../../../state/selectors/practices';
import {
	getAllFieldTypes,
	getNumberOfColumns,
	getQueueId,
	isQueueTransient,
} from '../../../state/selectors/queue';
import { getSortedByColumn, getSortOrder } from '../../../state/selectors/sorting';
import { isLoadingIssues } from '../../../state/selectors/ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onTableChange = (state: State, analyticsEvent: any) => {
	const queueId = getQueueId(state);
	const issueKeys = getIssueKeysForPage(state);
	const sortedBy = getSortedByColumn(state);
	const sortOrder = getSortOrder(state);
	const atlassianAccountId = getAtlassianAccountId(state);
	const issueStatusIdsCountsInQueue = getIssueStatusIdsCounts(state);
	const numberOfIssuesAssignedToCurrentUser = getIssueCountByAssignee(state, atlassianAccountId);

	!isLoadingIssues(state) &&
		fireTrackAnalytics(analyticsEvent, {
			containerType: 'project',
			containerId: `${getProjectId(state)}`,
			action: 'viewed',
			objectType: 'queue',
			objectId: `${fromQueueId(queueId)}`,
			actionSubjectId: `${fromQueueId(queueId)}`,
			attributes: {
				isAdmin: getIsAdmin(state),
				isTransient: isQueueTransient(state),
				numberOfIssuesDisplayed: issueKeys.length,
				numberOfColumnsDisplayed: getNumberOfColumns(state),
				displayedColumnsInOrder: getAllFieldTypes(state),
				sortedByColumnId: sortedBy,
				sortOrder,
				// store as a list of 1 to prevent amplitude creating too many attributes
				issueStatusIdsCountsInQueue: [issueStatusIdsCountsInQueue],
				numberOfIssuesAssignedToCurrentUser,
				...(isItsmProject(state) ? { itsmPractices: getEnabledPractices(state) } : {}),
			},
		});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(TABLE_CHANGED).switchMap((action) => {
		onTableChange(store.getState(), action.meta.analyticsEvent);
		return Observable.empty<never>();
	});
