import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import log from '@atlassian/jira-common-util-logging/src/log';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import {
	type UpdateFieldFailureAction,
	ERROR_RESPONSE,
	FIELD_UPDATE_FAILURE,
} from '../../../../state/actions/field/update';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>) =>
	action$.ofType(FIELD_UPDATE_FAILURE).switchMap((action: UpdateFieldFailureAction) => {
		const { fieldType, failureReason } = action.payload;
		if (failureReason.type === ERROR_RESPONSE) {
			const { statusCode } = failureReason;
			fireErrorAnalytics({
				meta: {
					id: 'opsFieldUpdate',
					packageName: 'jiraIssueTable',
					teamName: 'jsd-shield',
				},
				attributes: {
					message: 'Error response from server attempting to update field value',
					statusCode,
					fieldType,
				},
			});
			log.safeErrorWithoutCustomerData(
				'issue-table.ops.field.update',
				`[FD-3358] Error response from server attempting to update field value. statusCode: [${statusCode}], fieldType: [${fieldType}]`,
			);
		} else {
			fireErrorAnalytics({
				meta: {
					id: 'opsFieldUpdate',
					packageName: 'jiraIssueTable',
					teamName: 'jsd-shield',
				},
				attributes: {
					message: 'Unknown error attempting to update field value.',
					fieldType,
				},
			});
			log.safeErrorWithoutCustomerData(
				'issue-table.ops.field.update',
				`[FD-3358] Unknown error attempting to update field value. fieldType: [${fieldType}]`,
			);
		}
		return Observable.empty<never>();
	});
