import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CellWithIconIconWrapper = styled.div({
	display: 'flex',
	alignItems: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RestrictedWidthCell = styled.div<{ width: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => `${props.width}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SidebarIconOld = styled.div({
	marginLeft: token('space.100', '8px'),
	marginRight: token('space.200', '16px'),
	marginTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SidebarIconNew = styled.div({
	marginTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarIcon = componentWithFF(
	'jsm-queue-sidebar-bug-fixes-second-round_xuenh',
	SidebarIconNew,
	SidebarIconOld,
);
