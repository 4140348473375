import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers';

export const MIN_DETAILS_WIDTH_ISSUE = 440;
export const WINDOW_RESIZE_THROTTLE = 350;
const DETAIL_VIEW_WIDTH_RATIO = 'gh.detailViewWidth';
const localStorage = createLocalStorageProvider('jsmqueueagentview');
export const getDetailViewWidthRatio = (): number => {
	const value = localStorage.get(DETAIL_VIEW_WIDTH_RATIO);
	return value ? Number(value) : 0;
};
export const setDetailViewWidthRatio = (ratio: number) =>
	localStorage.set(DETAIL_VIEW_WIDTH_RATIO, ratio);
