import React, { type ComponentType } from 'react';
import noop from 'lodash/noop';
import { lazyForPaint } from 'react-loosely-lazy';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src';
import type { AnalyticsSource as PageAnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources';
import { log as logger } from '@atlassian/jira-common-util-logging';
import { ff, getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import type { IssueNavigatorData } from '@atlassian/jira-issue-context-service';
import {
	ChangeEventTypes,
	type ChangeEvent,
} from '@atlassian/jira-issue-view-model/src/change-type';
import { IssueBoundary } from '@atlassian/jira-issue-view/async';
import type { IssueAppProps } from '@atlassian/jira-issue-view/src/views/issue-details/issue-app';
import { getIssueContainerMaxWidth } from '@atlassian/jira-issue-view/src/views/issue-details/issue-layout/constants';
import { IssueMutation, MutationSource, useEcClient } from '@atlassian/jira-jsis-ec-client';
import { useAnalyticsEvents, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/jira-router';
import { ServiceDeskAnalyticsContext as ServiceDeskAnalyticsContextDI } from '@atlassian/jira-servicedesk-analytics';
import { saveIssueMutationAnalyticsToCache } from '@atlassian/jira-servicedesk-common/src/issue-change';
import ServicedeskIssueContainer from '@atlassian/jira-servicedesk-queues-issue-container/src/view/view';
import { toIssueKey, type IssueKey } from '@atlassian/jira-shared-types';
import { useQueryParam } from '@atlassian/jira-software-router-utils';

const isEcClientEnabled = () => {
	const experiences: string[] = getFeatureFlagValue<string>(
		'hela.ec.client.integration.jsm',
		'',
	).split(',');
	return Array.isArray(experiences) && experiences.includes('jsm-issue-view');
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyIssueApp: ComponentType<IssueAppProps> = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-app'
		),
	{ ssr: false },
);

export type Props = {
	backButtonUrl?: string;
	issueNavigatorData?: IssueNavigatorData;
	analyticsSource: PageAnalyticsSource;
	issueKey: IssueKey;
	onDispatchExternalActionRef: () => void;
	onIssueDeleteSuccess: () => void;
	ServiceDeskAnalyticsContext?: typeof ServiceDeskAnalyticsContextDI;
};

type OnIssueKeyChangeArgument = {
	fromIssueKey: IssueKey;
	toIssueKey: IssueKey;
	meta: {
		location: string;
	};
};

/**
 * NOTE: This component is used **only for issues navigated from a JSM queue**, not for any JSM issue. See this package's Readme.md for more info
 */
export const IssueView = ({
	backButtonUrl,
	issueNavigatorData,
	issueKey,
	onDispatchExternalActionRef,
	onIssueDeleteSuccess,
	analyticsSource,
	ServiceDeskAnalyticsContext = ServiceDeskAnalyticsContextDI,
}: Props) => {
	const routerActions = useRouterActions();
	const [selectedIssueKey, setIssueKey] = useQueryParam('selectedIssue');
	const { createAnalyticsEvent } = useAnalyticsEvents();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let saveIssueMutationToCache: any;
	if (isEcClientEnabled()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		saveIssueMutationToCache = useEcClient().saveIssueMutationToCache;
	}
	const onIssueKeyChange = (data: OnIssueKeyChangeArgument) => {
		setIssueKey(data.toIssueKey);

		if (ff('jsm-redirecting-issue-to-browse-url-when-old-key-is-invalid')) {
			logger.safeInfoWithoutCustomerData(
				'jsm.issue.view.issue-key-change',
				'Invalid issue key, redirecting to correct issue key',
			);
			routerActions.push(`/browse/${data.toIssueKey}`);
		}
	};

	const onChangeHandler = (event: ChangeEvent) => {
		if (ff('jsm-saveissuemutationtocache-refactor_oyhki', false)) {
			saveIssueMutationAnalyticsToCache(event, createAnalyticsEvent, saveIssueMutationToCache);
		} else {
			onChangeHandlerOld(event);
		}
	};

	const onChangeHandlerOld = (event: ChangeEvent) => {
		if (isEcClientEnabled() && event?.type === ChangeEventTypes.FIELD_CHANGED) {
			const mutationFailedAnalyticsPayload = {
				errorMsg: 'FAILED_JSM_ISSUEVIEW',
				error: true,
			};
			const analyticsEventObj = createAnalyticsEvent({});
			const analyticsDataKey = {
				analyticsEventObj,
				analyticsMetadata: {
					scenario: 'jsm-issue-view',
					type: event.type,
				},
			};

			saveIssueMutationToCache(
				new IssueMutation(event?.issueId, MutationSource.UPDATE),
				analyticsDataKey,
				mutationFailedAnalyticsPayload,
			);
		}
	};
	return (
		<ServicedeskIssueContainer>
			<ServiceDeskAnalyticsContext>
				<IssueBoundary packageName="servicedesk-issue-view">
					<LazyIssueApp
						onClose={noop}
						analyticsSource={analyticsSource}
						backButtonUrl={backButtonUrl}
						dispatchExternalActionRef={onDispatchExternalActionRef}
						shouldSetInitialFocus
						isLoadedWithPage
						issueKey={toIssueKey(selectedIssueKey) || issueKey}
						onIssueDeleteSuccess={onIssueDeleteSuccess}
						onIssueKeyChange={onIssueKeyChange}
						issueMaxWidth={getIssueContainerMaxWidth()}
						onChange={onChangeHandler}
						issueNavigatorData={issueNavigatorData}
					/>
				</IssueBoundary>
			</ServiceDeskAnalyticsContext>
		</ServicedeskIssueContainer>
	);
};

const getAnalyticsSourceFromUrl = () => {
	const { pathname } = window.location;
	if (pathname.includes('reports')) {
		return 'viewReportIssue';
	}
	return 'viewQueueIssue';
};

const WrappedIssueView = AnalyticsSource(getAnalyticsSourceFromUrl(), SCREEN)(IssueView);

const IssueViewContainer = (props: Props) => <WrappedIssueView {...props} />;

export default IssueViewContainer;
