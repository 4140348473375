import type { AssigneeInitialization } from '../../../../model/fields/json-fields/system-fields/types';

// UPDATE_CURRENT_USER

export const SET_ASSIGNEE_INITIALIZATION_STATE =
	'state.actions.field.initialization.SET_ASSIGNEE_INITIALIZATION_STATE' as const;

export type SetAssigneeInitializationStateAction = {
	type: typeof SET_ASSIGNEE_INITIALIZATION_STATE;
	payload: AssigneeInitialization;
};

export const setAssigneeInitializationStateAction = (
	assigneeInitialization: AssigneeInitialization,
): SetAssigneeInitializationStateAction => ({
	type: SET_ASSIGNEE_INITIALIZATION_STATE,
	payload: assigneeInitialization,
});
