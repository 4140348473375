import React, { forwardRef, type ReactNode, useCallback, useContext, useEffect } from 'react';

import { cssMap } from '@compiled/react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import mergeRefs from '@atlaskit/ds-lib/merge-refs';
import useControlled from '@atlaskit/ds-lib/use-controlled';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Popup, PopupContent, PopupTrigger } from '@atlaskit/popup/experimental';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { IsOpenContext, SetIsOpenContext } from './flyout-menu-item-context';
import { MenuItemBase } from './menu-item';
import { MenuListItem } from './menu-list-item';
import type { CommonMenuItemOnClick } from './types';

const styles = cssMap({
	popupContent: {
		width: '280px',
	},
});

export type FlyoutMenuItemProps = {
	children: ReactNode;
	/**
	 * ID that is assigned to the popup container element and used to associate the trigger with the content.
	 */
	id?: string;
	/**
	 * Allows to control the open state of the flyout externally.
	 */
	isOpen?: boolean;
	/**
	 * Whether the flyout menu is open by default.
	 */
	isDefaultOpen?: boolean;
	/**
	 * Callback that is called when the flyout menu is opened or closed.
	 */
	onOpenChange?: (isOpen: boolean) => void;
};

/**
 * __FlyoutMenuItem__
 *
 * Displays content in a flyout menu, triggered by a button.
 *
 * The top-level component that contains the trigger and content of a flyout menu.
 *
 * Usage example:
 * ```jsx
 * <FlyoutMenuItem>
 *   <FlyoutMenuItemTrigger>Trigger</FlyoutMenuItemTrigger>
 *   <FlyoutMenuItemContent>
 *     <MenuButtonItem>Item 1</MenuButtonItem>
 *     <MenuButtonItem>Item 2</MenuButtonItem>
 *   </FlyoutMenuItemContent>
 * </FlyoutMenuItem>
 * ```
 */
export const FlyoutMenuItem = ({
	children,
	id,
	isOpen: isOpenControlled,
	isDefaultOpen = false,
	onOpenChange,
}: FlyoutMenuItemProps) => {
	const [isOpen, setIsOpen] = useControlled(isOpenControlled, () => isDefaultOpen);

	useEffect(() => {
		onOpenChange?.(isOpen);
	}, [isOpen, onOpenChange]);

	return (
		<IsOpenContext.Provider value={isOpen}>
			<SetIsOpenContext.Provider value={setIsOpen}>
				<Popup id={id} isOpen={isOpen}>
					{children}
				</Popup>
			</SetIsOpenContext.Provider>
		</IsOpenContext.Provider>
	);
};

export type FlyoutMenuItemTriggerProps = {
	children: ReactNode;
	iconBefore?: ReactNode;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>, analyticsEvent: UIAnalyticsEvent) => void;
	isDisabled?: boolean;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
};

/**
 * __FlyoutMenuItemTrigger__
 *
 * The button that toggles the flyout menu.
 */
export const FlyoutMenuItemTrigger = forwardRef<HTMLButtonElement, FlyoutMenuItemTriggerProps>(
	({ children, iconBefore, onClick, isDisabled, interactionName }, forwardedRef) => (
		<MenuListItem>
			<PopupTrigger>
				{({
					ref,
					'aria-controls': ariaControls,
					'aria-expanded': ariaExpanded,
					'aria-haspopup': ariaHasPopup,
				}) => (
					<MenuItemBase
						ref={mergeRefs([ref, forwardedRef])}
						elemBefore={iconBefore}
						elemAfter={
							<Box paddingInline="space.075">
								<ChevronRightIcon label="" color={token('color.icon', '#44546F')} />
							</Box>
						}
						onClick={onClick as CommonMenuItemOnClick}
						ariaControls={ariaControls}
						ariaExpanded={ariaExpanded}
						ariaHasPopup={ariaHasPopup}
						isDisabled={isDisabled}
						interactionName={interactionName}
					>
						{children}
					</MenuItemBase>
				)}
			</PopupTrigger>
		</MenuListItem>
	),
);

export type FlyoutMenuItemContentProps = {
	children: React.ReactNode;
	/**
	 * A `testId` that is applied to the container element as the `data-testid` attribute.
	 */
	containerTestId?: string;
	onClose?: () => void;
};

/**
 * __FlyoutMenuItemContent__
 *
 * The content that appears when the flyout menu is open.
 */
export const FlyoutMenuItemContent = forwardRef<HTMLDivElement, FlyoutMenuItemContentProps>(
	({ children, containerTestId, onClose }, forwardedRef) => {
		const setIsOpen = useContext(SetIsOpenContext);

		const handleClose = useCallback(() => {
			onClose && onClose();
			setIsOpen(false);
		}, [setIsOpen, onClose]);

		return (
			<PopupContent
				onClose={handleClose}
				placement="right-start"
				testId={containerTestId}
				shouldFitViewport
			>
				{() => (
					<Box ref={forwardedRef} padding="space.100" xcss={styles.popupContent}>
						{children}
					</Box>
				)}
			</PopupContent>
		);
	},
);
