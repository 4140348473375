import { ASCENDING, DESCENDING } from '../../../../../../../../../model/columns';
import sortableIndicatorImage from './sortable-indicator.svg';
import ascSortedIndicatorImage from './sorted-indicator-asc.svg';
import descSortedIndicatorImage from './sorted-indicator-desc.svg';

type SortType = typeof DESCENDING | typeof ASCENDING | 'DEFAULT';

type IndicatorMetadata = Record<
	SortType,
	{
		nextSortOrder: SortType;
		image: string;
	}
>;

export const DEFAULT_SORT_INDICATOR_METADATA: IndicatorMetadata = {
	DESCENDING: {
		nextSortOrder: ASCENDING,
		image: descSortedIndicatorImage,
	},
	ASCENDING: {
		nextSortOrder: DESCENDING,
		image: ascSortedIndicatorImage,
	},
	DEFAULT: {
		nextSortOrder: ASCENDING,
		image: sortableIndicatorImage,
	},
};

export const INDICATOR_METADATA: IndicatorMetadata = {
	DESCENDING: {
		nextSortOrder: 'DEFAULT',
		image: descSortedIndicatorImage,
	},
	ASCENDING: {
		nextSortOrder: DESCENDING,
		image: ascSortedIndicatorImage,
	},
	DEFAULT: {
		nextSortOrder: ASCENDING,
		image: sortableIndicatorImage,
	},
};
