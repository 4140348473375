import React from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { QueueItem as QueueItemDI } from './queue-item';
import type { QueueListProps } from './types';

export const QueueList = ({
	QueueItem = QueueItemDI,
	queues,
	section,
	projectKey,
	priorityGroupId,
	isQueueListInFlyOut = false,
}: QueueListProps) => {
	if (ff('jsm-queues-nav4-refresh-m2-dogfooding_rh43y')) {
		return (
			<>
				{queues.map((queue) => (
					<QueueItem
						key={queue.id}
						queue={queue}
						section={section}
						projectKey={projectKey}
						priorityGroupId={priorityGroupId}
						isQueueInFlyOut={isQueueListInFlyOut}
					/>
				))}
			</>
		);
	}
	return (
		<>
			{queues.map((queue) => (
				<QueueItem key={queue.id} queue={queue} section={section} />
			))}
		</>
	);
};
