import React, { useRef, type FC } from 'react';
import Button, { IconButton } from '@atlaskit/button/new';
import Form, { Field } from '@atlaskit/form';
import CrossIcon from '@atlaskit/icon/glyph/cross';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import Select, { type OptionType, type ValueType } from '@atlaskit/select';
import { ShortcutScope } from '@atlassian/jira-common-components-keyboard-shortcuts';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import type { ButtonClickProps, FormOnSubmitProps } from '../../common/types';
import messages from './messages';

type SurveyModalProps = {
	onClose: ButtonClickProps;
	onSubmit: FormOnSubmitProps;
};

export const SurveyModal: FC<SurveyModalProps> = (props) => {
	const { onClose, onSubmit } = props;
	const { formatMessage } = useIntl();

	const TOOLS = [
		{ label: formatMessage(messages.toolsOption1), value: 'serviceNow' },
		{ label: formatMessage(messages.toolsOption2), value: 'bmcHelix' },
		{ label: formatMessage(messages.toolsOption3), value: 'freshWorks' },
		{ label: formatMessage(messages.toolsOption4), value: 'pagerduty' },
		{ label: formatMessage(messages.toolsOption5), value: 'cherwell' },
		// keeping the label as toolsOption9 as this key added after the translation changes PR is merged
		{ label: formatMessage(messages.toolsOption9), value: 'zendesk' },
		{ label: formatMessage(messages.toolsOption6), value: 'jira' },
		{ label: formatMessage(messages.toolsOption7), value: 'other' },
		{ label: formatMessage(messages.toolsOption8), value: 'exploring' },
	];

	const IMPORT_OPTIONS = [
		{ label: formatMessage(messages.importOption1), value: 'issues' },
		{ label: formatMessage(messages.importOption2), value: 'users' },
		{ label: formatMessage(messages.importOption3), value: 'customerOrganizations' },
		{ label: formatMessage(messages.importOption4), value: 'customFields' },
		{ label: formatMessage(messages.importOption5), value: 'other' },
	];

	const focusRef = useRef(null);

	return (
		<ContextualAnalyticsData sourceType={MODAL} sourceName="jsmImporterSurvey">
			<ShortcutScope>
				<Modal width="small" autoFocus={focusRef} onClose={onClose}>
					<Form onSubmit={onSubmit}>
						{({ formProps }) => (
							<form {...formProps}>
								<ModalHeader>
									<ModalTitle>{formatMessage(messages.modalHeading)}</ModalTitle>
									<IconButton
										appearance="subtle"
										onClick={onClose}
										label={formatMessage(messages.cancelButtonLabel)}
										icon={CrossIcon}
									/>
								</ModalHeader>
								<ModalBody>
									<Text>{formatMessage(messages.modalTitle)}</Text>
									<Field<ValueType<OptionType>>
										label={formatMessage(messages.dropDownQuestionWhereAreYouImportingFrom)}
										name="importTool"
										id="importTool"
									>
										{({ fieldProps: { id, ...rest } }) => (
											<Select
												ref={focusRef}
												id={`${id}-select`}
												isSearchable={false}
												options={TOOLS}
												menuPosition="fixed"
												placeholder={formatMessage(messages.placeholderLabel)}
												{...rest}
											/>
										)}
									</Field>
									<Field<ValueType<OptionType, true>>
										label={formatMessage(messages.dropDownQuestionWhatAreYouImporting)}
										name="importOptions"
										id="importOptions"
									>
										{({ fieldProps: { id, ...rest } }) => (
											<Select
												isMulti
												isSearchable={false}
												id={`${id}-select`}
												options={IMPORT_OPTIONS}
												menuPosition="fixed"
												placeholder={formatMessage(messages.placeholderLabel)}
												{...rest}
											/>
										)}
									</Field>
								</ModalBody>
								<ModalFooter>
									<Button appearance="primary" type="submit">
										{formatMessage(messages.continueToImporterButtonLabel)}
									</Button>
								</ModalFooter>
							</form>
						)}
					</Form>
				</Modal>
			</ShortcutScope>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};
