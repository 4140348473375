import type { Field } from '@atlassian/jira-issue-shared-types';

/**
 * Returns the first available value for a given field, excluding editingValue
 * pendingValue => value
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (field: Field) =>
	typeof field.pendingValue !== 'undefined' ? field.pendingValue : field.value;

/**
 * Returns the first available value for a given field, excluding editingValue
 * pendingValue => adfValue => value
 */
export const getPendingAdfValue = (field: Field) =>
	field.pendingValue || field.adfValue || field.value;
