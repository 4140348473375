import merge from 'lodash/merge';
import memoizeOne from 'memoize-one';
import type {
	List,
	Row,
	Header,
	Theme,
	CompiledTheme,
	CompiledHeader,
	CompiledList,
	CompiledRow,
} from '../../model/themes';
import defaultTheme from './theme';

export const mergeThemes = (aTheme: Theme, anotherTheme: Theme): Theme =>
	merge({}, aTheme, anotherTheme);

const createRowCompiledTheme = (
	{ height, minHeight, borderRadius, ...others }: Row,
	autoHeight: boolean,
): CompiledRow => ({
	defaultHeight: Math.max(height, minHeight),
	height: height && !autoHeight ? `${height}px` : 'auto',
	minHeight: `${Math.max(height, minHeight)}px`,
	borderRadius: `${borderRadius}px`,
	...others,
});

const createHeaderCompiledTheme = ({
	height,
	paddingBottom,
	paddingTop,
	...others
}: Header): CompiledHeader => ({
	height: height ? `${height}px` : 'auto',
	paddingBottom: `${paddingBottom}px`,
	paddingTop: `${paddingTop}px`,
	...others,
});

const createListCompiledTheme = ({ paddingTop }: List): CompiledList => ({
	paddingTop: `${paddingTop}px`,
});

const createCompiledTheme = (
	theme: Theme,
	autoHeight: boolean,
	customScrollBarOffset?: number,
): CompiledTheme => {
	const { decal, header, list, row, scrollbar, shadow } = theme;

	const scrollBarTheme =
		customScrollBarOffset !== undefined
			? { ...scrollbar, offset: customScrollBarOffset }
			: scrollbar;

	return {
		decal,
		header: createHeaderCompiledTheme(header),
		list: createListCompiledTheme(list),
		row: createRowCompiledTheme(row, autoHeight),
		scrollbar: scrollBarTheme,
		shadow,
	};
};

export const createTheme = (theme: Theme): Theme => mergeThemes(defaultTheme, theme);

export const compiledThemeWithProps: (
	theme: Theme,
	height: number,
	autoHeight: boolean,
	customScrollBarOffset?: number,
) => CompiledTheme = memoizeOne(
	(theme: Theme, height: number, autoHeight: boolean, customScrollBarOffset?: number) =>
		createCompiledTheme(
			merge({}, theme, {
				row: {
					defaultHeight: height,
					height,
				},
			}),
			autoHeight,
			customScrollBarOffset,
		),
);
