import type { AggTransformerFunction } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/transformer-type.tsx';
import type { Restriction } from '@atlassian/jira-issue-restrictions/src/common/types.tsx';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type ISSUE_RESTRICTION_GQL_FIELD,
	ISSUE_RESTRICTION_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { transformAggConnection, transformAggNodeToLegacyField } from './default-node-transformer';
import { getSystemAggJiraProject } from './project-field-transformer';

const transformJiraRoleToRestriction = (role: {
	readonly id: string;
	readonly roleId: string;
}): Restriction | null => {
	if (role == null) return null;
	return { restrictionValue: role.roleId };
};

export const transformAggIssueRestrictionToLegacyField: AggTransformerFunction<
	typeof ISSUE_RESTRICTION_GQL_FIELD
> = (node, context) => {
	if (node.type !== ISSUE_RESTRICTION_TYPE) return undefined;

	const roles = transformAggConnection(
		node.selectedRolesConnection,
		transformJiraRoleToRestriction,
	);

	const restrictions = roles.length > 0 ? { projectrole: roles } : {};

	const project = getSystemAggJiraProject(context);

	return {
		...transformAggNodeToLegacyField(node),
		value: {
			shouldDisplay: project.canSetIssueRestriction === true,
			issuerestrictions: restrictions,
		},
		schema: {
			configuration: null,
			items: null,
			renderer: PLAIN_TEXT,
			type: ISSUE_RESTRICTION_TYPE,
			custom: null,
			system: node.type,
		},
	};
};
