import { createSelector } from 'reselect';
import { SUBTASKS } from '@atlassian/jira-issue-view-configurations';
import { PROJECT_ACTIVE } from '@atlassian/jira-project-context-service/src/constants';
import {
	isMobileSelector,
	isBitbucketSelector,
	isServiceDeskSelector,
} from '../common/state/selectors/context-selector';
import { childIssuesLimitUrlSelector } from '../common/state/selectors/field-selector';
import {
	entitiesSelector,
	isSimplifiedProjectSelector,
	projectSelector,
} from '../common/state/selectors/issue-selector';
import { permissionsSelector } from '../common/state/selectors/permissions-selector';
import { hasChildrenIssuesSelector } from '../issue-field/state/selectors/children-issue-selector';
import { isIssueBaseLevel } from '../issue-field/state/selectors/hierarchy-level-selector';
import { issueTypeSelector } from './breadcrumbs-selector';
import { projectHasSubtaskValidatorsSelector } from './project-validators-selector';
import {
	isSubtaskTypesPresentSelector,
	hasUnsupportedTypeSelector,
} from './subtask-types-selector';

export const isSubtaskSelector = createSelector(
	issueTypeSelector,
	(issueType) => !!(issueType && issueType.subtask),
);

export const subtaskEntitiesSelector = createSelector(
	entitiesSelector,
	(entities) => entities.subtasks,
);

// this selector when child issues is over 100 issues is a bit inaccurate
// due to AGG changes when over 100 child issues, we do not know the child issue panel types
// so when we are over the limit (portfolio > 100) or (subtasks + portfolio > 100)
// we will default the field to subtask. This means the scenario (portfolio > 100) will default
// the field to subtasks even though there is no subtasks.
export const hasSubtasksSelector = createSelector(
	subtaskEntitiesSelector,
	childIssuesLimitUrlSelector,
	(subtasks, getChildIssuesLimitUrl) =>
		subtasks.length > 0 || Boolean(getChildIssuesLimitUrl(SUBTASKS)),
);

export const hasChildIssuesOrSubtasksSelector = createSelector(
	isSimplifiedProjectSelector,
	isIssueBaseLevel,
	hasChildrenIssuesSelector,
	hasSubtasksSelector,
	(
		isSimplifiedProject: boolean,
		issueBaseLevel: boolean,
		hasChildrenIssues: boolean,
		hasSubtasks: boolean,
	): boolean => (isSimplifiedProject ? issueBaseLevel && hasChildrenIssues : hasSubtasks),
);

export const shouldUseFallbackSubtaskDialogSelector = createSelector(
	hasUnsupportedTypeSelector,
	projectHasSubtaskValidatorsSelector,
	(hasRequiredFields, hasSubtaskValidators) => hasRequiredFields || hasSubtaskValidators,
);

// Deprecated. Use jira-settings-hook instead if possible
export const isSubtaskEnabledSelector = createSelector(
	entitiesSelector,
	({ jiraSettings }) => jiraSettings.subtasksConfiguration.enabled,
);

export const canCreateSubtaskSelector = createSelector(
	permissionsSelector,
	isSubtaskSelector,
	isSubtaskTypesPresentSelector,
	shouldUseFallbackSubtaskDialogSelector,
	isMobileSelector,
	isBitbucketSelector,
	isServiceDeskSelector,
	isSimplifiedProjectSelector,
	isSubtaskEnabledSelector,
	projectSelector,
	(
		{ canCreateSubtask },
		isSubtask,
		typesPresent,
		shouldUseFallbackDialog,
		isMobile,
		isBitbucket,
		isServicedesk,
		isSimplified,
		subtasksEnabled,
		project,
	) => {
		const shouldHideForMobileOrBitbucket = shouldUseFallbackDialog
			? isMobile || isBitbucket
			: false;

		const isProjectActive = (project?.projectStatus ?? PROJECT_ACTIVE) === PROJECT_ACTIVE;

		const shouldShowSubtask = isServicedesk ? !isSimplified : true;

		return !!(
			canCreateSubtask &&
			!isSubtask &&
			typesPresent &&
			!shouldHideForMobileOrBitbucket &&
			shouldShowSubtask &&
			subtasksEnabled &&
			isProjectActive
		);
	},
);
