import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import type { IssueType } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	entitiesSelector,
	isEpicSelector,
	uiSelector,
} from '../common/state/selectors/issue-selector';
import { issueTypeSelector } from './breadcrumbs-selector';

export const issueTypesSelector = createSelector(
	entitiesSelector,
	(entities) => entities.issueTypes,
);

// A collection of issue fields which are marked as `required`
// for the creation of an issue, but are considered as 'provided' since
// the data for these fields is supplied by JIRA.
const providedRequiredFields = ['summary', 'issuetype', 'parent', 'project'];

const isRequiredFieldProvided = (fieldName: string) =>
	providedRequiredFields.find((providedFieldName) => fieldName === providedFieldName);

// A field is considered 'unsupported' if not provided by JIRA,
// has no default value, and is a `required` field.
const hasUnsupportedRequiredField = (
	fields:
		| undefined
		| {
				// @ts-expect-error - TS2304 - Cannot find name 'FieldMeta'.
				[key: string]: FieldMeta;
		  },
) =>
	// @ts-expect-error - TS2769 - No overload matches this call.
	!!Object.keys(fields).find(
		(fieldName) =>
			!isRequiredFieldProvided(fieldName) &&
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
			!fields[fieldName].hasDefaultValue &&
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
			fields[fieldName].required,
	);

export const hasUnsupportedType = (issueType: IssueType) =>
	isEmpty(issueType.fields) || hasUnsupportedRequiredField(issueType.fields);

/**
 * use this selector only if currentIssueType is Epic. Otherwise it will not work properly. Or change the function's code.
 */
export const issueTypesForClassicProjectEpicSelector = createSelector(
	issueTypesSelector,
	issueTypeSelector,
	isEpicSelector,
	(
		issueTypes: IssueType[],
		currentIssueType?: {
			id: string;
		},
		// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
		isCurrentIssueAnEpic: boolean,
	) => {
		const epicIssueTypeId = isCurrentIssueAnEpic && currentIssueType && currentIssueType.id;

		return issueTypes.filter(
			// @ts-expect-error - TS2367 - This condition will always return 'true' since the types 'number' and 'string | boolean | undefined' have no overlap.
			(issueType) => !issueType.subtask && issueType.id !== epicIssueTypeId,
		);
	},
);

export const fetchIssueTypesStatusSelector = createSelector(
	uiSelector,
	(ui) => ui.fetchIssueTypesStatus,
);
