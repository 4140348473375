import forEach from 'lodash/forEach';
import memoizeOne from 'memoize-one';
import type { Optional } from '../../../model/optional';
import type { AddedRows } from '../../../model/rows';

export default memoizeOne((addedRows: Optional<AddedRows>, nextAddedRows: Optional<AddedRows>) => {
	if (nextAddedRows && nextAddedRows.persisted) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const result: Record<string, any> = {};
		forEach(nextAddedRows.persisted, (persistedRow, rowId) => {
			if (
				addedRows === undefined ||
				addedRows.persisted === undefined ||
				addedRows.persisted[rowId] === undefined
			) {
				result[rowId] = persistedRow;
			}
		});
		return result;
	}
	return undefined;
});
