// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { styled as styled2 } from '@compiled/react';
import isFunction from 'lodash/isFunction';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { token } from '@atlaskit/tokens';
import type { ColumnTitleType } from '../../../../../model/columns';
import { ellipsis } from '../../../../common/styles';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemWrapper = styled2.div<{ color: string; depth: number; isDisabled: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ color }) => color,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ depth }) => `${depth * 20}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isDisabled }) => (isDisabled ? 'not-allowed' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	...ellipsis,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WidthPlaceholder = styled2.div({
	width: token('space.200', '16px'),
	marginRight: token('space.100', '8px'),
});

type Props = {
	depth: number;
	title: ColumnTitleType;
	color: string;
	isDisabled: boolean;
	isChecked: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class ColumnConfigurationListItem extends PureComponent<Props> {
	renderCheckOrPlaceholder() {
		const { isChecked } = this.props;

		return <WidthPlaceholder>{isChecked && <CheckIcon size="small" label="" />}</WidthPlaceholder>;
	}

	renderTitle() {
		const { title } = this.props;

		if (!isFunction(title)) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return title as string;
		}

		const Title = title;
		return <Title />;
	}

	render() {
		const { depth, color, isDisabled } = this.props;

		return (
			<ItemWrapper depth={depth} color={color} isDisabled={isDisabled}>
				{this.renderCheckOrPlaceholder()}
				{this.renderTitle()}
			</ItemWrapper>
		);
	}
}
