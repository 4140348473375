import { connect } from '../../../../common/table-redux';
import {
	getVerticalScrollOffset,
	getTableWidthWithoutScrollbarOffset,
} from '../../../../state/internal/selectors';
import type { State } from '../../../../state/types';
import Blanket from './view';

// @ts-expect-error - Expected 2-4 arguments, but got 1.
export default connect((state: State) => ({
	scrollOffset: getVerticalScrollOffset(state),
	width: getTableWidthWithoutScrollbarOffset(state),
}))(Blanket);
