import type { ForgeResponse } from '@atlassian/jira-issue-fetch-services/src/types';
import type { Action as ForgeIssueAction } from '@atlassian/jira-issue-view-common-types/src/forge-types';
import type { IssueKey } from '@atlassian/jira-shared-types';

// FETCH_FORGE_SUCCESS
export const FETCH_FORGE_SUCCESS = 'FETCH_FORGE_SUCCESS' as const;
export const fetchForgeSuccess = (payload: ForgeResponse) => ({
	type: FETCH_FORGE_SUCCESS,
	payload,
});

// FORGE_ISSUE_ACTION_EXEC
export const FORGE_ISSUE_ACTION_EXEC = 'FORGE_ISSUE_ACTION_EXEC' as const;
export const forgeIssueActionExec = (action: ForgeIssueAction) => ({
	type: FORGE_ISSUE_ACTION_EXEC,
	payload: action,
});

// FORGE_ISSUE_ACTION_RENDERED
export const FORGE_ISSUE_ACTION_RENDERED = 'FORGE_ISSUE_ACTION_RENDERED' as const;
export const forgeIssueActionRendered = () => ({
	type: FORGE_ISSUE_ACTION_RENDERED,
});

// FORGE_ISSUE_ACTION_CLOSE
export const FORGE_ISSUE_ACTION_CLOSE = 'FORGE_ISSUE_ACTION_CLOSE' as const;
export const forgeIssueActionClose = () => ({
	type: FORGE_ISSUE_ACTION_CLOSE,
});

// FORGE_ISSUE_PANEL_ADDED
export const FORGE_ISSUE_PANEL_ADDED = 'FORGE_ISSUE_PANEL_ADDED' as const;
export const forgeIssuePanelAdded = (issuePanelKey: string) => ({
	type: FORGE_ISSUE_PANEL_ADDED,
	payload: issuePanelKey,
});

// FORGE_NEW_ISSUE_PANEL_UNTRACK
export const FORGE_NEW_ISSUE_PANEL_UNTRACK = 'FORGE_NEW_ISSUE_PANEL_UNTRACK' as const;
export const forgeNewIssuePanelUntrack = (issuePanelKey: string) => ({
	type: FORGE_NEW_ISSUE_PANEL_UNTRACK,
	payload: issuePanelKey,
});

// FORGE_ISSUE_PANEL_REMOVE
export const FORGE_ISSUE_PANEL_REMOVED = 'FORGE_ISSUE_PANEL_REMOVED' as const;
export const forgeIssuePanelRemoved = (issuePanelKey: string) => ({
	type: FORGE_ISSUE_PANEL_REMOVED,
	payload: issuePanelKey,
});

// FORGE_ISSUE_PANEL_UPDATE_REQUEST
type IssuePanelUpdateProps = {
	issueKey: IssueKey;
	panelKey: string;
};

export type IssuePanelUpdateRequestPayload = IssuePanelUpdateProps & {
	collapsed: boolean;
};

export const FORGE_ISSUE_PANEL_UPDATE_REQUEST = 'FORGE_ISSUE_PANEL_UPDATE_REQUEST' as const;
export const forgeIssuePanelUpdateRequest = (payload: IssuePanelUpdateRequestPayload) => ({
	type: FORGE_ISSUE_PANEL_UPDATE_REQUEST,
	payload,
});

// FORGE_ISSUE_PANEL_UPDATED
export type IssuePanelUpdatedPayload = {
	panelKey: string;
	collapsed: boolean;
};

export const FORGE_ISSUE_PANEL_UPDATED = 'FORGE_ISSUE_PANEL_UPDATED' as const;
export const forgeIssuePanelUpdated = (payload: IssuePanelUpdatedPayload) => ({
	type: FORGE_ISSUE_PANEL_UPDATED,
	payload,
});

export type Action =
	| ReturnType<typeof fetchForgeSuccess>
	| ReturnType<typeof forgeIssueActionExec>
	| ReturnType<typeof forgeIssueActionRendered>
	| ReturnType<typeof forgeIssueActionClose>
	| ReturnType<typeof forgeIssuePanelAdded>
	| ReturnType<typeof forgeNewIssuePanelUntrack>
	| ReturnType<typeof forgeIssuePanelUpdateRequest>
	| ReturnType<typeof forgeIssuePanelUpdated>
	| ReturnType<typeof forgeIssuePanelRemoved>;
