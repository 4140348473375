import React from 'react';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { EmojiIconForSentimentCustomField } from './utils';

type SentimentId = '1000' | '2000' | '3000';

type Props = {
	id: SentimentId;
	name: string;
};

export const ReadView = ({ id, name }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Inline>
			{EmojiIconForSentimentCustomField(id, formatMessage)}
			<Box xcss={contentStyles}>
				<Text as="p">{name}</Text>
			</Box>
		</Inline>
	);
};
const contentStyles = xcss({
	paddingTop: 'space.025',
	marginLeft: 'space.150',
	whiteSpace: 'nowrap',
});
