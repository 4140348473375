export {
	APPROVER_APPROVED,
	APPROVER_DECLINED,
	APPROVER_PENDING,
} from '@atlassian/jira-issue-shared-types/src/common/types/approval-type.tsx';
export {
	FROTHER,
	PLAIN_TEXT,
	RICH_CONTENT,
} from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
export {
	INWARD_LINK_DIRECTION,
	OUTWARD_LINK_DIRECTION,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
export { ISSUE_TYPE_HIERARCHY_LEVEL } from '@atlassian/jira-issue-shared-types/src/common/types/issue-hierarchy-type.tsx';

export {
	// Field types
	AFFECTS_VERSIONS_TYPE,
	APPROVALS_CF_TYPE,
	APPROVERS_LIST_CF_TYPE,
	ASSETS_CF_TYPE,
	ASSIGNEE_TYPE,
	BOOLEAN_GQL_FIELD,
	BASELINE_START_CF_TYPE,
	BASELINE_END_CF_TYPE,
	CASCADING_SELECT_CF_TYPE,
	CATEGORY_TYPE,
	CHILDREN_EXCEEDING_LIMIT_GQL_FIELD,
	CHILDREN_ISSUES,
	CHILDREN_ISSUES_CF_TYPE,
	CHILDREN_WITHIN_LIMIT_GQL_FIELD,
	CMDB_OBJECT_CF_TYPE,
	COLOR_CF_TYPE,
	COMPONENTS_TYPE,
	CREATED,
	DATE_CF_TYPE,
	DATETIME_CF_TYPE,
	DESCRIPTION_TYPE,
	DUE_DATE_TYPE,
	ENTITLEMENT_CF_TYPE,
	SENTIMENT_CF_TYPE,
	ENVIRONMENT_TYPE,
	EPIC_COLOR_TYPE,
	EPIC_ISSUES,
	EPIC_ISSUES_CF_TYPE,
	EPIC_NAME_TYPE,
	EPIC_STATUS_TYPE,
	EPIC_TYPE,
	FIRST_RESPONSE_DATE_CF_TYPE,
	FIX_VERSIONS_TYPE,
	FLAGGED_CF_TYPE,
	GRAPHQL_HIERARCHY_LEVEL_ABOVE,
	GRAPHQL_HIERARCHY_LEVEL_BELOW,
	GRAPHQL_HIERARCHY_LEVEL_SAME_LEVEL,
	GROUP_CF_TYPE,
	HIERARCHY_LEVEL_ABOVE,
	HIERARCHY_LEVEL_BELOW,
	HIERARCHY_LEVEL_SAME_LEVEL,
	ISSUE_FIELD_ANY_CF_TYPE,
	ISSUE_FIELD_DATE_CF_TYPE,
	ISSUE_FIELD_DATETIME_CF_TYPE,
	ISSUE_FIELD_NUMBER_CF_TYPE,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
	ISSUE_FIELD_PARENT_ISSUES_TYPE,
	ISSUE_FIELD_STRING_CF_TYPE,
	ISSUE_FIELD_USER_CF_TYPE,
	ISSUE_FIELD_VERSION_CF_TYPE,
	ISSUE_FIELD_OBJECT_CF_TYPE,
	ISSUE_FIELD_SINGLE_GROUP_CF_TYPE,
	ISSUE_FIELD_SINGLE_SELECT_CF_TYPE,
	ISSUE_LINKS_TYPE,
	ISSUE_RESTRICTION_TYPE,
	ISSUE_TYPE,
	LABELS_TYPE,
	LABELS_CF_TYPE,
	MAJOR_INCIDENT_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_USER_CF_TYPE,
	MULTI_VERSION_CF_TYPE,
	NUMBER_CF_TYPE,
	ORGANIZATIONS_CF_TYPE,
	PARENT_TYPE,
	PARENT_CF_TYPE,
	PARENT_ISSUE_TYPE,
	PARENT_ISSUES_CF_TYPE,
	PARTICIPANTS_CF_TYPE,
	PEOPLE_CF_TYPE,
	PORTFOLIO_CHILD_ISSUES,
	PORTFOLIO_ISSUES_CF_TYPE,
	PRIORITY_TYPE,
	PROFORMA_FORMS,
	PROJECT_PICKER_CF_TYPE,
	PROJECT_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	RANK_CF_TYPE,
	READ_ONLY_CF_TYPE,
	REPORTER_TYPE,
	REQUEST_FEEDBACK_DATE_CF_TYPE,
	REQUEST_PARTICIPANTS_CF_TYPE,
	REQUEST_TYPE_CF_TYPE,
	RESOLUTION_DATE,
	RESOLUTION_TYPE,
	REQUEST_LANGUAGE_CF_TYPE,
	RESPONDERS_CF_TYPE,
	SECURITY_LEVEL_TYPE,
	SECURITY_LEVEL_TYPE_OVERRIDE,
	SELECT_CF_TYPE,
	SERVICEDESK_APPROVAL_TYPE,
	SERVICEDESK_INCIDENT_MANAGEMENT_TYPE,
	SERVICE_ENTITY_CF_TYPE,
	SIMILAR_ISSUES_TYPE,
	SPRINT_TYPE,
	STATUS_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	STORY_POINTS_TYPE,
	SUBTASKS_TYPE,
	SUMMARY_TYPE,
	TIME_ESTIMATE_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	TEXT_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	TIME_TRACKING_TYPE,
	UPDATED,
	URL_CF_TYPE,
	USER_CF_TYPE,
	USER_PROPERTY_CF_TYPE,
	VERSION_CF_TYPE,
	LAST_UPDATER_OR_COMMENTER_CF_TYPE,
	VOTES_TYPE,
	WATCHES_TYPE,
	// AGG object types
	AFFECTED_SERVICES_GQL_FIELD,
	CASCADING_SELECT_GQL_FIELD,
	CHECKBOX_GQL_FIELD,
	CMDB_GQL_FIELD,
	COLOR_GQL_FIELD,
	COMPONENTS_GQL_FIELD,
	CONNECT_DATE_TIME_GQL_FIELD,
	CONNECT_NUMBER_GQL_FIELD,
	CONNECT_MULTI_SELECT_GQL_FIELD,
	CONNECT_RICH_TEXT_GQL_FIELD,
	CONNECT_SINGLE_SELECT_GQL_FIELD,
	CONNECT_TEXT_GQL_FIELD,
	DATE_PICKER_GQL_FIELD,
	DATE_TIME_GQL_FIELD,
	EPIC_LINK_GQL_FIELD,
	FLAG_GQL_FIELD,
	ISSUE_RESTRICTION_GQL_FIELD,
	ISSUE_TYPE_GQL_FIELD,
	JSM_APPROVAL_GQL_FIELD,
	JSM_GROUP_APPROVER_PRINCIPAL_FIELD,
	JSM_USER_APPROVER_PRINCIPAL_FIELD,
	JSM_ASSETS_GQL_FIELD,
	JSM_ENTITLEMENTS_GQL_FIELD,
	JSM_SENTIMENT_GQL_FIELD,
	JSM_PEOPLE_GQL_FIELD,
	JSM_ORGANIZATION_GQL_FIELD,
	JSM_REQUEST_FEEDBACK_GQL_FIELD,
	JSM_REQUEST_LANGUAGE_GQL_FIELD,
	JSM_REQUEST_TYPE_GQL_FIELD,
	JSM_RESPONDERS_GQL_FIELD,
	JSM_TEAM_RESPONDER_FIELD,
	JSM_MAJOR_INCIDENT_FIELD,
	JSM_USER_RESPONDER_GQL_FIELD,
	LABEL_GQL_FIELD,
	MULTI_GROUP_PICKER_GQL_FIELD,
	MULTI_SELECT_GQL_FIELD,
	MULTI_USER_PICKER_GQL_FIELD,
	MULTI_VERSION_GQL_FIELD,
	NUMBER_GQL_FIELD,
	ORIGINAL_ESTIMATE_GQL_FIELD,
	PARENT_GQL_FIELD,
	PEOPLE_GQL_FIELD,
	RADIO_GQL_FIELD,
	PRIORITY_GQL_FIELD,
	PROJECT_GQL_FIELD,
	RICH_TEXT_GQL_FIELD,
	RESOLUTION_GQL_FIELD,
	REQUEST_FEEDBACK_CF_TYPE,
	SECURITY_GQL_FIELD,
	SINGLE_GROUP_PICKER_GQL_FIELD,
	SINGLE_SELECT_GQL_FIELD,
	SPRINT_GQL_FIELD,
	STATUS_GQL_FIELD,
	TEAM_CF_TYPE,
	TEAM_GQL_FIELD,
	TEAM_VIEW_GQL_FIELD,
	TEXT_GQL_FIELD,
	TIME_TRACKING_GQL_FIELD,
	URL_GQL_FIELD,
	USER_PICKER_GQL_FIELD,
	VERSION_GQL_FIELD,
	VOTES_GQL_FIELD,
	FORGE_STRING_GQL_FIELD,
	FORGE_DATE_GQL_FIELD,
	FORGE_STRINGS_GQL_FIELD,
	FORGE_NUMBER_GQL_FIELD,
	FORGE_DATETIME_GQL_FIELD,
	FORGE_OBJECT_GQL_FIELD,
	FORGE_GROUP_GQL_FIELD,
	FORGE_USER_GQL_FIELD,
	FORGE_GROUPS_GQL_FIELD,
	FORGE_USERS_GQL_FIELD,
	WATCHES_GQL_FIELD,
	PROFORMA_FORMS_GQL_FIELD,
	SERVICEDESK_PRACTICES_TYPE,
	DATA_CLASSIFICATION_TYPE,
	DATA_CLASSIFICATION_FIELD,
	GOALS_CF_TYPE,
	GOALS_GQL_FIELD,
} from '@atlassian/jira-platform-field-config';

export {
	APP_USER,
	ATLASSIAN_ACCOUNT_USER,
	CUSTOMER_USER,
	UNKNOWN_USER,
} from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';

// Based on `JiraVersionStatus` AGG object type
export const VersionStatus = {
	ARCHIVED: 'ARCHIVED',
	RELEASED: 'RELEASED',
	UNRELEASED: 'UNRELEASED',
} as const;
