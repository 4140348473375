import type { ReactElement } from 'react';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context';
import { queuesNavigationItems } from '@atlassian/jira-router-resources-service-desk-queues';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category';
import type { QueueResourceCustomContext } from '../../types';
import useCategorizedActionsStore from './actions-store';
import type { ChildrenProps } from './types';

type Props = {
	baseUrl: string;
	projectKey: string;
	category: ItsmPractice;
	children: (arg1: ChildrenProps) => ReactElement;
};

export const useCategorizedActions = (
	baseUrl: Props['baseUrl'],
	projectKey: Props['projectKey'],
	category: Props['category'],
	customContext?: QueueResourceCustomContext,
) => {
	const { update } = useResourceWithCustomRouterContext(queuesNavigationItems, customContext);
	const cachedActions = useCategorizedActionsStore(category, update, { baseUrl, projectKey });

	return [cachedActions] as const;
};

const ActionsProvider = ({ baseUrl, projectKey, category, children }: Props) => {
	const [actions] = useCategorizedActions(baseUrl, projectKey, category);
	return children(actions);
};

export default ActionsProvider;
