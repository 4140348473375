import React, {
	Component,
	type ComponentType,
	type ReactNode,
	type SyntheticEvent,
	type ReactElement,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
} from 'react';
import { ComponentWithAnalytics } from '@atlassian/jira-analytics-web-react/src';
import log from '@atlassian/jira-common-util-logging/src/log';
import type {
	FieldDescriptor,
	FieldDataSelectorChildrenProps,
	FieldDataSelectorProps,
} from '../../../model';
import type {
	TableStateConnectorProps,
	TableStateConnectorChildrenProps,
} from '../../../model/cell';
import type { MergedHtmlColumnProps, MergedJsonColumnProps } from '../../../model/columns';
import type { DataSelectorHtmlProps } from '../../../model/fields/field-rendered-value';
import type {
	DataSelectorJsonProps,
	FieldType,
	JsonComponentProps,
} from '../../../model/fields/types';
import FieldRenderedValue from '../../fields/field-rendered-value/view';
import { CellWrapper } from '../styled';
import type { Props as JsonCellProps } from './json-cell';

const LOCATION = 'issue-table.view.create-cell.view';

export const privacySafeRowId = (
	arg: string,
): {
	numericalValue: number | null;
	containsHyphen: boolean;
	length: number;
} => {
	const argSplitByHyphen: string[] = arg.split('-');
	return {
		containsHyphen: argSplitByHyphen.length > 1,
		numericalValue: parseInt(argSplitByHyphen[argSplitByHyphen.length - 1], 10),
		length: arg.length,
	};
};

export type JsonComponentArgs<T extends FieldType> = null | {
	fieldType: T;
	FieldComponent: ComponentType<JsonComponentProps<T>>;
};

export type MergedColumnProps<T extends FieldType> =
	| MergedHtmlColumnProps
	| MergedJsonColumnProps<T>;

export type Props<T extends FieldType> = {
	isUserPickerDisabled: boolean;
	isActive: boolean;
	shouldHydrateFully: boolean;
	canBeMultiLine?: boolean;
	jsonComponentArgs: JsonComponentArgs<T>;
	mergedColumnProps: MergedColumnProps<T>;
	FieldDataSelector: ComponentType<FieldDataSelectorProps>;
	TableStateConnector: ComponentType<TableStateConnectorProps>;
	rowListRef: HTMLDivElement | undefined;
	JsonCell: ComponentType<JsonCellProps<T>>;
	rowId: string;
	columnId?: string;
	width?: number;
	onMount?: () => void;
	onUpdate?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick: (fieldName: string) => (e: SyntheticEvent<any>) => void;
	onEditStart: (arg1: string, arg2: string) => void;
};

// eslint-disable-next-line jira/react/no-class-components
class Cell<T extends FieldType> extends Component<Props<T>> {
	componentDidMount() {
		const { onMount } = this.props;
		if (onMount) {
			onMount();
		}
	}

	componentDidUpdate() {
		const { onUpdate } = this.props;
		if (onUpdate) {
			onUpdate();
		}
	}

	onClick = () => {
		this.props.onClick(this.props.mergedColumnProps.fieldType);
	};

	getFieldDescriptor(): FieldDescriptor {
		const { fieldId, fieldType } = this.props.mergedColumnProps;
		return {
			fieldId,
			fieldType,
		};
	}

	renderLockableField = ({
		fieldData,
		issueFieldLockControls,
		isLastColumn,
		fieldName,
		onSetEditingFieldPosition,
		isFieldLocked,
		isTableDisabled,
		onIssueViewTransition,
	}: TableStateConnectorChildrenProps): ReactElement<ComponentProps<'div'>> | ReactNode => {
		const {
			jsonComponentArgs,
			mergedColumnProps,
			isActive,
			shouldHydrateFully,
			width,
			rowId,
			rowListRef,
			onEditStart,
			isUserPickerDisabled,
			JsonCell,
		} = this.props;
		if (fieldData) {
			// @ts-expect-error - TS2339 - Property 'fieldRenderedValue' does not exist on type 'AnyDataSelectorJsonProps | DataSelectorHtmlProps'. | TS2339 - Property 'fieldCssClass' does not exist on type 'AnyDataSelectorJsonProps | DataSelectorHtmlProps'.
			if (fieldData.fieldRenderedValue != null || fieldData.fieldCssClass != null) {
				const { canBeMultiLine } = this.props;
				return (
					<FieldRenderedValue
						canBeMultiLine={!!canBeMultiLine}
						isLastColumn={isLastColumn}
						doNotRenderTooltip={!!mergedColumnProps.doNotRenderTooltip}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{...(fieldData as DataSelectorHtmlProps)}
					/>
				);
			}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			const fieldDataType: FieldType = (fieldData as any).fieldType;

			if (jsonComponentArgs && jsonComponentArgs.fieldType === fieldDataType) {
				const { FieldComponent } = jsonComponentArgs;
				return (
					<JsonCell
						FieldComponent={FieldComponent}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						fieldData={fieldData as DataSelectorJsonProps<T>}
						tableInjectedProps={{
							fieldId: mergedColumnProps.fieldId,
							isActive,
							issueFieldLockControls,
							onEditStart,
							width,
							shouldHydrateFully,
							isLastColumn,
							issueKey: rowId,
							fieldName,
							rowListRef,
							onSetEditingFieldPosition,
							isFieldLocked,
							isUserPickerDisabled,
							isTableDisabled,
							onIssueViewTransition,
						}}
					/>
				);
			}
		} else {
			log.safeWarnWithoutCustomerData(
				LOCATION,
				`[FD-3358] Rendered an empty cell because a FieldDataSelector returned null without backup data. row-id details: "${JSON.stringify(
					privacySafeRowId(rowId),
				)}", column-type: "${mergedColumnProps.fieldType}"`,
			);
			return <div />;
		}

		throw new Error(
			`[FD-3358] Cannot render cell without either: prerendered html from the data selector OR field json data of the correct type for the current column. row-id details: "${JSON.stringify(
				privacySafeRowId(rowId),
			)}", column-type: "${mergedColumnProps.fieldType}", data-field-type: "${
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				fieldData && (fieldData as any).fieldType
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			}", data-css-class: "${fieldData && (fieldData as any).fieldCssClass}"`,
		);
	};

	renderField = (selectorFieldData: FieldDataSelectorChildrenProps) => {
		const { TableStateConnector, rowId } = this.props;
		return (
			<TableStateConnector
				issueKey={rowId}
				fieldDescriptor={this.getFieldDescriptor()}
				fieldData={selectorFieldData}
			>
				{this.renderLockableField}
			</TableStateConnector>
		);
	};

	render() {
		const { rowId, FieldDataSelector } = this.props;

		return (
			<CellWrapper onClick={this.onClick}>
				<FieldDataSelector issueKey={rowId} fieldDescriptor={this.getFieldDescriptor()}>
					{this.renderField}
				</FieldDataSelector>
			</CellWrapper>
		);
	}
}

export default ComponentWithAnalytics('field', {
	onClick: 'clicked',
	onEditStart: 'locked',
})(Cell);
