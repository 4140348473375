import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';
import type { CustomField, CustomFieldType } from '@atlassian/jira-forge-ui-types';
import { doesExtensionMeetConditions } from '@atlassian/jira-forge-ui-utils/src/utils/conditions';
import { toEcosystemKeys } from '@atlassian/jira-forge-ui-utils/src/utils/connect';
import {
	type ContextPanelItem,
	FORGE_CONTEXT_TYPE,
} from '@atlassian/jira-issue-layout-common-constants';
import { FORGE_ENTITY_TYPE } from '@atlassian/jira-issue-view-common-constants';
import type {
	ForgeStateExtension,
	Panel,
} from '@atlassian/jira-issue-view-common-types/src/forge-types';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { parseForgeIssuePanel } from '@atlassian/jira-issue-view-common-utils/src/ecosystem/module-key-helper';
import { toProjectId } from '@atlassian/jira-shared-types';
import {
	isEcosystemEnabledSelector,
	issueIdSelector,
	issueKeySelector,
	projectKeySelector,
} from '../../common/state/selectors/context-selector';
import { fieldsSelector } from '../../common/state/selectors/field-selector';
import {
	projectIdSelector,
	projectTypeSelector,
} from '../../common/state/selectors/issue-selector';
import { permissionsSelector } from '../../common/state/selectors/permissions-selector';
import { issueTypeIdSelector, issueTypeNameSelector } from '../../selectors/breadcrumbs-selector';
import { ecosystemEntitiesSelector, forgeEntitiesSelector } from '../entities-selector';

const filterExtensions = <E extends ForgeStateExtension, EC extends Object>(
	list: Array<E>,
	staticContext: EC,
) => list.filter((extension) => doesExtensionMeetConditions(extension, staticContext));

const sortIssuePanels = (first: Panel, second: Panel) => {
	if (first.name === second.name) {
		return 0;
	}
	return first.name > second.name ? 1 : -1;
};

export const forgeIssueViewStaticContextSelector = createSelector(
	permissionsSelector,
	issueIdSelector,
	issueKeySelector,
	issueTypeNameSelector,
	projectIdSelector,
	projectKeySelector,
	projectTypeSelector,
	(permissions, issueId, issueKey, issueType, projectId, projectKey, projectType) => ({
		issueId: issueId || null,
		issueKey,
		issueType,
		projectId,
		projectKey,
		projectType: projectType || null,

		// Instead of dumping permissions as is we'd like to normalize prop names a bit
		// as they are exposed externally and have to look respectable
		canAddComments: permissions.canAddComments,
		canAdministerJira: permissions.canAdministerJira,
		canAdministerProjects: permissions.canAdministerProject,
		canArchiveIssue: permissions.canArchiveIssue,
		canAssignIssues: permissions.canAssignIssues,
		canBeAssignedToIssues: permissions.canBeAssignedToIssues,
		canCloneIssues: permissions.canCloneIssue,
		canCreateAttachments: permissions.canCreateAttachments,
		canCreateChildren: permissions.canCreateChildren,
		canCreateSubtasks: permissions.canCreateSubtask,
		canDeleteAllAttachments: permissions.canDeleteAllAttachments,
		canDeleteAllComments: permissions.canDeleteAllComments,
		canDeleteAllWorklogs: permissions.canDeleteAllWorklogs,
		canDeleteIssues: permissions.canDeleteIssue,
		canDeleteOwnAttachments: permissions.canDeleteOwnAttachments,
		canDeleteOwnComments: permissions.canDeleteOwnComments,
		canDeleteOwnWorklogs: permissions.canDeleteOwnWorklogs,
		canEditAllComments: permissions.canEditAllComments,
		canEditAllWorklogs: permissions.canEditAllWorklogs,
		canEditIssues: permissions.canEditIssues,
		canEditOwnComments: permissions.canEditOwnComments,
		canEditOwnWorklogs: permissions.canEditOwnWorklogs,
		canLinkIssues: permissions.canLinkIssues,
		canLogWork: permissions.canLogWork,
		canManageWatchers: permissions.canManageWatchers,
		canModifyReporters: permissions.canModifyReporter,
		canMoveIssues: permissions.canMoveIssue,
		canScheduleIssues: permissions.canScheduleIssues,
		canUnarchiveIssue: permissions.canUnarchiveIssue,
		canUseServiceManagementAgentFeatures: permissions.canUseServiceDeskAgentFeatures,
		canBrowseUsers: permissions.canBrowseUsers,
		canViewDevTools: permissions.canViewDevTools,
		canViewWatchers: permissions.canViewWatchers,
		canExportIssue: permissions.canExportIssue,
	}),
);

// Returns transform forge context panels so they can be used as layout items. This is also done in useForgeContextPanels as a hook
export const forgeContextPanelsSelector = createSelector(
	isEcosystemEnabledSelector,
	forgeEntitiesSelector,
	forgeIssueViewStaticContextSelector,
	(isEcosystemEnabled, forge, issueViewStaticContext) => {
		if (!isEcosystemEnabled || !Array.isArray(forge.issueContexts)) {
			return [];
		}

		return forge.issueContexts
			.filter((extension) => doesExtensionMeetConditions(extension, issueViewStaticContext))
			.map<ContextPanelItem>((contextPanels) => {
				const {
					id,
					properties: { title, label, status, icon: url },
				} = contextPanels;
				return {
					id,
					type: FORGE_CONTEXT_TYPE,
					payload: {
						type: FORGE_CONTEXT_TYPE,
						label,
						name: title,
						status: status || null,
						icon: url ? { url } : null,
						contextPanelType: FORGE_ENTITY_TYPE,
						moduleKey: id,
						appKey: id,
						options: null,
					},
				};
			});
	},
);

export const forgeGlancesSelector = createSelector(
	isEcosystemEnabledSelector,
	forgeEntitiesSelector,
	forgeIssueViewStaticContextSelector,
	(isEcosystemEnabled, forge, issueViewStaticContext) => {
		if (!isEcosystemEnabled || !Array.isArray(forge.glances)) {
			return [];
		}

		return filterExtensions(forge.glances, issueViewStaticContext).map((extension) => {
			const {
				id,
				properties: { title, label, status, icon },
			} = extension;

			return {
				title,
				label,
				status,
				icon,
				extension,
				glanceType: FORGE_ENTITY_TYPE,
				// Legacy props, too much refactoring needed in order to get rid of them
				moduleKey: id,
				appKey: id,
			};
		});
	},
);

export const forgeUiModificationsSelector = createSelector(
	isEcosystemEnabledSelector,
	forgeEntitiesSelector,
	(isEcosystemEnabled, forge) => {
		if (!isEcosystemEnabled || !Array.isArray(forge.uiModifications)) {
			return [];
		}
		return forge.uiModifications;
	},
);

export const forgeIssueActionsSelector = createSelector(
	isEcosystemEnabledSelector,
	forgeEntitiesSelector,
	forgeIssueViewStaticContextSelector,
	(isEcosystemEnabled, forge, issueViewStaticContext) => {
		if (!isEcosystemEnabled || !Array.isArray(forge.actions)) {
			return [];
		}
		return filterExtensions(forge.actions, issueViewStaticContext).map((extension) => {
			const {
				id,
				properties: { title },
			} = extension;

			return {
				id,
				title,
				extension,
				type: FORGE_ENTITY_TYPE,
			};
		});
	},
);

export const forgePanelsSelector = createSelector(
	isEcosystemEnabledSelector,
	forgeEntitiesSelector,
	forgeIssueViewStaticContextSelector,
	(isEcosystemEnabled, forge, issueViewStaticContext) => {
		if (!isEcosystemEnabled || !Array.isArray(forge.panels)) {
			return [];
		}
		return filterExtensions(forge.panels, issueViewStaticContext)
			.filter((extension) => extension.properties.icon != null)
			.map((extension) => {
				const {
					id,
					properties: { title, icon, allowMultiple },
				} = extension;

				const { appKey, moduleKey } = toEcosystemKeys(id);

				return {
					name: title,
					tooltip: title,
					iconUrl: icon,
					extension,
					allowMultiple: !!allowMultiple,
					showByDefault: false,
					type: FORGE_ENTITY_TYPE,
					moduleKey,
					appKey,
				};
			})
			.sort(sortIssuePanels);
	},
);

// TODO ARKEN-384: follow-up task to re-implement issue panel state shouldn't need that
export const forgeUserCanSaveIssuePanelSelector = createSelector(
	isEcosystemEnabledSelector,
	forgeIssueViewStaticContextSelector,
	(isEcosystemEnabled, issueViewStaticContext) =>
		isEcosystemEnabled && issueViewStaticContext.canEditIssues,
);

export const forgeJustAddedPanelSelector = (panelKey: string) =>
	createSelector(
		ecosystemEntitiesSelector,
		(ecosystem) => ecosystem && ecosystem.selectedContentPanel === panelKey,
	);

export const forgeCustomFieldsSelector = createSelector(
	isEcosystemEnabledSelector,
	forgeEntitiesSelector,
	forgeIssueViewStaticContextSelector,
	(isEcosystemEnabled, forge, issueViewStaticContext) => {
		if (!isEcosystemEnabled || !Array.isArray(forge.customFields)) {
			return [];
		}
		return filterExtensions(forge.customFields, issueViewStaticContext);
	},
);

export const forgeCustomFieldTypesSelector = createSelector(
	isEcosystemEnabledSelector,
	forgeEntitiesSelector,
	forgeIssueViewStaticContextSelector,
	(isEcosystemEnabled, forge, issueViewStaticContext) => {
		if (!isEcosystemEnabled || !Array.isArray(forge.customFieldTypes)) {
			return [];
		}
		return filterExtensions(forge.customFieldTypes, issueViewStaticContext);
	},
);

export const hasForgeCustomFieldExtensionsSelector = createSelector(
	forgeCustomFieldsSelector,
	(fields) => fields.length > 0,
);

export const hasForgeCustomFieldTypeExtensionsSelector = createSelector(
	forgeCustomFieldTypesSelector,
	(fieldsTypes) => fieldsTypes.length > 0,
);

/**
 *  @deprecated - use useFetchIssueCustomFieldData instead.
 */
export const hasForgeFieldsExtensionsSelector = createSelector(
	hasForgeCustomFieldExtensionsSelector,
	hasForgeCustomFieldTypeExtensionsSelector,
	(hasCustomFields, hasCustomFieldTypes) => hasCustomFields || hasCustomFieldTypes,
);

export const forgeFieldsSelector = createSelector(
	forgeCustomFieldsSelector,
	forgeCustomFieldTypesSelector,
	(customFields, customFieldTypes) => [...customFields, ...customFieldTypes],
);

export const forgeActiveIssueActionSelector = createSelector(
	isEcosystemEnabledSelector,
	forgeEntitiesSelector,
	(isEcosystemEnabled, forge) => {
		if (!isEcosystemEnabled) {
			return null;
		}
		return forge.activeAction || null;
	},
);

/**
 *  @deprecated - use useFetchIssueCustomFieldData instead.
 */
export const forgeExtensionIdSelector = createSelector(
	fieldsSelector,
	(state: State, { fieldId }: { fieldId: string }) => fieldId,
	(fields, fieldId) => fields[fieldId]?.schema.custom || '',
);

/**
 *  @deprecated - use useFetchIssueCustomFieldData instead.
 */
export const forgeFieldSelector = createSelector(
	forgeFieldsSelector,
	forgeExtensionIdSelector,
	(forgeFields, extensionId) => {
		const extension = forgeFields.find((field) => field.id === extensionId);
		return extension || null;
	},
);

/**
 *  @deprecated - use useFetchIssueCustomFieldData instead.
 */
export const isForgeCustomFieldFilteredOutSelector = createSelector(
	isEcosystemEnabledSelector,
	forgeEntitiesSelector,
	forgeExtensionIdSelector,
	forgeIssueViewStaticContextSelector,
	(isEcosystemEnabled, forge, extensionId, issueViewStaticContext) => {
		if (
			!isEcosystemEnabled ||
			!Array.isArray(forge.customFields) ||
			!Array.isArray(forge.customFieldTypes)
		) {
			return false;
		}
		const extension: CustomField | CustomFieldType | undefined = [
			...forge.customFields,
			...forge.customFieldTypes,
		].find((field) => field.id === extensionId);

		return !extension ? false : !doesExtensionMeetConditions(extension, issueViewStaticContext);
	},
);

/**
 *  @deprecated - use useFetchIssueCustomFieldData instead.
 */
export const shouldForgeCustomFieldUseFormatter = createSelector(
	forgeFieldSelector,
	(extension) => {
		if (!extension) {
			return false;
		}
		const hasRenderingFunction = !isNil(extension.properties.function);
		const hasFormatterDefined = !isNil(extension.properties.formatter);

		return !hasRenderingFunction && hasFormatterDefined;
	},
);

/**
 *  @deprecated - use useFetchIssueCustomFieldData instead.
 */
export const forgeIssueDataSelector = createSelector(
	issueKeySelector,
	issueIdSelector,
	issueTypeIdSelector,
	issueTypeNameSelector,
	projectKeySelector,
	projectIdSelector,
	projectTypeSelector,
	(issueKey, issueId, issueTypeId, issueType, projectKey, projectId, projectType) => ({
		issue: {
			key: issueKey,
			id: issueId,
			type: issueType,
			typeId: issueTypeId,
		},
		project: {
			id: projectId !== null ? toProjectId(projectId.toString()) : projectId,
			key: projectKey,
			type: projectType,
		},
	}),
);

export const forgeIssueActivitySelector = createSelector(
	isEcosystemEnabledSelector,
	forgeEntitiesSelector,
	forgeIssueViewStaticContextSelector,
	(isEcosystemEnabled, forge, issueViewStaticContext) => {
		if (!isEcosystemEnabled || !Array.isArray(forge.actions)) {
			return [];
		}
		return filterExtensions(forge.activities, issueViewStaticContext).map((extension) => {
			const {
				id,
				properties: { title },
			} = extension;

			const { appKey, moduleKey } = toEcosystemKeys(id);

			return {
				name: title,
				extension,
				type: FORGE_ENTITY_TYPE,
				moduleKey,
				appKey,
			};
		});
	},
);

export const isForgePanelNewToIssueSelector = createSelector(
	forgeEntitiesSelector,
	(_: unknown, issuePanelKey: string) => issuePanelKey,
	(forge, issuePanelKey) => forge.panelsKeys.newPanels.includes(issuePanelKey),
);

export const getForgeIssuePanel = (forgePanelKey: string) =>
	createSelector(forgePanelsSelector, (panels) => {
		const { extensionId } = parseForgeIssuePanel(forgePanelKey) || {};
		return panels.find((panel) => extensionId === panel.extension.id);
	});

export const forgeIsCollapsedPanelSelector = createSelector(
	forgeEntitiesSelector,
	(_: unknown, issuePanelKey: string) => issuePanelKey,
	(forge, issuePanelKey) => forge.panelsKeys.collapsedPanels.includes(issuePanelKey),
);
