// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md
// brandfolder asset id = g4nfj272vxmnnng6jh384m
// brandfolder url = https://brandfolder.com/atlassian-brand-creative-library/atlassian-public-asset-library/#!asset/g4nfj272vxmnnng6jh384m
import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/software-development.svg'; // eslint-disable-line import/no-duplicates, jira/import/no-duplicates
import srcLight from '../../assets/software-development.svg'; // eslint-disable-line import/no-duplicates

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 497, 318];
export { srcLight, srcDark, viewBox };
