import {
	QUEUE_ROUTES,
	type QueueCategory,
} from '@atlassian/jira-servicedesk-common/src/navigation/queues';
import type { ProjectKey } from '@atlassian/jira-shared-types';

const getStrippedPathname = (pathname: string) => {
	// strip url params and trailing slashes if they exist
	const pathnameWithoutParams = pathname.split('?')[0];
	const pathnameWithoutTrailingSlash = pathnameWithoutParams.endsWith('/')
		? pathnameWithoutParams.slice(0, -1)
		: pathnameWithoutParams;

	return pathnameWithoutTrailingSlash;
};

// Queue URL Validators
export const isValidViewQueueUrl = (
	projectKey: ProjectKey,
	queueId: string | undefined,
	pathname: string,
) => {
	if (!queueId) {
		return false;
	}

	const currentPath = getStrippedPathname(pathname);
	const constructedPath = `${getQueuesUrl(projectKey)}/custom/${queueId}`;

	return currentPath.endsWith(constructedPath);
};
export const isValidQueuesUrl = (projectKey: ProjectKey, pathname: string) =>
	pathname.includes(getQueuesUrl(projectKey));

// Categorised queue URL Validators
export const isValidCategorisedViewQueueUrl = (
	projectKey: ProjectKey,
	category: QueueCategory,
	queueId: string | undefined,
	pathname: string,
) => {
	if (!queueId) {
		return false;
	}
	if (!category) {
		return isValidViewQueueUrl(projectKey, queueId, pathname);
	}

	const currentPath = getStrippedPathname(pathname);
	const constructedPath = `${getCategorisedQueuesUrl(projectKey, category)}/custom/${queueId}`;

	return currentPath.endsWith(constructedPath);
};
export const isValidCategorisedQueuesUrl = (
	projectKey: ProjectKey,
	category: QueueCategory,
	pathname: string,
) => {
	if (!category) {
		return isValidQueuesUrl(projectKey, pathname);
	}

	return pathname.includes(getCategorisedQueuesUrl(projectKey, category));
};

// URL Constructors
export const getCategorisedQueuesUrl = (projectKey: ProjectKey, category: QueueCategory) => {
	if (!category) {
		return getQueuesUrl(projectKey);
	}

	return `${getJsmProjectUrl(projectKey)}/${QUEUE_ROUTES[category]}`;
};
export const getQueuesUrl = (projectKey: ProjectKey) => `${getJsmProjectUrl(projectKey)}/queues`;
export const getJsmProjectUrl = (projectKey: ProjectKey) =>
	`/jira/servicedesk/projects/${projectKey}`;
