import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import type { QueueId } from '@atlassian/jira-servicedesk-queues-common/src/model';
import type {
	PageResponse,
	PrefetcherPageResponse,
} from '@atlassian/jira-servicedesk-queues-common/src/rest/page/types';
import transform from '@atlassian/jira-servicedesk-queues-common/src/services/issue';
import {
	getBaseUrl,
	getProjectKey,
} from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';
import type { QueuesWithSorting } from '../../model';
import get from '../../rest/page';
import type { State } from '../../state/reducers/types';
import { pageToQueues, getPrefetchedPagePromise, unsetPrefetchedPagePromise } from '../common';
import { addSortingToQueues } from '../sort';

const prefetcherTransformFunc = (
	queueId: QueueId,
	prefetcherPageResponse: PrefetcherPageResponse,
): QueuesWithSorting =>
	addSortingToQueues(queueId, pageToQueues(prefetcherPageResponse.data.queues));

const pageResponseTransformFunc =
	(queueId: QueueId) =>
	(pageResponse: PageResponse): QueuesWithSorting =>
		addSortingToQueues(queueId, pageToQueues(pageResponse.queues));

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (queueId: QueueId, state: State): Observable<QueuesWithSorting> => {
	const prefetchedPagePromise = getPrefetchedPagePromise();
	if (prefetchedPagePromise.isSome()) {
		return Observable.fromPromise(prefetchedPagePromise.some())
			.map((data) => {
				unsetPrefetchedPagePromise();
				// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'PrefetcherPageResponse'.
				return prefetcherTransformFunc(queueId, data);
			})

			.catch(() =>
				transform(
					// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
					get(getProjectKey(state), getBaseUrl(state)),
					pageResponseTransformFunc(queueId),
				),
			);
	}
	return transform(
		// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
		get(getProjectKey(state), getBaseUrl(state)),
		pageResponseTransformFunc(queueId),
	);
};
