import { connect } from '@atlassian/jira-react-redux';
import type { State } from '../../../../state/reducers/types';
import { canManageQueue, isQueueTransient } from '../../../../state/selectors/queue';
import { isManuallySorted } from '../../../../state/selectors/sorting';
import { showIssueFailureError } from '../../../../state/selectors/ui';
import ActionsMenu from './async-actions-menu';
import FavoriteButton from './favorite-button';
import OpenInIssueNavButton from './open-in-issue-nav-button';
import ResetSortingButton from './reset-sorting-button';
import Actions from './view';

const mapStateToProps = (state: State) => ({
	ActionsMenu,
	ResetSortingButton,
	FavoriteButton,
	OpenInIssueNavButton,
	canManageQueue: canManageQueue(state),
	isManuallySorted: isManuallySorted(state),
	isFavoriteEnabled: !isQueueTransient(state),
	showIssueFailureError: showIssueFailureError(state),
});

export default connect(mapStateToProps, {})(Actions);
