import { Component } from 'react';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { fetchJson } from '@atlassian/jira-fetch';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl';
import type { BasicAuthBannerProviderProps } from '../../view/layout/basic-auth-users-banner/types';

type State = {
	needsOAuthWarning: boolean | undefined;
};

// eslint-disable-next-line jira/react/no-class-components
class BasicAuthUserProvider extends Component<BasicAuthBannerProviderProps, State> {
	state = {
		needsOAuthWarning: false,
	};

	componentDidMount() {
		this.fetchEntries();
	}

	// eslint-disable-next-line jira/react-arrow-function-property-naming, jira/react-no-unneeded-arrow-function-properties
	fetchEntries = async () => {
		const { baseUrl, projectKey } = this.props;

		const baseNextState = {
			loading: false,
			error: undefined,
			needsOAuthWarning: this.state.needsOAuthWarning,
		};
		try {
			const needsOAuthWarning = await fetchJson(
				`${baseUrl}/rest/servicedesk/1/servicedesk/${projectKey}/incomingemail/showbanner`,
			);
			this.setState({
				needsOAuthWarning,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			this.setState({ ...baseNextState });
			fireErrorAnalytics({
				meta: {
					id: 'fetchShouldDisplayBasicAuthBanner',
					packageName: 'jiraServicedeskQueuesAgentView',
					teamName: 'jsd-shield',
				},
				error: e,
				sendToPrivacyUnsafeSplunk: true,
			});
			throw e;
		}
	};

	render() {
		const { children } = this.props;
		const { needsOAuthWarning } = this.state;

		return children({
			needsOAuthWarning,
		});
	}
}
export default injectIntl(BasicAuthUserProvider);
