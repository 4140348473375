import { connect } from '../../../../../../../common/table-redux';
import { navigateToRow } from '../../../../../../../ops/rows/navigate';
import { setHorizontalScrollOffset } from '../../../../../../../ops/scrolling/offset';
import {
	getHorizontalScrollOffset,
	getMaxHorizontalScrollOffset,
} from '../../../../../../../read-ops/scrolling';
import { getNavigationMode, isContentStale } from '../../../../../../../state/consumer/selectors';
import { clearActiveItem } from '../../../../../../../state/internal/actions';
import { getActiveRowIndex } from '../../../../../../../state/selectors';
import type { State } from '../../../../../../../state/types';
import KeyboardShortcuts from './index-dumb';

const mapDispatchToProps = {
	navigateToRow,
	clearActiveItem,
	setHorizontalScrollOffset,
} as const;

export default connect(
	(state: State) => ({
		maxHorizontalScrollOffset: getMaxHorizontalScrollOffset(state),
		horizontalScrollOffset: getHorizontalScrollOffset(state),
		navigationMode: getNavigationMode(state),
		activeRowIndex: getActiveRowIndex(state),
		isContentStale: isContentStale(state),
	}),
	mapDispatchToProps,
)(KeyboardShortcuts);
