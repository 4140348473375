import React, { type ReactNode, Component, type KeyboardEvent } from 'react';
import noop from 'lodash/noop';
import keycode from 'keycode';
import { ff } from '@atlassian/jira-feature-flagging';
import type { ActiveItemChangeCallback } from '../../../../../../model/callbacks';
import type {
	CellComponentType,
	CellWrapperComponentType,
	RenderSidebarIcon,
} from '../../../../../../model/cell-component';
import { type ColumnId, SUMMARY } from '../../../../../../model/columns';
import {
	ACTIVE_CELL_TYPE,
	type ActiveCell,
	type NavigateCell,
} from '../../../../../../model/navigation';
import {
	type CellNavigationDirection,
	UP,
	DOWN,
	LEFT,
	RIGHT,
} from '../../../../../../model/navigation-directions';
import type { Optional } from '../../../../../../model/optional';
import type { RowId } from '../../../../../../model/rows';
import { CellWithIconIconWrapper, RestrictedWidthCell, SidebarIcon } from './styled';

const getWrapperStyles = (width: number) => ({
	flex: '0 0 auto',
	width: `${width}px`,
});

const NAVIGATION_KEYS = {
	[keycode('up')]: UP,
	[keycode('right')]: RIGHT,
	[keycode('down')]: DOWN,
	[keycode('left')]: LEFT,
} as const;

type Props = {
	rowId: RowId;
	columnId: ColumnId;
	width: number;
	depth: number;
	maxDepth: number;
	activatedFrom: Optional<CellNavigationDirection>;
	isInCellNavigationMode: boolean;
	isActive: boolean;
	isRowTemporary: boolean;
	isRowHovered: boolean;
	isRowKeyboardActive: boolean;
	shouldHydrateFully: boolean;
	confirmToNavigate: boolean;
	CellComponent: CellComponentType;
	NonTemporaryCellWrapper: Optional<CellWrapperComponentType>;
	canBeMultiLine: boolean;
	activeItemChanged: ActiveItemChangeCallback;
	clearActiveItem: () => void;
	setActiveCell: (arg1: ActiveCell) => void;
	navigateToCell: (cellAndDirection: NavigateCell) => void;
	onCellMount: () => void;
	onCellBlur: () => void; // remove on clean up of jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff
	/*
        parentId and isDraggable props are used in hover function of DropTarget
     */
	parentId: Optional<ColumnId>;
	renderSidebarIcon?: RenderSidebarIcon;
};

type State = {
	shouldFireOnRowBlur: boolean; // remove on clean up of jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff
};
// eslint-disable-next-line jira/react/no-class-components
class CellWrapper extends Component<Props, State> {
	static defaultProps = {
		isRowTemporary: false,
		isRowHovered: false,
		isRowKeyboardActive: false,
		confirmToNavigate: true,
	};

	// remove on clean up of jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff
	constructor(props: Props) {
		super(props);

		this.state = {
			shouldFireOnRowBlur: true,
		};
	}

	shouldComponentUpdate(nextProps: Props) {
		return (
			this.props.rowId !== nextProps.rowId ||
			this.props.columnId !== nextProps.columnId ||
			this.props.width !== nextProps.width ||
			this.props.depth !== nextProps.depth ||
			this.props.maxDepth !== nextProps.maxDepth ||
			this.props.activatedFrom !== nextProps.activatedFrom ||
			this.props.isActive !== nextProps.isActive ||
			this.props.shouldHydrateFully !== nextProps.shouldHydrateFully ||
			this.props.isRowHovered !== nextProps.isRowHovered ||
			this.props.isRowKeyboardActive !== nextProps.isRowKeyboardActive ||
			this.props.confirmToNavigate !== nextProps.confirmToNavigate ||
			this.props.activeItemChanged !== nextProps.activeItemChanged ||
			this.props.renderSidebarIcon !== nextProps.renderSidebarIcon
		);
	}

	componentDidUpdate(prevProps: Props) {
		const { rowId, columnId, isActive, activeItemChanged } = this.props;
		if (!prevProps.isActive && isActive) {
			activeItemChanged({ rowId, columnId });
		}
	}

	onFocus = () => {
		const { rowId, columnId, setActiveCell, isInCellNavigationMode } = this.props;
		if (isInCellNavigationMode) {
			setActiveCell({ type: ACTIVE_CELL_TYPE, rowId, columnId });
		}
	};

	onBlur = () => {
		const { clearActiveItem, isInCellNavigationMode, onCellBlur } = this.props;
		if (isInCellNavigationMode) {
			clearActiveItem();
		}
		if (this.state.shouldFireOnRowBlur) {
			if (!ff('jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff', false)) {
				onCellBlur();
			}
		}
	};

	onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		const { confirmToNavigate, isInCellNavigationMode, columnId } = this.props;
		let direction;

		if (
			!ff('jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff', false) &&
			columnId === SUMMARY &&
			event.keyCode === keycode('tab')
		) {
			this.setState({ shouldFireOnRowBlur: false });
			setTimeout(() => this.setState({ shouldFireOnRowBlur: true }), 100);
		}
		if (!isInCellNavigationMode) {
			return;
		}

		/* Special handling for enter since it can be ignored */
		if (event.keyCode === keycode('enter') && confirmToNavigate) {
			direction = DOWN;
		} else {
			direction = NAVIGATION_KEYS[event.keyCode];
		}

		if (direction) {
			event.preventDefault();
			event.stopPropagation();

			const { rowId, navigateToCell } = this.props;
			navigateToCell({ rowId, columnId, direction });
		}
	};

	getTargetRef = (div?: HTMLElement | null) => {
		this.targetRef = div;
	};

	targetRef: HTMLElement | undefined | null;

	render() {
		const {
			rowId,
			width,
			columnId,
			activatedFrom,
			isActive,
			isRowTemporary,
			isRowHovered,
			isRowKeyboardActive,
			CellComponent,
			NonTemporaryCellWrapper,
			onCellMount,
			canBeMultiLine,
			shouldHydrateFully,
			renderSidebarIcon,
		} = this.props;

		const onMultiLineCellUpdated = canBeMultiLine ? onCellMount : noop;

		const commonProps = {
			rowId,
			width,
			columnId,
			activatedFrom,
			isActive,
			isRowTemporary,
			isRowHovered,
			isRowKeyboardActive,
			onMount: onMultiLineCellUpdated,
			onUpdate: onMultiLineCellUpdated,
			canBeMultiLine,
			shouldHydrateFully,
		};

		/* We just use the wrapping element for bubbling */

		if (renderSidebarIcon) {
			const colWidth = width;

			const renderCell = (): ReactNode => {
				if (columnId === SUMMARY) {
					// Leave 60px space for icon summary cells
					const colAdjust = 60;

					return (
						<CellWithIconIconWrapper>
							<RestrictedWidthCell width={colWidth - colAdjust}>
								<CellComponent {...commonProps} />
							</RestrictedWidthCell>
							{(isRowHovered || isRowKeyboardActive) && (
								<SidebarIcon>{renderSidebarIcon(rowId)}</SidebarIcon>
							)}
						</CellWithIconIconWrapper>
					);
				}

				return <CellComponent {...commonProps} />;
			};

			return (
				<div
					ref={this.getTargetRef}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={getWrapperStyles(width)}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyDown={this.onKeyDown}
					data-testid={`common.components.virtual-table.table.content.rows.common.cell-wrapper-row${rowId}-${columnId}`}
					role="presentation"
				>
					{isRowTemporary || NonTemporaryCellWrapper === undefined ? (
						<>{renderCell()}</>
					) : (
						<NonTemporaryCellWrapper Cell={CellComponent} {...commonProps} />
					)}
				</div>
			);
		}

		return (
			<div
				ref={this.getTargetRef}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={getWrapperStyles(width)}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onKeyDown={this.onKeyDown}
				// eslint-disable-next-line jira/integration/enforce-data-testid-usage
				data-test-id={`common.components.virtual-table.table.content.rows.common.cell-wrapper-row${rowId}-${columnId}`}
				role="presentation"
			>
				{isRowTemporary || NonTemporaryCellWrapper === undefined ? (
					<CellComponent {...commonProps} />
				) : (
					<NonTemporaryCellWrapper Cell={CellComponent} {...commonProps} />
				)}
			</div>
		);
	}
}

export default CellWrapper;
