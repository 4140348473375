import { assoc, chain } from 'icepick';
import {
	type FilterBadQueryAction,
	type FilterQueryChangedAction,
	FILTER_QUERY_CHANGED,
	IS_NEW_FILTER_QUERY_PENDING,
	type IsNewFilterQueryPendingAction,
	IS_FILTER_FOCUSED,
	type IsFilterFocusedAction,
	IS_FILTER_FAILED,
} from '../../../actions/filter';
import { LOAD_ISSUES_SUCCESS, type LoadIssuesSuccessAction } from '../../../actions/issue';
import { initialState } from './constants';
import type { FilterState } from './types';

export type Actions =
	| LoadIssuesSuccessAction
	| FilterQueryChangedAction
	| IsNewFilterQueryPendingAction
	| IsFilterFocusedAction
	| FilterBadQueryAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: FilterState = initialState, action: Actions) => {
	switch (action.type) {
		case LOAD_ISSUES_SUCCESS: {
			const filtered = action.payload.loadedIssuesResponse.isFiltered;

			return chain(state)
				.assoc('isCurrentDataFiltered', filtered)
				.assoc('isNewFilterQueryPending', false)
				.value();
		}
		case FILTER_QUERY_CHANGED: {
			const { filterQuery } = action.payload;

			return assoc(state, 'filterQuery', filterQuery);
		}
		case IS_NEW_FILTER_QUERY_PENDING: {
			const { isNewFilterQueryPending } = action.payload;

			return assoc(state, 'isNewFilterQueryPending', isNewFilterQueryPending);
		}
		case IS_FILTER_FOCUSED: {
			const { isFilterFocused } = action.payload;

			return assoc(state, 'isFilterFocused', isFilterFocused);
		}

		case IS_FILTER_FAILED: {
			return chain(state)
				.assoc('isCurrentDataFiltered', true)
				.assoc('isNewFilterQueryPending', false)
				.value();
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
