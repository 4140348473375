import { connect } from '@atlassian/jira-react-redux';
import {
	getBaseUrl,
	getProjectId,
} from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';
import type { State } from '../../../state/reducers/types';
import { getCurrentQueueOrder } from '../../../state/selectors/queue';
import { isQueueVisible, isLoadingIssues } from '../../../state/selectors/ui';
import DemoDripFeed from './view';

const mapStateToProps = (state: State) => ({
	queueOrder: getCurrentQueueOrder(state),
	isQueueVisible: isQueueVisible(state),
	isLoadingIssues: isLoadingIssues(state),
	projectId: getProjectId(state),
	baseUrl: getBaseUrl(state),
});

export default connect(mapStateToProps, {})(DemoDripFeed);
