import { log } from '@atlassian/jira-common-util-logging';
import { performGetRequest } from '@atlassian/jira-fetch';
import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const jsmGetBoardIssueCountExperience = new UFOExperience(
	'jsm-board-promotion-get-issue-count',
	{
		type: ExperienceTypes.Operation,
		performanceType: ExperiencePerformanceTypes.Custom,
	},
);

const PERMISSION_BASE_URL = '/rest/api/3/mypermissions';
const CREATE_SHARED_OBJECTS_PERMISSION_PARAM = 'permissions=CREATE_SHARED_OBJECTS';

export const getCreateSharedObjectsPermission = async (): Promise<boolean | undefined> => {
	try {
		const myPermission = await performGetRequest(
			`${PERMISSION_BASE_URL}?${CREATE_SHARED_OBJECTS_PERMISSION_PARAM}`,
		);

		return myPermission?.permissions?.CREATE_SHARED_OBJECTS?.havePermission;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'queues-agent-view.services.board-promotion.getCreateSharedObjectsPermission',
			'Unable to fetch create shared objects permission',
			error,
		);
		return undefined;
	}
};

export const getBoardIssueCount = async (
	projectKey: string | undefined,
): Promise<number | undefined> => {
	const issueCountBaseUrl = '/rest/api/2/search';
	const jqlParam = encodeURIComponent(
		`project=${projectKey} and status != Resolved or (status = Resolved and updated >= 1d) ORDER BY Rank ASC`,
	);
	const url = `${issueCountBaseUrl}?jql=${jqlParam}&maxResults=0&fields=id&LIMIT=4001`;
	try {
		jsmGetBoardIssueCountExperience.start();
		const issueCount = await performGetRequest(url);
		jsmGetBoardIssueCountExperience.success();
		return issueCount?.total;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		jsmGetBoardIssueCountExperience.failure();
		log.safeErrorWithoutCustomerData(
			'queues-agent-view.services.board-promotion.getBoardIssueCount',
			`Unable to fetch jql for unresolved issues in project ${projectKey}`,
			error,
		);
		return undefined;
	}
};
