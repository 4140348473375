import { connect } from '@atlassian/jira-react-redux';
import { setIssueKeyContext } from '../../../state/actions/ui';
import { IssueContextListener } from './view';

export type Props = {
	onSetIssueKeyContext: (arg1: string | undefined) => void;
};

const mapDispatchToProps = {
	onSetIssueKeyContext: setIssueKeyContext,
} as const;

export default connect(null, mapDispatchToProps)(IssueContextListener);
