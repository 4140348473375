import React, { type SyntheticEvent, useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { useThemeObserver } from '@atlaskit/tokens';
import HelpPanelButton from '@atlassian/jira-help-panel-button';
import { JSMImporterSurvey, useIsJSMImporterSurveyEnabled } from '@atlassian/jira-importer-survey';
import { FormattedMessage, useIntlV2 as useIntl } from '@atlassian/jira-intl';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal';
import { JSM_EMPTY_QUEUE_CREATE_REQUEST } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { usePracticeList } from '@atlassian/jira-servicedesk-queues-categorized-store';
import type { QueueCategory } from '@atlassian/jira-servicedesk-queues-common/src/model';
import GenericEmptyQueueImage from './common/assets/generic.svg';
import GenericEmptyQueueImageInDarkMode from './common/assets/genericDark.svg';
import { EmptyPracticeQueue } from './common/ui/empty-practice-queue';
import { learnMoreUrl, MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH } from './constants';
import messages from './messages';

export type EmptyStateProps = {
	header: string;
	description: string;
	category: QueueCategory;
	projectId: number;
	showCTA: boolean;
};

export const EmptyQueue = (props: EmptyStateProps) => {
	const { category, projectId, showCTA = true } = props;
	const [practices] = usePracticeList();

	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';
	const openCreateIssueDialogModern = useCallback(
		(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'emptyQueueCreateIssueButton', {
				isAnyWorkCategoryEnabled: false,
			});
			openIssueCreateModal({
				triggerPointKey: JSM_EMPTY_QUEUE_CREATE_REQUEST,
			});
		},
		[openIssueCreateModal],
	);
	const { showJSMImporterSurvey } = useIsJSMImporterSurveyEnabled();

	const { formatMessage } = useIntl();

	// If ITSM Project, render the ITSM empty states
	if (category !== undefined && practices && practices.length > 1) {
		return (
			// passing showJSMImporterSurvey as a prop to reduce 1 API call for getting the value
			<EmptyPracticeQueue projectId={projectId} showJSMImporterSurvey={showJSMImporterSurvey} />
		);
	}

	const IPHButton = (
		<HelpPanelButton
			articleId="5b7BuVaVJqmbyCZ2mbMYHD"
			appearance="link"
			spacing="none"
			fallbackComponent={
				<a target="_blank" href={learnMoreUrl}>
					{formatMessage(messages.emptyQueueLearnMoreText)}
				</a>
			}
		>
			{formatMessage(messages.emptyQueueLearnMoreText)}
		</HelpPanelButton>
	);

	return (
		<EmptyState
			header={formatMessage(messages.emptyQueueHeader)}
			description={
				<FormattedMessage
					{...messages.emptyQueueDescription}
					values={{
						// @ts-expect-error - Type '(chunks: string) => JSX.Element' is not assignable to type 'ReactNode'.
						p: (chunks: string) => <p>{chunks}</p>,
						IPHArticle: IPHButton,
						break: <br />,
					}}
				/>
			}
			imageUrl={isDarkMode ? GenericEmptyQueueImageInDarkMode : GenericEmptyQueueImage}
			maxImageWidth={MAX_IMAGE_WIDTH}
			maxImageHeight={MAX_IMAGE_HEIGHT}
			primaryAction={
				showCTA ? (
					<Button
						appearance="primary"
						onClick={openCreateIssueDialogModern}
						data-pid={projectId}
						testId="servicedesk-queues-empty-queue.create-issue-button"
					>
						{formatMessage(messages.emptyQueueButtonLabel)}
					</Button>
				) : undefined
			}
			secondaryAction={
				showCTA && showJSMImporterSurvey ? <JSMImporterSurvey isFromEmptyState /> : undefined
			}
		/>
	);
};

export default EmptyQueue;
