import React, { useState, type ComponentType } from 'react';
import { Stack, xcss } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import PageHeader from '@atlassian/jira-common-components-page-header';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { QueuesBreadcrumbRenderer } from '@atlassian/jira-servicedesk-queues-breadcrumb';
import { FFErrorBoundary } from '../../../common/ff-error-boundary';
import { messages } from './messages';

export type Props = {
	queueName: string;
	Actions: ComponentType<{ onDeleteComplete: (arg1: string) => void }>;
	IssueCount: ComponentType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	IssueSearch: ComponentType<any>;
	onDeleteComplete: (arg1: string) => void;
	queueId: number | string;
	isQueuesErrorHeaderDisplayed: boolean;
	renderDisplayError: () => void;
};

/**
 * @deprecated: This component is deprecated and will be removed in the future
 * The component was split into multiple components to accomodate nav 4 changes,
 * See QueuesHeader and QueuesDetails for the replacement components
 */
export default function Header(props: Props) {
	const [addTopMargin, setAddTopMargin] = useState(false);

	const {
		queueName,
		onDeleteComplete,
		renderDisplayError,
		Actions,
		IssueCount,
		IssueSearch,
		isQueuesErrorHeaderDisplayed,
	} = props;
	const { formatMessage } = useIntl();

	const handleError = (location?: string) => {
		if (location === 'common.error-boundary.queue-page-header') {
			setAddTopMargin(true);
		}
		renderDisplayError();
	};

	const handleReload = () => {
		window.location.reload();
	};

	const errorSectionDefaultStyles = xcss({
		marginTop: 'space.0',
	});

	const errorMarginTopStyles = xcss({
		marginTop: 'space.400',
	});

	return (
		<FFErrorBoundary onError={handleError} id="queue-page-header-wrapper">
			<FFErrorBoundary onError={handleError} id="queue-page-header">
				<PageHeader
					truncateTitle
					actions={<Actions onDeleteComplete={onDeleteComplete} />}
					breadcrumbs={<QueuesBreadcrumbRenderer />}
				>
					{queueName}
				</PageHeader>
			</FFErrorBoundary>
			<Stack
				space="space.400"
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
				xcss={[errorSectionDefaultStyles, addTopMargin && errorMarginTopStyles]}
			>
				{isQueuesErrorHeaderDisplayed && (
					<SectionMessage
						title={formatMessage(messages.errorSectionTitle)}
						appearance="error"
						actions={
							<SectionMessageAction onClick={handleReload}>
								<FormattedMessage {...messages.reloadPageLinkText} />
							</SectionMessageAction>
						}
					>
						<FormattedMessage {...messages.errorSectionBody} />
					</SectionMessage>
				)}
				<FFErrorBoundary onError={renderDisplayError} id="queue-issue-search">
					<Stack space="space.0">
						<IssueSearch />
						<IssueCount />
					</Stack>
				</FFErrorBoundary>
			</Stack>
		</FFErrorBoundary>
	);
}
