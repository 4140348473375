import { ff } from '@atlassian/jira-feature-flagging';
import type { AggTransformerFunction } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/transformer-type.tsx';
import { FROTHER } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { Version } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import {
	type MULTI_VERSION_GQL_FIELD,
	MULTI_VERSION_CF_TYPE,
	FIX_VERSIONS_TYPE,
	AFFECTS_VERSIONS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { transformAggConnection, transformAggNodeToLegacyField } from './default-node-transformer';
import {
	transformAggVersionToLegacyVersion,
	transformAggVersionToLegacyVersionOld,
} from './version-node-transformer';

const defaultSchema = {
	configuration: null,
	custom: null,
	items: 'version',
	renderer: FROTHER,
	system: null,
	type: 'array',
} as const;

export const transformAggMultipleVersionPickerToLegacyField: AggTransformerFunction<
	typeof MULTI_VERSION_GQL_FIELD
> = (node) => {
	if (
		node.type !== MULTI_VERSION_CF_TYPE &&
		node.type !== AFFECTS_VERSIONS_TYPE &&
		node.type !== FIX_VERSIONS_TYPE
	) {
		return undefined;
	}

	const legacyFieldValues = {
		...transformAggNodeToLegacyField(node),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		allowedValues: transformAggConnection(
			node.multiPickerVersions,
			ff('issue.details.relay-release-version-field-issue-view-integration')
				? transformAggVersionToLegacyVersion
				: transformAggVersionToLegacyVersionOld,
		) as Version[],
		value: transformAggConnection(
			node.selectedVersionsConnection,
			ff('issue.details.relay-release-version-field-issue-view-integration')
				? transformAggVersionToLegacyVersion
				: transformAggVersionToLegacyVersionOld,
		),
	};

	switch (node.type) {
		case MULTI_VERSION_CF_TYPE:
			return {
				...legacyFieldValues,
				value: legacyFieldValues.value.length === 0 ? null : legacyFieldValues.value,
				schema: {
					...defaultSchema,
					custom: MULTI_VERSION_CF_TYPE,
				},
			};
		case AFFECTS_VERSIONS_TYPE:
		case FIX_VERSIONS_TYPE:
			return {
				...legacyFieldValues,
				allowedValues: legacyFieldValues.allowedValues.filter((v) => !v.archived),
				schema: {
					...defaultSchema,
					system: node.type,
				},
			};
		default:
			return undefined;
	}
};
