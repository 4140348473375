import type { Dispatch } from 'redux';
import { connect } from '@atlassian/jira-react-redux';
import { LOAD_ISSUES_ACTION_SOURCE_FILTER } from '../../../../model';
import { isNewFilterQueryPendingAction } from '../../../../state/actions/filter';
import { loadIssuesAction } from '../../../../state/actions/issue';
import type { State } from '../../../../state/reducers/types';
import { getIsCurrentDataFiltered } from '../../../../state/selectors/filter';
import { getIssueCount } from '../../../../state/selectors/issues';
import { isLoadingIssues } from '../../../../state/selectors/ui';
import IssueCount from './view';

const mapDispatchToProps = (
	dispatch: Dispatch<{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		type: any;
	}>,
) => ({
	onFilterReloadClick: () => {
		dispatch(isNewFilterQueryPendingAction(true));
		dispatch(loadIssuesAction(0, LOAD_ISSUES_ACTION_SOURCE_FILTER));
	},
});

const mapStateToProps = (state: State) => ({
	issueCount: getIssueCount(state),
	isCurrentDataFiltered: getIsCurrentDataFiltered(state),
	isLoadingIssues: isLoadingIssues(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueCount);
