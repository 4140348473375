// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type KeyboardEvent } from 'react';
import { set } from 'icepick';
import debounce from 'lodash/debounce';
import defer from 'lodash/defer';
import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import noop from 'lodash/noop';
import keycode from 'keycode';
import uuid from 'uuid';
import Item from './item';
import { itemTypes, type ItemType } from './item-types';
import { Menu, ListWrapper, Explanation } from './styles';
import type { Props, State, TransformedListItem } from './types';

const canSelect = ({ type }: { type: ItemType }): boolean => type !== itemTypes.HEADING;

// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getItemKey = (keyMap: WeakMap<any, any>, item) => {
	if (keyMap.has(item)) {
		return keyMap.get(item);
	}
	const newKey = uuid.v4();
	keyMap.set(item, newKey);
	return newKey;
};

// eslint-disable-next-line jira/react/no-class-components
class MultiSelectList extends PureComponent<Props, State> {
	static defaultProps = {
		items: [],
		type: itemTypes.DEFAULT,
		explanation: undefined,
		maxHeight: undefined,
		canSelectBySpaceKey: true,
		onSelect: noop,
		autoFocus: true,
	};

	constructor(props: Props) {
		super(props);
		this.state = { items: [] };
		this.keyMap = new WeakMap();
	}

	UNSAFE_componentWillMount() {
		this.transformItems(this.props);
	}

	componentDidMount() {
		const { autoFocus } = this.props;
		autoFocus && this.focus();
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.items !== this.props.items || nextProps.type !== this.props.type) {
			this.transformItems(nextProps);
		}
	}

	onKeydown = (event: KeyboardEvent<HTMLElement>) => {
		switch (event.keyCode) {
			case keycode('down'):
				this.navigateFocus({ isPrev: false });
				break;
			case keycode('up'):
				this.navigateFocus({ isPrev: true });
				break;
			case keycode('enter'):
				this.triggerOnSelect();
				break;
			case keycode('space'):
				/* Searchable lists need the space key for typing in the input */
				if (!this.props.canSelectBySpaceKey) {
					return;
				}
				this.triggerOnSelect();
				break;
			default:
				return;
		}
		event.stopPropagation();
		event.preventDefault();
	};

	focus() {
		this.menu && this.menu.focus();
	}

	transformItems(props: Props) {
		const { items, type } = props;
		const { navigateFocusTo, triggerOnSelect } = this;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const transformedItems: TransformedListItem[] = items.reduce<Array<any>>((retVal, item) => {
			const heading = item.heading && {
				...item.heading,
				type: itemTypes.HEADING,
				origin: item.heading,
			};
			// @ts-expect-error - TS2365 - Operator '+' cannot be applied to types 'number' and 'boolean'.
			const index = retVal.length + !!heading;
			const children = item.items.map((child, i) => ({
				...child,
				isFocused: false,
				onHover: navigateFocusTo.bind(this, i + index, child),
				onSelect: triggerOnSelect,
				type,
				origin: child,
			}));

			if (heading) {
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				return [...retVal, heading, ...children];
			}
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			return [...retVal, ...children] as const;
		}, []);

		this.setState(
			{
				items: transformedItems,
			},
			() => {
				const { lastFocusedIndex } = this;
				if (
					lastFocusedIndex !== undefined &&
					transformedItems[lastFocusedIndex] &&
					canSelect(transformedItems[lastFocusedIndex])
				) {
					// refocus
					this.updateFocus(lastFocusedIndex);
				} else {
					// focus first selectable item
					this.lastFocusedIndex = undefined;
					this.navigateFocus({ isPrev: false });
				}
			},
		);
	}

	navigateFocus = ({ isPrev }: { isPrev: boolean }) => {
		const {
			lastFocusedIndex,
			state: { items },
		} = this;
		let nextFocusIndex;
		if (lastFocusedIndex === undefined || lastFocusedIndex >= items.length) {
			nextFocusIndex = findIndex(items, canSelect);
		} else if (isPrev) {
			nextFocusIndex = findLastIndex(items, canSelect, Math.max(lastFocusedIndex - 1, 0));
		} else {
			nextFocusIndex = findIndex(items, canSelect, lastFocusedIndex + 1);
		}
		if (nextFocusIndex !== -1) {
			this.updateFocus(nextFocusIndex);
			// disable mouse move handler when use keyboard navigation
			this.shouldBypassMouseMove();
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	navigateFocusTo = (nextFocusIndex: number, child: any) => {
		if (!this.bypassMouseMove && canSelect(child)) {
			this.updateFocus(nextFocusIndex);
		}
	};

	shouldBypassMouseMove() {
		this.bypassMouseMove = true;
		this.shouldNotByPassMouseMove();
	}

	// should not enable mouse move focus if user keeping navigate by keyboard
	shouldNotByPassMouseMove = debounce(() => {
		this.bypassMouseMove = false;
	}, 300);

	updateFocus(nextFocusIndex: number) {
		const {
			state: { items },
		} = this;
		if (!items[nextFocusIndex].isFocused) {
			this.lastFocusedIndex = nextFocusIndex;
			this.setState(
				{
					items: items.map((item, index) => set(item, 'isFocused', index === nextFocusIndex)),
				},
				() => {
					if (this.list) {
						const focusedItem = this.list.children[nextFocusIndex];
						const { offsetHeight, scrollTop, offsetTop } = this.list;
						const maxVisibleHeight = offsetHeight + scrollTop;
						// @ts-expect-error - TS2339 - Property 'offsetTop' does not exist on type 'Element'.
						const itemTop = focusedItem.offsetTop - offsetTop;
						// @ts-expect-error - TS2339 - Property 'offsetHeight' does not exist on type 'Element'.
						const itemBottom = focusedItem.offsetHeight + itemTop;
						if (itemTop < scrollTop) {
							// item is hide in the top
							this.list.scrollTop = itemTop;
						} else if (maxVisibleHeight < itemBottom) {
							// item is hide in the bottom
							this.list.scrollTop = itemBottom - offsetHeight;
						}
					}
				},
			);
		}
	}

	// @ts-expect-error - TS2564 - Property 'props' has no initializer and is not definitely assigned in the constructor.
	props: Props;

	menu: HTMLDivElement | undefined | null;

	list: HTMLElement | undefined | null;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	keyMap: WeakMap<any, any>;

	// @ts-expect-error - TS2564 - Property 'bypassMouseMove' has no initializer and is not definitely assigned in the constructor.
	bypassMouseMove: boolean;

	lastFocusedIndex: number | undefined;

	/*
        when select changes potentially cause re-render,
        re-render will cause click event pass through the list and flyout close.
    */
	triggerOnSelect = defer.bind(this, () => {
		if (this.lastFocusedIndex !== undefined) {
			this.props.onSelect(this.state.items[this.lastFocusedIndex].origin);
		}
	});

	renderContent() {
		const { explanation } = this.props;
		const {
			keyMap,
			state: { items },
		} = this;

		return (
			<div>
				<ListWrapper
					// @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'string | number'.
					maxHeight={this.props.maxHeight}
					ref={(el) => {
						this.list = el;
					}}
				>
					{items.map(({ origin, ...itemProps }) => (
						<Item key={getItemKey(keyMap, origin)} {...itemProps} />
					))}
				</ListWrapper>
				{explanation && <Explanation>{explanation}</Explanation>}
			</div>
		);
	}

	render() {
		return (
			<Menu
				ref={(el) => {
					this.menu = el;
				}}
				tabIndex={-1}
				onKeyDown={this.onKeydown}
				role="menu"
				// eslint-disable-next-line jira/integration/enforce-data-testid-usage
				data-test-id="virtual-table.table.header.column-configuration.list.flyout.multi-select-list.list.menu"
			>
				{this.renderContent()}
			</Menu>
		);
	}
}

export default MultiSelectList;
