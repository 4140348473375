// eslint-disable-next-line jira/restricted/react
import React, { type ComponentType, PureComponent, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { ComponentWithAnalytics } from '@atlassian/jira-analytics-web-react/src';
import { setMark } from '@atlassian/jira-common-performance';
import { layers } from '@atlassian/jira-common-styles/src';
import type { ExtraErrorAttributes } from '@atlassian/jira-error-boundary';
import { getFeatureFlagValue, ff } from '@atlassian/jira-feature-flagging';
import type {
	SortOrder,
	IssueTableComponent,
	IssueTableRef,
	ColumnProps,
	FieldDataSelectorProps,
	ActiveItemChangeCallback,
	VerticalPosition as IssueTableVerticalPosition,
} from '@atlassian/jira-issue-table/src';
import { useEcClient } from '@atlassian/jira-jsis-ec-client';
import { getIssueTransitionOverride } from '@atlassian/jira-servicedesk-common/src/issue-transition-override';
import {
	getQueueFragment,
	getIssueFragment,
} from '@atlassian/jira-servicedesk-common/src/navigation/queues';
import {
	fromQueueId,
	type QueueId,
	type QueueCategory,
} from '@atlassian/jira-servicedesk-queues-common/src/model';
import { QueuesPushProvider } from '@atlassian/jira-servicedesk-spa-commons';
import { toCloudId } from '@atlassian/jira-shared-types';
import { FFErrorBoundary } from '../../../common/ff-error-boundary';
import {
	DIRECTION_DOWN,
	DIRECTION_UNCHANGED,
	DIRECTION_UP,
	type VerticalPosition,
} from '../../../model';
import { serviceViewPageLoad } from '../../../services/common/performance-analytics';
import { AgentTableApdexProvider } from './apdex';

const isEcClientEnabled = (): boolean => {
	const experiences: string[] =
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(getFeatureFlagValue('hela.ec.client.integration.jsm', '') as string).split(',');

	return Array.isArray(experiences) && experiences.includes('jsm-inline');
};

export type Props = {
	issueKeys: string[];
	isLoading: boolean;
	isQueueVisible: boolean;
	isQueueTransient: boolean;
	isContentStale: boolean;
	queueId: QueueId;
	queueCategory: QueueCategory;
	columns: ColumnProps[];
	IssueTable: IssueTableComponent;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	KeyboardShortcutListener: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	AsyncBulkActionToolbar: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	SelectedIssuesSubscriber: ComponentType<any>;
	currentPage: number;
	totalPages: number;
	sortedBy: string;
	sortOrder: SortOrder;
	fieldTypes: string;
	projectKey: string;
	FieldDataSelector: ComponentType<FieldDataSelectorProps>;
	appProps: {
		baseUrl: string;
		cloudId: string;
		fabricPfDirUrl: string;
		atlassianAccountId: string;
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	DemoDripFeed: ComponentType<any>;
	metricKey: string;
	controlPolling: {
		restartPolling: () => void;
		stopPolling: () => void;
	};
	onActiveItemChanged: ActiveItemChangeCallback;
	onPageChange: (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		event: SyntheticEvent<any>,
		newPage: number,
		analyticsEvent: UIAnalyticsEvent,
	) => void;
	onTableChange: () => void;
	onIssueDataRequested: (startIndex: number) => void;
	onVerticalScrollChanged: (arg1: VerticalPosition) => void;
	hasLoadedIssueDataFromCache: boolean;
	canFocusTable: boolean;
	useJSMQueueDurationEvent: () => void;
	width?: string | number;
	tableWidth?: number;
	renderSidebarIcon?: (issueKey: string) => React.ReactNode;
	shouldRerenderRowOnHover?: boolean;
	onSetSelectedIssueKeys: (selectedIssueKeys: string[] | undefined) => void;
	onRefreshSidebar: ({ issueKey }: { issueKey?: string }) => void;
	onSetIssueKey: (issueKey: string | null) => void;
	onNonPremiumSLAColumnError: (
		location?: string,
		error?: Error,
		errorAttributes?: ExtraErrorAttributes,
	) => void;
};

// min scroll offset before the offset changed callback is called
const MIN_SCROLL_OFFSET_DELTA = 80;

const hasQueueBecomeVisible = (prevProps: Props, nextProps: Props) =>
	!prevProps.isQueueVisible && nextProps.isQueueVisible;

const hasQueueOrPageOrSortingChanged = (prevProps: Props, nextProps: Props) =>
	prevProps.queueId !== nextProps.queueId ||
	prevProps.currentPage !== nextProps.currentPage ||
	prevProps.isLoading !== nextProps.isLoading ||
	prevProps.sortOrder !== nextProps.sortOrder ||
	prevProps.sortedBy !== nextProps.sortedBy;

const onIssueViewTransitionOld = memoizeOne(
	(
		queueId: QueueId,
		projectKey: string,
		queueCategory: QueueCategory,
		isQueueTransient: boolean,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		queuesPush: (path: string) => any /* Observable<any> */,
	) => {
		const push = (issueKey: string) => {
			const path = isQueueTransient
				? getIssueFragment(issueKey)
				: `${getQueueFragment(fromQueueId(queueId), queueCategory)}/${issueKey}`;
			queuesPush(path);
		};

		return getIssueTransitionOverride(push);
	},
);

const onIssueViewTransition = memoizeOne(
	(
		queueId: QueueId,
		projectKey: string,
		queueCategory: QueueCategory,
		isQueueTransient: boolean,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		queuesPush: (path: string) => any /* Observable<any> */,
		onSetIssueKey: (issueKey: string | null) => void,
	) => {
		const push = (issueKey: string) => {
			const path = isQueueTransient
				? getIssueFragment(issueKey)
				: `${getQueueFragment(fromQueueId(queueId), queueCategory)}/${issueKey}`;
			queuesPush(path);
			onSetIssueKey(null);
		};

		return getIssueTransitionOverride(push);
	},
);

// Init tenantContext for ECClient
const SaveInlineMutations = () => {
	useEcClient();
	return null;
};

// eslint-disable-next-line jira/react/no-class-components
export class Table extends PureComponent<Props> {
	constructor(props: Props) {
		super(props);
		setMark('jsd.performance.profile.queues.issuetable.mount');
		this.initECClientEnabled = isEcClientEnabled();
	}

	componentDidMount() {
		if (this.props.canFocusTable) {
			this.focusTable();
		}
		this.props.onTableChange();
		this.prevOffsetFromTop = 0;
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.canFocusTable && hasQueueBecomeVisible(prevProps, this.props)) {
			this.focusTable();
		}
		if (hasQueueOrPageOrSortingChanged(prevProps, this.props)) {
			this.isFirstUpdateAfterQueueChange = true;
			if (this.props.canFocusTable) {
				this.focusTable();
			}
			this.scrollToTop();
			this.props.onTableChange();
		}
	}

	// Placing this here to keep es-lint happy. Error thrown : `....should be placed after componentDidUpdate react/sort-comp`
	initECClientEnabled: boolean; // FF check, which will be deleted during FF cleanup.

	onIssueDataRequested = (startIndex: number) => {
		const { onIssueDataRequested, isContentStale } = this.props;

		if (!this.isFirstUpdateAfterQueueChange && !isContentStale) {
			onIssueDataRequested(startIndex);
		}

		this.isFirstUpdateAfterQueueChange = false;
	};

	onPageChange = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		event: SyntheticEvent<any>,
		newPage: number,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		this.props.onPageChange(event, newPage, analyticsEvent);
	};

	onSetIssueTableRef = (ref: IssueTableRef | null) => {
		this.issueTableRef = ref;
	};

	onVerticalScrollOffsetChanged = (verticalPosition: IssueTableVerticalPosition) => {
		const { offsetFromTop } = verticalPosition;
		const { onVerticalScrollChanged } = this.props;
		let direction = DIRECTION_UNCHANGED;
		if (Math.abs(offsetFromTop - this.prevOffsetFromTop) > MIN_SCROLL_OFFSET_DELTA) {
			// @ts-expect-error - TS2322 - Type '"UP" | "DOWN"' is not assignable to type '"UNCHANGED"'.
			direction = offsetFromTop > this.prevOffsetFromTop ? DIRECTION_DOWN : DIRECTION_UP;
			this.prevOffsetFromTop = offsetFromTop;
		}
		onVerticalScrollChanged &&
			onVerticalScrollChanged({
				...verticalPosition,
				direction,
			});
	};

	// @ts-expect-error - TS2564 - Property 'issueTableRef' has no initializer and is not definitely assigned in the constructor.
	issueTableRef: IssueTableRef | null;

	// @ts-expect-error - TS2564 - Property 'prevOffsetFromTop' has no initializer and is not definitely assigned in the constructor.
	prevOffsetFromTop: number;

	isFirstUpdateAfterQueueChange = false;

	scrollToTop() {
		const { issueTableRef } = this;
		issueTableRef && issueTableRef.scrollToTop();
	}

	focusTable() {
		const { issueTableRef } = this;
		issueTableRef && issueTableRef.focusContent();
	}

	render() {
		const {
			columns,
			onActiveItemChanged,
			IssueTable,
			DemoDripFeed,
			issueKeys,
			isContentStale,
			isLoading,
			isQueueVisible,
			metricKey,
			currentPage,
			totalPages,
			queueId,
			queueCategory,
			isQueueTransient,
			projectKey,
			FieldDataSelector,
			controlPolling,
			KeyboardShortcutListener,
			AsyncBulkActionToolbar,
			SelectedIssuesSubscriber,
			appProps: { cloudId, fabricPfDirUrl, atlassianAccountId },
			hasLoadedIssueDataFromCache,
			useJSMQueueDurationEvent,
			renderSidebarIcon,
			shouldRerenderRowOnHover,
			width,
			tableWidth,
			onSetSelectedIssueKeys,
			onRefreshSidebar,
			onSetIssueKey,
			onNonPremiumSLAColumnError,
		} = this.props;

		return (
			<OuterContainer
				data-testid="servicedesk-queues-agent-view.layout.table.out"
				width={tableWidth}
			>
				<Container width={width} data-testid="servicedesk-queues-agent-view.layout.table.container">
					<AgentTableApdexProvider
						queueId={queueId}
						isLoading={isLoading}
						isVisible={isQueueVisible}
						metricKey={metricKey}
						category={queueCategory}
						// @ts-expect-error - TS2322 - Type 'PageLoadMetric' is not assignable to type 'BM3Metric'.
						metric={serviceViewPageLoad}
						hasLoadedIssueDataFromCache={hasLoadedIssueDataFromCache}
					>
						<QueuesPushProvider projectKey={projectKey}>
							{({ push }) => (
								<IssueTableAnchor>
									<FFErrorBoundary
										onError={onNonPremiumSLAColumnError}
										id="queue-sidebar-bulk-edit-bug-fix"
									>
										<ToolbarPositioning>
											{ff('jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff', false) ? (
												<AsyncBulkActionToolbar
													controlPolling={controlPolling}
													width={tableWidth}
												/>
											) : (
												<AsyncBulkActionToolbar controlPolling={controlPolling} />
											)}
										</ToolbarPositioning>
									</FFErrorBoundary>
									<SelectedIssuesSubscriber>
										{/* @ts-expect-error - TS7031 - Binding element 'selectedIssueKeys' implicitly has an 'any' type. */}
										{({ issueKeys: selectedIssueKeys }) => {
											onSetSelectedIssueKeys(selectedIssueKeys);
											return (
												<KeyboardShortcutListener isDisabled={isContentStale}>
													{this.initECClientEnabled && <SaveInlineMutations />}
													<IssueTable
														ref={this.onSetIssueTableRef}
														isContentStale={isContentStale}
														isDisabled={isContentStale}
														columns={columns}
														issueKeys={issueKeys}
														selectedIssueKeys={selectedIssueKeys}
														contentKey={String(queueId)}
														FieldDataSelector={FieldDataSelector}
														onActiveItemChanged={onActiveItemChanged}
														onIssueDataRequested={this.onIssueDataRequested}
														onVerticalScrollOffsetChanged={this.onVerticalScrollOffsetChanged}
														currentPage={currentPage}
														onPageChange={this.onPageChange}
														totalPages={totalPages}
														appProps={{
															cloudId: toCloudId(cloudId),
															fabricPfDirUrl,
															atlassianAccountId,
															premiumSLAColumns: ['issuekey', 'summary', 'reporter'],
														}}
														onIssueViewTransition={
															// eslint-disable-next-line no-nested-ternary
															push
																? ff('jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff')
																	? onIssueViewTransition(
																			queueId,
																			projectKey,
																			queueCategory,
																			isQueueTransient,
																			push,
																			onSetIssueKey,
																		)
																	: onIssueViewTransitionOld(
																			queueId,
																			projectKey,
																			queueCategory,
																			isQueueTransient,
																			push,
																		)
																: undefined
														}
														useJSMQueueDurationEvent={useJSMQueueDurationEvent}
														renderSidebarIcon={renderSidebarIcon}
														refreshSidebar={onRefreshSidebar}
														shouldRerenderRowOnHover={shouldRerenderRowOnHover}
													/>
												</KeyboardShortcutListener>
											);
										}}
									</SelectedIssuesSubscriber>
								</IssueTableAnchor>
							)}
						</QueuesPushProvider>
					</AgentTableApdexProvider>
					<DemoDripFeed />
				</Container>
			</OuterContainer>
		);
	}
}

export default ComponentWithAnalytics('queue', { onTableChange: 'viewed' })(Table);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const Container = styled.div<{ width?: string | number }>(({ width }) =>
	width !== undefined
		? {
				flexGrow: 1,
				width: `${width}px`,
			}
		: {
				flexGrow: 1,
			},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const OuterContainer = styled.div<{ width?: number }>(({ width }) =>
	width !== undefined
		? {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor: token('elevation.surface', colors.N0),
				display: 'flex',
				flexGrow: 1,
				width: `${width}px`,
			}
		: {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor: token('elevation.surface', colors.N0),
				display: 'flex',
				flexGrow: 1,
			},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ToolbarPositioning = styled.div({
	position: 'absolute',
	top: token('space.100', '8px'),
	left: token('space.600', '48px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.dialog,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueTableAnchor = styled.div({
	position: 'relative',
	height: '100%',
});
