import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import take from 'lodash/take';
import without from 'lodash/without';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { BULK_SELECTION_LIMIT } from '../../constants';
import type { State } from '../../types';

export const refreshSelection =
	(existingIssueKeys: IssueKey[]): Action<State> =>
	({ getState, setState }) => {
		const { issueKeys } = getState();

		const deselectIssueKeys = difference(issueKeys, existingIssueKeys);

		!isEmpty(deselectIssueKeys) &&
			setState({
				issueKeys: take(without(issueKeys, ...deselectIssueKeys), BULK_SELECTION_LIMIT),
			});
	};
