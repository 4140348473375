import React, { Component, type ComponentType, createRef } from 'react';
import { styled } from '@compiled/react';
import InlineDialog from '@atlaskit/inline-dialog';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { UnselectableChildGutterPaddingContainer } from '../styled';

type State = {
	dialogOpen: boolean;
	isHovered: boolean;
};

type Props = {
	isLastColumn: boolean;
	tooltipMessage: string;
	tooltipTitle: string | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Icon: ComponentType<any>;
	color: string;
	backgroundColor: string;
};

const DIALOG_MAX_WIDTH = gridSize * 36;
const TABLE_LEFT_MARGIN = gridSize * 5;

// eslint-disable-next-line jira/react/no-class-components
export default class Alert extends Component<Props, State> {
	static defaultProps = {
		isLastColumn: false,
		tooltipTitle: undefined,
	};

	state = {
		dialogOpen: false,
		isHovered: false,
	};

	onClick = () => this.setState({ dialogOpen: !this.state.dialogOpen });

	onMouseEnterContainer = () =>
		this.setState({
			...this.state,
			isHovered: true,
		});

	onMouseLeaveContainer = () =>
		this.setState({
			...this.state,
			isHovered: false,
		});

	calculateDialogPosition() {
		if (this.alertRef.current) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			const { left } = this.alertRef.current.getBoundingClientRect();

			if (
				this.alertRef.current &&
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				this.alertRef.current.offsetParent &&
				left >
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					this.alertRef.current.offsetParent.getBoundingClientRect().left +
						DIALOG_MAX_WIDTH +
						TABLE_LEFT_MARGIN
			) {
				return 'bottom-end';
			}
		}

		return 'bottom-start';
	}

	alertRef = createRef();

	renderDialogContent() {
		return (
			<DialogContainer>
				{this.props.tooltipTitle && <b>{this.props.tooltipTitle}</b>}
				<p>{this.props.tooltipMessage}</p>
			</DialogContainer>
		);
	}

	render() {
		const { isLastColumn, Icon, color, backgroundColor } = this.props;

		return (
			<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
				<InlineDialog
					placement={this.calculateDialogPosition()}
					content={this.renderDialogContent()}
					isOpen={this.state.dialogOpen}
					onClose={this.onClick}
				>
					<Container
						onClick={this.onClick}
						color={color}
						backgroundColor={backgroundColor}
						/* @ts-expect-error - TS2322 - Type 'RefObject<unknown>' is not assignable to type '{ current: HTMLDivElement | null; }'. */
						ref={this.alertRef}
						tabIndex={0}
						onMouseEnter={this.onMouseEnterContainer}
						onMouseLeave={this.onMouseLeaveContainer}
						isHovered={this.state.isHovered}
					>
						<IconContainer>
							<Icon color={color} />
						</IconContainer>
					</Container>
					<Container
						onClick={this.onClick}
						color={color}
						backgroundColor={backgroundColor}
						/* @ts-expect-error - TS2322 - Type 'RefObject<unknown>' is not assignable to type '{ current: HTMLDivElement | null; }'. */
						ref={this.alertRef}
						tabIndex={0}
					>
						<IconContainer>
							<Icon color="currentColor" />
						</IconContainer>
					</Container>
				</InlineDialog>
			</UnselectableChildGutterPaddingContainer>
		);
	}
}

interface ContainerProps {
	color: string;
	backgroundColor: string;
	isHovered?: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.button<ContainerProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: ({ color }) => `2px solid ${color}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ color }) => color,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor }) => backgroundColor,
	borderRadius: '6px',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 5}px`,
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isHovered }) => (isHovered ? 'pointer' : 'auto'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div({
	marginTop: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 36}px`,
});
