import type { SetStateAction } from 'react';
import type { ForgeResponse } from '@atlassian/jira-issue-fetch-services/src/types';
import type { StoreApi } from '../types';
import { calculateRateLimitedUntil } from './utils';

export const setForge =
	(forge: ForgeResponse & { isForgeLoadingFailed: boolean; isForgeDataComplete: boolean }) =>
	({ setState }: StoreApi) => {
		setState(forge);
	};

export const setForgeLoadingFailed =
	() =>
	({ setState }: StoreApi) => {
		setState({ isForgeLoadingFailed: true });
	};

export const setFailedEventsCount =
	(param: SetStateAction<number>) =>
	({ setState, getState }: StoreApi) => {
		if (typeof param === 'function') {
			const currentFailedEventsCount = getState().failedEventsCount;
			const result = param(currentFailedEventsCount);
			setState({
				failedEventsCount: result,
				forgeRateLimitedUntil: calculateRateLimitedUntil(result),
			});
			return;
		}

		setState({
			failedEventsCount: param,
			forgeRateLimitedUntil: calculateRateLimitedUntil(param),
		});
	};

export const isForgeRateLimited =
	() =>
	({ getState }: StoreApi) => {
		const state = getState();
		const throttleTime = state.forgeRateLimitedUntil - Date.now();
		return throttleTime > 0;
	};
