import { batchActions } from 'redux-batched-actions';
import 'rxjs/add/observable/zip';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';
import { combineEpics } from 'redux-observable';
import flattenDeep from 'lodash/flattenDeep';
import size from 'lodash/size';
import { Observable } from 'rxjs/Observable';
import { errorHandlerFactory } from '../errors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericAction = any;

type TransactionTreeLeaf = GenericAction | Observable<GenericAction> | Observable<GenericAction[]>;
type TransactionTreeNode = TransactionTreeLeaf | TransactionTreeNode[];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TransactionFunction = (payload?: any, state?: any) => TransactionTreeNode;

const errorHandler = errorHandlerFactory();

export const defineTransaction =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(type: string, callback: TransactionFunction) => (action$: any, store: any) =>
		action$
			.ofType(type)
			// @ts-expect-error - TS7006 - Parameter 'payload' implicitly has an 'any' type.
			.flatMap((payload) => {
				const callbackResult = callback ? callback(payload, store.getState()) : [];

				const flatTree = Array.isArray(callbackResult)
					? flattenDeep(callbackResult)
					: [callbackResult];

				const observables: TransactionTreeLeaf[] = [];

				flatTree.forEach((leaf: TransactionTreeLeaf) => {
					if (leaf instanceof Observable) {
						observables.push(leaf);
					} else {
						observables.push(Observable.of(leaf));
					}
				});

				if (size(observables) === 1) {
					return observables[0];
				}

				return Observable.zip(...observables, (...resolvedActions) =>
					// @ts-expect-error - TS2345 - Argument of type 'unknown[]' is not assignable to parameter of type 'Action[]'.
					batchActions(flattenDeep(resolvedActions)),
				);
			})
			.catch(errorHandler);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultGeneratePayload = (action: any) => action.payload;

export const defineAPIOp =
	<TAction, TPayload, TOutput, TState>(
		type: string,
		method: (arg1: TPayload) => Observable<TOutput>,
		callback?: TransactionFunction,
		generatePayload?: (arg1: TAction, arg2: TState) => TPayload,
	) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(action$: any, store: any) =>
		action$
			.ofType(type)
			// @ts-expect-error - TS7006 - Parameter 'action' implicitly has an 'any' type.
			.flatMap((action) => {
				const observable = method(
					generatePayload === undefined
						? defaultGeneratePayload(action)
						: generatePayload(action, store.getState()),
				);

				if (typeof callback !== 'undefined') {
					return observable.map((response) => {
						const actions = callback ? callback(response, store.getState()) : [];

						if (Array.isArray(actions)) {
							return batchActions(actions);
						}
						return actions;
					});
				}
				return observable.ignoreElements();
			})
			.catch(errorHandler);

/*
 * Defines transaction that dispatches set of action `before` and `after` api call
 */
export const defineStandardOp = <TAction, TPayload, TOutput, TState>(
	type: string,
	before: TransactionFunction,
	method: (arg1: TPayload) => Observable<TOutput>,
	after?: TransactionFunction,
	generatePayload?: (arg1: TAction, arg2: TState) => TPayload,
) => {
	const optimisticTransaction = defineTransaction(type, before);

	const apiTransaction = defineAPIOp(type, method, after, generatePayload);

	return combineEpics(optimisticTransaction, apiTransaction);
};
