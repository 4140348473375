// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md

import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/balloons-darkmode.svg';
import srcLight from '../../assets/balloons.svg';

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 595, 601];
export { srcLight, srcDark, viewBox };
