import isEqual from 'lodash/isEqual';
import type { APIState } from '../../../model/api';
import type { Column } from '../../../model/columns';
import { setConsumerState } from '../../../state/consumer/actions';
import { isInRowNavigationMode } from '../../../state/consumer/selectors';
import { hasAnyMultiLineColumn, getAllColumnIds } from '../../../state/consumer/selectors/columns';
import { getAddedRows, getRowTree } from '../../../state/consumer/selectors/rows';
import {
	setSortOrderWithOverride,
	setSortedRowIdsHash,
	clearActiveItem,
	clearRowHeightMapping,
} from '../../../state/internal/actions';
import {
	getActiveSortedColumnConfiguration,
	getSortedRowIdsHash,
} from '../../../state/internal/selectors';
import { getActiveRowIndex } from '../../../state/selectors';
import type { State } from '../../../state/types';
import getSortedInternalState from '../../common/sorting';
import { defineTransaction } from '../../common/transactions';
import { setActiveRow } from '../../items/active/set';
import { UPDATE_STATE_FROM_PROPS, type UpdateStateFromPropsAction } from './action';

const getActiveRowSync = (state: State, consumerState: APIState) => {
	if (isInRowNavigationMode(state)) {
		const activeRowIndex = getActiveRowIndex(state);
		const { rootIds } = consumerState.rowTree;

		if (consumerState.contentKey !== state.consumer.contentKey) {
			return [clearActiveItem()];
		}

		if (activeRowIndex !== undefined) {
			if (!rootIds.length) {
				return [clearActiveItem()];
			}
			if (rootIds[activeRowIndex] === undefined) {
				return [
					setActiveRow({
						rowIndex: rootIds.length - 1,
					}),
				];
			}
			// Causes the row to refocus in the case that it is rerendered
			return [
				setActiveRow({
					rowIndex: activeRowIndex,
					setFocusOnRow: true,
				}),
			];
		}
	}
	return [];
};

const isSameColumnSet = (state: State, nextColumns: Column[]) => {
	const prevColumnIds = getAllColumnIds(state);
	const nextColumnIds = nextColumns.map((column) => column.id);
	return isEqual(new Set([prevColumnIds]), new Set([nextColumnIds]));
};

// Strictly speaking the property we care about is the that the width of
// multiline columns is unchanged, as this may in turn influence cell height
// if any multi-line column is present.
//
// Invalidating this isn't essential, but may save briefly underbuffering w/
// virtualisation.  Hence we use the same set of columns as a proxy for change
// to multiline column width.
const isRowHeightMapStale = (state: State, nextColumns: Column[]) =>
	!isSameColumnSet(state, nextColumns) && hasAnyMultiLineColumn(state);

const getRowHeightMappingInvalidation = (state: State, consumerState: APIState) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const nextColumns: Column[] = Object.values(consumerState.columnTree.columns) as any;
	if (isRowHeightMapStale(state, nextColumns)) {
		return [clearRowHeightMapping()];
	}
	return [];
};

const getConditionalActions = (state: State, consumerState: APIState) => [
	...getActiveRowSync(state, consumerState),
	...getRowHeightMappingInvalidation(state, consumerState),
];

export default defineTransaction(
	UPDATE_STATE_FROM_PROPS,
	(action: UpdateStateFromPropsAction, state: State) => {
		const { defaultSortedColumn, ...consumerState } = action.payload;

		const sortedRowIdsHash = getSortedInternalState(
			getAddedRows(state),
			getRowTree(state),
			getActiveSortedColumnConfiguration(state),

			true, // sortOverride enabled
			getActiveSortedColumnConfiguration(state),
			getSortedRowIdsHash(state),

			consumerState.addedRows,
			consumerState.rowTree,
			consumerState.columnComparators,
			consumerState.defaultComparator,
		);

		consumerState.callbacks.onSortedRowIdsChanged &&
			consumerState.callbacks.onSortedRowIdsChanged(sortedRowIdsHash);

		return [
			setConsumerState(consumerState),
			...getConditionalActions(state, action.payload),
			setSortOrderWithOverride({
				isSortOverrideEnabled: true,
			}),
			setSortedRowIdsHash(sortedRowIdsHash),
		] as const;
	},
);
