/**
 * This copies the logic that currently exists in the backend monolith
 * in PageLanguageTools.java into the Jira frontend so that it can be used
 * by pages in the SPA.
 *
 * (https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-plugins/jira-servicedesk/sd-components/project-ui/src/main/java/com/atlassian/servicedesk/project/internal/web/action/PageLanguageTools.java)
 */

import includes from 'lodash/includes';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';

const SUPPORTED_LANGUAGES = [
	'af',
	'ar-ma',
	'ar-sa',
	'ar-tn',
	'ar',
	'az',
	'be',
	'bg',
	'bn',
	'bo',
	'br',
	'bs',
	'ca',
	'cs',
	'cv',
	'cy',
	'da',
	'de-at',
	'de',
	'el',
	'en-au',
	'en-ca',
	'en-gb',
	'eo',
	'es',
	'et',
	'eu',
	'fa',
	'fi',
	'fo',
	'fr-ca',
	'fr',
	'fy',
	'gl',
	'he',
	'hi',
	'hr',
	'hu',
	'hy-am',
	'id',
	'is',
	'it',
	'ja',
	'jv',
	'ka',
	'km',
	'ko',
	'lb',
	'lt',
	'lv',
	'me',
	'mk',
	'ml',
	'mr',
	'ms-my',
	'my',
	'nb',
	'ne',
	'nl',
	'nn',
	'pl',
	'pt-br',
	'pt',
	'ro',
	'ru',
	'si',
	'sk',
	'sl',
	'sq',
	'sr-cyrl',
	'sr',
	'sv',
	'ta',
	'th',
	'tl-ph',
	'tr',
	'tzm-latn',
	'tzm',
	'uk',
	'uz',
	'vi',
	'zh-cn',
	'zh-tw',
];

const isSupportedLanguage = (language: string) => includes(SUPPORTED_LANGUAGES, language);

const getLanguage = (locale: Locale) => {
	const localeStr = locale.replace('_', '-');
	if (isSupportedLanguage(localeStr)) {
		return localeStr;
	}

	return localeStr.split('-')[0];
};

const addMomentLanguage = (resources: string[], locale: Locale) => {
	const language = getLanguage(locale);

	if (language !== 'en' && isSupportedLanguage(language)) {
		return resources.concat([`com.atlassian.servicedesk.core-ui:momentjs-sd-${language}`]);
	}
	return resources;
};

export const getMomentResources = (locale: Locale): string[] =>
	addMomentLanguage(['com.atlassian.servicedesk.core-ui:lib-moment'], locale);
