import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { combineEpics, type ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { SET_CONSUMER_STATE } from '../../../state/consumer/actions';
import { getHorizontalScrollOffsetChangedCallback } from '../../../state/consumer/selectors';
import { SET_HORIZONTAL_SCROLL_OFFSET, SET_TABLE_SIZE } from '../../../state/internal/actions';
import { getOffsetFromLeft, getOffsetFromRight } from '../../../state/selectors';
import type { State } from '../../../state/types';
import { withUnpackedBatchActions } from '../../common/batch';
import { errorHandlerFactory } from '../../common/errors';

const DEBOUNCE_MILLIS = 100;

const handleOffsetChange = (store: MiddlewareAPI<State>) => {
	const state = store.getState();
	const onHorizontalScrollOffsetChanged = getHorizontalScrollOffsetChangedCallback(state);

	if (onHorizontalScrollOffsetChanged) {
		const offsetFromLeft = getOffsetFromLeft(state);
		const offsetFromRight = getOffsetFromRight(state);
		onHorizontalScrollOffsetChanged({ offsetFromLeft, offsetFromRight });
	}

	return Observable.empty<never>();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debouncedEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) => {
	const errorHandler = errorHandlerFactory();
	return (
		withUnpackedBatchActions(action$)
			// @ts-expect-error - TS2339 - Property 'ofType' does not exist on type 'Observable<any>'.
			.ofType(SET_HORIZONTAL_SCROLL_OFFSET, SET_TABLE_SIZE)
			.debounceTime(DEBOUNCE_MILLIS)
			.mergeMap(() => handleOffsetChange(store))
			.catch(errorHandler)
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const consumerStateEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) => {
	const errorHandler = errorHandlerFactory();
	return (
		withUnpackedBatchActions(action$)
			// @ts-expect-error - TS2339 - Property 'ofType' does not exist on type 'Observable<any>'.
			.ofType(SET_CONSUMER_STATE)
			.mergeMap(() => handleOffsetChange(store))
			.catch(errorHandler)
	);
};

export default combineEpics(debouncedEpic, consumerStateEpic);
