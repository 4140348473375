import React, { type ComponentType } from 'react';
import { injectIntlV2 as injectIntl, type IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import { JsmPageInteractiveEvent } from '@atlassian/jira-servicedesk-common';
import { getAgentViewMarks } from '@atlassian/jira-servicedesk-queues-common/src/analytics';
import {
	type QueueId,
	fromQueueIdString,
	type QueueCategory,
} from '@atlassian/jira-servicedesk-queues-common/src/model';
import { SpaStatePageReadyOnUpdate } from '@atlassian/jira-spa-state-controller/src/components';
import { serviceViewPageLoad } from '../../../services/common/performance-analytics';
import type { EmptyStateMessage } from './types';

export type Props = {
	isQueueVisible: boolean;
	projectId: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	EmptyQueueComponent: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	DemoDripFeed: ComponentType<any>;
	queueId: QueueId;
	emptyStateMessage: EmptyStateMessage;
	metricKey: string;
	category: QueueCategory;
	intl: IntlShape;
};

export function EmptyQueue({
	intl: { formatMessage },
	projectId,
	isQueueVisible,
	queueId,
	EmptyQueueComponent,
	DemoDripFeed,
	emptyStateMessage: { header, description },
	metricKey,
	category,
}: Props) {
	return (
		<>
			{isQueueVisible && <SpaStatePageReadyOnUpdate comparer={[queueId]} />}
			<EmptyQueueComponent
				projectId={projectId}
				header={formatMessage(header)}
				description={formatMessage(description)}
				category={category}
			/>
			{isQueueVisible && (
				<JsmPageInteractiveEvent
					key={fromQueueIdString(queueId)}
					metricKey={metricKey}
					withMarks={getAgentViewMarks()}
					// @ts-expect-error - TS2322 - Type 'PageLoadMetric' is not assignable to type 'BM3Metric'.
					metric={serviceViewPageLoad}
					extra={{ category: category || 'no-category', empty: true }}
				/>
			)}
			{DemoDripFeed ? <DemoDripFeed /> : null}
		</>
	);
}

export default injectIntl(EmptyQueue);
