import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	bannerMessage: {
		id: 'servicedesk.queues.agent-view.layout.basic-auth-users-banner.banner-message',
		defaultMessage:
			'You must reconnect your Microsoft email in {boldStart}Project settings > Email requests{boldEnd} to continue receiving customer requests. ',
		description: 'Message in the banner',
	},
	navigateToEmailRequestLinkMessage: {
		id: 'servicedesk.queues.agent-view.layout.basic-auth-users-banner.navigate-to-email-request-link-message',
		defaultMessage: 'Reconnect',
		description: 'Link to navigate to Email Request page to change the connection type',
	},
	bannerMessageForAgent: {
		id: 'servicedesk.queues.agent-view.layout.basic-auth-users-banner.banner-message-for-agent',
		defaultMessage:
			'Your admin must reconnect the Microsoft account with OAuth immediately to continue receiving email requests. ',
		description: 'Message in the banner for agent',
	},
	navigateToEmailRequestLinkMessageForAgent: {
		id: 'servicedesk.queues.agent-view.layout.basic-auth-users-banner.navigate-to-email-request-link-message-for-agent',
		defaultMessage: 'Learn more',
		description: 'Link to navigate to Email Request page to change the connection type for agent',
	},
	bannerMessagePostDeprecation: {
		id: 'servicedesk.queues.agent-view.layout.basic-auth-users-banner.banner-message-post-deprecation',
		defaultMessage:
			'You must connect your Microsoft account again using OAuth to receive customer requests via email. ',
		description: 'Message in the banner',
	},
	bannerMessageForAgentPostDeprecation: {
		id: 'servicedesk.queues.agent-view.layout.basic-auth-users-banner.banner-message-for-agent-post-deprecation',
		defaultMessage:
			'Your admin must reconnect the Microsoft account using OAuth to receive customer requests via email. ',
		description: 'Message in the banner for agent',
	},
});
