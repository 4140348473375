import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';

/*
 * There are components in Issue and Service Desk that invoke legacy UI components. Some of these
 * components require Jira Calendar resources, but only two of the three required resources are
 * loaded implicitly. The third resource must be loaded dynamically because it depends on the
 * current user's locale.
 *
 * Here we generate the resource name for that calendar locale dependency, for loading through WRM.
 *
 * For more context in Jira, see:
 * - com.atlassian.jira.web.action.util.CalendarResourceIncluder
 * - jira-components/jira-webapp-resources/src/main/webapp/includes/lib/calendar
 * - system-webresources-plugin.xml
 */

const calendarLocaleMap = {
	cs_CZ: 'calendar-cs',
	da_DK: 'calendar-da',
	de_DE: 'calendar-de',
	en_GB: 'calendar-en',
	en_UK: 'calendar-en',
	en_ZZ: 'calendar-en',
	en_US: 'calendar-en',
	es_ES: 'calendar-es',
	et_EE: 'calendar-en', // Jira has no calendar translation for Estonian
	fi_FI: 'calendar-fi',
	fr_FR: 'calendar-fr',
	hu_HU: 'calendar-hu',
	is_IS: 'calendar-en', // Jira has no calendar translation for Icelandic
	it_IT: 'calendar-it',
	ja_JP: 'calendar-jp',
	ko_KR: 'calendar-ko',
	nb_NO: 'calendar-no',
	nl_NL: 'calendar-nl',
	pl_PL: 'calendar-pl',
	pt_BR: 'calendar-pt',
	pt_PT: 'calendar-pt',
	ro_RO: 'calendar-ro',
	ru_RU: 'calendar-ru',
	sk_SK: 'calendar-sk',
	sv_SE: 'calendar-sv',
	tr_TR: 'calendar-tr',
	zh_CN: 'calendar-zh',
	zh_TW: 'calendar-zh',
} as const;

const getCalendarResource = (locale: Locale): string =>
	calendarLocaleMap[locale] || calendarLocaleMap.en_US;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (locale: Locale): string => `wrc!jira.webresources:${getCalendarResource(locale)}`;
