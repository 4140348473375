import { lazy } from 'react-loosely-lazy';
import type IssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/issue-app';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export default lazy<typeof IssueAppType>(
	() =>
		import(
			/* webpackChunkName: "async-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-app'
		),
);
