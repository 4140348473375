import { combineReducers } from 'redux';
import bulkSelection from './bulk-selection';
import fieldInitialization from './field-initialization';
import persisted from './persisted';
import ui from './ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const noopReducer = (state: any) => state || {};

export default combineReducers({
	persisted,
	ui,
	fieldInitialization,
	bulkSelection,
	initialAppProps: noopReducer,
});
