// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import memoizeOne from 'memoize-one';
import { CSSTransition } from 'react-transition-group';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { IssueContextSubscriber } from '@atlassian/jira-issue-context-services';
import type { TableRef, HorizontalPositioning } from '@atlassian/jira-virtual-table/src';
import type { TableSize } from '@atlassian/jira-virtual-table/src/model';
import type { ConnectProps } from '../model/cell';
import { defaultProps } from './common';

const ANIMATION_TIMEOUT = 200;
const ASSIGNEE_HEIGHT = 40;
const ASSIGNEE_DROPDOWN_WIDTH_HEIGHT = 300;
const OFFSET_PADDING = 4;
const BOTTOM_LEFT = 'BOTTOM_LEFT';
const BOTTOM_RIGHT = 'BOTTOM_RIGHT';
const TOP_LEFT = 'TOP_LEFT';
const TOP_RIGHT = 'TOP_RIGHT';
const DROPDOWN_WIDTH_MARGIN = 7 * gridSize;
const DROPDOWN_HEIGHT_MARGIN = 9 * gridSize;

export type Props = {
	showPagination: boolean;
} & ConnectProps & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Pagination: ComponentType<any>;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Table: ComponentType<any>;
		editingFieldVerticalOffset: number;
		editingFieldHorizontalOffset: number;
		editingFieldWidth: number;
		horizontalScrollOffset: number;
		verticalScrollOffset: number;
		tableWidth: number;
		tableHeight: number;
		tableRef: (arg1: TableRef | null) => void;
		loadedIssueKeys: string[];
		ssrTableSize?: TableSize;
		onActiveRowIdChanged: (arg1: string | undefined) => void;
	};

const spaceAvailableRightOfField = (
	tableWidth: number,
	horizontalScrollOffset: number,
	horizontalOffset: number,
) =>
	tableWidth + horizontalScrollOffset - horizontalOffset >
	ASSIGNEE_DROPDOWN_WIDTH_HEIGHT + DROPDOWN_WIDTH_MARGIN;

const spaceAvailableUnderField = (
	tableHeight: number,
	verticalScrollOffset: number,
	verticalOffset: number,
) =>
	tableHeight + verticalScrollOffset - verticalOffset >
	ASSIGNEE_DROPDOWN_WIDTH_HEIGHT + DROPDOWN_HEIGHT_MARGIN;

const getPositionType = (
	horizontalOffset: number,
	horizontalScrollOffset: number,
	verticalOffset: number,
	verticalScrollOffset: number,
	tableWidth: number,
	tableHeight: number,
) => {
	if (spaceAvailableUnderField(tableHeight, verticalScrollOffset, verticalOffset)) {
		if (spaceAvailableRightOfField(tableWidth, horizontalScrollOffset, horizontalOffset)) {
			return BOTTOM_LEFT;
		}
		return BOTTOM_RIGHT;
	}
	if (spaceAvailableRightOfField(tableWidth, horizontalScrollOffset, horizontalOffset)) {
		return TOP_LEFT;
	}

	return TOP_RIGHT;
};

export const calculatePortalPosition = (
	horizontalOffset: number,
	horizontalScrollOffset: number,
	verticalOffset: number,
	verticalScrollOffset: number,
	fieldWidth: number,
	tableWidth: number,
	tableHeight: number,
) => {
	switch (
		getPositionType(
			horizontalOffset,
			horizontalScrollOffset,
			verticalOffset,
			verticalScrollOffset,
			tableWidth,
			tableHeight,
		)
	) {
		case BOTTOM_LEFT: {
			return {
				left: `${horizontalOffset - horizontalScrollOffset - OFFSET_PADDING}px`,
				top: `${verticalOffset + ASSIGNEE_HEIGHT}px`,
				bottom: undefined,
			};
		}

		case BOTTOM_RIGHT: {
			return {
				left: `${
					horizontalOffset -
					horizontalScrollOffset +
					fieldWidth -
					OFFSET_PADDING -
					ASSIGNEE_DROPDOWN_WIDTH_HEIGHT
				}px`,
				top: `${verticalOffset + ASSIGNEE_HEIGHT}px`,
				bottom: undefined,
			};
		}

		case TOP_LEFT: {
			return {
				left: `${horizontalOffset - horizontalScrollOffset - OFFSET_PADDING}px`,
				top: 'auto',
				bottom: `${-verticalOffset - OFFSET_PADDING}px`,
			};
		}

		default: {
			return {
				left: `${
					horizontalOffset -
					horizontalScrollOffset +
					fieldWidth -
					OFFSET_PADDING -
					ASSIGNEE_DROPDOWN_WIDTH_HEIGHT
				}px`,
				top: 'auto',
				bottom: `${-verticalOffset - OFFSET_PADDING}px`,
			};
		}
	}
};

// eslint-disable-next-line jira/react/no-class-components
export default class IssueTable extends PureComponent<Props> {
	static defaultProps = defaultProps;

	onUpdateHorizontalScrollOffset = (horizontalPositioning: HorizontalPositioning) => {
		const { onHorizontalScrollOffsetChanged, onUpdateHorizontalScrollOffset } = this.props;
		onUpdateHorizontalScrollOffset(horizontalPositioning);
		onHorizontalScrollOffsetChanged && onHorizontalScrollOffsetChanged(horizontalPositioning);
	};

	onSetIssueKeyContextCallback = memoizeOne(
		(setIssueKeyContext: (arg1: string | undefined) => void) => (rowId: string) => {
			const isIssueDataLoaded = this.props.loadedIssueKeys.includes(rowId);
			if (isIssueDataLoaded) {
				setIssueKeyContext(rowId);
			} else {
				setIssueKeyContext(undefined);
			}

			this.props.onActiveRowIdChanged && this.props.onActiveRowIdChanged(rowId);
		},
	);

	render() {
		const { currentPage, totalPages, onPageChange } = this.props;
		const {
			Table,
			showPagination,
			Pagination,
			editingFieldHorizontalOffset,
			editingFieldVerticalOffset,
			editingFieldWidth,
			horizontalScrollOffset,
			verticalScrollOffset,
			tableWidth,
			tableHeight,
			onUpdateHorizontalScrollOffset,
			onActiveRowIdChanged,
			...otherProps
		} = this.props;

		const portalPosition = calculatePortalPosition(
			editingFieldHorizontalOffset,
			horizontalScrollOffset,
			editingFieldVerticalOffset,
			verticalScrollOffset,
			editingFieldWidth,
			tableWidth,
			tableHeight,
		);

		return (
			<Container>
				<TableContainer
					animationTimeout={ANIMATION_TIMEOUT}
					hasPagination={showPagination}
					left={portalPosition.left}
					top={portalPosition.top}
					bottom={portalPosition.bottom}
				>
					<IssueContextSubscriber>
						{(__, { setIssueKeyContext }) => (
							<Table
								{...otherProps}
								onActiveRowIdChanged={this.onSetIssueKeyContextCallback(setIssueKeyContext)}
								onUpdateHorizontalScrollOffset={this.onUpdateHorizontalScrollOffset}
							/>
						)}
					</IssueContextSubscriber>
				</TableContainer>
				<CSSTransition
					in={showPagination}
					classNames="fade"
					timeout={ANIMATION_TIMEOUT}
					unmountOnExit
				>
					<Animate timeout={ANIMATION_TIMEOUT}>
						{showPagination && (
							<Pagination
								currentPage={currentPage}
								totalPages={totalPages}
								onPageChange={onPageChange}
							/>
						)}
					</Animate>
				</CSSTransition>
			</Container>
		);
	}
}

const APPROX_PAGINATION_HEIGHT = 48;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableContainer = styled.div<{
	animationTimeout: number;
	hasPagination: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	left: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	top: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	bottom: any;
}>(
	{
		height: '100%',
		flex: 9,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		transition: ({ animationTimeout }) => `all ${animationTimeout}ms ease-in`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ hasPagination }) => (hasPagination ? `padding-bottom: ${APPROX_PAGINATION_HEIGHT}px;` : ''),
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& .virtual-table-row-list-portal > div': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			left: ({ left }) => left,
			top: 'auto',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& .virtual-table-row-list-portal > div > div': {
			position: 'absolute',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			top: ({ top }) => top,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			bottom: ({ bottom }) => bottom,
		},
	},
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Animate = styled.div<{ timeout: any }>({
	position: 'fixed',
	bottom: 0,
	right: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.fade-enter': {
		opacity: 0,
		maxHeight: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.fade-enter.fade-enter-active': {
		opacity: 1,
		maxHeight: `${APPROX_PAGINATION_HEIGHT}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		transition: ({ timeout }) => `all ${timeout}ms ease-in`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.fade-exit': {
		opacity: 1,
		maxHeight: `${APPROX_PAGINATION_HEIGHT}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.fade-exit.fade-exit-active': {
		opacity: 0,
		maxHeight: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		transition: ({ timeout }) => `all ${timeout}ms ease-in`,
	},
});
