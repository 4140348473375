import { connect } from '../../../common/table-redux';
import { setHeaderHeight } from '../../../ops/header/size';
import {
	getVisibleCoreColumnIds,
	getVisibleAdditionalColumnIds,
	isColumnConfigurationVisible,
} from '../../../read-ops/columns';
import { isAutoRowHeight } from '../../../read-ops/rows';
import {
	hasVerticallyScrolled,
	isHorizontalScrollLeftShadowVisible,
	isHorizontalScrollRightShadowVisible,
} from '../../../read-ops/scrolling';
import { getTableWidthWithoutScrollbarOffset } from '../../../read-ops/size';
import type { State } from '../../../state/types';
import AdditionalColumnsContainer from '../common/additional-columns-container';
import Column from './column';
import ColumnConfiguration from './column-configuration';
import ColumnHeaders from './index-dumb';

export default connect(
	(state: State) => ({
		coreColumnIds: getVisibleCoreColumnIds(state),
		additionalColumnIds: getVisibleAdditionalColumnIds(state),
		isColumnConfigurationVisible: isColumnConfigurationVisible(state),
		hasVerticallyScrolled: hasVerticallyScrolled(state),
		tableHasLeftShadow: isHorizontalScrollLeftShadowVisible(state),
		tableHasRightShadow: isHorizontalScrollRightShadowVisible(state),
		bottomShadowWidth: getTableWidthWithoutScrollbarOffset(state),
		isAutoRowHeight: isAutoRowHeight(state),
		AdditionalColumnsContainer,
		Column,
		ColumnConfiguration,
	}),
	{ setHeaderHeight },
)(ColumnHeaders);
