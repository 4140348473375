import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { log } from '@atlassian/jira-common-util-logging';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import type { ErrorResponse } from '@atlassian/jira-servicedesk-common/src/utils/fetch';
import { LOAD_ISSUES_ACTION_SOURCE_PAGE } from '../../../model';
import getPageData from '../../../services/page';
import { loadIssuesAction } from '../../../state/actions/issue';
import {
	LOAD_PAGE_DATA,
	loadPageDataSuccessAction,
	loadPageDataFailureAction,
} from '../../../state/actions/page';
import type { Action } from '../../../state/actions/types';
import type { State } from '../../../state/reducers/types';
import { getCurrentPageStartIndex } from '../../../state/selectors/pagination';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(LOAD_PAGE_DATA).switchMap((action) => {
		const state = store.getState();
		return getPageData(action.payload.queueId, state)
			.flatMap((result) =>
				Observable.of(
					loadPageDataSuccessAction(result),
					// @ts-expect-error - TS2769 - No overload matches this call.
					loadIssuesAction(getCurrentPageStartIndex(state), LOAD_ISSUES_ACTION_SOURCE_PAGE),
				),
			)
			.catch((error: ErrorResponse) => {
				fireErrorAnalytics({
					meta: {
						id: 'opsPageLoad',
						packageName: 'jiraServicedeskQueuesAgentView',
						teamName: 'jsd-shield',
					},
					// @ts-expect-error - TS2345 - Argument of type 'ErrorResponse' is not assignable to parameter of type 'string'.
					error: new Error(error),
				});
				log.safeErrorWithoutCustomerData(
					'servicedesk.queues.agent-view.ops.page.load',
					`[FD-3358] Error while trying to load page data: ${JSON.stringify(
						error.response.errors,
					)}`,
				);
				return Observable.of(loadPageDataFailureAction(error.response, error.statusCode));
			});
	});
