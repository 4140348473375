import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import { REJECT_NAVIGATION, CONFIRM_NAVIGATION } from './actions';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (onNavigationConfirm: () => void, onNavigationReject: () => void) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(action$: ActionsObservable<any>): Observable<any> =>
		action$.ofType(REJECT_NAVIGATION, CONFIRM_NAVIGATION).switchMap((action) => {
			switch (action.type) {
				case REJECT_NAVIGATION: {
					onNavigationReject();
					break;
				}
				case CONFIRM_NAVIGATION: {
					onNavigationConfirm();
					break;
				}
				default:
					return Observable.empty<never>();
			}
			return Observable.empty<never>();
		});
