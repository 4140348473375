import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import type { ExtraErrorAttributes } from '@atlassian/jira-error-boundary';

export const FFErrorBoundary = ({
	children,
	onError,
	id,
}: {
	children: React.ReactNode;
	onError: (location?: string, error?: Error, errorAttributes?: ExtraErrorAttributes) => void;
	id: string;
}) => (
	<JSErrorBoundary
		onError={onError}
		id={id}
		sendToPrivacyUnsafeSplunk
		fallback="unmount"
		teamName="jsd-shield"
	>
		{children}
	</JSErrorBoundary>
);
