import { connect } from '@atlassian/jira-react-redux';
import { openIssueAction } from '../../../../state/actions/issue';
import { getIssueKeyContext } from '../../../../state/selectors/ui';
import KeyboardShortcutListener from './view';

const mapDispatchToProps = {
	onOpenIssue: openIssueAction,
} as const;

export default connect(
	(state) => ({
		// @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type 'State'.
		activeIssueKey: getIssueKeyContext(state),
	}),
	mapDispatchToProps,
)(KeyboardShortcutListener);
