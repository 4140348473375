import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import sortBy from 'lodash/sortBy';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { toIssueKey } from '@atlassian/jira-shared-types';
import { SELECT_ROW, setLastClickedRowAction } from '../../../state/actions/bulk-selection';
import type { State } from '../../../state/reducers/types';
import {
	getLastClickedRow,
	getSelectionServiceActions,
} from '../../../state/selectors/bulk-selection';
import { getAllKnownIssueKeys } from '../../../state/selectors/issues';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(SELECT_ROW).switchMap((action) => {
		const state = store.getState();
		const selectionServiceActions = getSelectionServiceActions(state);
		if (!selectionServiceActions) {
			log.safeErrorWithoutCustomerData(
				'issue-table.ops.bulk-select.select-rows',
				'selectionServiceActions is not set.',
			);
			fireErrorAnalytics({
				meta: {
					id: 'opsSelectRows',
					packageName: 'jiraIssueTable',
					teamName: 'jsd-shield',
				},
			});
			return Observable.empty<never>();
		}

		const { selectIssues, deselectIssues } = selectionServiceActions;
		const { rowId, selection, isShiftKeyDown } = action.payload;
		const { analyticsEvent } = action.meta;

		const lastClickedRow = getLastClickedRow(state);

		if (isShiftKeyDown && !!lastClickedRow) {
			const allIssues = getAllKnownIssueKeys(state);
			const [start, end] = sortBy([
				allIssues.indexOf(toIssueKey(rowId)),
				allIssues.indexOf(toIssueKey(lastClickedRow)),
			]);
			const issuesIncluded = allIssues.slice(start, end + 1);
			(selection ? selectIssues : deselectIssues)(issuesIncluded, analyticsEvent);
			return Observable.of(setLastClickedRowAction({ rowId }));
		}
		(selection ? selectIssues : deselectIssues)([toIssueKey(rowId)], analyticsEvent);

		return Observable.of(setLastClickedRowAction({ rowId }));
	});
