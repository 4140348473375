import type { Offsets } from '../../reducers/persisted/types';
import type { State } from '../../reducers/types';
import { getPersisted } from '../common';

export const getOffsets = (state: State): Offsets => getPersisted(state).offsets;

export const getHorizontalScrollOffset = (state: State): number =>
	getOffsets(state).horizontalScrollOffset;

export const getVerticalScrollOffset = (state: State): number =>
	getOffsets(state).verticalScrollOffset;
