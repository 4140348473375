import React from 'react';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import { useRouter } from '@atlassian/jira-router';
import IssueView from '@atlassian/jira-servicedesk-issue';
import { Page as ServiceDeskLegacyIssuePage } from '@atlassian/jira-servicedesk-legacy-bridges/src/ui/legacy-issue-page/src/index.tsx';
import { useKbConfluenceContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/kb-confluence-context/index.tsx';
import { useShouldShowLegacyIssueView } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/should-show-legacy-issue-view-provider/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import type { QueuesIssueViewContentProps, LegacyIssueViewProps } from '../../types';

const LegacyIssueView = (props: LegacyIssueViewProps) => {
	const { push, match } = props;

	const [{ location }] = useRouter();
	const tenantContext = useTenantContext();

	const projectKey =
		match && match.params && match.params.projectKey ? match.params.projectKey : undefined;
	const [{ data: kbLink, loading }] = useKbConfluenceContext(projectKey);

	const { locale } = tenantContext;

	if (loading) {
		return <Spinner />;
	}

	return match && match.params && match.params.issueKey ? (
		<UFOSegment name="queues-legacy-issue-view">
			<ServiceDeskLegacyIssuePage
				entity={match.params.queueId || ''}
				issueKey={match.params.issueKey}
				onNavigation={push}
				locale={locale}
				wrmDataForPage={{
					'com.atlassian.servicedesk.core-ui:util-base-url.base-url': `https://${
						// @ts-expect-error - TS2339 - Property 'host' does not exist on type 'Location'.
						location.host || window.location.host
					}`,
					moment_user_locale: locale,
					'sd-kb-link': kbLink,
				}}
				pathname={location.pathname}
			/>
		</UFOSegment>
	) : null;
};

const QueuesIssueViewContent = (props: QueuesIssueViewContentProps) => {
	const { pageContext, push, match, issueNavigatorData } = props;
	const { isProjectSimplified, canSeeNewIssueView } = pageContext;
	const [
		{
			location: { search },
		},
	] = useRouter();

	const { showLegacyIssueView } = useShouldShowLegacyIssueView(
		isProjectSimplified,
		canSeeNewIssueView,
		search,
	);

	if (showLegacyIssueView) {
		return <LegacyIssueView push={push} match={match} />;
	}

	return (
		<UFOSegment name="queues-issue-view">
			<IssueView issueNavigatorData={issueNavigatorData} />
		</UFOSegment>
	);
};

export default QueuesIssueViewContent;
