import React, { type ReactNode } from 'react';
import Banner from '@atlaskit/banner';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Text } from '@atlaskit/primitives';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { ff } from '@atlassian/jira-feature-flagging';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	ContextualAnalyticsData,
	SCREEN,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import BasicAuthUserProvider from '../../../services/basic-auth-connections';
import messages from './messages';

type BasicAuthUsersBannerType = {
	baseUrl: string;
	projectKey: string;
	isAdmin?: boolean;
};

type Appearance = 'warning' | 'error' | 'announcement';
const warning = 'warning';
const Bold = (props: { children: ReactNode }) => <Text as="strong">{props.children}</Text>;
const LEARN_MORE =
	'https://support.atlassian.com/jira-service-management-cloud/docs/switch-microsoft-and-google-email-accounts-to-oauth/';

const BannerForAdmin = (props: BasicAuthUsersBannerType) => {
	const { baseUrl, projectKey } = props;
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'link',
	});
	const fireAnalytics = () => {
		fireUIAnalytics(analyticsEvent, 'navigateToEmailRequestBasicAuthConnection');
	};

	const appearance: Appearance = warning;
	const icon: React.ReactChild = <WarningIcon label="" secondaryColor="inherit" />;
	const message = formatMessage(messages.bannerMessage, {
		boldStart: '{boldStart}',
		boldEnd: '{boldEnd}',
	});

	return (
		<ContextualAnalyticsData sourceName="queuesAgentViewBasicAuthBanner" sourceType={SCREEN}>
			<Banner
				testId={`servicedesk-queues-agent-view.layout.basic-auth-users-banner.admin-banner-${appearance}`}
				appearance={appearance}
				icon={icon}
			>
				<FormattedI18nMessage
					componentsMapping={{
						bold: Bold,
					}}
					message={message}
				/>
				<a
					href={`${baseUrl}/jira/servicedesk/projects/${projectKey}/settings/email`}
					target="_blank"
					onClick={fireAnalytics}
				>
					{formatMessage(messages.navigateToEmailRequestLinkMessage)}
				</a>
			</Banner>

			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};

const BannerForAgent = (_props: BasicAuthUsersBannerType) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'link',
	});
	const fireAnalytics = () => {
		fireUIAnalytics(analyticsEvent, 'navigateToEmailRequestBasicAuthConnectionForAgent');
	};

	const appearance: Appearance = warning;
	const icon: React.ReactChild = <WarningIcon label="" secondaryColor="inherit" />;
	const message = formatMessage(messages.bannerMessageForAgent);

	return (
		<ContextualAnalyticsData
			sourceName="queuesAgentViewBasicAuthBannerForAgent"
			sourceType={SCREEN}
		>
			<Banner
				testId={`servicedesk-queues-agent-view.layout.basic-auth-users-banner.agent-banner-${appearance}`}
				appearance={appearance}
				icon={icon}
			>
				{message}
				<a href={LEARN_MORE} target="_blank" onClick={fireAnalytics}>
					{formatMessage(messages.navigateToEmailRequestLinkMessageForAgent)}
				</a>
			</Banner>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};

const BasicAuthBanner = (props: BasicAuthUsersBannerType) => {
	const { baseUrl, projectKey, isAdmin } = props;
	if (isAdmin) {
		return <BannerForAdmin baseUrl={baseUrl} projectKey={projectKey} />;
	}
	if (ff('banner-for-basic-auth-connected-agents_j4xfi')) {
		return <BannerForAgent baseUrl={baseUrl} projectKey={projectKey} />;
	}
	return null;
};

const BasicAuthUsersBanner = (props: BasicAuthUsersBannerType) => {
	const { baseUrl, projectKey, isAdmin } = props;

	return (
		<JSErrorBoundary
			id="jiraQueueViewBasicAuthWarningBanner"
			packageName="jiraServicedeskQueuesAgentView"
			teamName="jsd-shield"
		>
			<BasicAuthUserProvider baseUrl={baseUrl} projectKey={projectKey}>
				{({ needsOAuthWarning }) =>
					needsOAuthWarning && (
						<BasicAuthBanner baseUrl={baseUrl} projectKey={projectKey} isAdmin={isAdmin} />
					)
				}
			</BasicAuthUserProvider>
		</JSErrorBoundary>
	);
};

export default BasicAuthUsersBanner;
