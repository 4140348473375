import { fetchJson } from '@atlassian/jira-fetch';
import type { AssigneeSearchResponse as Response } from './types';

export type AssigneeSearchResponse = Response;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (issueKey: string, query: string): Promise<Response> =>
	fetchJson(`/rest/api/latest/user/assignable/search?issueKey=${issueKey}&query=${query}`, {
		method: 'GET',
	});
