import React from 'react';
import { RespondersInlineView } from '@atlassian/jira-responders/src/ui/inline';
import type { RESPONDERS_CUSTOM_FIELD_TYPE } from '../../../model/fields/json-fields/custom-fields/types';
import type { JsonComponentProps } from '../../../model/fields/types';

type Props = JsonComponentProps<typeof RESPONDERS_CUSTOM_FIELD_TYPE>;

const Responders = ({ dataSelectorProps: { value } }: Props) => (
	<RespondersInlineView values={value} />
);

export default Responders;
