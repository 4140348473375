import { assoc, chain } from 'icepick';
import { REST_SOURCE } from '@atlassian/jira-servicedesk-queues-common/src/model';
import {
	DIRECTION_DOWN,
	DIRECTION_UNCHANGED,
	LOAD_ISSUES_ACTION_SOURCE_PAGE,
} from '../../../model';
import {
	type FilterBadQueryAction,
	FILTER_QUERY_SUBMITTED,
	type FilterQuerySubmittedAction,
	IS_FILTER_FAILED,
} from '../../actions/filter';
import {
	LOAD_ISSUES_SUCCESS,
	LOAD_ISSUES_FAILURE,
	type LoadIssuesSuccessAction,
	type LoadIssuesFailureAction,
} from '../../actions/issue';
import {
	LOAD_PAGE_DATA,
	LOAD_PAGE_DATA_FAILURE,
	LOAD_PAGE_DATA_SUCCESS,
	SET_QUEUE_VISIBILITY,
	RESET_STATE,
	type LoadPageDataAction,
	type LoadPageDataFailureAction,
	type LoadPageDataSuccessAction,
	type SetQueueVisibilityAction,
	type ResetStateAction,
	DISPLAY_QUEUES_ERROR_HEADER,
	type DisplayQueuesErrorHeaderAction,
} from '../../actions/page';
import {
	PAGE_CHANGED,
	SORT_ORDER_CHANGED,
	RESET_SORT_ORDER,
	TOGGLE_QUEUE_HEADER,
	type PageChangedAction,
	type SortOrderChangedAction,
	type ResetSortOrderAction,
	type ToggleQueueHeaderAction,
} from '../../actions/table';
import {
	type SetSelectedIssueKeysAction,
	type SetSidebarIssueKeyAction,
	type SetSidebarReactKeyAction,
	type RefreshSidebarIssueViewAction,
	ONBOARDING_RESOLVED,
	SET_DELETE_MODAL_VISIBILITY,
	DELETE_QUEUE,
	SET_MODAL_IS_DELETING,
	SET_NAV_COLLAPSED,
	SET_ISSUE_KEY_CONTEXT,
	SET_SIDEBAR_ISSUE_KEY,
	SET_SIDEBAR_REACT_KEY,
	REFRESH_SIDEBAR_ISSUE_VIEW,
	SET_SELECTED_ISSUE_KEYS,
	type OnboardingResolvedAction,
	type SetDeleteModalVisibilityAction,
	type DeleteQueueAction,
	type SetModalIsDeletingAction,
	type SetNavCollapsedAction,
	type SetIssueKeyContextAction,
} from '../../actions/ui';
import currentQueueReducer from './current-queue';
import type { UIState } from './types';

export type Actions =
	| OnboardingResolvedAction
	| SetDeleteModalVisibilityAction
	| DeleteQueueAction
	| SetModalIsDeletingAction
	| SetNavCollapsedAction
	| SetIssueKeyContextAction
	| SetQueueVisibilityAction
	| LoadIssuesSuccessAction
	| LoadIssuesFailureAction
	| LoadPageDataAction
	| LoadPageDataFailureAction
	| LoadPageDataSuccessAction
	| PageChangedAction
	| SortOrderChangedAction
	| ResetSortOrderAction
	| ResetStateAction
	| ToggleQueueHeaderAction
	| FilterQuerySubmittedAction
	| FilterBadQueryAction
	| SetSidebarIssueKeyAction
	| SetSidebarReactKeyAction
	| SetSelectedIssueKeysAction
	| RefreshSidebarIssueViewAction
	| DisplayQueuesErrorHeaderAction;

const DEFAULT_UI_STATE: UIState = {
	onboardingResolved: false,
	isNavCollapsed: true,
	isHeaderCollapsed: false,
	sidebarIssueKey: null,
	isQueuesErrorHeaderDisplayed: false,
	sidebarReactKey: Math.random().toString(),
};

const rootUiReducer = (state: UIState, action: Actions) => {
	switch (action.type) {
		case SET_QUEUE_VISIBILITY: {
			return assoc(state, 'isQueueVisible', action.payload.isQueueVisible);
		}
		case ONBOARDING_RESOLVED: {
			return assoc(state, 'onboardingResolved', action.payload.onboardingResolved);
		}
		case SET_NAV_COLLAPSED: {
			return assoc(state, 'isNavCollapsed', action.payload.isNavCollapsed);
		}
		case SET_DELETE_MODAL_VISIBILITY: {
			return chain(state).setIn(['deleteModalOpen'], action.payload.isVisible).value();
		}
		case SET_ISSUE_KEY_CONTEXT: {
			return assoc(state, 'issueKeyContext', action.payload.issueKey);
		}
		case SET_SIDEBAR_ISSUE_KEY: {
			return assoc(state, 'sidebarIssueKey', action.payload.sidebarIssueKey);
		}
		case SET_SIDEBAR_REACT_KEY: {
			return assoc(state, 'sidebarReactKey', action.payload.sidebarReactKey);
		}
		case SET_SELECTED_ISSUE_KEYS: {
			return assoc(state, 'selectedIssueKeys', action.payload.selectedIssueKeys);
		}
		case REFRESH_SIDEBAR_ISSUE_VIEW: {
			if (
				state.sidebarIssueKey &&
				(state.selectedIssueKeys?.includes(state.sidebarIssueKey) ||
					state.sidebarIssueKey === action.payload.issueKey)
			) {
				return assoc(state, 'sidebarReactKey', Math.random().toString());
			}
			return state;
		}
		case DELETE_QUEUE: {
			return assoc(state, 'deletingQueue', true);
		}
		case SET_MODAL_IS_DELETING: {
			return assoc(state, 'deletingQueue', action.payload.isDeleting);
		}
		case PAGE_CHANGED:
		case LOAD_PAGE_DATA:
		case SORT_ORDER_CHANGED:
		case FILTER_QUERY_SUBMITTED:
		case RESET_SORT_ORDER: {
			return assoc(state, 'isLoadingIssues', true);
		}
		case LOAD_PAGE_DATA_FAILURE: {
			const { error } = action.payload;
			return chain(state).assoc('isLoadingPage', false).assoc('pageError', error).value();
		}
		case LOAD_PAGE_DATA_SUCCESS: {
			return chain(state).assoc('isLoadingPage', false).dissoc('pageError').value();
		}
		case LOAD_ISSUES_SUCCESS: {
			const { isFiltered, isFilterRequested } = action.payload.loadedIssuesResponse;
			const isLoadingIssues = isFilterRequested !== isFiltered;

			const areIssuesLoadedFromServer = action.payload.loadedIssuesResponse.source === REST_SOURCE;

			return chain(state)
				.assoc('isLoadingIssues', isLoadingIssues)
				.assoc('areIssuesLoadedFromServer', areIssuesLoadedFromServer)
				.dissoc('showIssueFailureError')
				.dissoc('shouldShowBadFilterQueryError')
				.value();
		}
		case LOAD_ISSUES_FAILURE: {
			const { source, isQueueEmpty } = action.payload;
			const updatedState = chain(state).assoc('isLoadingIssues', false);

			if (source === LOAD_ISSUES_ACTION_SOURCE_PAGE && isQueueEmpty) {
				updatedState.assoc('showIssueFailureError', true);
			}
			return updatedState.value();
		}
		case RESET_STATE: {
			return chain(state)
				.assoc('isLoadingPage', true)
				.assoc('isLoadingIssues', true)
				.assoc('areIssuesLoadedFromServer', false)
				.assoc('isQueueVisible', true)
				.dissoc('showIssueFailureError')
				.dissoc('shouldShowBadFilterQueryError')
				.value();
		}
		case TOGGLE_QUEUE_HEADER: {
			const direction = action.payload;
			if (direction === DIRECTION_UNCHANGED) {
				return state;
			}
			return assoc(state, 'isHeaderCollapsed', direction === DIRECTION_DOWN);
		}

		case IS_FILTER_FAILED: {
			return chain(state)
				.assoc('isLoadingIssues', false)
				.assoc('issueCount', 0)
				.assoc('shouldShowBadFilterQueryError', true)
				.value();
		}
		case DISPLAY_QUEUES_ERROR_HEADER: {
			return assoc(state, 'isQueuesErrorHeaderDisplayed', true);
		}
		default: {
			const _exhaustiveCheck = action;
			return state;
		}
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (state: UIState = DEFAULT_UI_STATE, action: any) => {
	const { currentQueue } = state;
	const nextState = rootUiReducer(state, action);
	const nextQueue = currentQueueReducer(currentQueue, action);

	// Do not return a new object if no changes are made.
	// Or else anything using this state as reference will
	// cause a re-render even if leaf values are the same
	if (state === nextState && currentQueue === nextQueue) {
		return state;
	}

	return {
		...nextState,
		currentQueue: nextQueue,
	};
};
