import React from 'react';
import {
	EntitlementsFieldReadView,
	getNameFromFieldData,
} from '@atlassian/jira-servicedesk-customer-service-entitlements-field';
import type { ENTITLEMENT_CUSTOM_FIELD_TYPE } from '../../../model/fields/json-fields/custom-fields/types';
import type { JsonComponentProps } from '../../../model/fields/types';
import { UnselectableChildGutterPaddingContainer } from '../common/styled';

type Props = JsonComponentProps<typeof ENTITLEMENT_CUSTOM_FIELD_TYPE>;

const Entitlement = ({
	dataSelectorProps: { value },
	tableInjectedProps: { isLastColumn },
}: Props) =>
	value ? (
		<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
			<EntitlementsFieldReadView name={getNameFromFieldData(value)} />
		</UnselectableChildGutterPaddingContainer>
	) : null;

export default Entitlement;
