import React, { Component, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { ff } from '@atlassian/jira-feature-flagging';
import { AsyncFlagsDispatcher } from '@atlassian/jira-flags/src/async';
import { useSelectedIssues } from '@atlassian/jira-issue-table-selection-services';
import type { TableRef } from '@atlassian/jira-virtual-table/src';
import flagsMapper from './flags';
import type {
	FieldDataSelectorProps,
	Props as IssueTableProps,
	ComponentRef as IssueTableRef,
	ComponentClass as IssueTableComponent,
} from './model';
import rootEpic from './ops';
import {
	setHasSelectionAction,
	setSelectionServiceActionsAction,
} from './state/actions/bulk-selection';
import { updateColumnsAction } from './state/actions/columns';
import { unlockAllFieldsAction } from './state/actions/field/lock';
import { updateProvidedIssueKeysAction } from './state/actions/issues';
import { setRowListRefAction } from './state/actions/table';
import {
	setCurrentPageAction,
	setIsTableDisabled,
	setJSMQueueDurationEvent,
	setOnIssueViewTransition,
	setRenderSidebarIcon,
	setRefreshSidebar,
} from './state/actions/ui';
import { DEFAULT_STATE } from './state/reducers/ui';
import { getColumns } from './state/selectors/columns';
import { getProvidedIssueKeys } from './state/selectors/issues';
import { getCurrentPage, isTableDisabled, getOnIssueViewTransition } from './state/selectors/ui';
import { createStore, Provider } from './state/store';
import IssueTableView from './view';

// @ts-expect-error - TS7031 - Binding element 'store' implicitly has an 'any' type. | TS7031 - Binding element 'tableProps' implicitly has an 'any' type.
const BulkSelectionAwareTableStateUpdater = ({ store, tableProps }) => {
	const { dispatch } = store;
	const state = store.getState();
	const {
		columns,
		issueKeys,
		currentPage,
		isDisabled,
		contentKey,
		onIssueViewTransition,
		useJSMQueueDurationEvent,
		renderSidebarIcon,
		refreshSidebar,
	} = tableProps;
	const [{ issueKeys: selectedIssueKeys }, actions] = useSelectedIssues();
	const hasBulkSelection = selectedIssueKeys.length > 0;

	useEffect(() => {
		actions.refreshSelection([]);
	}, [actions, contentKey]);

	useEffect(() => {
		// @ts-expect-error - TS2322 - Type 'BoundActions<State, typeof import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/platform/services/issue-table-selection/src/services/actions/index")>' is not assignable to type 'typeof import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/platform/services/issue-table-selection/src/services/actions/index")'.
		dispatch(setSelectionServiceActionsAction({ actions }));
		dispatch(setHasSelectionAction({ hasSelection: hasBulkSelection }));

		if (hasBulkSelection) {
			dispatch(
				// @ts-expect-error - TS7006 - Parameter 'c' implicitly has an 'any' type.
				updateColumnsAction(columns.map((c) => ({ ...c, isDisabled: hasBulkSelection }))),
			);
			dispatch(setIsTableDisabled(hasBulkSelection));
		}
		if (!isEqual(columns, getColumns(state)) && !hasBulkSelection) {
			dispatch(updateColumnsAction(columns));
			dispatch(unlockAllFieldsAction());
		}
		if (!isEqual(issueKeys, getProvidedIssueKeys(state))) {
			dispatch(updateProvidedIssueKeysAction(issueKeys));
		}
		if (currentPage !== getCurrentPage(state)) {
			dispatch(unlockAllFieldsAction());
			dispatch(setCurrentPageAction(currentPage));
		}
		if (isDisabled !== isTableDisabled(state) && !hasBulkSelection) {
			dispatch(setIsTableDisabled(isDisabled || false));
		}
	}, [actions, columns, currentPage, dispatch, hasBulkSelection, isDisabled, issueKeys, state]);

	useEffect(() => {
		if (onIssueViewTransition && onIssueViewTransition !== getOnIssueViewTransition(state)) {
			dispatch(setOnIssueViewTransition(onIssueViewTransition));
		}
	}, [dispatch, onIssueViewTransition, state, tableProps]);

	useEffect(() => {
		useJSMQueueDurationEvent && dispatch(setJSMQueueDurationEvent(useJSMQueueDurationEvent));
	}, [dispatch, useJSMQueueDurationEvent]);

	useEffect(() => {
		renderSidebarIcon && dispatch(setRenderSidebarIcon(renderSidebarIcon));
	}, [dispatch, renderSidebarIcon]);

	if (ff('jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			refreshSidebar && dispatch(setRefreshSidebar(refreshSidebar));
		}, [dispatch, refreshSidebar]);
	}

	return null;
};

// eslint-disable-next-line jira/react/no-class-components
export default class IssueTable extends Component<IssueTableProps> {
	onTableFocusFunctionChanged = (tableFocusFunction: (() => void) | null): void => {
		this.tableFocusFunction = tableFocusFunction;
	};

	onTableRefChanged = (ref: HTMLDivElement) => {
		this.store.dispatch(setRowListRefAction(ref));
	};

	onSetTableRef = (ref: TableRef | null) => {
		this.tableRef = ref;
	};

	focusContent() {
		this.tableFocusFunction && this.tableFocusFunction();
	}

	scrollToTop() {
		this.tableRef && this.tableRef.resetVerticalScrollOffset();
	}

	store = createStore(rootEpic, {
		initialAppProps: this.props.appProps,
		ui: {
			...DEFAULT_STATE,
			onIssueViewTransition: this.props.onIssueViewTransition,
			useJSMQueueDurationEvent: this.props.useJSMQueueDurationEvent,
		},
		persisted: {
			columns: __SERVER__ ? this.props.columns : [],
			providedIssueKeys: this.props.issueKeys,
			lockedIssues: {},
			offsets: {
				verticalScrollOffset: 0,
				horizontalScrollOffset: 0,
			},
			rowListRef: undefined,
		},
	});

	// @ts-expect-error - TS2564 - Property 'tableRef' has no initializer and is not definitely assigned in the constructor.
	tableRef: TableRef | null;

	// @ts-expect-error - TS2564 - Property 'tableFocusFunction' has no initializer and is not definitely assigned in the constructor.
	tableFocusFunction: (() => void) | null;

	render() {
		return (
			<Provider store={this.store}>
				<>
					<AsyncFlagsDispatcher store={this.store} mapper={flagsMapper} />
					<BulkSelectionAwareTableStateUpdater store={this.store} tableProps={this.props} />
					<IssueTableView
						{...this.props}
						tableRef={this.onSetTableRef}
						onTableFocusFunctionChanged={this.onTableFocusFunctionChanged}
						onTableRefChanged={this.onTableRefChanged}
					/>
				</>
			</Provider>
		);
	}
}

export { ASC, DESC, NONE } from './model/fields/sort-order';
export type {
	FieldDataSelectorChildrenProps,
	ColumnProps,
	FieldDataSelectorCoordinates,
	VerticalPosition,
	InitialAppProps,
} from './model';

export type { SortOrder } from './model/fields/sort-order';
export type { IssueTableProps, IssueTableComponent, IssueTableRef, FieldDataSelectorProps };

export type {
	UserProps,
	StatusProps,
	IssueTypeProps,
	ReporterProps,
	PriorityProps,
} from './model/fields/json-fields/common/types';
export type {
	OnChangeHandler,
	InlineEditableFieldProps,
	AssigneeInitialization,
	AssigneeDataSelectorProps,
	ReporterDataSelectorProps,
	CreatorDataSelectorProps,
	IssueKeyDataSelectorProps,
	IssueTypeDataSelectorProps,
	StatusDataSelectorProps,
	SummaryDataSelectorProps,
	LabelsDataSelectorProps,
	PriorityDataSelectorProps,
	DateDataSelectorProps,
	MeatballMenuDataSelectorProps,
	RequestParticipantsDataSelectorProps,
} from './model/fields/json-fields/system-fields/types';

export { REQUEST_PARTICIPANTS_FIELD_TYPE } from './model/fields/json-fields/system-fields/types';

export {
	ONGOING,
	COMPLETED,
	PAUSED,
	SLA_CUSTOM_FIELD_TYPE,
	SERVICE_ENTITY_CUSTOM_FIELD_TYPE,
	CMDB_OBJECT_CUSTOM_FIELD_TYPE,
	RESPONDERS_CUSTOM_FIELD_TYPE,
	MAJOR_INCIDENT_CUSTOM_FIELD_TYPE,
	MULTI_USER_CUSTOM_FIELD_TYPE,
	MULTI_CHECKBOX_CUSTOM_FIELD_TYPE,
	MULTI_SELECT_CUSTOM_FIELD_TYPE,
	MULTI_VERSION_CUSTOM_FIELD_TYPE,
	MULTI_GROUP_PICKER_CUSTOM_FIELD_TYPE,
	ENTITLEMENT_CUSTOM_FIELD_TYPE,
	SENTIMENT_CUSTOM_FIELD_TYPE,
} from './model/fields/json-fields/custom-fields/types';

export type {
	SlaStatus,
	SlaProps,
	SlaError,
	SlaDataSelectorProps,
	ServiceEntityDataSelectorProps,
	CmdbObjectDataSelectorProps,
	EntitlementDataSelectorProps,
	RespondersDataSelectorProps,
	MajorIncidentDataSelectorProps,
	MultiUserDataSelectorProps,
	MultiSelectDataSelectorProps,
	MultiCheckboxDataSelectorProps,
	MultiVersionDataSelectorProps,
	MultiGroupPickerDataSelectorProps,
	SentimentDataSelectorProps,
} from './model/fields/json-fields/custom-fields/types';
export type { FieldType } from './model/fields/types';
export type {
	FieldRenderedValueProps,
	DataSelectorHtmlProps,
} from './model/fields/field-rendered-value';
export { default as SortColumnHeader } from './common/sort-column-header';

export type {
	ActiveItemChangeCallback,
	DisplayedRowsChangeCallback,
	VerticalScrollOffsetChangeCallback,
	RowListFocusFunctionChangedCallback,
	HorizontalPositioning,
} from '@atlassian/jira-virtual-table/src';

export { MAX_ISSUES_PER_PAGE } from './common/table/constants';
