import { createSelector } from 'reselect';
import { Checkbox } from '@atlaskit/checkbox';
import { SelectedIssuesSubscriber } from '@atlassian/jira-issue-table-selection-services';
import type { State } from '../../../../state/reducers/types';
import { getAllKnownIssueKeys } from '../../../../state/selectors/issues';
import { connect } from '../../../../state/store';
import CheckboxHeaderWrapper from './view';

const getAllKnownIssueKeysFactory = () => createSelector(getAllKnownIssueKeys, (res) => res);
const mapStateToPropsFactory = () => {
	const getAllKnownIssueKeysInstance = getAllKnownIssueKeysFactory();
	return (state: State) => ({
		allIssueKeys: getAllKnownIssueKeysInstance(state),
		SelectedIssuesSubscriber,
		Checkbox,
	});
};

// @ts-expect-error - TS2554 - Expected 3-4 arguments, but got 1. | TS2345 - Argument of type 'typeof CheckboxWrapper' is not assignable to parameter of type 'Component<DispatchProp<any>>'.
export default connect(mapStateToPropsFactory)(CheckboxHeaderWrapper);
