import { connect } from '../../../../../../common/table-redux';
import { getPremiumSLAColumns } from '../../../../../../state/consumer/selectors';
import type { State } from '../../../../../../state/types';
import ErrorInlineMessage from './error-inline-message';
import { ConditionalErrorBoundary } from './view';

export default connect(
	(state: State) => ({
		columnsToIgnore: getPremiumSLAColumns(state),
		ErrorInlineMessage,
	}),
	{},
)(ConditionalErrorBoundary);
