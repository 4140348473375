import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category';
import {
	type Action,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import type { QueuesMenuPoppedOutState } from '../../types';
import {
	setQueuesMenuPoppedOutStateInLocalStorage,
	getQueuesMenuPoppedOutStateFromLocalStorage,
	getEvictedState,
} from '../local-storage';

export const MAX_POPPED_OUT_STATE_CACHE_LENGTH = 50;
export const MAX_POPPED_OUT_STATE_CACHE_ENTRY_AGE = 1000 * 60 * 60 * 24 * 28; // 28 days in milliseconds

export const actions = {
	updatePoppedOutState:
		(
			projectKey: string,
			practice: ItsmPractice,
			isPoppedOut: boolean,
		): Action<QueuesMenuPoppedOutState> =>
		({ setState, getState }) => {
			const currentState = getState();

			const newState = {
				...currentState,
				[getPoppedOutStateKey(projectKey, practice)]: {
					isPoppedOut,
					latestUpdateTimestamp: Date.now(),
				},
			};
			// setState is a merge operation - passing in an evicted state won't remove data for old keys
			setState(newState);

			// Save a evicted state to local storage to prevent unboounded growth.
			const evictedState = getEvictedState(
				newState,
				MAX_POPPED_OUT_STATE_CACHE_LENGTH,
				MAX_POPPED_OUT_STATE_CACHE_ENTRY_AGE,
			);
			setQueuesMenuPoppedOutStateInLocalStorage(evictedState);
		},
};

type Actions = typeof actions;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const initialState: QueuesMenuPoppedOutState = {} as QueuesMenuPoppedOutState;

const Store = createStore<QueuesMenuPoppedOutState, Actions>({
	name: 'jsm-queues-groups-popped-out-state-store',
	initialState,
	actions,
});

export const PoppedOutStateContainer = createContainer<QueuesMenuPoppedOutState, Actions>(Store, {
	onInit:
		() =>
		({ setState }) => {
			const stateFromStorage = getQueuesMenuPoppedOutStateFromLocalStorage();
			if (stateFromStorage) {
				setState(stateFromStorage);
			}
		},
});

export const usePoppedOutState = createHook(Store, {
	selector: (state: QueuesMenuPoppedOutState, categoryKey: string) =>
		state[categoryKey]?.isPoppedOut || false,
});

export const getPoppedOutStateKey = (projectKey: string, practice: ItsmPractice): string =>
	`${projectKey}-${practice}`;
