import isNil from 'lodash/isNil';
import type { DataState } from '@atlassian/jira-common-services/src/types';
import { useIsItsmTemplateProject } from '@atlassian/jira-router-resources-service-desk-is-itsm-project';
import { useIsDemoProject } from '../is-demo-project';

export const useIsItsmSampleProject = (): DataState<boolean> => {
	const {
		data: isDemoProject,
		loading: loadingIsDemoProject,
		error: errorIsDemoProject,
	} = useIsDemoProject();
	const {
		data: isItsmProject,
		loading: loadingIsItsmProject,
		error: errorIsItsmProject,
	} = useIsItsmTemplateProject();

	return {
		data:
			!isNil(isDemoProject) && !isNil(isItsmProject)
				? isDemoProject === true && isItsmProject === true
				: null,
		loading: loadingIsDemoProject || loadingIsItsmProject,
		error: errorIsDemoProject || errorIsItsmProject,
	};
};
