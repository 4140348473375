import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { JsonComponentProps } from '../../../model/fields/types';
import ErrorField from '../error';

type Props = JsonComponentProps<'priority'>;

export default function FieldRenderedValue(props: Props) {
	if (props.dataSelectorProps.value === undefined) {
		return <ErrorField />;
	}
	const {
		dataSelectorProps: {
			value: { iconUrl, name },
		},
	} = props;

	return (
		<StyledWrapper>
			<img height="16" width="16" src={iconUrl} alt={name} title={name} />
		</StyledWrapper>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledWrapper = styled.div({
	border: 0,
	padding: `${token('space.150', '12px')} ${token('space.100', '8px')} ${token(
		'space.100',
		'8px',
	)} ${token('space.050', '4px')}`,
});
