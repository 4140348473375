import type { LoadingRowAnalyticsActionPayload } from '../../../model/rows';

export const FIRE_LOADING_ROW_ANALYTICS = 'ops.rows.analytics.FIRE_LOADING_ROW_ANALYTICS' as const;

export type FireLoadingRowAnalyticsAction = {
	type: typeof FIRE_LOADING_ROW_ANALYTICS;
	payload: LoadingRowAnalyticsActionPayload;
};

export const fireLoadingRowAnalytics = (
	payload: LoadingRowAnalyticsActionPayload,
): FireLoadingRowAnalyticsAction => ({
	type: FIRE_LOADING_ROW_ANALYTICS,
	payload,
});
