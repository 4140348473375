import {
	FORBIDDEN,
	NOT_FOUND,
	UNAUTHORIZED,
} from '@atlassian/jira-common-constants/src/http-status-codes';

const RESTRICTED_ACCESS_CODES = [FORBIDDEN, NOT_FOUND, UNAUTHORIZED];

export const isAccessError = (
	error: {
		statusCode?: number;
	} | null,
): boolean =>
	error?.statusCode != null ? RESTRICTED_ACCESS_CODES.includes(error.statusCode) : false;
