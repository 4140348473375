import React from 'react';
import { useServiceDeskContext } from '@atlassian/jira-servicedesk-spa-commons';
import { EmptyQueueAdminView } from './empty-queue-admin-view';
import { EmptyQueueAgentView } from './empty-queue-agent-view';

export const EmptyUnconfiguredPracticeQueue = () => {
	const [{ data }] = useServiceDeskContext();
	const isAgentAdmin = data?.userRole === 'agent-project-admin';

	return isAgentAdmin ? <EmptyQueueAdminView /> : <EmptyQueueAgentView />;
};
