// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { styled } from '@compiled/react';
import { withTheme } from '../../app/context/theme-context';
import type { DetailsComponentType } from '../../model/details';
import type { Optional } from '../../model/optional';
import type { RowId } from '../../model/rows';
import type { CompiledTheme } from '../../model/themes';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Panel = styled.div<{ theme: CompiledTheme }>({
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderLeft: ({ theme }) => `1px solid ${theme.header.borderColor}`,
	outline: 'none',
	overflow: 'hidden',
});

type Props = {
	activeRowId: Optional<RowId>;
	DetailsContent: Optional<DetailsComponentType>;
	theme: CompiledTheme;
};

// eslint-disable-next-line jira/react/no-class-components
class DetailsPanel extends PureComponent<Props> {
	componentDidMount() {
		const { DetailsContent } = this.props;

		// only try focus the panel if it would be open (some DetailsContent is supplied)
		DetailsContent && this.panelRef && this.panelRef.focus();
	}

	panelRef: HTMLDivElement | null | undefined;

	render() {
		const { activeRowId, DetailsContent, theme } = this.props;

		if (DetailsContent) {
			return (
				<Panel
					tabIndex={0}
					ref={(div) => {
						this.panelRef = div;
					}}
					theme={theme}
				>
					<DetailsContent activeRowId={activeRowId} />
				</Panel>
			);
		}

		return null;
	}
}

export default withTheme(DetailsPanel);
