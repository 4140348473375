import { useMemo, useEffect } from 'react';
import isNil from 'lodash/isNil';
import { useContainerContext } from '@atlassian/jira-providers-container-context';
import { toProjectId, toBaseUrl } from '@atlassian/jira-shared-types';
import { createHook, createStore } from '@atlassian/react-sweet-state';
import { type StoreActions, actions as subscriberActions } from './actions';
import { initialIsDemoProjectState } from './constants';
import type { IsDemoProjectStoreState, IsDemoProjectState } from './types';

export const initialState: IsDemoProjectStoreState = {};

export const isDemoProjectStore = createStore<IsDemoProjectStoreState, StoreActions>({
	name: 'IsDemoProject',
	initialState,
	actions: subscriberActions,
});

const useIsDemoProjectStore = createHook(isDemoProjectStore);

// @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type. | TS7031 - Binding element 'loading' implicitly has an 'any' type. | TS7031 - Binding element 'error' implicitly has an 'any' type.
const useStateMemo = ({ data, loading, error }) =>
	useMemo(
		() => ({
			data,
			loading,
			error,
		}),
		[data, loading, error],
	);

export const useIsDemoProject = (): IsDemoProjectState => {
	const [{ data: contextData }] = useContainerContext();
	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'. | TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	const numProjectId = contextData && contextData.project && contextData.project.id;
	const projectId = toProjectId(String(numProjectId));
	const baseUrl = toBaseUrl('');
	const [state, { fetchIsDemoProject }] = useIsDemoProjectStore();
	const memoizedState = useStateMemo({
		...(state[projectId] ?? initialIsDemoProjectState),
	});
	const { data } = memoizedState;

	useEffect(() => {
		if (!isNil(numProjectId) && isNil(data)) {
			// @ts-expect-error - TS2349 - This expression is not callable.
			fetchIsDemoProject({ baseUrl, projectId })();
		}
	}, [fetchIsDemoProject, baseUrl, numProjectId, projectId, data]);

	return memoizedState;
};
