import React, { useCallback, useEffect, type ReactNode } from 'react';
import isEqual from 'lodash/fp/isEqual';
import Button from '@atlaskit/button';
import { Box } from '@atlaskit/primitives';
import { SkeletonItem } from '@atlaskit/side-navigation';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { staticJSMProjectConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources';
import { ConnectMenu } from '@atlassian/jira-navigation-apps-sidebar-nav4-connect';
import { useConnectAppNavigationData } from '@atlassian/jira-navigation-apps-sidebar-nav4-connect/src/controllers/use-connect-app-navigation-data';
import { ForgeServiceDeskQueueSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-forge';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous';
import {
	type Attributes,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { usePathParam } from '@atlassian/jira-router';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context';
import {
	useCategorizedNavItems,
	usePracticeMetadata,
} from '@atlassian/jira-servicedesk-queues-categorized-store';
import { usePriorityGroups } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/priority-group';
import type { NavItem } from '@atlassian/jira-servicedesk-queues-categorized-store/src/types';
import { NO_CATEGORY, toItsmPractice } from '@atlassian/jira-servicedesk-work-category';
import { useOnFavoritesChanged } from '../../controllers/favoriting';
import { useIssueCountEnabledQueues } from '../../controllers/issue-count-enabled-queues';
import { useSelectedNavLocation } from '../../controllers/nav-location';
import { QueueListSection } from '../../types';
import { AllQueuesSection } from './all-queues-section';
import { AlwaysVisibleSection } from './always-visible-section';
import { HiddenSection } from './hidden-section';
import { messages } from './messages';
import { QueueGroupsSection } from './queue-groups';
import { StarredSection } from './starred-section';
import type { PracticeQueuesProps } from './types';

const gatherQueuesCounts = (queues: NavItem[] = []) => {
	let starredCount = 0;
	let teamPriorityCount = 0;

	queues.forEach((queue: NavItem) => {
		if (queue.starred) {
			starredCount += 1;
		} else if (queue.canBeHidden === false || queue.canBeHidden === undefined) {
			teamPriorityCount += 1;
		}
	});

	const otherQueuesCount = queues.length - starredCount - teamPriorityCount;

	return {
		starredCount,
		teamPriorityCount,
		otherQueuesCount,
	};
};

const PracticeQueuesError = ({ onRefresh }: { onRefresh: () => void }) => {
	const { formatMessage } = useIntl();
	return (
		<Box padding="space.100">
			{formatMessage(messages.fetchQueuesError, {
				button: (buttonLabel: ReactNode) => (
					<Button spacing="none" appearance="link" onClick={onRefresh}>
						{buttonLabel}
					</Button>
				),
			})}
		</Box>
	);
};

const PracticeQueuesLegacy = ({
	practice = undefined,
	isMatchingUrlForSelectedQueue,
	projectKey,
}: PracticeQueuesProps) => {
	const currentCategory = practice || NO_CATEGORY;
	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const { data: queueConnectItems } = useConnectAppNavigationData(
		projectKey,
		staticJSMProjectConnectAddonsNavigationResource,
	);
	const [queues, , queuesError, methods, refreshQueues] = useCategorizedNavItems(
		'',
		projectKey,
		currentCategory,
		queueCustomContext,
	);

	const [metadata] = usePracticeMetadata(practice, queueCustomContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const previousCategory = usePrevious(currentCategory);

	useOnFavoritesChanged(
		projectKey,
		practice || NO_CATEGORY,
		queues,
		methods.editItemFavorite,
		methods.refreshIssueCountForItems,
	);

	const isCountDataRequired = Boolean(
		metadata && !metadata.hasRequestedCount && isMatchingUrlForSelectedQueue,
	);

	// perform the initial fetch of issue counts
	useEffect(() => {
		if (isCountDataRequired && methods.initialFetchNavItems) {
			const event = createAnalyticsEvent({ action: 'mounted' });
			methods.initialFetchNavItems(event);
		}
	}, [createAnalyticsEvent, isCountDataRequired, methods]);

	useEffect(() => {
		if (queues && previousCategory !== currentCategory && isCountDataRequired) {
			const attrs: Attributes = {
				...gatherQueuesCounts(queues),
				flexQEnabled: true,
				queueCategory: currentCategory,
			};
			const event = createAnalyticsEvent({});

			fireTrackAnalytics(event, 'nav4PracticeQueueNavItems rendered', attrs);
		}
	}, [createAnalyticsEvent, currentCategory, isCountDataRequired, previousCategory, queues]);

	if (queuesError && fg('jsm_throw_error_on_resource_auth_pre-check_failed')) {
		return <PracticeQueuesError onRefresh={refreshQueues} />;
	}

	return (
		<>
			{/* @ts-expect-error - TS2322 - Type 'ItsmPractice | undefined' is not assignable to type 'ItsmPractice'. */}
			<StarredSection practice={practice} projectKey={projectKey} />
			{/* @ts-expect-error - TS2322 - Type 'ItsmPractice | undefined' is not assignable to type 'ItsmPractice'. */}
			<AlwaysVisibleSection practice={practice} projectKey={projectKey} />
			{/* @ts-expect-error - TS2322 - Type 'ItsmPractice | undefined' is not assignable to type 'ItsmPractice'. */}
			<HiddenSection practice={practice} projectKey={projectKey} />

			{(!practice || practice === NO_CATEGORY) && (
				<ConnectMenu items={queueConnectItems} showIcons />
			)}

			{(!practice || practice === NO_CATEGORY) && (
				<ForgeServiceDeskQueueSection projectKey={projectKey} />
			)}
		</>
	);
};

const PracticeQueuesNew = ({ practice = undefined, projectKey }: PracticeQueuesProps) => {
	const currentCategory = practice || NO_CATEGORY;
	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const { data: queueConnectItems } = useConnectAppNavigationData(
		projectKey,
		staticJSMProjectConnectAddonsNavigationResource,
	);
	const [queues, queuesLoading, queuesError, methods, refreshQueues] = useCategorizedNavItems(
		'',
		projectKey,
		currentCategory,
		queueCustomContext,
	);

	const [projectKeyFromPath] = usePathParam('projectKey');
	const [queueIdFromPath] = usePathParam('queueId');
	const [practiceFromPath] = usePathParam('practiceType');
	const categoryFromPath = toItsmPractice(practiceFromPath);
	const queueFromPath = queues?.find((queue) => queue.id === queueIdFromPath);
	const [selectedNavLocation, { updateSelectedNavLocation }] = useSelectedNavLocation(
		queueIdFromPath || '',
	);
	const [groups, groupsLoading, groupsFetchingError, refreshGroups] = usePriorityGroups(
		currentCategory,
		queueCustomContext,
	);
	const [issueCountEnabledQueues, { updateIssueCountEnabledQueues }] = useIssueCountEnabledQueues();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useOnFavoritesChanged(
		projectKey,
		practice || NO_CATEGORY,
		queues,
		methods.editItemFavorite,
		methods.refreshIssueCountForItems,
	);

	const isInvalidNavLocation = useCallback(() => {
		if (queueIdFromPath) {
			if (!queueFromPath?.starred && selectedNavLocation.section === QueueListSection.STARRED) {
				return true;
			}
		}
		return false;
	}, [queueIdFromPath, queueFromPath, selectedNavLocation]);

	const selectDefaultNavLocation = useCallback(() => {
		if (queueIdFromPath === undefined) {
			return;
		}

		// Queue is in starred section
		if (queueFromPath?.starred) {
			updateSelectedNavLocation(queueIdFromPath, QueueListSection.STARRED);
			return;
		}

		// Queue is in priority group
		const firstGroupWithQueue = groups.find((group) =>
			group.queues.includes(Number(queueIdFromPath)),
		);
		if (firstGroupWithQueue) {
			updateSelectedNavLocation(
				queueIdFromPath,
				QueueListSection.PRIORITY_GROUP,
				firstGroupWithQueue.id,
			);
			return;
		}

		// Queue is in all queues section
		updateSelectedNavLocation(queueIdFromPath, QueueListSection.ALL_QUEUES);
	}, [queueIdFromPath, queueFromPath, groups, updateSelectedNavLocation]);

	const disableIssueCountRefreshForAllQueues = useCallback(() => {
		if (issueCountEnabledQueues.length > 0) {
			methods.enableIssueCountForSelectedQueues([]);
			updateIssueCountEnabledQueues([]);
		}
	}, [issueCountEnabledQueues, methods, updateIssueCountEnabledQueues]);

	const enableIssueCountRefreshForSelectedQueues = useCallback(
		(newQueues: number[]) => {
			if (!queues) {
				return;
			}

			// Enable issue-counts for starred queues as well
			const starredQueues = queues
				.filter((queue) => queue.starred)
				.map((queue) => Number(queue.id));
			const queuesToRefresh = Array.from(new Set([...newQueues, ...starredQueues]));

			// If the queues to enable have not changed, do nothing
			if (isEqual(queuesToRefresh, issueCountEnabledQueues)) {
				return;
			}

			// Refresh issue count for the newly visible queues in the selected group
			const newlyVisibleQueuesInSelectedGroup = queuesToRefresh
				.filter((queueId) => !issueCountEnabledQueues.includes(queueId))
				.map((queueId) => String(queueId));

			methods.refreshIssueCountForItems(newlyVisibleQueuesInSelectedGroup);
			updateIssueCountEnabledQueues(queuesToRefresh);

			// Enable issue count for the selected queues - group queues only
			methods.enableIssueCountForSelectedQueues(newQueues);
		},
		[queues, methods, issueCountEnabledQueues, updateIssueCountEnabledQueues],
	);

	const oldDeps = [
		projectKey,
		projectKeyFromPath,
		queueIdFromPath,
		groups,
		groupsLoading,
		groupsFetchingError,
		selectedNavLocation,
		issueCountEnabledQueues,
		selectDefaultNavLocation,
		isInvalidNavLocation,
		disableIssueCountRefreshForAllQueues,
		enableIssueCountRefreshForSelectedQueues,
		currentCategory,
		categoryFromPath,
	];

	const deps = [...oldDeps, queuesLoading, queuesError];

	// Enable issue-counts refresh for the correct queues for the current nav location
	useEffect(() => {
		if (!queueIdFromPath) {
			disableIssueCountRefreshForAllQueues();
			return;
		}

		const inSelectedProject = projectKey === projectKeyFromPath;
		const inSelectedCategory = currentCategory === categoryFromPath;

		const shouldUpdateIssueCountsOld =
			!inSelectedProject || !inSelectedCategory || groupsLoading || groupsFetchingError;

		const shouldUpdateIssueCounts = shouldUpdateIssueCountsOld || queuesLoading || queuesError;

		if (
			fg('jsm_throw_error_on_resource_auth_pre-check_failed')
				? shouldUpdateIssueCounts
				: shouldUpdateIssueCountsOld
		) {
			return;
		}

		// If the queue does not have a valid section, set a default location
		if (selectedNavLocation.section === undefined || isInvalidNavLocation()) {
			selectDefaultNavLocation();
			return;
		}

		const selectedGroup = groups.find((group) => group.id === selectedNavLocation.groupId);
		enableIssueCountRefreshForSelectedQueues(selectedGroup?.queues || []);
		// Replace with list during jsm_throw_error_on_resource_auth_pre-check_failed cleanup
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);

	// analytics
	useEffect(() => {
		if (!queues && !queuesLoading) {
			return;
		}

		if (queuesError) {
			fireErrorAnalytics({
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				error: queuesError as Error,
				meta: {
					id: 'jsmQueuesListError',
					packageName: 'jiraServicedeskQueuesNav4',
					teamName: 'jsd-shield',
				},
			});
		} else {
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'nav4PracticeQueueNavItemsWithQueueGroups rendered',
				{
					projectKey,
					category: currentCategory,
					packageName: 'jiraServicedeskQueuesNav4',
					teamName: 'jsd-shield',
				},
			);
		}
	}, [createAnalyticsEvent, currentCategory, queues, queuesError, projectKey, queuesLoading]);

	if (queuesError && fg('jsm_throw_error_on_resource_auth_pre-check_failed')) {
		return <PracticeQueuesError onRefresh={refreshQueues} />;
	}

	if ((!projectKey || queues == null) && !fg('jsm_throw_error_on_resource_auth_pre-check_failed')) {
		return <SkeletonItem />;
	}

	return (
		<>
			<StarredSection practice={currentCategory} projectKey={projectKey} />
			<QueueGroupsSection
				projectKey={projectKey}
				currentCategory={currentCategory}
				onGroupDataRefresh={refreshGroups}
			/>
			<AllQueuesSection practice={currentCategory} projectKey={projectKey} />
			{(!practice || practice === NO_CATEGORY) && (
				<ConnectMenu items={queueConnectItems} showIcons />
			)}

			{(!practice || practice === NO_CATEGORY) && (
				<ForgeServiceDeskQueueSection projectKey={projectKey} />
			)}
		</>
	);
};

export const PracticeQueues = componentWithFF(
	'jsm-queues-nav4-refresh-m2-dogfooding_rh43y',
	PracticeQueuesNew,
	PracticeQueuesLegacy,
);
