import React from 'react';
import { forgeModulesConcurrentLoadTime } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/main.tsx';
import {
	EnvironmentLozenge,
	nameWithCustomEnvironment,
} from '@atlassian/jira-forge-ui-extension-title/src/ui/environment-lozenge/index.tsx';
import { useGetLinkProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/common/utils/get-link-props/index.tsx';
import { MenuLinkItem } from '@atlassian/jira-navigation-system/src/v4';
import type { ForgeItem as ForgeItemProps } from '../../../types';
import { ForgeIcon } from '../forge-icon';

export const ForgeItemSimple = (props: ForgeItemProps) => {
	const { getLinkProps } = useGetLinkProps();
	const {
		showIcon,
		id: itemId,
		iconUrl,
		environmentType,
		environmentKey,
		url,
		name,
		overrides,
	} = props;

	if (overrides?.showSelectedAppMenuOnly) {
		return null;
	}

	return (
		<MenuLinkItem
			analytics={{ itemId }}
			elemBefore={showIcon ? <ForgeIcon url={iconUrl} /> : undefined}
			elemAfter={<EnvironmentLozenge environmentType={environmentType} />}
			{...getLinkProps(url, { shouldMatchExactPath: false })}
			onClick={() => {
				forgeModulesConcurrentLoadTime(itemId).start({ startTime: performance.now() });
			}}
		>
			{nameWithCustomEnvironment(name, environmentType, environmentKey)}
		</MenuLinkItem>
	);
};
