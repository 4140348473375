import React from 'react';
import { IssueContextSubscriber } from '@atlassian/jira-issue-context-services';
import type { Props } from './index';

export function IssueContextListener({ onSetIssueKeyContext }: Props) {
	return (
		<IssueContextSubscriber>
			{({ issueKeyContext }) => {
				onSetIssueKeyContext(issueKeyContext);
				return null;
			}}
		</IssueContextSubscriber>
	);
}
