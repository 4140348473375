import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations';
import SearchNoResultsImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results';
import { useIntl } from '@atlassian/jira-intl';
import noSearchResultsImg from '@atlassian/jira-servicedesk-common/src/utils/assets/no-search-results.svg';
import messages from './messages';

const EmptyView = () => {
	const { formatMessage } = useIntl();
	const a11yFeatureFlag = ff('a11y-fission-queues-related-issues_giuuk');
	const consolidateFeatureGate = fg('jsm_queues_consolidate_jfe_assets');

	return (
		<EmptyState
			renderImage={
				consolidateFeatureGate ? getAkEmptyStateRenderImageFn(SearchNoResultsImage) : undefined
			}
			imageUrl={consolidateFeatureGate ? undefined : noSearchResultsImg}
			header={formatMessage(messages.header)}
			description={formatMessage(messages.description)}
			headingLevel={a11yFeatureFlag ? 2 : undefined}
		/>
	);
};

export default EmptyView;
