import { createSubscriber, createHook, createContainer } from '@atlassian/react-sweet-state';
import Store from './store';
import type { State, Actions } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IssueContextContainer = createContainer<State, Actions, Record<any, any>>(Store);

export const IssueContextSubscriber = createSubscriber<State, Actions>(Store);

export const useIssueContext = createHook(Store);
