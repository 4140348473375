import { useCallback, useEffect } from 'react';
import logger from '@atlassian/jira-common-util-logging/src/log';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import ecClient, { EcClient } from '../index';
import type IssueMutation from '../issue-mutation';

/**
 * Intializes the EC client for capturing issue mutations as well as providing
 * a mechanism for reconciling issues
 */

export const useEcClient = (): {
	saveIssueMutationToCache: Function;
	saveIssueMutationsToCache: Function;
	searchAndReconcile: Function;
	getIssueMutationFromCache: Function;
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let tenantContext: any;

	try {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		tenantContext = useTenantContext();
	} catch (e) {
		logger.safeErrorWithoutCustomerData(
			'jsis-ec-client',
			`tenant context : ${e?.toString() || ''}`,
		);
	}

	useEffect(() => {
		EcClient.setTenantDetails(
			tenantContext?.activationId,
			tenantContext?.cloudId,
			tenantContext?.atlassianAccountId,
		);
	}, [tenantContext]);

	const saveIssueMutationToCache = useCallback(
		(
			issueMutation: IssueMutation,
			key:
				| { analyticsEventObj: Object; analyticsMetadata: { scenario: string } }
				| { analyticsMetadata: { scenario: string } } = {
				analyticsMetadata: {
					scenario: 'unknown',
				},
			},
			analyticsFailureEventObj: Object = {},
		): Promise<boolean> =>
			ecClient.saveIssueMutationToCache(issueMutation, key, analyticsFailureEventObj),
		[],
	);

	const saveIssueMutationsToCache = useCallback(
		(
			issueMutations: IssueMutation[],
			key:
				| { analyticsEventObj: Object; analyticsMetadata: { scenario: string } }
				| { analyticsMetadata: { scenario: string } } = {
				analyticsMetadata: {
					scenario: 'unknown',
				},
			},
			analyticsFailureEventObj: Object = {},
		): Promise<boolean[]> =>
			ecClient.saveIssueMutationsToCache(issueMutations, key, analyticsFailureEventObj),
		[],
	);

	const searchAndReconcile = useCallback(
		(
			matchApiUrl: string,
			jqlMatchingKey: string,
			search: Promise<{
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				[key: string]: any;
			}>,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			parseIssueData: (issueData: { [key: string]: any }) => any[],
			capability: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
		): Promise<any> =>
			ecClient.searchAndReconcile(matchApiUrl, jqlMatchingKey, search, parseIssueData, capability),
		[],
	);

	const getIssueMutationFromCache = (
		key: null | string | string[],
	): Promise<{
		[key: string]: IssueMutation;
	}> => ecClient.getIssueMutationFromCache(key);

	return {
		saveIssueMutationToCache,
		saveIssueMutationsToCache,
		searchAndReconcile,
		getIssueMutationFromCache,
	};
};
