import type { TableSize } from '../../model';
import type { RenderSidebarIcon } from '../../model/cell-component';
import type {
	ColumnId,
	SortOrderConfiguration as ColumnSortOrderConfiguration,
} from '../../model/columns';
import type { ActiveItem } from '../../model/navigation';
import type { RowId, SortedRowIdsHash, RowHeightMapping } from '../../model/rows';

// set vertical scroll offset
export const SET_VERTICAL_SCROLL_OFFSET = 'state.internal.SET_VERTICAL_SCROLL_OFFSET' as const;

export type SetVerticalScrollOffsetAction = {
	type: typeof SET_VERTICAL_SCROLL_OFFSET;
	payload: number;
};

export const setVerticalScrollOffset = (offset: number): SetVerticalScrollOffsetAction => ({
	type: SET_VERTICAL_SCROLL_OFFSET,
	payload: offset,
});

// set vertical scroll delta
export const SET_VERTICAL_SCROLL_DELTA = 'state.internal.SET_VERTICAL_SCROLL_DELTA' as const;

export type SetVerticalScrollDeltaAction = {
	type: typeof SET_VERTICAL_SCROLL_DELTA;
	payload: number;
};

export const setVerticalScrollDelta = (delta: number): SetVerticalScrollDeltaAction => ({
	type: SET_VERTICAL_SCROLL_DELTA,
	payload: delta,
});

// set horizontal scroll offset
export const SET_HORIZONTAL_SCROLL_OFFSET = 'state.internal.SET_HORIZONTAL_SCROLL_OFFSET' as const;

export type SetHorizontalScrollOffsetAction = {
	type: typeof SET_HORIZONTAL_SCROLL_OFFSET;
	payload: number;
};

export const setHorizontalScrollOffset = (offset: number): SetHorizontalScrollOffsetAction => ({
	type: SET_HORIZONTAL_SCROLL_OFFSET,
	payload: offset,
});

// set table size
export const SET_TABLE_SIZE = 'state.internal.SET_TABLE_SIZE' as const;

export type SetTableSizeAction = {
	type: typeof SET_TABLE_SIZE;
	payload: TableSize;
};

export const setTableSize = (tableSize: TableSize): SetTableSizeAction => ({
	type: SET_TABLE_SIZE,
	payload: tableSize,
});

// set/clear active item
export const SET_ACTIVE_ITEM = 'state.internal.SET_ACTIVE_ITEM' as const;

export type SetActiveItemAction = {
	type: typeof SET_ACTIVE_ITEM;
	payload: ActiveItem;
};

export const setActiveItem = (payload: ActiveItem): SetActiveItemAction => ({
	type: SET_ACTIVE_ITEM,
	payload,
});

export const CLEAR_ACTIVE_ITEM = 'state.internal.CLEAR_ACTIVE_ITEM' as const;

export type ClearActiveItemAction = {
	type: typeof CLEAR_ACTIVE_ITEM;
};

export const clearActiveItem = (): ClearActiveItemAction => ({
	type: CLEAR_ACTIVE_ITEM,
});

// set resizing column
export const SET_RESIZING_COLUMN = 'state.internal.SET_RESIZING_COLUMN' as const;

export type SetResizingColumnAction = {
	type: typeof SET_RESIZING_COLUMN;
	payload: ColumnId | undefined;
};

export const setResizingColumn = (payload?: ColumnId): SetResizingColumnAction => ({
	type: SET_RESIZING_COLUMN,
	payload,
});

// set column sort order
export const SET_COLUMN_SORT_ORDER = 'state.internal.SET_COLUMN_SORT_ORDER' as const;

export type SetColumnSortOrderAction = {
	type: typeof SET_COLUMN_SORT_ORDER;
	payload: ColumnSortOrderConfiguration | undefined;
};

export const setColumnSortOrder = (
	payload?: ColumnSortOrderConfiguration,
): SetColumnSortOrderAction => ({
	type: SET_COLUMN_SORT_ORDER,
	payload,
});

export const SET_SORT_ORDER_WITH_OVERRIDE = 'state.internal.SET_SORT_ORDER_WITH_OVERRIDE' as const;

export type SetSortOrderWithOverride = {
	isSortOverrideEnabled: boolean;
};

export type SetSortOrderWithOverrideAction = {
	type: typeof SET_SORT_ORDER_WITH_OVERRIDE;
	payload: SetSortOrderWithOverride;
};

export const setSortOrderWithOverride = (
	payload: SetSortOrderWithOverride,
): SetSortOrderWithOverrideAction => ({
	type: SET_SORT_ORDER_WITH_OVERRIDE,
	payload,
});

// multi add anchor
export const SET_MULTI_ADD_ANCHOR = 'state.internal.SET_MULTI_ADD_ANCHOR' as const;

export type SetMultiAddAnchorAction = {
	type: typeof SET_MULTI_ADD_ANCHOR;
	payload: RowId;
};

export const setMultiAddAnchor = (payload: RowId): SetMultiAddAnchorAction => ({
	type: SET_MULTI_ADD_ANCHOR,
	payload,
});

export const CLEAR_MULTI_ADD_ANCHOR = 'state.internal.CLEAR_MULTI_ADD_ANCHOR' as const;

export type ClearMultiAddAnchorAction = {
	type: typeof CLEAR_MULTI_ADD_ANCHOR;
};

export const clearMultiAddAnchor = (): ClearMultiAddAnchorAction => ({
	type: CLEAR_MULTI_ADD_ANCHOR,
});

// set sorted rowIds hash
export const SET_SORTED_ROW_IDS_HASH = 'state.internal.SET_SORTED_ROW_IDS_HASH' as const;

export type SetSortedRowIdsHashAction = {
	type: typeof SET_SORTED_ROW_IDS_HASH;
	payload: SortedRowIdsHash;
};

export const setSortedRowIdsHash = (payload: SortedRowIdsHash): SetSortedRowIdsHashAction => ({
	type: SET_SORTED_ROW_IDS_HASH,
	payload,
});

// SET ROW BORDER WIDTH

export const SET_ROW_BORDER_WIDTH = 'state.internal.SET_ROW_BORDER_WIDTH' as const;

export type SetRowBorderWidthAction = {
	type: typeof SET_ROW_BORDER_WIDTH;
	payload: number;
};

export const setRowBorderWidth = (payload: number): SetRowBorderWidthAction => ({
	type: SET_ROW_BORDER_WIDTH,
	payload,
});

// SET ROW HEIGHT MAPPING

export const SET_ROW_HEIGHT_MAPPING = 'state.internal.SET_ROW_HEIGHT_MAPPING' as const;

export type SetRowHeightMappingAction = {
	type: typeof SET_ROW_HEIGHT_MAPPING;
	payload: RowHeightMapping;
};

export const setRowHeightMapping = (payload: RowHeightMapping): SetRowHeightMappingAction => ({
	type: SET_ROW_HEIGHT_MAPPING,
	payload,
});

export const clearRowHeightMapping = () => setRowHeightMapping({});

// BATCH UPDATE ROW HEIGHT MAPPING

export const BATCH_UPDATE_ROW_HEIGHT_MAPPING =
	'state.internal.BATCH_UPDATE_ROW_HEIGHT_MAPPING' as const;

export type BatchUpdateRowHeightMappingAction = {
	type: typeof BATCH_UPDATE_ROW_HEIGHT_MAPPING;
	payload: {
		rowIds: RowId[];
		rowHeightMapping: RowHeightMapping;
	};
};

export const batchUpdateRowHeightMapping = (
	rowIds: RowId[],
	rowHeightMapping: RowHeightMapping,
): BatchUpdateRowHeightMappingAction => ({
	type: BATCH_UPDATE_ROW_HEIGHT_MAPPING,
	payload: {
		rowIds,
		rowHeightMapping,
	},
});

export const SET_SCROLLBAR_SIZE = 'state.internal.SET_SCROLLBAR_SIZE' as const;

export type SetScrollbarSizeAction = {
	type: typeof SET_SCROLLBAR_SIZE;
	payload: number;
};

export const setScrollbarSize = (payload: number): SetScrollbarSizeAction => ({
	type: SET_SCROLLBAR_SIZE,
	payload,
});

// set header height
export const SET_HEADER_HEIGHT = 'state.internal.SET_HEADER_HEIGHT' as const;

export type SetHeaderHeightAction = {
	type: typeof SET_HEADER_HEIGHT;
	payload: number;
};

export const setHeaderHeight = (height: number): SetHeaderHeightAction => ({
	type: SET_HEADER_HEIGHT,
	payload: height,
});

// SET_RENDER_SIDE_BAR_ICON

export const SET_RENDER_SIDE_BAR_ICON = 'state.consumer.SET_RENDER_SIDE_BAR_ICON' as const;

export type SetRenderSidebarIconAction = {
	type: typeof SET_RENDER_SIDE_BAR_ICON;

	payload: RenderSidebarIcon;
};

export const setRenderSidebarIcon = (payload: RenderSidebarIcon): SetRenderSidebarIconAction => ({
	type: SET_RENDER_SIDE_BAR_ICON,

	payload,
});
