import React, { Fragment, useMemo, useCallback, useEffect, useRef } from 'react';
import urlParse from 'url-parse';
import AddonIcon from '@atlaskit/icon/glyph/addon';
import { useConnectItemClickHistory } from '@atlassian/jira-connect-item-click-history-controller';
import { matchUrlExact } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import type { ConnectDataItem } from '@atlassian/jira-navigation-apps-sidebar-connect/src/common/types.tsx';
import {
	ContainerAvatar,
	Divider,
	ExpandableMenuItem,
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
	MenuLinkItem,
} from '@atlassian/jira-navigation-system/src/v4';
import { useRouter } from '@atlassian/jira-router';

export type ConnectItemProps = {
	item: ConnectDataItem;
	showIcons?: boolean;
};

export const ConnectItem = ({ item, showIcons }: ConnectItemProps) => {
	const { addNew } = useConnectItemClickHistory();

	const icon = useMemo(() => {
		if (showIcons !== true || item.section) return undefined;
		return item.iconUrl != null && item.iconUrl.trim().length > 0 ? (
			<ContainerAvatar src={item.iconUrl} />
		) : (
			<AddonIcon label="" />
		);
	}, [showIcons, item.iconUrl, item.section]);

	const [{ location, query }] = useRouter();
	const getIsCurrentLocationMatchItemUrl = useCallback(
		(itemUrl?: string) => {
			if (!itemUrl) return false;

			// match pathname and query params
			// code adapted from src/packages/navigation-apps/sidebar/common/src/common/ui/menu-item/main.tsx
			const currentLocation = { ...location, query };
			const urlMatcher = matchUrlExact();
			const itemLocation = urlParse(itemUrl || '/', true);
			// @ts-expect-error - Property 'search' is missing in type 'URLParse' but required in type 'MatcherLocation'.
			return urlMatcher(currentLocation, itemLocation);
		},
		[location, query],
	);

	const isItemSelected = useMemo(
		() => getIsCurrentLocationMatchItemUrl(item.url),
		[item.url, getIsCurrentLocationMatchItemUrl],
	);

	const getIsChildSelected = useCallback(
		(parentItem: ConnectDataItem): boolean =>
			parentItem.items?.some(
				(child) => getIsCurrentLocationMatchItemUrl(child.url) || getIsChildSelected(child),
			) ?? false,
		[getIsCurrentLocationMatchItemUrl],
	);

	const isChildSelected = useMemo(() => getIsChildSelected(item), [item, getIsChildSelected]);

	const onItemClick = useCallback(() => {
		item.url != null && addNew(item.url);
	}, [addNew, item.url]);

	const anchorElement = useRef<HTMLAnchorElement>(null);
	useEffect(() => {
		// inject style class names used by Connect app js to attach event listener to display modal dialog
		if (item.styleClass) {
			const classes = item.styleClass
				.split(' ')
				.map((className) => className.trim())
				.filter(Boolean)
				.filter((className) => anchorElement.current?.classList?.contains(className) === false);
			anchorElement.current?.classList?.add(...classes);
		}
	}, [item.styleClass]);

	if (item.items && item.items.length > 0) {
		const childSections = item.items.filter((child) => child.section);
		const isAllSectionsUnnamed =
			childSections.length > 0 && childSections.every((section) => !section.name);

		const menuContent = item.items.map((child, index) => (
			<Fragment key={child.id}>
				{isAllSectionsUnnamed && child.section && index > 0 ? <Divider /> : null}
				<ConnectItem item={child} showIcons={showIcons} />
			</Fragment>
		));

		return item.name ? (
			<ExpandableMenuItem
				isDefaultExpanded={item.section || isChildSelected}
				isChildSelected={isChildSelected}
			>
				<ExpandableMenuItemTrigger elemBefore={icon}>{item.name}</ExpandableMenuItemTrigger>
				<ExpandableMenuItemContent>{menuContent}</ExpandableMenuItemContent>
			</ExpandableMenuItem>
		) : (
			<>{menuContent}</>
		);
	}

	return (
		<MenuLinkItem
			analytics={{ itemId: item.id }}
			elemBefore={icon}
			href={item.url ?? ''}
			isSelected={isItemSelected}
			ref={anchorElement}
			onClick={onItemClick}
		>
			{item.name}
		</MenuLinkItem>
	);
};
