import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import keyBy from 'lodash/keyBy';
// @ts-expect-error - TS2305 - Module '"monet"' has no exported member 'MaybeType'.
import { Maybe, type MaybeType } from 'monet';
import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import { type FieldsById, type Column, type QueueId, fromQueueId } from '../../model';
import type { ResponseField } from '../../rest/issue/fields/types';
import type {
	PrefetcherIssueResponse,
	ResponseColumn,
	ResponseIssue,
} from '../../rest/issue/types';
import type { IssueWithPos } from './transform/types';

export const transformColumns = (responseColumns: ResponseColumn[]): Column[] =>
	responseColumns.map((responseColumn) => {
		const column = {
			id: responseColumn.fieldId,
			title: responseColumn.columnHeaderText,
			fieldType: responseColumn.fieldType,
			sortable: responseColumn.sortable,
		};

		if (responseColumn.sortOrder) {
			return {
				...column,
				currentSorting: responseColumn.sortOrder,
			};
		}

		return column;
	});

const mapFieldsByColumnId = (fields: ResponseField[]): FieldsById =>
	// @ts-expect-error - TS2322 - Type 'Dictionary<number | ResponseField | { (callbackfn: (previousValue: ResponseField, currentValue: ResponseField, currentIndex: number, array: ResponseField[]) => ResponseField): ResponseField; (callbackfn: (previousValue: ResponseField, currentValue: ResponseField, currentIndex: number, array: ResponseField[]) => Resp...' is not assignable to type 'FieldsById'. | TS2339 - Property 'fieldId' does not exist on type 'ResponseField'. | TS2339 - Property 'fieldCssClass' does not exist on type 'ResponseField'.
	keyBy(fields, (field) => field.fieldId || field.fieldCssClass);

export const transformIssues = (issues: ResponseIssue[]): IssueWithPos[] =>
	issues.map((issue) => ({
		id: issue.id,
		key: issue.key,
		fields: mapFieldsByColumnId(issue.fields),
		position: issue.position,
	}));

export const getPrefetchedIssuePromise = (
	queueId: QueueId,
): MaybeType<Promise<PrefetcherIssueResponse>> =>
	Maybe.fromNull(
		window.JSD_QUEUES_PRELOAD_PROMISES &&
			window.JSD_QUEUES_PRELOAD_PROMISES[`queueIssues_${fromQueueId(queueId)}`],
	);

export const unsetPrefetchedIssuePromise = (queueId: QueueId): void => {
	if (window.JSD_QUEUES_PRELOAD_PROMISES) {
		window.JSD_QUEUES_PRELOAD_PROMISES[`queueIssues_${fromQueueId(queueId)}`] = undefined;
	}
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <TRest, TDomain>(
	rest: ObservableType<TRest>,
	transformFunc: (arg1: TRest) => TDomain,
): ObservableType<TDomain> =>
	rest.mergeMap((payload: TRest): ObservableType<TDomain> => Observable.of(transformFunc(payload)));
