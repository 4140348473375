import React, { useMemo } from 'react';
import AvatarGroup from '@atlaskit/avatar-group';
import type { JsonComponentProps } from '../../../model/fields/types';
import { Container, Content } from './styled';

type Props =
	| JsonComponentProps<'com.atlassian.servicedesk:sd-request-participants'>
	| JsonComponentProps<'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker'>;

const MultiUserField = (props: Props) => {
	const {
		dataSelectorProps: { value: items },
	} = props;

	const userData = useMemo(
		() =>
			items.map((user) => ({
				email: user.email,
				key: user.email,
				name: user.name,
				src: user.avatarUrl,
			})),
		[items],
	);

	if (!items || items.length === 0) {
		return null;
	}

	return (
		<Container>
			<Content>
				<AvatarGroup size="small" maxCount={5} appearance="stack" data={userData} />
			</Content>
		</Container>
	);
};

export default MultiUserField;
