import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type BulkActionToolbar from './index';
import type { Props as BulkActionsToolbarProps } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncBulkActionToolbarLazy = lazyForPaint<typeof BulkActionToolbar>(
	() => import(/* webpackChunkName: "async-servicedesk-bulk-actions-toolbar" */ './index'),
	{ ssr: false },
);

export const AsyncBulkActionToolbar = (props: BulkActionsToolbarProps) => (
	<ErrorBoundary
		id="async.jira-servicedesk.bulk-actions.toolbar"
		packageName="atlassian/jira-servicedesk-bulk-actions-toolbar"
	>
		<Placeholder name="servicedesk-bulk-actions-toolbar" fallback={null}>
			<AsyncBulkActionToolbarLazy {...props} />
		</Placeholder>
	</ErrorBoundary>
);
