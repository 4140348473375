import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useMajorIncidentConfig } from '../use-major-incident-config';

type Props = {
	issueKey: IssueKey;
	onSuccess?: (isMarkedMajor: string | null) => void;
	onFailure?: (error: Error, esponders: string | null) => void;
	onSubmit?: (
		oldValue: string | null,
		newValue: string | null,
		meta: null,
		event: UIAnalyticsEvent,
	) => void;
};

export const useMajorIncidentField = ({
	issueKey,
	onSuccess,
	onFailure,
	onSubmit,
}: Props): [
	{
		value: string | null;
		error: Error | null;
		loading: boolean;
	},
	{
		saveValue: (
			newValue: string | null,
			meta: null,
			analyticsEvent: UIAnalyticsEvent,
		) => Promise<void>;
		resetError: () => void;
	},
] => {
	const { majorIncidentFieldIdKey: fieldKey, fieldType } = useMajorIncidentConfig(issueKey);

	const [{ value, loading, error }, { saveValue, resetError }] = useEditField<string | null, null>({
		issueKey,
		fieldKey,
		fieldType,
		initialValue: null,
		onSuccess,
		onFailure,
		onSubmit,
	});

	return [
		{ value, loading, error },
		{ saveValue, resetError },
	];
};
