// @ts-expect-error - TS2305 - Module '"monet"' has no exported member 'MaybeType'.
import { Maybe, type MaybeType } from 'monet';
import type { AnError } from '@atlassian/jira-servicedesk-common/src/model/error/anerror';
import type { SortedQueue } from '../../../model';
import type { State } from '../../reducers/types';
import type { UIState } from '../../reducers/ui/types';
import { getIsNewFilterQueryPending } from '../filter';
import { getIssueCount } from '../issues';

const getUIState = (state: State): UIState => state.ui;

export const getCurrentQueue = (state: State): SortedQueue | undefined =>
	getUIState(state).currentQueue;

export const getOnboardingResolved = (state: State): boolean =>
	getUIState(state).onboardingResolved;

export const getDeletingQueue = (state: State): boolean =>
	// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
	Maybe.fromNull(getUIState(state).deletingQueue).orSome(false);

export const getDeleteModalOpen = (state: State): boolean =>
	// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
	Maybe.fromNull(getUIState(state).deleteModalOpen).orSome(false);

export const getIssueKeyContext = (state: State): string | undefined =>
	getUIState(state).issueKeyContext;

export const getSidebarIssueKey = (state: State): string | null =>
	getUIState(state).sidebarIssueKey;

export const getSidebarReactKey = (state: State): string | null =>
	getUIState(state).sidebarReactKey;

export const getSelectedIssues = (state: State): string[] | undefined =>
	getUIState(state).selectedIssueKeys;

export const isQueueVisible = (state: State): boolean =>
	// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
	Maybe.fromNull(getUIState(state).isQueueVisible).orSome(true);

export const isInitialized = (state: State): boolean => !!getCurrentQueue(state);

export const isLoadingPage = (state: State): boolean =>
	// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
	Maybe.fromNull(getUIState(state).isLoadingPage).orSome(false);

export const isLoadingIssues = (state: State): boolean =>
	Maybe.fromNull(getUIState(state).isLoadingIssues).orSome(true) ||
	getIsNewFilterQueryPending(state);

export const isQueueEmpty = (state: State): boolean =>
	!isLoadingIssues(state) && getIssueCount(state) === 0;

const getPageError = (state: State): MaybeType<AnError> =>
	Maybe.fromNull(getUIState(state).pageError);

export const hasPageError = (state: State): boolean => getPageError(state).isSome();

export const showIssueFailureError = (state: State): boolean =>
	// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
	Maybe.fromNull(getUIState(state).showIssueFailureError).orSome(false);

export const isRetrying = (state: State): boolean => hasPageError(state) && isLoadingPage(state);

export const shouldShowBadFilterQueryError = (state: State): boolean =>
	// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
	Maybe.fromNull(getUIState(state).shouldShowBadFilterQueryError).orSome(false);

export const isQueuesErrorHeaderDisplayed = (state: State): boolean =>
	getUIState(state).isQueuesErrorHeaderDisplayed || false;
