import React from 'react';
import { ProjectContextProvider } from '@atlassian/jira-providers-project-context';
import { useIsItsmTemplateProject } from '@atlassian/jira-router-resources-service-desk-is-itsm-project';
import { AsyncChangeManagementTourQueues } from '@atlassian/jira-servicedesk-change-management-tour/src/async';
import { SHORT_FORM_CHANGES } from '@atlassian/jira-servicedesk-work-category';
import type { ProjectId } from '@atlassian/jira-shared-types';
import { usePathParam } from '@atlassian/react-resource-router';

export type Props = {
	projectId: ProjectId;
};

export const ChangeManagementTour = (props: Props) => {
	const [practiceType] = usePathParam('practiceType');
	const { data: isItsmProject } = useIsItsmTemplateProject();
	if (isItsmProject !== true) {
		return null;
	}

	return practiceType === SHORT_FORM_CHANGES ? (
		<ProjectContextProvider>
			{({ data }) =>
				data && data.isProjectAdmin === true ? <AsyncChangeManagementTourQueues {...props} /> : null
			}
		</ProjectContextProvider>
	) : null;
};
