import {
	type Action,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import type { QueueListSection, QueueNavState } from '../../types';
import {
	setQueueGroupsNavStateInLocalStorage,
	getQueueGroupsNavStateFromLocalStorage,
	getEvictedState,
} from '../local-storage';

export const MAX_NAV_LOCATION_HISTORY_LENGTH = 50;
export const MAX_NAV_LOCATION_HISTORY_TIME = 1000 * 60 * 60 * 24 * 28; // 28 days in milliseconds

export const actions = {
	updateSelectedNavLocation:
		(
			queueId: string,
			selectedSection: QueueListSection,
			selectedGroupId?: string,
		): Action<QueueNavState> =>
		({ setState, getState }) => {
			const currentState = getState();
			const currentStateForQueueId = currentState[queueId] || {};

			const newState = {
				...currentState,
				[queueId]: {
					...currentStateForQueueId,
					selectedSection,
					selectedGroupId,
					latestUpdateTimestamp: Date.now(),
				},
			};
			// setState is a merge operation - passing in an evicted state won't remove data for old queueIds
			setState(newState);

			// Save a evicted state to local storage to prevent unboounded growth.
			const evictedState = getEvictedState(
				newState,
				MAX_NAV_LOCATION_HISTORY_LENGTH,
				MAX_NAV_LOCATION_HISTORY_TIME,
			);
			setQueueGroupsNavStateInLocalStorage(evictedState);
		},
};

type Actions = typeof actions;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const initialState: QueueNavState = {} as QueueNavState;

const Store = createStore<QueueNavState, Actions>({
	name: 'jsm-queue-groups-nav-location-store',
	initialState,
	actions,
});

export const NavigationDetailsContainer = createContainer<QueueNavState, Actions>(Store, {
	onInit:
		() =>
		({ setState }) => {
			const stateFromStorage = getQueueGroupsNavStateFromLocalStorage();
			if (stateFromStorage) {
				setState(stateFromStorage);
			}
		},
});

export const useSelectedNavLocation = createHook(Store, {
	selector: (state: QueueNavState, queueId: string) => ({
		section: state[queueId]?.selectedSection,
		groupId: state[queueId]?.selectedGroupId,
	}),
});
