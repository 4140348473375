import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	teamPriority: {
		id: 'servicedesk-queues-nav4.common.practice-queues.always-visible-section.team-priority',
		defaultMessage: 'Team priority',
		description: 'Header for team priority queues section',
	},
	archive: {
		id: 'servicedesk-queues-nav4.common.practice-queues.always-visible-section.archive',
		defaultMessage: 'Archive',
		description: 'Text for archives trigger in sidebar',
	},
});
