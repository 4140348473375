import { assoc, unset } from 'icepick';
import { LOAD_ISSUES_SUCCESS, type LoadIssuesSuccessAction } from '../../../actions/issue';
import {
	POLL_SUCCESS,
	type PollSuccessAction,
	RESET_PROJECT_STATE_HASH,
	type ResetProjectStateHashAction,
} from '../../../actions/update-metadata';
import type { UpdateMetadata } from './types';

export type Actions = PollSuccessAction | LoadIssuesSuccessAction | ResetProjectStateHashAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Partial<UpdateMetadata> = {}, action: Actions) => {
	switch (action.type) {
		case LOAD_ISSUES_SUCCESS: {
			const { issueHash } = action.payload.loadedIssuesResponse;

			return assoc(state, 'issueHash', issueHash);
		}
		case POLL_SUCCESS: {
			return assoc(state, 'projectStateHash', action.payload.projectStateHash);
		}
		case RESET_PROJECT_STATE_HASH: {
			return unset(state, 'projectStateHash');
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
