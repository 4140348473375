import type { Reducer } from 'redux';
import { assoc, chain, merge, updateIn } from 'icepick';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import performance, { getMark, setMark } from '@atlassian/jira-common-performance';
import type { Issue, IndexItems } from '@atlassian/jira-servicedesk-queues-common/src/model';
import type { IssueWithPos } from '@atlassian/jira-servicedesk-queues-common/src/services/issue/transform/types';
import {
	CHANGE_ISSUE_FIELD_VALUE,
	type ChangeIssueFieldValueAction,
} from '@atlassian/jira-servicedesk-queues-common/src/state/actions/issue/field';
import type { CollectionItem } from '../../../../model';
import { type FilterBadQueryAction, IS_FILTER_FAILED } from '../../../actions/filter';
import { LOAD_ISSUES_SUCCESS, type LoadIssuesSuccessAction } from '../../../actions/issue';
import { RESET_STATE, type ResetStateAction } from '../../../actions/page';
import {
	PAGE_CHANGED,
	SORT_ORDER_CHANGED,
	type PageChangedAction,
	type SortOrderChangedAction,
} from '../../../actions/table';
import type { Issues } from './types';

export type Actions =
	| LoadIssuesSuccessAction
	| ChangeIssueFieldValueAction
	| PageChangedAction
	| SortOrderChangedAction
	| ResetStateAction
	| FilterBadQueryAction;

const DEFAULT_ISSUES_STATE = {
	collection: [],
	index: {},
	totalCount: 0,
} as const;

const resetIssuesState = () => ({
	...DEFAULT_ISSUES_STATE,
	timeWhenLastInitialized: performance.now(),
});

// Removes issue from its old position in collection, and adds it to its new position
const updateCollection = (
	collection: CollectionItem[],
	index: IndexItems,
	issue: IssueWithPos,
	startIndex: number,
): CollectionItem[] => {
	const { key } = issue;
	const position = issue.position + startIndex;
	const newCollection = collection;
	// Find where the issue used to live in the collection, and delete if it exists
	if (index[key] != null) {
		const oldPosition = index[key];
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		if (newCollection[oldPosition] != null && newCollection[oldPosition].key === key) {
			delete newCollection[oldPosition];
		}
	}
	newCollection[position] = omit(issue, 'position');
	return newCollection;
};

const updateIndex = (index: IndexItems, issue: IssueWithPos, startIndex: number): IndexItems => {
	const { key } = issue;
	const position = startIndex + issue.position;
	const newIndex = index;
	newIndex[key] = position;
	return newIndex;
};

// @ts-expect-error - TS2314 - Generic type 'Reducer' requires 1 type argument(s). | TS1016 - A required parameter cannot follow an optional parameter.
const reducer: Reducer<Issues, Actions> = (state?: Issues, action: Actions) => {
	if (state === undefined) {
		return resetIssuesState();
	}
	switch (action.type) {
		case RESET_STATE: {
			return resetIssuesState();
		}
		case PAGE_CHANGED: {
			return chain(state)
				.assoc('collection', [])
				.assoc('index', {})
				.assoc('timeWhenLastInitialized', performance.now())
				.value();
		}

		case IS_FILTER_FAILED: {
			return chain(state).assoc('totalCount', 0).value();
		}

		case LOAD_ISSUES_SUCCESS: {
			// This is here because setting the mark in a side effect
			// doesn't guarantee this is fired straight away.
			if (!getMark('jsd.performance.profile.queues.issues.loaded')) {
				setMark('jsd.performance.profile.queues.issues.loaded');
			}
			const {
				startIndex,
				loadedIssuesResponse: { totalCount, isUsingDefaultSorting },
			} = action.payload;
			const issuesPayload: IssueWithPos[] = action.payload.loadedIssuesResponse.issues;

			// Slice as any issues in collection that exist beyond totalCount need to be deleted
			const collection = state.collection.slice(0, totalCount);
			const index = { ...state.index };

			issuesPayload.forEach((newIssue) => {
				updateCollection(collection, index, newIssue, startIndex);
				updateIndex(index, newIssue, startIndex);
			});

			if (
				state.totalCount === totalCount &&
				state.isUsingDefaultSorting === isUsingDefaultSorting &&
				isEqual(state.index, index) &&
				isEqual(state.collection, collection)
			) {
				return state;
			}

			return chain(state)
				.assoc('collection', collection)
				.assoc('index', index)
				.assoc('totalCount', totalCount)
				.assoc('isUsingDefaultSorting', isUsingDefaultSorting)
				.value();
		}
		case CHANGE_ISSUE_FIELD_VALUE: {
			const { issueKey, columnId, newValue } = action.payload;
			const issueIndex: number = state.index[issueKey];
			return updateIn(state, ['collection'], (issueCollection: CollectionItem[]) => {
				// TODO FREE-2659 - unfortunately need to ensure the index entry wasn't stale, restructure issue object array + index -> a store where issues can be accessed by key
				const issue = issueCollection[issueIndex];
				if (issue && issue.key === issueKey) {
					const newIssueValue: Issue = updateIn(issue, ['fields', columnId], (storedValue) =>
						merge(storedValue, newValue),
					);
					return chain(issueCollection).set(issueIndex, newIssueValue).value();
				}
				return issueCollection;
			});
		}
		case SORT_ORDER_CHANGED: {
			return assoc(state, 'timeWhenLastInitialized', performance.now());
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
export default reducer;
