/* eslint-disable jira/react/no-style-attribute */
import React, { Component, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { colors as akColors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { JsonComponentProps } from '../../../model/fields/types';
import {
	UnselectableChildGutterPaddingContainer,
	SelectableChildGutterPaddingContainer,
} from '../common/styled';

type Props = JsonComponentProps<'issuekey'> & {
	useJSMQueueDurationEvent?: () => void;
	linkStyle: {
		[key: string]: string | number;
	};
};

type State = {
	isFocused: boolean;
	tooltipText: string | null;
};

const TruncateFirstSpan = ({
	children,
	innerRef,
}: {
	innerRef: (arg1: HTMLElement | null) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
}) => (
	<span
		ref={innerRef}
		style={{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			textOverflow: 'ellipsis',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			flexGrow: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			flexShrink: 10000,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			overflow: 'hidden',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			minWidth: '12px',
		}}
	>
		{children}
	</span>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TruncateSecondSpan = ({ children }: { children: any }) => (
	<span
		style={{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			textOverflow: 'ellipsis',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			flexGrow: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			flexShrink: 1,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			flexBasis: 'auto',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			overflow: 'hidden',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			minWidth: '12px',
		}}
	>
		{children}
	</span>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StandaloneTruncateDiv = ({ children }: { children: any }) => (
	<div
		style={{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			textOverflow: 'ellipsis',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			whiteSpace: 'nowrap',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			overflow: 'hidden',
		}}
	>
		{children}
	</div>
);

const SPAN_PARENT_FLEX_TRUNCATE_STYLE = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	display: 'flex',
	flexGrow: 1,
} as const;
const SECONDARY_LINK_STYLE = {
	font: token('font.heading.xxsmall'),
	color: token('color.text.subtlest', akColors.N90),
} as const;

const getSecondaryLinkStyle = () => SECONDARY_LINK_STYLE;

const PADDING_STYLE = {
	padding: `10px ${token('space.075', '6px')} 10px ${token('space.075', '6px')}`,
	width: 'auto',
} as const;

const getFocusStyle = (isFocused: boolean) => ({
	borderColor: isFocused ? `${token('color.border.focused', akColors.B200)}` : 'transparent',
	borderRadius: '3px',
	borderWidth: '2px',
	borderStyle: 'solid',
	boxShadow: 'none',
	outline: 'none',
});

const getFocusableTruncatingParentLinkStyle = (isFocused: boolean) => ({
	...SPAN_PARENT_FLEX_TRUNCATE_STYLE,
	...getSecondaryLinkStyle(),
	...PADDING_STYLE,
	...getFocusStyle(isFocused),
});

const disabledStyle = {
	...SPAN_PARENT_FLEX_TRUNCATE_STYLE,
	...getSecondaryLinkStyle(),
	padding: `${token('space.150', '12px')} 0px`,
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
export const LinkCellWhitespace = () => <div style={{ flexGrow: 10 }} />;

const renderNonInteractiveIssueKey = (isLastColumn: boolean, issueKey: string) => (
	// Monolith still relies on 'issuekey' class selector for it's webdriver tests
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<UnselectableChildGutterPaddingContainer className="issuekey" isLastColumn={isLastColumn}>
		<StandaloneTruncateDiv>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
			<div style={disabledStyle}>{issueKey}</div>
		</StandaloneTruncateDiv>
	</UnselectableChildGutterPaddingContainer>
);

// eslint-disable-next-line jira/react/no-class-components
export default class FieldRenderedValue extends Component<Props, State> {
	static defaultProps = {
		linkStyle: {},
	};

	state = {
		isFocused: false,
		tooltipText: '',
	};

	shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
		return (
			this.props.dataSelectorProps.value !== nextProps.dataSelectorProps.value ||
			this.props.tableInjectedProps.isTableDisabled !==
				nextProps.tableInjectedProps.isTableDisabled ||
			this.state.isFocused !== nextState.isFocused ||
			this.state.tooltipText !== nextState.tooltipText
		);
	}

	onSetProjectKeyRef = (ref?: HTMLElement | null) => {
		// @ts-expect-error - TS2322 - Type 'HTMLElement | null | undefined' is not assignable to type 'null'.
		this.projectKeyRef = ref;
	};

	onFocus = () => {
		this.setState({ isFocused: true });
	};

	onBlur = () => {
		this.setState({ isFocused: false });
	};

	onMouseOver = () => {
		if (this.projectKeyRef) {
			let tooltipText = '';

			// Check if the element has overflown. If yes, set the innerText in state for tooltip to display
			// @ts-expect-error - TS2339 - Property 'scrollWidth' does not exist on type 'never'. | TS2339 - Property 'offsetWidth' does not exist on type 'never'.
			if (this.projectKeyRef.scrollWidth > this.projectKeyRef.offsetWidth) {
				tooltipText = this.props.dataSelectorProps.value;
			}
			if (this.state.tooltipText !== tooltipText) {
				this.setState(() => ({
					tooltipText,
				}));
			}
		}
	};

	onClick = (event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		const { onIssueViewTransition } = this.props.tableInjectedProps;
		const { useJSMQueueDurationEvent } = this.props;
		useJSMQueueDurationEvent && useJSMQueueDurationEvent();
		onIssueViewTransition?.(event);
		fireUIAnalytics(analyticsEvent, 'tableIssueLink');
	};

	onAuxClick = () => {
		const { useJSMQueueDurationEvent } = this.props;
		useJSMQueueDurationEvent && useJSMQueueDurationEvent();
	};

	projectKeyRef = null;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	renderIssueKeyLink(issueKey: string, projectKey: string, issueNumber: string): any {
		return (
			<>
				<Link
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						...getFocusableTruncatingParentLinkStyle(this.state.isFocused),
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						...this.props.linkStyle,
					}}
					onMouseOver={this.onMouseOver}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					href={`/browse/${issueKey}`}
					onClick={this.onClick}
					onAuxClick={this.onAuxClick}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="issue-link"
					{...{ 'data-issue-key': issueKey }}
				>
					<TruncateFirstSpan innerRef={this.onSetProjectKeyRef}>{projectKey}</TruncateFirstSpan>-
					<TruncateSecondSpan>{issueNumber}</TruncateSecondSpan>
				</Link>
				<LinkCellWhitespace />
			</>
		);
	}

	renderNonInteractiveIssueKeyFF(
		isLastColumn: boolean,
		issueKey: string,
		projectKey: string,
		issueNumber: string,
	) {
		return (
			<UnselectableChildGutterPaddingContainer
				// Monolith still relies on 'issuekey' class selector for it's webdriver tests
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="issuekey"
				isLastColumn={isLastColumn}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
				<div style={disabledStyle}>
					<TruncateFirstSpan innerRef={this.onSetProjectKeyRef}>{projectKey}</TruncateFirstSpan>-
					<TruncateSecondSpan>{issueNumber}</TruncateSecondSpan>
				</div>
				<LinkCellWhitespace />
			</UnselectableChildGutterPaddingContainer>
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	renderWithTooltipIfRequired(content: any) {
		const { tooltipText } = this.state;

		return (
			<SelectableChildGutterPaddingContainer
				// Monolith still relies on 'issuekey' class selector for it's webdriver tests
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="issuekey"
				isLastColumn={this.props.tableInjectedProps.isLastColumn}
			>
				{tooltipText ? (
					<Tooltip delay={0} content={tooltipText}>
						{content}
					</Tooltip>
				) : (
					content
				)}
			</SelectableChildGutterPaddingContainer>
		);
	}

	render() {
		const {
			dataSelectorProps: { value: issueKey },
			tableInjectedProps: { isLastColumn, isTableDisabled },
		} = this.props;
		const splitIssueKey: string[] | null = issueKey.split('-');

		if (splitIssueKey && splitIssueKey.length === 2) {
			if (!isTableDisabled) {
				return this.renderWithTooltipIfRequired(
					this.renderIssueKeyLink(issueKey, splitIssueKey[0], splitIssueKey[1]),
				);
			}
			return this.renderNonInteractiveIssueKeyFF(
				isLastColumn,
				issueKey,
				splitIssueKey[0],
				splitIssueKey[1],
			);
		}
		return renderNonInteractiveIssueKey(isLastColumn, issueKey);
	}
}
