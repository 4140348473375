import {
	type LayoutContainerNode,
	type LayoutContainerTemplateItem,
	type LayoutContainerFieldItem,
	layoutContainerItemTypes,
} from '@atlassian/jira-issue-layout-common-constants';

/**
 * This function uses getLayoutItemId which will return the id depending on the type:
 * field => fieldItem.id
 * panel => panelItemId
 * tab => tabContainerId
 * default => id // legacy
 *
 * @param layoutItem the item to get the id from
 */
export const getLayoutItemId = (layoutItem: LayoutContainerTemplateItem) => {
	switch (layoutItem.type) {
		case layoutContainerItemTypes.field:
			return layoutItem.fieldItemId;
		case layoutContainerItemTypes.panel:
			return layoutItem.panelItemId;
		case layoutContainerItemTypes.tab:
			return layoutItem.tabContainerId;
		default:
			return layoutItem.id;
	}
};

/**
 * This function is used to filter out selected items from the provided array of layout items.
 * It is so the layout remove items that should not be present, such as if they are not visible due to UI modifications
 *
 * @param items The layout items that will have items filtered out of them
 * @param idsToBeFilteredOut Ids of the items to remove
 */

export const filterOutItemsWithProvidedIds = (
	items: LayoutContainerNode[],
	idsToBeFilteredOut: string[],
): LayoutContainerNode[] => {
	const filteredOutIdsSet = new Set(idsToBeFilteredOut);
	return (
		items
			// Filter out items with the provided Ids
			.filter((item) => !filteredOutIdsSet.has(getLayoutItemId(item)))
			// Filter out items with the provided Ids nested in the container tab items
			.map((item) => {
				if (item.type === layoutContainerItemTypes.tab && item.items?.nodes) {
					return {
						...item,
						items: {
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							nodes: filterOutItemsWithProvidedIds(
								item.items.nodes,
								idsToBeFilteredOut,
							) as LayoutContainerFieldItem[],
						},
					};
				}
				return item;
			})
	);
};
