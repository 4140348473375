import React, { useState, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { MemoizedInsightIcon } from '@atlassian/jira-servicedesk-insight-icon';
import type { CmdbObject } from '@atlassian/jira-servicedesk-insight-shared-types';
import type { JsonComponentProps } from '../../../model/fields/types';
import messages from './messages';
import { filterEnrichedObjects, sortCmdbObjects } from './utils';

type Props = JsonComponentProps<'com.atlassian.jira.plugins.cmdb:cmdb-object-cftype'>;

export const CmdbObjects = (props: Props) => {
	const { formatMessage } = useIntl();
	const [isExpanded, setIsExpanded] = useState(false);
	const enrichedObjects = filterEnrichedObjects(props.dataSelectorProps.value);
	const [firstObject, ...remainingObjects] = sortCmdbObjects(enrichedObjects);

	const handleShowMoreClick = (
		event: SyntheticEvent<HTMLElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		fireUIAnalytics(analyticsEvent, 'insightObjectQueuesToggleShowMore', {
			action: isExpanded ? 'hiding' : 'showing',
		});
		setIsExpanded(!isExpanded);
	};

	const renderObject = (object: CmdbObject) => (
		<SingleObject key={object.id}>
			<MemoizedInsightIcon
				mediaClientConfig={object.avatar.mediaClientConfig}
				size="xsmall"
				iconUrl={object.avatar.url16}
				label={object.label}
			/>
			<ObjectLabel>{object.label}</ObjectLabel>
		</SingleObject>
	);

	const hasMoreObjects = remainingObjects.length > 0;
	const showAllObjects = isExpanded && hasMoreObjects;

	if (!firstObject) {
		return (
			<EmptyObject>
				<MessageWrapper>{formatMessage(messages.none)}</MessageWrapper>
			</EmptyObject>
		);
	}

	return (
		<Wrapper>
			{renderObject(firstObject)}
			{showAllObjects && remainingObjects.map(renderObject)}
			{hasMoreObjects && (
				<Button appearance="link" onClick={handleShowMoreClick}>
					{isExpanded
						? formatMessage(messages.showLess)
						: formatMessage(messages.showMore, {
								numberOfObjects: remainingObjects.length,
							})}
				</Button>
			)}
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	padding: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SingleObject = styled.div({
	display: 'flex',
	margin: `${token('space.050', '4px')} 0`,
	padding: `${token('space.025', '2px')} ${token('space.100', '8px')} ${token(
		'space.025',
		'2px',
	)} ${token('space.050', '4px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ObjectLabel = styled.div({
	marginLeft: token('space.050', '4px'),
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${gridSize * 1.25}px ${token('space.050', '4px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyObject = styled.div({
	display: 'flex',
	margin: `${token('space.050', '4px')} 0`,
	padding: `${token('space.025', '2px')} ${token('space.100', '8px')} ${token(
		'space.025',
		'2px',
	)} ${token('space.050', '4px')}`,
});
