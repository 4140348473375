import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import { LOAD_ISSUES_ACTION_SOURCE_SCROLL } from '../../model';
import { loadIssuesAction } from '../../state/actions/issue';
import { FETCH_ISSUE_DATA_ONSCROLL } from '../../state/actions/table';
import type { Action } from '../../state/actions/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>) =>
	action$
		.ofType(FETCH_ISSUE_DATA_ONSCROLL)
		.switchMap((action) =>
			Observable.of(loadIssuesAction(action.payload, LOAD_ISSUES_ACTION_SOURCE_SCROLL)),
		);
