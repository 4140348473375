import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { combineEpics, type ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { getActiveRowIdChangedCallback } from '../../../../state/consumer/selectors';
import { setActiveItem } from '../../../../state/internal/actions';
import { rowIndexToRowId } from '../../../../state/selectors';
import type { State } from '../../../../state/types';
import { withUnpackedBatchActions } from '../../../common/batch';
import { errorHandlerFactory } from '../../../common/errors';
import { defineTransaction } from '../../../common/transactions';
import { type SetActiveCellAction, SET_ACTIVE_CELL, SET_ACTIVE_ROW } from './action';

export const setCell = defineTransaction(SET_ACTIVE_CELL, (action: SetActiveCellAction) =>
	setActiveItem(action.payload),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setRow = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) => {
	const errorHandler = errorHandlerFactory();
	return (
		withUnpackedBatchActions(action$)
			// @ts-expect-error - TS2339 - Property 'ofType' does not exist on type 'Observable<any>'.
			.ofType(SET_ACTIVE_ROW)
			// @ts-expect-error - TS7006 - Parameter 'action' implicitly has an 'any' type.
			.mergeMap((action) => {
				const state = store.getState();
				const activeRowIdChanged = getActiveRowIdChangedCallback(state);
				if (activeRowIdChanged != null) {
					const activeRowId = rowIndexToRowId(state, action.payload.rowIndex);
					activeRowIdChanged(activeRowId);
				}
				return Observable.of(setActiveItem(action.payload));
			})
			.catch(errorHandler)
	);
};

export default combineEpics(setCell, setRow);
