import React, { type ComponentType, type FC } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Button from '@atlaskit/button';
import MoreIcon from '@atlaskit/icon/glyph/more';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const MeatballMenu = lazyForPaint<ComponentType<Props>>(
	() => import(/* webpackChunkName: "async-meatball-menu" */ './index'),
	{ ssr: false },
);

const AsyncMeatballMenu: FC<Props> = (props: Props) => (
	<Placeholder
		name="async-meatball-menu"
		fallback={
			<Button appearance="subtle" iconBefore={<MoreIcon label="" size="medium" />} isDisabled />
		}
	>
		<MeatballMenu {...props} />
	</Placeholder>
);
export default AsyncMeatballMenu;
