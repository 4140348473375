import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import {
	RELOAD_PAGE_DATA,
	loadPageDataAction,
	resetStateAction,
} from '../../../state/actions/page';
import { stopPollAction } from '../../../state/actions/update-metadata';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>) =>
	action$.ofType(RELOAD_PAGE_DATA).switchMap((action) => {
		const { queueId } = action.payload;
		return Observable.of(
			stopPollAction(),
			// @ts-expect-error - TS2769 - No overload matches this call.
			resetStateAction(queueId),
			loadPageDataAction(queueId),
		);
	});
