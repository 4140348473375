import type { AggAffectedServiceNode } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/fields-type.tsx';
import type { AggTransformerFunction } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/transformer-type.tsx';
import type { AffectedServicesValue } from '@atlassian/jira-issue-shared-types/src/common/types/affected-services-type.tsx';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type AFFECTED_SERVICES_GQL_FIELD,
	SERVICE_ENTITY_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { transformAggConnection, transformAggNodeToLegacyField } from './default-node-transformer';

const transformJiraServicesToLegacyServices = ({
	serviceId,
	name,
}: NonNullable<AggAffectedServiceNode>): AffectedServicesValue => ({
	id: serviceId,
	name: name ?? undefined,
});

export const transformAggAffectedServicesToLegacyField: AggTransformerFunction<
	typeof AFFECTED_SERVICES_GQL_FIELD
> = (node) => {
	if (node.type !== SERVICE_ENTITY_CF_TYPE) {
		return undefined;
	}

	const hasValue = Boolean(node.selectedAffectedServicesConnection?.edges?.length);
	const value = hasValue
		? transformAggConnection(
				node.selectedAffectedServicesConnection,
				transformJiraServicesToLegacyServices,
			)
		: null;

	return {
		...transformAggNodeToLegacyField(node),
		autoCompleteUrl: node.searchUrl != null ? `${node.searchUrl}?query=` : null, // See BENTO-11604
		value,
		schema: {
			configuration: null,
			custom: SERVICE_ENTITY_CF_TYPE,
			items: 'service-entity-field',
			renderer: PLAIN_TEXT,
			system: null,
			type: 'array',
		},
	};
};
