import type { AggJiraResponderNode } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/fields-type.tsx';
import type { AggTransformerFunction } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/transformer-type.tsx';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { Responder } from '@atlassian/jira-issue-shared-types/src/common/types/responders-type.tsx';
import {
	type JSM_RESPONDERS_GQL_FIELD,
	JSM_TEAM_RESPONDER_FIELD,
	JSM_USER_RESPONDER_GQL_FIELD,
	RESPONDERS_CF_TYPE,
	ISSUE_FIELD_STRING_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { transformAggNodeToLegacyField, transformAggConnection } from './default-node-transformer';

const transformJiraResponderToLegacyResponder = (
	responder: AggJiraResponderNode,
): Responder | undefined => {
	if (responder == null) {
		return undefined;
	}

	switch (responder.__typename) {
		case JSM_USER_RESPONDER_GQL_FIELD: {
			if (responder.user === null) {
				return undefined;
			}

			return {
				// @ts-expect-error - 'responder.user' is possibly 'undefined'.
				ari: responder.user.canonicalAccountId,
				// @ts-expect-error - 'responder.user' is possibly 'undefined'.
				avatarUrl: responder.user.picture,
				// @ts-expect-error - 'responder.user' is possibly 'undefined'.
				name: responder.user.name,
				type: 'user',
			};
		}

		case JSM_TEAM_RESPONDER_FIELD: {
			return {
				// @ts-expect-error - Type 'string | null | undefined' is not assignable to type 'string | null'.
				ari: responder.teamId,
				// @ts-expect-error - Type 'string | null | undefined' is not assignable to type 'string | null'.
				name: responder.teamName,
				type: 'team',
			};
		}
		default:
			return undefined;
	}
};

export const transformAggJSMRespondersToLegacyField: AggTransformerFunction<
	typeof JSM_RESPONDERS_GQL_FIELD
> = (node) => {
	if (node.type !== RESPONDERS_CF_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		autoCompleteUrl: node.searchUrl != null ? `${node.searchUrl}?query=` : null, // See BENTO-11795
		value: transformAggConnection(
			node.respondersConnection,
			transformJiraResponderToLegacyResponder,
		),
		schema: {
			configuration: null,
			custom: RESPONDERS_CF_TYPE,
			items: ISSUE_FIELD_STRING_CF_TYPE,
			renderer: PLAIN_TEXT,
			system: null,
			type: 'array',
		},
	};
};
