import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey, toAccountId } from '@atlassian/jira-shared-types';
import type { UserProps } from '../../../model/fields/json-fields/common/types';
import {
	ASSIGNEE,
	REPORTER,
	type DataSelectorFieldValue,
	type FieldType,
	type FieldTypeForAnalytics,
} from '../../../model/fields/types';
import { putFieldValue$ } from '../../../rest/issue/field';
import type { NewUserFieldValue } from '../../../rest/issue/field/types';

const toAccountIdOrNull = (accountId?: string | null) =>
	accountId ? toAccountId(accountId) : null;

const putUserFieldValue = (
	// TODO: SHIELD-3213 - Not actually UserProps being passed - also includes fieldType
	value?: UserProps,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	fieldId: string,
	issueKey: string,
) => {
	const newUserFieldValue: NewUserFieldValue | null = value
		? { id: toAccountIdOrNull(value.accountId) }
		: null;
	const newFieldValues = {
		[fieldId]: newUserFieldValue,
	};
	return putFieldValue$(toIssueKey(issueKey), newFieldValues);
};

export const updateFieldValue$ = <T extends FieldType>(
	issueKey: string,
	fieldId: string,
	newValue: DataSelectorFieldValue<T>,
) => {
	switch (newValue.fieldType) {
		case ASSIGNEE:
		case REPORTER: {
			// @ts-expect-error - TS2345 - Argument of type 'DataSelectorFieldValue<T>' is not assignable to parameter of type 'UserProps'.
			return putUserFieldValue(newValue, fieldId, issueKey);
		}
		default:
			return Observable.throw(new Error(`fieldType ${newValue.fieldType} not supported`));
	}
};

export const trackUpdatedField = (
	event: UIAnalyticsEvent,
	field: string,
	fieldData: FieldTypeForAnalytics,
) => {
	const { oldId, oldCategory, newId, newCategory } = fieldData;
	fireTrackAnalytics(event, 'issueFields updated', {
		updatedField: field,
		updatedFieldOldID: oldId,
		updatedFieldNewID: newId,
		statusCategoryOld: oldCategory,
		statusCategoryNew: newCategory,
	});
};
