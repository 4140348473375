import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	allTicketsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.all-tickets-header',
		defaultMessage: 'All of your requests will show up here',
		description: 'All Tickets queues empty state header',
	},
	allTicketsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.all-tickets-description',
		defaultMessage:
			'Talk with customers, notify your teammates and track work all in one place. Create a request of your own to see it in action.',
		description: 'All Tickets queues empty state description',
	},
	allTicketsDescriptionForJsmImporterSurvey: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.all-tickets-description-for-jsm-importer-survey',
		defaultMessage:
			'Talk with customers, notify your teammates and track work all in one place. Create or import issues to see them in action.',
		description: 'All Tickets queues empty state description for JSM importer survey',
	},
	allTicketsCreateIssueButtonText: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.all-tickets-create-issue-button-text',
		defaultMessage: 'Create a request',
		description: 'All Tickets queues empty state button text',
	},
	serviceRequestsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.service-requests-header',
		defaultMessage: 'Solve service requests',
		description: 'Service requests queues empty state header',
	},
	serviceRequestsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.service-requests-description',
		defaultMessage: 'Track requests for support, monitor SLAs, and communicate with help seekers.',
		description: 'Service request queues empty state description',
	},
	serviceRequestsCreateIssueButtonText: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.service-requests-create-issue-button-text',
		defaultMessage: 'Create a service request',
		description: 'Service requests queues empty state button text',
	},
	incidentsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.incidents-header',
		defaultMessage: 'Resolve disruptions',
		description: 'Incidents queues empty state header',
	},
	incidentsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.incidents-description',
		defaultMessage:
			'Detect, respond to and resolve incidents. Catch issues early with alerts and on-call scheduling, and keep stakeholders updated at every step.',
		description: 'Incidents queues empty state description',
	},
	incidentsCreateIssueButtonText: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.incidents-create-issue-button-text',
		defaultMessage: 'Report an incident',
		description: 'Incidents queues empty state button text',
	},
	problemsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.problems-header',
		defaultMessage: 'Fix all the things',
		description: 'Problems queues empty state header',
	},
	problemsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.problems-description',
		defaultMessage:
			'Investigate the root cause of repeat incidents and track their resolution from start to finish.',
		description: 'Problems queues empty state description',
	},
	problemsCreateIssueButtonText: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.problems-create-issue-button-text',
		defaultMessage: 'Investigate a problem',
		description: 'Problems queues empty state button text',
	},
	changesHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.changes-header',
		defaultMessage: 'Make things happen',
		description: 'Changes queues empty state header',
	},
	changesDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.changes-description',
		defaultMessage:
			'Get full visibility of scheduled changes. Set approval steps, minimize disruption, create change records, and keep impacted stakeholders updated.',
		description: 'Changes queues empty state description',
	},
	changesCreateIssueButtonText: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.changes-create-issue-button-text',
		defaultMessage: 'Create a change request',
		description: 'Changes queues empty state button text',
	},
	postIncidentReviewsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.post-incident-reviews-header',
		defaultMessage: 'Learn from post-incident reviews',
		description: 'Post-incident reviews queues empty state header',
	},
	postIncidentReviewsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.post-incident-reviews-description',
		defaultMessage:
			'Bring your team together to capture the causes and impacts of an incident, and learn how to prevent similar incidents from happening again.',
		description: 'Post-incident reviews queues empty state description',
	},
	postIncidentReviewsCreateIssueButtonText: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.post-incident-reviews-create-issue-button-text',
		defaultMessage: 'Start a post-incident review',
		description: 'Post-incident reviews queues empty state button text',
	},
});
