import { connect } from '@atlassian/jira-react-redux';
import { getProjectId } from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';
import ReadyForUser from '@atlassian/jira-servicedesk-queues-common/src/view/queues-ready-for-user';
import EmptyQueueComponent from '@atlassian/jira-servicedesk-queues-empty-queue';
import type { State } from '../../../state/reducers/types';
import { getQueueId, getMetricKey, getCategory } from '../../../state/selectors/queue';
import { isQueueVisible } from '../../../state/selectors/ui';
import DemoDripFeed from '../demo-drip-feed';
import { getEmptyStateMessage } from './selectors';
import EmptyQueue from './view';

const mapStateToProps = (state: State) => ({
	emptyStateMessage: getEmptyStateMessage(state),
	projectId: getProjectId(state),
	queueId: getQueueId(state),
	isQueueVisible: isQueueVisible(state),
	category: getCategory(state),
	EmptyQueueComponent,
	DemoDripFeed,
	ReadyForUser,
	metricKey: getMetricKey(state),
});

export default connect(mapStateToProps, {})(EmptyQueue);
