import React, { useMemo, useState, useCallback, type FormEvent } from 'react';
import Heading from '@atlaskit/heading';
import AlignLeftIcon from '@atlaskit/icon/core/align-left';
import SearchIcon from '@atlaskit/icon/core/search';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import {
	FlyoutMenuItem,
	FlyoutMenuItemTrigger,
	FlyoutMenuItemContent,
	MenuSection,
} from '@atlassian/jira-navigation-system/src/v4';
import { usePathParam } from '@atlassian/jira-router';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context';
import { useCategorizedNavItems } from '@atlassian/jira-servicedesk-queues-categorized-store';
import { QueueListSection } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/navigation-category';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category';
import { usePopoutConfig } from '../../../../utils/popout-view';
import { useIsQueueSelected } from '../common';
import { QueueList } from '../queue-list';
import { QueueItem } from '../queue-list/queue-item';
import { AllQueuesFlyout } from './all-queues-fly-out';
import { AllQueuesPopOut } from './all-queues-pop-out';
import { messages } from './messages';

type AllQueuesSectionProps = {
	projectKey: string;
	practice: ItsmPractice;
};

const AllQueuesSectionNew = ({ projectKey, practice }: AllQueuesSectionProps) => {
	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const [queueIdFromPath] = usePathParam('queueId');
	const [allQueues] = useCategorizedNavItems('', projectKey, practice, queueCustomContext);
	const popoutConfig = usePopoutConfig(projectKey, practice);
	const selectedQueue = useMemo(
		() => allQueues?.find((queue) => queue.id === queueIdFromPath),
		[allQueues, queueIdFromPath],
	);
	const isQueueSelected = useIsQueueSelected(selectedQueue, QueueListSection.ALL_QUEUES);
	const hasSelectedQueue = isQueueSelected && selectedQueue !== undefined;

	if (!allQueues || allQueues.length === 0) {
		return null;
	}

	if (popoutConfig.isPoppedOut) {
		return <AllQueuesPopOut projectKey={projectKey} practice={practice} />;
	}

	return (
		<>
			{hasSelectedQueue && (
				<QueueItem queue={selectedQueue} section={QueueListSection.ALL_QUEUES} />
			)}
			<AllQueuesFlyout projectKey={projectKey} practice={practice} />
		</>
	);
};

const AllQueuesSectionLegacy = ({
	projectKey,
	practice,
}: AllQueuesSectionProps): JSX.Element | null => {
	const { formatMessage } = useIntl();
	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const [queueIdFromPath] = usePathParam('queueId');
	const [allQueues] = useCategorizedNavItems('', projectKey, practice, queueCustomContext);
	const [filter, setFilter] = useState('');
	const searchResultQueues = useMemo(
		() =>
			allQueues?.filter((queue) =>
				queue.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
			) ?? [],
		[allQueues, filter],
	);
	const selectedQueue = useMemo(
		() => allQueues?.find((queue) => queue.id === queueIdFromPath),
		[allQueues, queueIdFromPath],
	);
	const isQueueSelected = useIsQueueSelected(selectedQueue, QueueListSection.ALL_QUEUES);
	const SearchIconMemo = useMemo(
		() => <SearchIcon label="" spacing="spacious" color={token('color.icon')} />,
		[],
	);
	const handleChange = useCallback(
		(event: FormEvent<HTMLInputElement>) => setFilter(event.currentTarget.value),
		[],
	);

	if (!allQueues || allQueues.length === 0) {
		return null;
	}

	return (
		<>
			{isQueueSelected && selectedQueue !== undefined ? (
				<QueueItem queue={selectedQueue} section={QueueListSection.ALL_QUEUES} />
			) : null}
			<FlyoutMenuItem>
				<FlyoutMenuItemTrigger
					iconBefore={<AlignLeftIcon label="" spacing="spacious" color={token('color.icon')} />}
				>
					{formatMessage(messages.viewAllQueues)}
				</FlyoutMenuItemTrigger>
				<FlyoutMenuItemContent>
					<MenuSection>
						<Stack space="space.050">
							<Box paddingBlock="space.100">
								<Heading size="xsmall">{formatMessage(messages.allQueues)}</Heading>
							</Box>
							<Stack space="space.050" xcss={scrollBoxStyles}>
								<div>
									<Textfield
										isCompact
										value={filter}
										onChange={handleChange}
										aria-label={formatMessage(messages.searchYourQueue)}
										placeholder={formatMessage(messages.searchYourQueue)}
										elemBeforeInput={SearchIconMemo}
									/>
								</div>
								<div>
									{searchResultQueues.length > 0 ? (
										<QueueList
											queues={searchResultQueues}
											section={QueueListSection.ALL_QUEUES}
											isQueueListInFlyOut
										/>
									) : (
										<Box paddingBlock="space.100" xcss={noSearchResultsFoundStyles}>
											{formatMessage(messages.noSearchResultsFound)}
										</Box>
									)}
								</div>
							</Stack>
						</Stack>
					</MenuSection>
				</FlyoutMenuItemContent>
			</FlyoutMenuItem>
		</>
	);
};

export const AllQueuesSection = componentWithFF(
	'jsm-queues-nav4-refresh-m2-queues-pop-out-view_atq13',
	AllQueuesSectionNew,
	AllQueuesSectionLegacy,
);

const noSearchResultsFoundStyles = xcss({
	fontStyle: 'italic',
});

const scrollBoxStyles = xcss({
	maxHeight: '500px',
	width: '250px',
	overflow: 'auto',
	scrollbarGutter: 'stable',
});
