import React, {
	Component,
	type ComponentType,
	type ReactElement,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
} from 'react';
import debounce from 'lodash/debounce';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { setMark } from '@atlassian/jira-common-performance';
import { withTheme } from '../../../../../../../app/context/theme-context';
import type { ColumnId } from '../../../../../../../model/columns';
import type { Optional } from '../../../../../../../model/optional';
import type {
	RowId,
	RowTree,
	TemporaryAddedRow,
	RowAnalyticsData,
	RowContentMountCallback,
	RowContentUpdateCallback,
	LoadingRowUnmountCallback,
	RowHeightMapping,
} from '../../../../../../../model/rows';
import type { CompiledTheme } from '../../../../../../../model/themes';
import { Spacer, Wrapper } from './styled';

const ANALYTICS_DEBOUNCE_TIME = 5000;

type Props = {
	rowIds: RowId[];
	displayStart: number;
	displayEnd: number;
	rowTree: RowTree;
	expandedRowIdsHash: Record<RowId, boolean>;
	addBarOverflow: number;
	temporaryAddedRow: Optional<TemporaryAddedRow>;
	absoluteRowHeight: number;
	rowHeightMapping: RowHeightMapping;
	lastRowHasAddBar: boolean;
	addLinkShown: boolean;
	visibleColumns: ColumnId[];
	RowItem: ComponentType<{
		id: RowId;
		rowIndex: number;
		onRowContentMount: RowContentMountCallback;
		onRowContentUpdate: RowContentUpdateCallback;
		onLoadingRowUnmount: LoadingRowUnmountCallback;
	}>;
	onRowContentMount: (arg1: RowAnalyticsData[]) => void;
	onRowContentUpdate: RowContentUpdateCallback;
	onLoadingRowUnmount: LoadingRowUnmountCallback;
	theme: CompiledTheme;
};

type State = {
	rowIds: RowId[];
};

// eslint-disable-next-line jira/react/no-class-components
class RowList extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		const { rowIds, displayStart, displayEnd, visibleColumns } = props;

		this.state = { rowIds };
		this.accumulatedRowAnalyticsData = [];
		// TODO: Remove this once we have sufficient numbers for performance profiling
		const rowsRendered = displayEnd - displayStart + 1;
		const columnsRendered = visibleColumns.length;
		setMark(
			`virtualtable.rowlist.render.${
				rowsRendered * columnsRendered
			}.(${rowsRendered}x${columnsRendered}).cells.start`,
		);
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { rowIds, displayStart, displayEnd } = this.props;

		// if the incoming rows are different
		if (
			rowIds !== nextProps.rowIds ||
			displayStart !== nextProps.displayStart ||
			displayEnd !== nextProps.displayEnd
		) {
			this.resetState(nextProps.rowIds);
		}
	}

	shouldComponentUpdate(nextProps: Props, nextState: State) {
		const { displayStart, displayEnd } = this.props;

		return (
			displayStart !== nextProps.displayStart ||
			displayEnd !== nextProps.displayEnd ||
			this.state.rowIds !== nextState.rowIds
		);
	}

	resetState(rowIds: RowId[]) {
		this.setState({ rowIds });
	}

	accumulatedRowAnalyticsData: RowAnalyticsData[];

	fireRowMountAnalytics = debounce(() => {
		this.props.onRowContentMount(this.accumulatedRowAnalyticsData);
		this.accumulatedRowAnalyticsData = [];
	}, ANALYTICS_DEBOUNCE_TIME);

	gatherRowMountAnalyticsData = (
		mountBeginTime: number,
		mountEndTime: number,
		columns: ColumnId[],
		numberOfColumns: number,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const { displayStart, displayEnd } = this.props;

		if (!displayStart) {
			return;
		}
		this.accumulatedRowAnalyticsData.push({
			displayStart,
			displayEnd,
			mountBeginTime,
			mountEndTime,
			columns: columns.slice(0).sort(),
			numberOfColumns,
			numberOfRows: this.state.rowIds.length,
			analyticsEvent,
		});
		this.fireRowMountAnalytics();
	};

	render() {
		const {
			displayStart,
			displayEnd,
			temporaryAddedRow,
			addBarOverflow,
			RowItem,
			absoluteRowHeight,
			rowHeightMapping,
			onRowContentUpdate,
			lastRowHasAddBar,
			addLinkShown,
			onLoadingRowUnmount,
			theme,
		} = this.props;
		const { rowIds } = this.state;

		const rows: ReactElement<ComponentProps<typeof Spacer | typeof RowItem>>[] = [];
		const numberOfRows = rowIds.length - 1;
		const alwaysVisibleRows: Record<RowId, boolean> = {
			[rowIds[0]]: true,
			[rowIds[numberOfRows]]: true,
			...(temporaryAddedRow && { [temporaryAddedRow.temporaryId]: true }),
		};

		let spacerHeight = 0;
		let spacerStartRow = null;

		for (let index = 0; index <= numberOfRows; index += 1) {
			const isAlwaysVisible = alwaysVisibleRows[rowIds[index]];
			const isDisplay = index >= displayStart && index <= displayEnd;

			if (isAlwaysVisible || isDisplay) {
				if (spacerHeight > 0 && spacerStartRow) {
					// by using a key which is virtual row range it represents,
					// it can still be usefully reconciled (during dragging for example)
					const spacerKey = `${spacerStartRow}${rowIds[index - 1]}`;
					rows.push(<Spacer key={spacerKey} height={spacerHeight} />);
					spacerHeight = 0;
					spacerStartRow = null;
				}

				rows.push(
					<RowItem
						key={rowIds[index]}
						id={rowIds[index]}
						rowIndex={index}
						onRowContentMount={this.gatherRowMountAnalyticsData}
						onRowContentUpdate={onRowContentUpdate}
						onLoadingRowUnmount={onLoadingRowUnmount}
					/>,
				);
			} else {
				spacerHeight += rowHeightMapping[rowIds[index]] || absoluteRowHeight;
				spacerStartRow = rowIds[index];
			}
		}

		return (
			<Wrapper
				addPadding={lastRowHasAddBar || addLinkShown}
				addBarOverflow={addBarOverflow}
				theme={theme}
			>
				{rows}
			</Wrapper>
		);
	}
}

export default withTheme(RowList);
