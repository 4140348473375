import memoizeOne from 'memoize-one';
import { fireOperationalAnalytics } from '@atlassian/jira-analytics-web-react/src';
import { connect } from '@atlassian/jira-react-redux';
import { fromQueueId } from '@atlassian/jira-servicedesk-queues-common/src/model';
import { getIsAdmin } from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';
import type { State } from '../../../../state/reducers/types';
import { getIssueCacheSize, getIssueCount } from '../../../../state/selectors/issues';
import { getQueueId } from '../../../../state/selectors/queue';
import {
	getSortFieldType,
	getSortOrder,
	isManuallySorted,
} from '../../../../state/selectors/sorting';
import { isQueueVisible } from '../../../../state/selectors/ui';
import { getTimeSinceIssueListInitialized } from './selectors';
import AnalyticsWrapper from './view';

const mapStateToPropsFactory = () => {
	// @ts-expect-error - TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
	const onUpdateFactory = memoizeOne((queueId: number, state: State) => (analyticsEvent) => {
		fireOperationalAnalytics(analyticsEvent, {
			containerType: 'queue',
			containerId: `${fromQueueId(queueId)}`,
			attributes: {
				isAdmin: getIsAdmin(state),
				cachedIssueCount: getIssueCacheSize(state),
				availableIssueCount: getIssueCount(state),
				timeSinceIssueListInitialized: getTimeSinceIssueListInitialized(state),
				isQueueVisible: isQueueVisible(state),
				sortFieldType: getSortFieldType(state),
				sortOrder: getSortOrder(state),
				isDefaultSorting: !isManuallySorted(state),
			},
		});
	});
	return (state: State) => {
		const queueId = getQueueId(state);
		return {
			onUpdate: onUpdateFactory(queueId, state),
			// this is used to force re-renders on every state update due to use of `pure` option in react-redux v5
			state,
		};
	};
};

// @ts-expect-error - TS2769 - Argument of type 'null' is not assignable to parameter of type 'MergeProps<{ onUpdate: (analyticsEvent: any) => void; state: State; }, {}, {}, {}>'.
export default connect(mapStateToPropsFactory, {}, null, {
	// TODO: this option is deprecated react-redux v8
	pure: false,
	shouldHandleStateChanges: false,
})(AnalyticsWrapper);
