import isEmpty from 'lodash/isEmpty';
import memoizeOne from 'memoize-one';
import type { SortOrderConfiguration } from '../../../model/columns';
import type { Optional } from '../../../model/optional';
import type { AddedRows, SortedRowIdsHash, RowTree } from '../../../model/rows';
import type { ComparatorHash, DefaultComparator } from '../../../model/sorting';
import defaultComparatorFunc from '../../../state/consumer/selectors/columns/default-comparator-func';
import getNewlyPersistedAdddedRows from './diff-added-rows';
import getSortedInternalState from './sort-row-ids';

export default memoizeOne(
	(
		lastAddedRows: Optional<AddedRows>,
		lastRowTree: RowTree,
		lastSortedColumnConfiguration: Optional<SortOrderConfiguration>,
		sortOverrideEnabled: boolean,
		activeSortedColumnConfiguration: Optional<SortOrderConfiguration>,
		lastSortedRowIdsHash: SortedRowIdsHash,
		addedRows: Optional<AddedRows>,
		rowTree: RowTree,
		columnComparators: ComparatorHash,
		defaultComparator: DefaultComparator,
	) => {
		const newAddedRows = getNewlyPersistedAdddedRows(lastAddedRows, addedRows);

		// bypass sorting if possible (no new added rows, rowTree didn't change, sortConfiguration didn't change)
		if (
			isEmpty(newAddedRows) &&
			lastRowTree === rowTree &&
			lastSortedColumnConfiguration === activeSortedColumnConfiguration
		) {
			return lastSortedRowIdsHash;
		}

		return getSortedInternalState({
			rowTree,
			columnComparators,
			defaultComparator: defaultComparatorFunc(defaultComparator, columnComparators),

			persistedAddedRows: newAddedRows,
			isSortOverrideEnabled: sortOverrideEnabled,
			activeSortedColumnConfiguration,
			sortedRowIdsHash: lastSortedRowIdsHash,
		});
	},
);
