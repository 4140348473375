import { combineReducers } from 'redux';
import consumer from './consumer/reducer';
import internal, { initialState } from './internal/reducer';

export { initialState as initialInternalState };

export default combineReducers({
	consumer,
	internal,
});
