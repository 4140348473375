import { asyncWrmRequire } from '@atlassian/jira-wrm/src';
import type { LegacyIssueViewClass } from './types';

export const getLegacyIssueView = (): Promise<LegacyIssueViewClass> =>
	asyncWrmRequire([
		'wrc!jira.view.issue',
		'wrc!sd.project.legacy.issue.view',
		'wrc!sd.project.report.legacy.issue.view.bridge',
		// @ts-expect-error - TS2304 - Cannot find name '__jsdReport'.
	]).then(() => __jsdReport.LegacyIssueView);
