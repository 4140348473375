import React from 'react';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/async';
import { RouterActions } from '@atlassian/jira-router';
import type { FieldComponentProps } from '../../../../model/fields/types';
import AssigneeButton from './assignee-button';
import UserSelect from './user-select';
import SingleUserSelectInternal, { type UserFieldType } from './view';

const SingleUserSelectWithAnalytics = AnalyticsEventToProps('issueField', {
	onEditSuccess: 'updated',
})(SingleUserSelectInternal);

const SingleUserSelect = <T extends UserFieldType>(props: FieldComponentProps<T>) => (
	<RouterActions>
		{({ push }) => (
			<SingleUserSelectWithAnalytics
				UserSelect={UserSelect}
				ProfileCard={AsyncProfileCardNext}
				AssigneeButton={AssigneeButton}
				onPushAction={push}
				{...props}
			/>
		)}
	</RouterActions>
);
export default SingleUserSelect;
