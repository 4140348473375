import { connect } from '@atlassian/jira-react-redux';
import type { State } from '../../../../state/reducers/types';
import { getAppProps } from '../../../../state/selectors/common';
import { BoardPromoSpotlightView } from './view';

const mapStateToProps = (state: State) => ({
	isAdmin: getAppProps(state).isAdmin || getAppProps(state).isJiraAdmin,
	projectKey: getAppProps(state).projectKey,
});

export default connect(mapStateToProps, {})(BoardPromoSpotlightView);
