import React, { type ReactNode } from 'react';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { PROJECT_PATH } from './constants';
import ProductAccessCheck from './product-access-check';
import SettingsAccessCheck from './settings-access-check';

type Props = {
	children: ReactNode;
};

const AccessCheck = ({ children }: Props) => {
	const isSettingsRoute = useCurrentRoute().path.includes(`${PROJECT_PATH}/:projectKey/settings/`);
	const Gate = isSettingsRoute ? SettingsAccessCheck : ProductAccessCheck;

	return <Gate>{children}</Gate>;
};

export default AccessCheck;
