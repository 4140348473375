import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/switchMap';
import { LOCAL_STORAGE_SOURCE } from '@atlassian/jira-servicedesk-queues-common/src/model';
import {
	LOAD_ISSUES_ACTION_SOURCE_PAGE,
	LOAD_ISSUES_ACTION_SOURCE_SCROLL,
	LOAD_ISSUES_ACTION_SOURCE_SORT,
} from '../../model';
import { LOAD_ISSUES_FAILURE, LOAD_ISSUES_SUCCESS } from '../../state/actions/issue';
import {
	displayUnsuccessfulFetchErrorOnScrollAction,
	displayUnsuccessfulFetchWarningAction,
} from '../../state/actions/table';
import type { Action } from '../../state/actions/types';
import {
	displayUnsuccessfulPollWarningAction,
	dismissAllFlagsAction,
	dismissWarningFlagAction,
	POLL_FAILURE,
} from '../../state/actions/update-metadata';

export const SHOW_UNSUCCESSFUL_POLL_WARNING_AFTER_MILLIS = 10 * 60 * 1000;
// time after which the error flag will be shown after fetch-on-scroll failure
export const SHOW_UNSUCCESSFUL_FETCH_ERROR_AFTER_MILLIS = 2 * 1000;
export const DELAY_AFTER_DISMISSING_FLAG_MILLIS = 500;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>) => {
	let isFailing = false;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isSuccess = (action: any) =>
		action.type === LOAD_ISSUES_SUCCESS &&
		action.payload.loadedIssuesResponse.source !== LOCAL_STORAGE_SOURCE;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isFirstPollFailure = (action: any) => action.type === POLL_FAILURE && isFailing === false;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isFetchFailureInCachedQueue = (action: any) =>
		action.type === LOAD_ISSUES_FAILURE &&
		action.payload.source === LOAD_ISSUES_ACTION_SOURCE_PAGE &&
		!action.payload.isQueueEmpty;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isFetchFailureInEmptyQueue = (action: any) =>
		action.type === LOAD_ISSUES_FAILURE &&
		action.payload.source === LOAD_ISSUES_ACTION_SOURCE_PAGE &&
		action.payload.isQueueEmpty;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isFetchFailureInSortQueue = (action: any) =>
		action.type === LOAD_ISSUES_FAILURE && action.payload.source === LOAD_ISSUES_ACTION_SOURCE_SORT;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const shouldShowWarning = (action: any) =>
		isFetchFailureInCachedQueue(action) ||
		isFetchFailureInSortQueue(action) ||
		isFirstPollFailure(action);

	// show error flag only in case of failure during scroll. Show warning in all other cases
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const shouldShowError = (action: any) =>
		action.type === LOAD_ISSUES_FAILURE &&
		action.payload.source === LOAD_ISSUES_ACTION_SOURCE_SCROLL;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const shouldDismissAllFlags = (action: any) =>
		isSuccess(action) || isFetchFailureInEmptyQueue(action);

	return (
		action$
			.ofType(POLL_FAILURE, LOAD_ISSUES_SUCCESS, LOAD_ISSUES_FAILURE)
			.filter(
				(action) =>
					shouldDismissAllFlags(action) || shouldShowWarning(action) || shouldShowError(action),
			)
			// @ts-expect-error - TS2345: Types of property 'type' are incompatible. Type 'state.actions.update-metadata.DISMISS_ALL_FLAGS' is not assignable to type 'state.actions.update-metadata.DISPLAY_UNSUCCESSFUL_POLL_WARNING'.
			.switchMap((action) => {
				if (shouldShowWarning(action) || shouldShowError(action)) {
					let returnObservable = Observable.of(displayUnsuccessfulPollWarningAction());
					if (shouldShowError(action)) {
						// @ts-expect-error - TS2322 - Type 'Observable<DisplayUnsuccessfulFetchErrorOnScrollAction | DismissWarningFlagAction>' is not assignable to type 'Observable<DisplayUnsuccessfulPollWarningAction>'.
						returnObservable = Observable.concat(
							Observable.of(dismissWarningFlagAction()),
							Observable.of(displayUnsuccessfulFetchErrorOnScrollAction()).delay(
								DELAY_AFTER_DISMISSING_FLAG_MILLIS,
							),
						);
					} else if (isFetchFailureInCachedQueue(action)) {
						// @ts-expect-error - TS2322 - Type 'Observable<DisplayUnsuccessfulFetchWarningAction>' is not assignable to type 'Observable<DisplayUnsuccessfulPollWarningAction>'.
						returnObservable = Observable.of(displayUnsuccessfulFetchWarningAction());
					}

					return returnObservable
						.do(() => {
							isFailing = true;
						})
						.delay(
							isFirstPollFailure(action)
								? SHOW_UNSUCCESSFUL_POLL_WARNING_AFTER_MILLIS
								: SHOW_UNSUCCESSFUL_FETCH_ERROR_AFTER_MILLIS,
						);
				}
				if (shouldDismissAllFlags(action)) {
					isFailing = false;
					return Observable.of(dismissAllFlagsAction());
				}
				return Observable.empty<never>();
			})
	);
};
