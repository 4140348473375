import { assoc, assocIn, dissoc } from 'icepick';
import isNaN from 'lodash/isNaN';
import {
	LOAD_ISSUES_ACTION_SOURCE_SORT,
	LOAD_ISSUES_ACTION_SOURCE_FILTER,
} from '../../../../model';
import { LOAD_ISSUES_SUCCESS, type LoadIssuesSuccessAction } from '../../../actions/issue';
import {
	RESET_STATE,
	LOAD_PAGE_DATA,
	LOAD_PAGE_DATA_SUCCESS,
	type LoadPageDataAction,
	type LoadPageDataSuccessAction,
	type ResetStateAction,
} from '../../../actions/page';
import { QUEUE_CHANGED, type QueueChangedAction } from '../../../actions/queue';
import {
	PAGE_CHANGED,
	SORT_ORDER_CHANGED,
	RESET_SORT_ORDER,
	FETCH_ISSUE_DATA_ONSCROLL,
	type PageChangedAction,
	type SortOrderChangedAction,
	type ResetSortOrderAction,
	type FetchIssueDataOnScrollAction,
} from '../../../actions/table';
import type { QueueState } from './types';

export type Actions =
	| LoadPageDataAction
	| LoadPageDataSuccessAction
	| PageChangedAction
	| SortOrderChangedAction
	| ResetStateAction
	| ResetSortOrderAction
	| LoadIssuesSuccessAction
	| FetchIssueDataOnScrollAction
	| QueueChangedAction;

const DEFAULT_QUEUE_STATE = {
	pagination: {
		currentPage: 1,
	},
	fetchStartIndex: 0,
	category: undefined,
} as const;

// SHIELD-3575 This reducer describes queue page data and should be renamed to
// avoid ambiguity with state in ui/current-queue
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: QueueState = DEFAULT_QUEUE_STATE, action: Actions) => {
	switch (action.type) {
		case LOAD_PAGE_DATA:
		case RESET_STATE: {
			const { queueId } = action.payload;
			return assoc(DEFAULT_QUEUE_STATE, 'queueId', queueId);
		}
		case LOAD_PAGE_DATA_SUCCESS: {
			if (action.payload.queuesWithSorting.manualSorting) {
				return assoc(state, 'manualSorting', action.payload.queuesWithSorting.manualSorting);
			}
			return state;
		}
		case PAGE_CHANGED: {
			const { currentPage: actionPage } = action.payload;
			let currentPage = Math.max(1, Math.ceil(actionPage));
			if (isNaN(currentPage)) {
				currentPage = 1;
			}
			return assocIn(state, ['pagination', 'currentPage'], currentPage);
		}
		case SORT_ORDER_CHANGED: {
			return assoc(state, 'manualSorting', undefined);
		}
		case RESET_SORT_ORDER: {
			return dissoc(state, 'manualSorting');
		}
		case LOAD_ISSUES_SUCCESS: {
			/* Due to various reason (sorting field id is not longer valid, custom field has been removed, sorting field
    is no longer visible in the queue, etc), the sorting returned from the /issuelist endpoint maybe different
    from what we stored in the state. We should keep them in sync after we got the response. */

			return action.payload.source === LOAD_ISSUES_ACTION_SOURCE_SORT ||
				action.payload.source === LOAD_ISSUES_ACTION_SOURCE_FILTER
				? assocIn(state, ['pagination', 'currentPage'], 1)
				: state;
		}
		case FETCH_ISSUE_DATA_ONSCROLL: {
			return assoc(state, 'fetchStartIndex', action.payload);
		}
		case QUEUE_CHANGED: {
			return assoc(state, 'category', action.payload.queue.category);
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
