import type { APIState } from '../../../model/api';
import type { Theme } from '../../../model/themes';

export const SET_STATE_FROM_PROPS = 'ops.hydrate.set.SET_STATE_FROM_PROPS' as const;

export type SetStateFromPropsAction = {
	type: typeof SET_STATE_FROM_PROPS;
	payload: {
		apiState: APIState;
		theme: Theme;
	};
};

export const setStateFromProps = (apiState: APIState, theme: Theme): SetStateFromPropsAction => ({
	type: SET_STATE_FROM_PROPS,
	payload: { apiState, theme },
});
