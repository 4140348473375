import type { State } from '../../../state/reducers/types';
import { getUseJSMQueueDurationEvent } from '../../../state/selectors/ui';
import { connect } from '../../../state/store';
import type { StateProps, OwnProps } from './types';
import SummaryField from './view';

const mapStateToProps = (state: State) => ({
	useJSMQueueDurationEvent: getUseJSMQueueDurationEvent(state),
});

const mergeProps = (stateProps: StateProps, dispatchProps: {}, ownProps: OwnProps) => ({
	...ownProps,
	...stateProps,
});

export default connect(mapStateToProps, {}, mergeProps)(SummaryField);
