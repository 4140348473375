import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { ff } from '@atlassian/jira-feature-flagging';

// Please consider refactoring code to use either only padding in styled components or only GUTTER, not both. Otherwise, it leads to bugs and unexpected behavior, e.g. GHOST-4901
export const COLUMN_GUTTER = {
	left: gridSize,
	right: gridSize * 3,
	rightLastColumn: gridSize,
} as const;

const breakWordStyle = {
	minWidth: '0px',
	overflowWrap: 'break-word',
} as const;

type GutterProps = {
	isLastColumn: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UnselectableChildGutterPaddingContainer = styled.div<GutterProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isLastColumn }) =>
		`0px ${isLastColumn ? token('space.100', '8px') : token('space.300', '24px')} 0px ${token('space.100', '8px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...breakWordStyle,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SelectableChildGutterPaddingContainer = styled.div<GutterProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isLastColumn }) =>
		`0px ${
			// eslint-disable-next-line no-nested-ternary
			isLastColumn
				? '0px'
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					ff('jsm-queue-sidebar-bug-fixes-second-round_xuenh')
					? '0px'
					: token('space.200', '16px')
		} 0px 0px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...breakWordStyle,
});
