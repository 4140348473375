import {
	type HierarchyLevel,
	ISSUE_HIERARCHY_LEVEL_BASE,
	ISSUE_HIERARCHY_LEVEL_EPIC,
	ISSUE_HIERARCHY_LEVEL_SUBTASK,
} from '@atlassian/jira-issue-type-hierarchies';

// Based on `GlobalHierarchyLevelConstants` in backend
export const ISSUE_TYPE_HIERARCHY_LEVEL = {
	SUBTASK_LEVEL: ISSUE_HIERARCHY_LEVEL_SUBTASK,
	BASE_LEVEL: ISSUE_HIERARCHY_LEVEL_BASE,
	LEVEL_ONE: ISSUE_HIERARCHY_LEVEL_EPIC,
	LEVEL_TWO: 2,
} as const;

export type IssueType = {
	id: string;
	name: string;
	self: string;
	description: string;
	iconUrl: string;
	subtask: boolean;
	avatarId: number;
	hierarchyLevel: HierarchyLevel;
};

export type IssueTypesForHierarchyLevel = {
	level: number;
	name: string;
	issueTypeIds: number[] | string[];
	issueTypes: IssueType[];
};

/**
 * Issue type data returned by `/gira`, used for remote linked issues
 *
 * See: `getAssociatedIssueFragment()` in `jira-providers-issue/issue-express`
 */
export type ServerRemoteIssueType = {
	iconUrl: string;
	name: string;
};
