import React from 'react';
import AppIcon from '@atlaskit/icon/core/app';
import { token } from '@atlaskit/tokens';
import { ContainerAvatar } from '@atlassian/jira-navigation-system/src/v4';

export const ForgeIcon = ({ url }: { url?: string }) =>
	url != null && String(url).trim().length > 0 ? (
		<ContainerAvatar src={url} />
	) : (
		<AppIcon label="" color={token('color.icon')} />
	);
