import React, {
	// eslint-disable-next-line jira/restricted/react
	PureComponent,
	useEffect,
	useRef,
	useState,
	type ComponentType,
	type ReactNode,
} from 'react';
import { enableBatching } from 'redux-batched-actions';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsSource, AnalyticsData } from '@atlassian/jira-analytics-web-react/src';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsEvents, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import { useQueryParam } from '@atlassian/jira-router';
import { ServiceDeskAnalyticsContext } from '@atlassian/jira-servicedesk-analytics/src';
import ServiceDeskSubAppBase from '@atlassian/jira-servicedesk-common/src/ui/components/servicedesk-subapp-base';
import { ANALYTIC_CONTEXTS } from '@atlassian/jira-servicedesk-common/src/utils/analytics';
import { APP_NAMES } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import {
	AsyncProjectBuilderDrawer as ProjectBuilderDrawer,
	canUseProjectBuilder,
} from '@atlassian/jira-servicedesk-project-builder/src/async';
import { SHOW_PROJECT_BUILDER_QUERY_PARAM_DEV } from '@atlassian/jira-servicedesk-project-builder/src/common/constants';
import { QueuesErrorView } from '@atlassian/jira-servicedesk-queues-common/src/view/queues-error-state';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons';
import { toProjectId } from '@atlassian/jira-shared-types';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller';
import { useIsStagingOrDevOrLocal } from '@atlassian/jira-tenant-context-controller/src/components/environment';
import type {
	OnAtLeastOneIssueLoadedCallback,
	OnQueueRenderFailureCallback,
	SortedQueue,
} from '../model';
import rootEpic from '../ops';
import { sendAnalyticCreatorAction } from '../state/actions/analytic';
import { resetProjectContextAction } from '../state/actions/app-props';
import rootReducer from '../state/reducers';
import type { InitialState } from '../state/reducers/types';
import AgentView from './layout';
import BasicAuthUsersBanner from './layout/basic-auth-users-banner';
import ChangeManagementTour from './layout/change-management-tour';
import QueuesExperienceTrackerProvider from './layout/experience-tracker/provider';
import FlagsManager from './layout/flags-manager';
import IncidentManagementTour from './layout/incident-management-tour';

export type Props = {
	isQueueVisible: boolean;
	initialState: InitialState;
	// SHIELD-3608: payload should be of type Queue. Currently used as SortedQueue
	// to support legacy
	queue: SortedQueue;
	locale: Locale;
	push: QueuesPush;
	onAtLeastOneIssueLoaded: OnAtLeastOneIssueLoadedCallback;
	onQueueRenderFailure: OnQueueRenderFailureCallback | undefined;
	onIssuesChanged?: (keys: (string | undefined)[]) => void;
	onIssuesCountChanged?: (count: number) => void;
};

type ReduxAnalyticsProps = {
	children?: ReactNode;
	setAnalyticsToRedux: (createAnalyticsEvent: CreateUIAnalyticsEvent) => void;
};

// for local dev, to be able to open Project Builder without creating project each time
const ProjectBuilderWithQueryParamDev = () => {
	const isAdmin = useIsAdmin();
	const isStagingOrDev = useIsStagingOrDevOrLocal();

	const [projectBuilderQueryParamDev] = useQueryParam(SHOW_PROJECT_BUILDER_QUERY_PARAM_DEV);

	const [isDrawerOpen] = useState(() => isStagingOrDev && projectBuilderQueryParamDev === 'true');

	if (isDrawerOpen) {
		return canUseProjectBuilder(isAdmin) ? <ProjectBuilderDrawer isOpenInitial /> : null;
	}
	return null;
};

const ReduxAnalyticsOld = connect(null, (dispatch) => ({
	setAnalyticsToRedux: (createAnalyticsEvent: CreateUIAnalyticsEvent) => {
		dispatch(sendAnalyticCreatorAction(createAnalyticsEvent));
	},
}))(({ setAnalyticsToRedux }: ReduxAnalyticsProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	// Save setAnalyticsToReduxRef to ref to avoid useEffect reevaluation on every rerender
	const setAnalyticsToReduxRef = useRef<ReduxAnalyticsProps['setAnalyticsToRedux']>();
	setAnalyticsToReduxRef.current = setAnalyticsToRedux;

	useEffect(() => {
		setAnalyticsToReduxRef.current && setAnalyticsToReduxRef.current(createAnalyticsEvent);
	}, [createAnalyticsEvent]);

	return null;
});

const ReduxAnalyticsNew = connect(null, (dispatch) => ({
	setAnalyticsToRedux: (createAnalyticsEvent: CreateUIAnalyticsEvent) => {
		dispatch(sendAnalyticCreatorAction(createAnalyticsEvent));
	},
}))(({ setAnalyticsToRedux, children }: ReduxAnalyticsProps) => {
	const [isAnalyticsSet, setIsAnalyticsSet] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	// Save setAnalyticsToReduxRef to ref to avoid useEffect reevaluation on every rerender
	const setAnalyticsToReduxRef = useRef<ReduxAnalyticsProps['setAnalyticsToRedux']>();
	setAnalyticsToReduxRef.current = setAnalyticsToRedux;

	useEffect(() => {
		setAnalyticsToReduxRef.current && setAnalyticsToReduxRef.current(createAnalyticsEvent);
		setIsAnalyticsSet(true);
	}, [createAnalyticsEvent]);

	if (isAnalyticsSet) {
		return children;
	}

	return null;
});

// eslint-disable-next-line jira/react/no-class-components
class AgentApp extends PureComponent<Props> {
	static defaultProps = {
		queue: undefined,
		push: undefined,
	};

	renderAgentView() {
		const {
			isQueueVisible,
			onAtLeastOneIssueLoaded,
			queue,
			onIssuesChanged,
			onIssuesCountChanged,
		} = this.props;

		// forceRenderWhileLoading guards against remounting caused by
		// ServiceDeskAnalyticsContext in rare scenarios, which can in turn
		// result in "Cannot update during an existing state transition".
		//
		// See: `this.triggerQueueChange()` called during render() in https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/jira/src/packages/servicedesk/queues-agent-view/src/view/layout/view.tsx#313
		return (
			<ServiceDeskAnalyticsContext forceRenderWhileLoading>
				<AgentView
					queue={queue}
					isQueueVisible={isQueueVisible}
					onAtLeastOneIssueLoaded={onAtLeastOneIssueLoaded}
					onIssuesChanged={onIssuesChanged}
					onIssuesCountChanged={onIssuesCountChanged}
				/>
				<FlagsManager />
				{!fg('view_queues_local_storage_data_updated') && <ReduxAnalyticsOld />}
			</ServiceDeskAnalyticsContext>
		);
	}

	render() {
		const { isQueueVisible, initialState, locale, onQueueRenderFailure, push } = this.props;
		const { appProps: { isAdmin } = {} } = initialState;
		const state = {
			appProps: initialState.appProps,
		};

		const { projectKey } = state.appProps;

		return (
			<ServiceDeskSubAppBase
				appName={APP_NAMES.QUEUES_AGENT_VIEW}
				initialState={state}
				locale={locale}
				rootReducer={enableBatching(rootReducer)}
				rootEpic={rootEpic(push)}
				onErrorHandler={onQueueRenderFailure}
				ErrorView={QueuesErrorView}
				resetProjectContextAction={resetProjectContextAction}
			>
				{fg('view_queues_local_storage_data_updated') ? (
					<ServiceDeskAnalyticsContext forceRenderWhileLoading>
						<ReduxAnalyticsNew>
							{ff('sptn-1202-banner-for-basic-auth-connected-admins_dpyqu') && isQueueVisible && (
								<BasicAuthUsersBanner baseUrl="" projectKey={projectKey} isAdmin={isAdmin} />
							)}
							<QueuesExperienceTrackerProvider>
								{this.renderAgentView()}
							</QueuesExperienceTrackerProvider>
							<ProjectBuilderWithQueryParamDev />
							<ChangeManagementTour projectId={toProjectId(`${state.appProps.projectId}`)} />
							<JSErrorBoundary
								id="incidentManagementTourQueues"
								// eslint-disable-next-line jira/js/package-name-enforcer-for-error-boundary
								packageName="jiraServicedeskIncidentManagementTour"
								fallback="flag"
							>
								<IncidentManagementTour projectId={toProjectId(`${state.appProps.projectId}`)} />
							</JSErrorBoundary>
						</ReduxAnalyticsNew>
					</ServiceDeskAnalyticsContext>
				) : (
					<>
						{ff('sptn-1202-banner-for-basic-auth-connected-admins_dpyqu') && isQueueVisible && (
							<BasicAuthUsersBanner baseUrl="" projectKey={projectKey} isAdmin={isAdmin} />
						)}
						<QueuesExperienceTrackerProvider>
							{this.renderAgentView()}
						</QueuesExperienceTrackerProvider>
						<ProjectBuilderWithQueryParamDev />
						<ChangeManagementTour projectId={toProjectId(`${state.appProps.projectId}`)} />
						<JSErrorBoundary
							id="incidentManagementTourQueues"
							// eslint-disable-next-line jira/js/package-name-enforcer-for-error-boundary
							packageName="jiraServicedeskIncidentManagementTour"
							fallback="flag"
						>
							<IncidentManagementTour projectId={toProjectId(`${state.appProps.projectId}`)} />
						</JSErrorBoundary>
					</>
				)}
			</ServiceDeskSubAppBase>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const AgentAppWithAnalytics: ComponentType<Props> = AnalyticsSource(
	'viewQueue',
	SCREEN,
)(
	AnalyticsData({
		attributes: {
			context: ANALYTIC_CONTEXTS.QUEUES,
			redesign: true,
		},
	})(AgentApp),
) as any; // eslint-disable-line @typescript-eslint/no-explicit-any

export default AgentAppWithAnalytics;
