import type { BlockingSpanEntry, InProgressBlockingSpan } from '../types';

type BlockedOnBackendInternalState = {
	currentKey?: string;

	timestamps: {
		[blockingReasonKey: string]: InProgressBlockingSpan;
	};
};

export type BlockedOnBackendEntryLog = ReturnType<typeof createBlockedOnBackendEntryLog>;

export const createBlockedOnBackendEntryLog = () => {
	const blockedOnBackendState: BlockedOnBackendInternalState = { timestamps: {} };

	// Internal use, use initialiseBlockedOnBackendStateForUpdatedKey externally
	const clearBlockedOnBackendState = () => {
		blockedOnBackendState.timestamps = {};
		blockedOnBackendState.currentKey = undefined;
	};

	// Internal use, use markStartBackendBlockingTimestamp or markEndBackendBlockingTimestamp
	const getOrCreateTimestampsEntryForKey = (blockingReasonKey: string) => {
		let blockingDurationTimestamps = blockedOnBackendState.timestamps[blockingReasonKey];
		if (!blockingDurationTimestamps) {
			blockingDurationTimestamps = {};
			blockedOnBackendState.timestamps[blockingReasonKey] = blockingDurationTimestamps;
		}
		return blockingDurationTimestamps;
	};

	/**
	 * Initialise the store for a given key string.
	 * Only resets the state the first if the key string updates from its initial undefined OR if the key updates from the last time this was called
	 */
	const initialiseBlockedOnBackendStateForUpdatedKey = (key?: string) => {
		if (key !== blockedOnBackendState.currentKey) {
			clearBlockedOnBackendState();
			blockedOnBackendState.currentKey = key;
		}
	};

	/**
	 * Fire this to mark the start of being blocked on backend for a given reason.
	 * @param blockingReasonKey An identifier for the reason for blocking. Until state is re-initialised, this reason cannot be used again to start a blocking span. Overlapping reasons will not be double counted
	 * @param timestamp optional, defaults to current time
	 */
	const markStartBackendBlockingTimestamp = (
		blockingReasonKey: string,
		timestamp = performance.now(),
	) => {
		const blockingDurationTimestamps = getOrCreateTimestampsEntryForKey(blockingReasonKey);
		if (!blockingDurationTimestamps.blockingStart) {
			blockingDurationTimestamps.blockingStart = timestamp;
		}
	};

	/**
	 * Fire this to mark the end of being blocked on backend for a given reason
	 * @param blockingReasonKey
	 * @param timestamp optional, defaults to current time
	 */
	const markEndBackendBlockingTimestamp = (
		blockingReasonKey: string,
		timestamp = performance.now(),
	) => {
		const blockingDurationTimestamps = getOrCreateTimestampsEntryForKey(blockingReasonKey);
		if (!blockingDurationTimestamps.blockingEnd) {
			blockingDurationTimestamps.blockingEnd = timestamp;
		}
	};

	/**
	 * Return a list of the entries gathered so far
	 * @param sorted whether the returned results should be sorted based on their start time. true by default
	 */
	const getBlockedOnBackendEntries = ({
		sorted = true,
	}: { sorted?: boolean } = {}): BlockingSpanEntry[] => {
		let entries = Object.entries(blockedOnBackendState.timestamps).filter(
			(entry): entry is BlockingSpanEntry => {
				const [, blockingTimestamps] = entry;

				return (
					blockingTimestamps.blockingStart != null &&
					blockingTimestamps.blockingEnd != null &&
					blockingTimestamps.blockingStart <= blockingTimestamps.blockingEnd
				);
			},
		);
		if (sorted) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			entries = entries.sort(
				([, { blockingStart: start1 }], [, { blockingStart: start2 }]) => start1 - start2,
			) as BlockingSpanEntry[];
		}
		return entries;
	};

	return {
		initialiseBlockedOnBackendStateForUpdatedKey,
		markStartBackendBlockingTimestamp,
		markEndBackendBlockingTimestamp,
		getBlockedOnBackendEntries,
	};
};
