import { fetchJson } from '@atlassian/jira-fetch';
import type { CurrentAssignee as CurrentAssigneeResponse } from './types';

const getCurrentAssigneeUrl = (issueKey: string) => `/rest/api/3/issue/${issueKey}?fields=assignee`;

export const getCurrentAssignee = (issueKey: string): Promise<CurrentAssigneeResponse> =>
	fetchJson(getCurrentAssigneeUrl(issueKey), {
		method: 'GET',
	});

export type CurrentAssignee = CurrentAssigneeResponse;
