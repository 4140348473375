import { createSelector } from 'reselect';
import { createWeakMapSelector } from '@atlassian/jira-common-selectors';
import { addBarCircleSize } from '../../../../constants';
import type { Optional } from '../../../../model/optional';
import type {
	AddedRows,
	PersistedAddedRows,
	Row,
	RowAddState,
	RowHeightMapping,
	RowId,
	RelativePosition as RowRelativePosition,
	RowTree,
	RowsConfiguration,
	TemporaryAddedRow,
} from '../../../../model/rows';
import getDepthForRow from '../../../common/get-tree-depth';
import getPathForRow from '../../../common/get-tree-path';
import type { State } from '../../../types';

export const getRowTree = (state: State): RowTree => state.consumer.rowTree;
export const getRowsConfiguration = (state: State): RowsConfiguration =>
	state.consumer.rowsConfiguration;
export const getAddedRows = (state: State): Optional<AddedRows> => state.consumer.addedRows;
export const getMaxDepth = (state: State) => state.consumer.maxDepth;
export const getAddLinkCaption = (state: State): Optional<string> => state.consumer.addLinkCaption;
export const getExpandedRowIds = (state: State) => state.consumer.expandedRowIds;

export const getExpandedRowIdsHash = createSelector(getExpandedRowIds, (expandedRowIds) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	expandedRowIds.reduce<Record<string, any>>((acc, next) => {
		// for effeciency sake we are mutating the accumulator to build up our hash
		acc[next] = true;
		return acc;
	}, {}),
);

export const isRowExpanded = (state: State, rowId: RowId): boolean =>
	!!getExpandedRowIdsHash(state)[rowId];

export const getTemporaryAddedRow = (state: State): Optional<TemporaryAddedRow> =>
	state.consumer.addedRows !== undefined ? state.consumer.addedRows.temporary : undefined;

export const getPersistedAddedRows = (state: State): Optional<PersistedAddedRows> =>
	state.consumer.addedRows !== undefined ? state.consumer.addedRows.persisted : undefined;

export const isAnyRowBeingAdded = (state: State): boolean =>
	getTemporaryAddedRow(state) !== undefined;

export const isRowBeingAdded = (state: State, rowId: RowId): boolean => {
	const temporaryAddedRow = getTemporaryAddedRow(state);
	return temporaryAddedRow !== undefined && temporaryAddedRow.temporaryId === rowId;
};

export const getRowAddingStatus = (state: State, rowId: RowId): Optional<RowAddState> => {
	const addedRows = getAddedRows(state);

	if (addedRows !== undefined) {
		if (!!addedRows.temporary && addedRows.temporary.temporaryId === rowId) {
			return addedRows.temporary.isPersisting ? 'PERSISTING' : 'NEW';
		}
		if (!!addedRows.persisted && addedRows.persisted[rowId]) {
			return 'PERSISTED';
		}
	}

	return undefined;
};

export const getParentRowId = (state: State, rowId: RowId): Optional<RowId> =>
	getRowTree(state).rows[rowId].parentId;

export const getRowHasChildren = (state: State, rowId: RowId): boolean => {
	// a temp child will not be included in the row tree children - need to check both.
	const temporaryAddedRow = getTemporaryAddedRow(state);
	const row = getRowTree(state).rows[rowId];
	return (
		(row && row.childrenIds.length > 0) ||
		(temporaryAddedRow !== undefined &&
			temporaryAddedRow.anchorId === rowId &&
			temporaryAddedRow.position === 'INSIDE')
	);
};

export const getRow: (arg1: State, arg2: RowId) => Optional<Row> = (state: State, rowId: RowId) =>
	getRowTree(state).rows[rowId];

export const getDefaultRowHeight = (state: State): number => state.consumer.rowHeight;

const getRowBorderWidth = (state: State): number => state.internal.rowBorderWidth;

export const getRowHeightMapping = (state: State): RowHeightMapping =>
	state.internal.rowHeightMapping;

export const getCalculatedRowHeight = createSelector(
	getRowHeightMapping,
	getDefaultRowHeight,
	(rowHeightMapping, defaultRowHeight) => {
		const vals = Object.values(rowHeightMapping).sort();
		const medianIdx = Math.floor(vals.length / 2);
		return vals[medianIdx] || defaultRowHeight;
	},
);

export const getAbsoluteRowHeight = (state: State): number =>
	Math.max(
		0,
		(getCalculatedRowHeight(state) || getDefaultRowHeight(state)) - getRowBorderWidth(state),
	);

export const getRowHeight = (state: State, rowId: RowId): number => {
	const knownRowHeight = getRowHeightMapping(state)[rowId];
	if (knownRowHeight) {
		return Math.max(0, knownRowHeight);
	}
	return getAbsoluteRowHeight(state);
};

export const isAutoRowHeight = (state: State): boolean => state.consumer.autoRowHeight;

export type RowDepthPathHash = Record<
	RowId,
	{
		depth: number;
		path: string[];
	}
>;

export const getStaticRowPathAndDepth = createSelector(getRowTree, (rowTree) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const rowPathAndDepthHash: Record<string, any> = {};
	Object.keys(rowTree.rows).forEach((rowId) => {
		const depth = getDepthForRow(rowTree.rows, rowId);
		const path = getPathForRow(rowTree.rows, rowId);
		rowPathAndDepthHash[rowId] = {
			depth,
			path,
		};
	});
	return rowPathAndDepthHash;
});

export type RowDepthHash = Record<
	RowId,
	{
		depth: number;
	}
>;

export const getStaticRowDepth = createSelector(getRowTree, (rowTree) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const rowDepthHash: Record<string, any> = {};
	Object.keys(rowTree.rows).forEach((rowId) => {
		const depth = getDepthForRow(rowTree.rows, rowId);
		rowDepthHash[rowId] = {
			depth,
		};
	});
	return rowDepthHash;
});

export type RowPathHash = Record<
	RowId,
	{
		path: string[];
	}
>;

export const getStaticRowPath = createSelector(getRowTree, (rowTree) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const rowPathHash: Record<string, any> = {};
	Object.keys(rowTree.rows).forEach((rowId) => {
		const path = getPathForRow(rowTree.rows, rowId);
		rowPathHash[rowId] = {
			path,
		};
	});
	return rowPathHash;
});

export const getMaxDepthAndExpandedRowIds = createSelector(
	getMaxDepth,
	getExpandedRowIds,
	(maxDepth, expandedRowIds) => ({
		maxDepth,
		expandedRowIds,
	}),
);

export const getRowAddPosition: (arg1: State, arg2: RowId) => RowRelativePosition =
	createWeakMapSelector(
		getRow,
		getStaticRowDepth,
		getMaxDepthAndExpandedRowIds,
		// @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type. | TS7006 - Parameter 'rowDepth' implicitly has an 'any' type. | TS7031 - Binding element 'maxDepth' implicitly has an 'any' type. | TS7031 - Binding element 'expandedRowIds' implicitly has an 'any' type.
		(row, rowDepth, { maxDepth, expandedRowIds }) => {
			if (row === undefined) {
				throw new Error('row must not be undefined');
			}
			const rowId = row.id;
			const currentRowDepth = rowDepth[rowId].depth;

			// if a row can have children and is expanded, add a child
			// if a row can have children but is collapsed, add a sibling
			if (currentRowDepth < maxDepth) {
				if (expandedRowIds.indexOf(rowId) !== -1) {
					return 'INSIDE';
				}
				return 'AFTER';
			}

			// otherwise it is a child row, add a sibling
			return 'AFTER';
		},
	);

export const getAddBarOverflow = (state: State): number =>
	(addBarCircleSize - getRowBorderWidth(state)) / 2;

export const getRowDragIndicatorColor = (state: State, rowId: RowId): Optional<string> => {
	const rowConfiguration = getRowsConfiguration(state)[rowId];
	return rowConfiguration ? rowConfiguration.dragIndicatorColor : undefined;
};

export const isRowLoading = (state: State, rowId: RowId): boolean => {
	const rowConfiguration = getRowsConfiguration(state)[rowId];
	if (rowConfiguration) {
		return rowConfiguration.isLoading || false;
	}
	return false;
};

export const getRowCanAddChildren = (state: State, rowId: RowId): boolean => {
	const rowConfiguration = getRowsConfiguration(state)[rowId];
	if (rowConfiguration) {
		return rowConfiguration.canAddChildren !== undefined ? rowConfiguration.canAddChildren : true;
	}
	return true;
};

export const getRowCanAddSibling = (state: State, rowId: RowId): boolean => {
	const rowConfiguration = getRowsConfiguration(state)[rowId];
	if (rowConfiguration) {
		return rowConfiguration.canAddSibling !== undefined ? rowConfiguration.canAddSibling : true;
	}
	return true;
};

export const isRowSelected = (state: State, rowId: RowId): boolean => {
	const rowConfiguration = getRowsConfiguration(state)[rowId];
	if (rowConfiguration) {
		return rowConfiguration.isSelected || false;
	}
	return false;
};
