import { connect } from '@atlassian/jira-react-redux';
import { getProjectKey } from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';
import type { State } from '../../../../../state/reducers/types';
import { getCompleteJql } from '../../../../../state/selectors/queue';
import { OpenInIssueNavButton } from './view';

const mapStateToProps = (state: State) => ({
	projectKey: getProjectKey(state),
	queueJql: getCompleteJql(state),
});

export default connect(mapStateToProps, {})(OpenInIssueNavButton);
