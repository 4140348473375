import { assoc } from 'icepick';
import {
	SHOW_PAGINATION,
	SET_EDITING_FIELD_POSITION,
	SET_CURRENT_PAGE,
	SET_IS_TABLE_DISABLED,
	DISABLE_USER_PICKER,
	SET_ON_ISSUE_VIEW_TRANSITION,
	SET_JSM_QUEUE_DURATION_EVENT,
	SET_RENDER_SIDE_BAR_ICON,
	type SetPaginationVisibilityAction,
	type SetEditingFieldPositionAction,
	type SetCurrentPageAction,
	type SetIsTableDisabled,
	type DisableUserPickerAction,
	type SetOnIssueViewTransition,
	type SetJSMQueueDurationEvent,
	type SetRenderSidebarIcon,
	type SetRefreshSidebar,
	SET_REFRESH_SIDE_BAR,
} from '../../actions/ui';
import type { UiState } from '../types';

type Actions =
	| SetPaginationVisibilityAction
	| SetEditingFieldPositionAction
	| SetCurrentPageAction
	| SetIsTableDisabled
	| DisableUserPickerAction
	| SetOnIssueViewTransition
	| SetJSMQueueDurationEvent
	| SetRenderSidebarIcon
	| SetRefreshSidebar;

export const DEFAULT_STATE = {
	showPagination: false,
	currentPage: 1,
	userPickerDisabled: false,
	isTableDisabled: false,
	onIssueViewTransition: undefined,
	useJSMQueueDurationEvent: undefined,
	renderSidebarIcon: undefined,
	refreshSidebar: undefined,
} as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: UiState = DEFAULT_STATE, action: Actions) => {
	switch (action.type) {
		case SHOW_PAGINATION: {
			return assoc(state, 'showPagination', action.payload);
		}
		case SET_EDITING_FIELD_POSITION: {
			return assoc(state, 'editingFieldPosition', {
				horizontalOffset: action.payload.horizontalOffset,
				verticalOffset: action.payload.verticalOffset,
				fieldWidth: action.payload.fieldWidth,
				tableWidth: action.payload.tableWidth,
				tableHeight: action.payload.tableHeight,
			});
		}
		case SET_CURRENT_PAGE: {
			return assoc(state, 'currentPage', action.payload);
		}
		case SET_IS_TABLE_DISABLED: {
			return assoc(state, 'isTableDisabled', action.payload);
		}
		case DISABLE_USER_PICKER: {
			return assoc(state, 'userPickerDisabled', true);
		}
		case SET_ON_ISSUE_VIEW_TRANSITION: {
			return assoc(state, 'onIssueViewTransition', action.payload);
		}
		case SET_JSM_QUEUE_DURATION_EVENT: {
			return assoc(state, 'useJSMQueueDurationEvent', action.payload);
		}
		case SET_RENDER_SIDE_BAR_ICON: {
			return assoc(state, 'renderSidebarIcon', action.payload);
		}
		case SET_REFRESH_SIDE_BAR: {
			return assoc(state, 'refreshSidebar', action.payload);
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
