import type { MiddlewareAPI } from 'redux';
import type { BatchAction } from 'redux-batched-actions';
import { type ActionsObservable, combineEpics, type Epic } from 'redux-observable';
import type { Observable } from 'rxjs/Observable';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import log from '@atlassian/jira-servicedesk-queues-common/src/log';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons';
import type { Action } from '../state/actions/types';
import type { State } from '../state/reducers/types';
import { withUnpackedBatchActions } from './common/batch';
import filterEpic from './filter';
import issueEpic from './issue';
import outdatedDataEpic from './outdated-data';
import pageEpic from './page';
import pollEpic from './poll';
import queueEpic from './queue';
import recentEpic from './recent';
import scrollEpic from './scroll';
import sortEpic from './sort';
import tableEpic from './table';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (push: QueuesPush) => {
	const epic: Epic<Action | BatchAction, State> = combineEpics(
		queueEpic(push),
		issueEpic(push),
		pageEpic,
		pollEpic(),
		sortEpic,
		// @ts-expect-error - No overload matches this call.
		outdatedDataEpic,
		recentEpic,
		scrollEpic,
		tableEpic,
		filterEpic,
	);
	return (
		action$: ActionsObservable<Action | BatchAction>,
		store: MiddlewareAPI<State>,
	): Observable<Action | BatchAction> =>
		// @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type. | TS7006 - Parameter 'stream' implicitly has an 'any' type.
		epic(withUnpackedBatchActions(action$), store).catch((error, stream) => {
			fireErrorAnalytics({
				meta: {
					id: 'agentViewOps',
					packageName: 'jiraServicedeskQueuesAgentView',
					teamName: 'jsd-shield',
				},
				error: new Error(error),
			});
			log.safeErrorWithoutCustomerData(
				'servicedesk.queues.agent-view.ops',
				`Unhandled error: ${error}`,
			);
			return stream;
		});
};
