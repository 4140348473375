import React, { memo } from 'react';
import { styled } from '@compiled/react';
import Avatar, { Skeleton, AVATAR_SIZES, type SizeType } from '@atlaskit/avatar';
import type { FileIdentifier, MediaStoreGetFileImageParams } from '@atlaskit/media-client';
import type { AsapBasedAuth, ClientBasedAuth } from '@atlaskit/media-core';
import { MediaImage } from '@atlaskit/media-image';
import { token } from '@atlaskit/tokens';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { fg } from '@atlassian/jira-feature-gating';
import type { CmdbMediaClientConfig } from '@atlassian/jira-servicedesk-insight-shared-types';

export type InsightIconProps = {
	mediaClientConfig?: CmdbMediaClientConfig;
	iconUrl: string;
	size: SizeType;
	label: string;
};

// Remove when cleaning up assets_rearch_media_client_auth
export const hasAuth = (mediaClientConfig: CmdbMediaClientConfig): boolean =>
	Boolean(mediaClientConfig.mediaBaseUrl) &&
	Boolean(mediaClientConfig.mediaJwtToken) &&
	Boolean(mediaClientConfig.issuer) &&
	Boolean(mediaClientConfig.fileId);

export const hasClientAuth = (mediaClientConfig: CmdbMediaClientConfig): boolean =>
	Boolean(mediaClientConfig.mediaBaseUrl) &&
	Boolean(mediaClientConfig.mediaJwtToken) &&
	Boolean(mediaClientConfig.clientId) &&
	Boolean(mediaClientConfig.fileId);

const DefaultIcon = ({ size, label }: { size: SizeType; label: string }) => (
	<Avatar appearance="square" size={size} name={label} />
);

export const getCMDBClientBasedAuth = (
	clientAuth: CmdbMediaClientConfig,
): Promise<ClientBasedAuth> =>
	Promise.resolve({
		clientId: clientAuth.clientId,
		token: clientAuth.mediaJwtToken,
		baseUrl: clientAuth.mediaBaseUrl,
	});

// Remove when cleaning up assets_rearch_media_client_auth
export const getCMDBAsapBasedAuth = (asapAuth: CmdbMediaClientConfig): Promise<AsapBasedAuth> =>
	Promise.resolve({
		asapIssuer: asapAuth.issuer,
		token: asapAuth.mediaJwtToken,
		baseUrl: asapAuth.mediaBaseUrl,
	});

export const InsightIcon = (props: InsightIconProps) => {
	const { iconUrl, size, label, mediaClientConfig } = props;

	if (fg('assets_rearch_media_client_auth')) {
		if (!mediaClientConfig || hasClientAuth(mediaClientConfig) === false) {
			if (iconUrl) {
				return <Avatar src={iconUrl} appearance="square" size={size} name={label} />;
			}

			return <DefaultIcon size={size} label={label} />;
		}
	} else if (!mediaClientConfig || hasAuth(mediaClientConfig) === false) {
		if (iconUrl) {
			return <Avatar src={iconUrl} appearance="square" size={size} name={label} />;
		}

		return <DefaultIcon size={size} label={label} />;
	}

	const fileIdentifier: FileIdentifier = {
		id: mediaClientConfig.fileId,
		mediaItemType: 'file',
	};
	const imageSize: MediaStoreGetFileImageParams = {
		width: AVATAR_SIZES[size],
		height: AVATAR_SIZES[size],
	};

	return (
		<MediaImage
			identifier={fileIdentifier}
			mediaClientConfig={{
				authProvider: () =>
					fg('assets_rearch_media_client_auth')
						? getCMDBClientBasedAuth(mediaClientConfig)
						: getCMDBAsapBasedAuth(mediaClientConfig),
			}}
			apiConfig={imageSize}
		>
			{({ loading, error, data }) => {
				if (loading) {
					return <Skeleton appearance="square" size={size} />;
				}

				if (error) {
					fireErrorAnalytics({
						// @ts-expect-error - TS2322 - Type 'true' is not assignable to type 'Error | undefined'.
						error,
						meta: {
							id: 'insightIcon',
							packageName: 'jiraServicedeskInsightIcon',
							teamName: 'falcons',
						},
					});
					return <DefaultIcon size={size} label={label} />;
				}

				if (!data) {
					return <DefaultIcon size={size} label={label} />;
				}

				return <Avatar src={data.src} appearance="square" size={size} name={label} />;
			}}
		</MediaImage>
	);
};

export const MemoizedInsightIcon = memo<InsightIconProps>(InsightIcon);

/**
 *
 * A wrapper around MemoizedInsightIcon that includes style overrides to prevent
 * extra unwanted spacing around the icon.
 *
 * Context: The default InsightIcon component uses an atlaskit avatar component
 * behind the scenes which includes some default styling (namely, a 2px border)
 * which causes the icon to be 4px wider and taller than expected. This causes
 * some trouble when trying to render the icon with exact dimension.
 */
export const UnstyledInsightIcon = (props: InsightIconProps) => (
	<UnstyledInsightIconWrapper>
		<MemoizedInsightIcon {...props} />
	</UnstyledInsightIconWrapper>
);

/**
 * The reason for calling !important on display block is to ovveride the inherited
 * CSS from Avatar atlaskit component in order to centre the icon within the reference
 * object container.
 */
// Double check the shadow !
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UnstyledInsightIconWrapper = styled.div({
	margin: token('space.negative.025', '-2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		display: 'block !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> span': {
			boxShadow: 'none',
		},
	},
});
