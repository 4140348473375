import { connect } from '../../../../../common/table-redux';
import { setVerticalScrollOffset } from '../../../../../ops/scrolling/offset';
import { getDefaultRowHeight } from '../../../../../read-ops/rows';
import { getVerticalScrollOffset } from '../../../../../read-ops/scrolling';
import { getTableContentHeight } from '../../../../../read-ops/size';
import { getFacade, getVisibleColumnCount } from '../../../../../state/consumer/selectors';
import {
	hasHorizontalScrollbar,
	getShouldVirtualize,
	getVirtualBoundaries,
} from '../../../../../state/selectors';
import type { State } from '../../../../../state/types';
import VerticalScrollbar from './index-dumb';

export default connect(
	(state: State) => ({
		visibleColumnCount: getVisibleColumnCount(state),
		facade: getFacade(state),
		isVirtualised: getShouldVirtualize(state),
		virtualBoundaries: getVirtualBoundaries(state),
		viewportHeight: getTableContentHeight(state),
		scrollTop: getVerticalScrollOffset(state),
		defaultRowHeight: getDefaultRowHeight(state),
		isHorizontalScrollVisible: hasHorizontalScrollbar(state),
	}),
	{ setVerticalScrollOffset },
)(VerticalScrollbar);
