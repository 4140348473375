import { NO_CATEGORY, type ItsmPractice } from '@atlassian/jira-servicedesk-work-category';
import type { Queues, QueuesRequestPayload } from '../../types';

export const transformQueues = (queues: Queues, category?: ItsmPractice): QueuesRequestPayload => ({
	order: queues.map((queue) => queue.id),
	id: 'com.atlassian.servicedesk.project-ui:sd-queues-custom',
	inline: true,
	deleted: [],
	...(category && category !== NO_CATEGORY ? { category } : undefined),
});
