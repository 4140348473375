import type { IssueId, IssueKey } from '@atlassian/jira-shared-types';
import type { ServerGiraPriorityField } from './fields/priority-field-type';
import type { ServerGiraStatusField } from './fields/status-field-type';
import type { IssueType, ServerRemoteIssueType } from './issue-hierarchy-type';
import type { ServerRestPriority } from './priority-type';
import type { ServerRestResolution } from './resolution-type';
import type { ServerRestStatus } from './status-type';
import type { TimeTrackingState } from './time-tracking-type';
import type { ServerGiraUserField, ServerRestUser } from './user-type';

export const associatedIssueErrorTypes = {
	createChild: 'createChild',
	linkChild: 'linkChild',
} as const;

export type AssociatedIssueErrorType = keyof typeof associatedIssueErrorTypes;

/**
 * Associated issue fields returned by `/graphql`
 */
export type ServerAssociatedIssueFields = {
	issuetype: IssueType;
	priority: ServerRestPriority | null;
	status: ServerRestStatus;
	summary: string;
	assignee?: ServerRestUser | null;
	timetracking?: TimeTrackingState | null;
	resolution?: ServerRestResolution | null;
};

/**
 * Associated issue fields returned by `/gira`, used for remote linked issues
 *
 * See: `getAssociatedIssueFragment()` in `jira-providers-issue/issue-express`
 */
export type ServerRemoteAssociatedIssueFields = {
	assignee: ServerGiraUserField | null;
	issueType: ServerRemoteIssueType;
	priority: ServerGiraPriorityField | null;
	status: ServerGiraStatusField;
	summary: {
		textValue: string;
	};
	isResolved: boolean;
};

/**
 * Defines associated issues for the following field keys:
 *  - issue links (`issuelinks`)
 *  - subtasks (`subtasks`)
 *  - portfolio epic child issues (`portfolio-child-issues`)
 *  - team-managed parent (next-gen `parent-issue`)
 *  - company-managed parent (classic `parent`)
 *
 * This is returned by the legacy GraphQL endpoint, based on the `AssociatedIssueGraphQLType` object from the backend.
 */
export type ServerAssociatedIssue = {
	id: string;
	key: IssueKey;
	fields: ServerAssociatedIssueFields;
	self?: string;
};

/**
 * Defines associated issues in remote issue links, returned by the `/gira` endpoint.
 */
export type ServerRemoteAssociatedIssue = ServerRemoteAssociatedIssueFields & {
	issueId: number;
	issueKey: {
		stringValue: string;
	};
};

export type AssociatedIssueFields = {
	assigneeUrl?: string | null;
	assigneeDisplayName?: string | null;
	issueTypeId?: string;
	issueTypeIconUrl?: string | null;
	issueTypeName?: string | null;
	issuePriorityUrl?: string | null;
	issuePriorityName?: string | null;
	issuePrioritySequence?: number;
	issueTimeTracking?: TimeTrackingState | null;
	issueSummary?: string;
	statusCategory?: string;
	statusCategoryId?: number;
	statusId?: string;
	statusName?: string;
	isResolved?: boolean;
};

/**
 * Defines the Bento-transformed `ServerAssociatedIssue`.
 *
 * An "abstract" type meant to be extended by other types that represent issues that are related to other issues.
 */
export type AssociatedIssue = {
	id: string;
	issueId?: IssueId; // Some functions use a separate `issueId` instead of `id`,
	issueKey?: string;
	issueLink?: string;
	hasError?: boolean;
	isLoading?: boolean;
} & AssociatedIssueFields;

export type AssociatedIssueContext = {
	estimateFieldId?: string | undefined;
	isResolved: boolean;
	hasError?: boolean;
	errorType?: AssociatedIssueErrorType;
};
