import { createStore, applyMiddleware } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { createEpicMiddleware } from 'redux-observable';
import composeWithDevtools from '@atlassian/jira-common-util-compose-with-devtools';
import rootEpic from '../../../ops';
import rootReducer from '../../../state/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (initialState: any = undefined) =>
	createStore(
		enableBatching(rootReducer),
		initialState,
		composeWithDevtools({ name: 'Table' })(applyMiddleware(createEpicMiddleware(rootEpic))),
	);
