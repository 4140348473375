import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Actions } from '@atlassian/jira-issue-table-selection-services';

// SELECT_ROW

export const SELECT_ROW = 'state.actions.bulk-selection.SELECT_ROW' as const;

type SelectRowActionPayload = {
	rowId: string;
	selection: boolean;
	isShiftKeyDown: boolean;
};

export type SelectRowAction = {
	type: typeof SELECT_ROW;
	payload: SelectRowActionPayload;
	meta: {
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const selectRowAction = (
	data: SelectRowActionPayload,
	analyticsEvent: UIAnalyticsEvent,
): SelectRowAction => ({
	type: SELECT_ROW,
	payload: data,
	meta: {
		analyticsEvent,
	},
});

// SET_LAST_CLICKED_ROW

export const SET_LAST_CLICKED_ROW = 'state.actions.bulk-selection.SET_LAST_CLICKED_ROW' as const;

type SetLastClickedRowActionPayload = {
	rowId: string;
};

export type SetLastClickedRowAction = {
	type: typeof SET_LAST_CLICKED_ROW;
	payload: SetLastClickedRowActionPayload;
};
export const setLastClickedRowAction = (
	data: SetLastClickedRowActionPayload,
): SetLastClickedRowAction => ({
	type: SET_LAST_CLICKED_ROW,
	payload: data,
});

// SET_SELECTION_SERVICE_ACTIONS

export const SET_SELECTION_SERVICE_ACTIONS =
	'state.actions.bulk-selection.SET_SELECTION_SERVICE_ACTIONS' as const;

type SetSelectionServiceActionsActionPayload = {
	actions: Actions;
};

export type SetSelectionServiceActionsAction = {
	type: typeof SET_SELECTION_SERVICE_ACTIONS;
	payload: SetSelectionServiceActionsActionPayload;
};

export const setSelectionServiceActionsAction = (data: {
	actions: Actions;
}): SetSelectionServiceActionsAction => ({
	type: SET_SELECTION_SERVICE_ACTIONS,
	payload: data,
});

// SET_HAS_SELECTION

export const SET_HAS_SELECTION = 'state.actions.bulk-selection.SET_HAS_SELECTION' as const;

type SetHasSelectionPayload = {
	hasSelection: boolean;
};

export type SetHasSelectionAction = {
	type: typeof SET_HAS_SELECTION;
	payload: SetHasSelectionPayload;
};
export const setHasSelectionAction = (data: SetHasSelectionPayload): SetHasSelectionAction => ({
	type: SET_HAS_SELECTION,
	payload: data,
});
