import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { JsonComponentProps } from '../../../model/fields/types';
import { UnselectableChildGutterPaddingContainer } from '../common/styled';

type Props = JsonComponentProps<'issuetype'>;

export default function FieldRenderedValue(props: Props) {
	const {
		dataSelectorProps: {
			value: { iconUrl, description, name },
		},
		tableInjectedProps: { isLastColumn },
	} = props;

	return (
		<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
			<StyledWrapper>
				<img height="16" width="16" src={iconUrl} alt={description} title={name} />
			</StyledWrapper>
		</UnselectableChildGutterPaddingContainer>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledWrapper = styled.div({
	border: 0,
	padding: `10px ${token('space.100', '8px')} ${token('space.100', '8px')} 0`,
});
