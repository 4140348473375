import { getConsumerState } from '../../../../state/consumer/selectors';
import { getAddedRows, getRowTree } from '../../../../state/consumer/selectors/rows';
import { setColumnSortOrder, setSortedRowIdsHash } from '../../../../state/internal/actions';
import {
	getSortedRowIdsHash,
	getActiveSortedColumnConfiguration,
} from '../../../../state/internal/selectors';
import getSortedInternalState from '../../../common/sorting';
import { defineTransaction } from '../../../common/transactions';
import { CLEAR_COLUMN_SORT_ORDER } from './action';

export default defineTransaction(CLEAR_COLUMN_SORT_ORDER, (action, state) => {
	const consumerState = getConsumerState(state);

	const sortedRowIdsHash = getSortedInternalState(
		getAddedRows(state),
		getRowTree(state),
		getActiveSortedColumnConfiguration(state),

		false, // sort override disabled
		undefined, // no active sort column configuration
		getSortedRowIdsHash(state),

		consumerState.addedRows,
		consumerState.rowTree,
		consumerState.columnComparators,
		consumerState.defaultComparator,
	);

	consumerState.callbacks.onSortedRowIdsChanged &&
		consumerState.callbacks.onSortedRowIdsChanged(sortedRowIdsHash);

	return [setColumnSortOrder(undefined), setSortedRowIdsHash(sortedRowIdsHash)] as const;
});
