import {
	PROBLEMS,
	CHANGES,
	INCIDENTS,
	SERVICE_REQUEST,
	POST_INCIDENT_REVIEWS,
	NO_CATEGORY,
	type ItsmPractice,
} from './common/constants';

export const toItsmPractice = (practiceString?: string | null): ItsmPractice => {
	switch (practiceString) {
		case 'incidents':
			return INCIDENTS;
		case 'changes':
			return CHANGES;
		case 'service-requests':
			return SERVICE_REQUEST;
		case 'problems':
			return PROBLEMS;
		case 'post-incident-reviews':
			return POST_INCIDENT_REVIEWS;
		default:
			return NO_CATEGORY;
	}
};

export const isValidItsmPractice = (
	practiceString?: string | null,
): practiceString is ItsmPractice => {
	switch (practiceString) {
		case INCIDENTS:
		case CHANGES:
		case SERVICE_REQUEST:
		case PROBLEMS:
		case POST_INCIDENT_REVIEWS:
		case NO_CATEGORY:
			return true;
		default:
			return false;
	}
};
