import { performGetRequest } from '@atlassian/jira-fetch';

export type GetCreatedRequestsResponse = string[];

export type RestGetProjectPropertyResponse = {
	key: string;
	value: GetCreatedRequestsResponse;
};

export const constructEndpoint = (baseUrl: string, projectId: number, propertyKey: string) =>
	`${baseUrl}/rest/api/3/project/${projectId}/properties/${propertyKey}`;

export const get = (
	baseUrl: string,
	projectId: number,
	propertyKey: string,
): Promise<RestGetProjectPropertyResponse> => {
	const url = constructEndpoint(baseUrl, projectId, propertyKey);
	return performGetRequest(url);
};
