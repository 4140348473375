import { connect } from '../../../../../../../common/table-redux';
import type { ColumnId } from '../../../../../../../model/columns';
import {
	getColumnTitle,
	getColumnTooltip,
	isChildColumn,
	isParentColumn,
	getParentId,
	getColumnHeaderComponent,
} from '../../../../../../../read-ops/columns';
import type { State } from '../../../../../../../state/types';
import Column from '../common/column';
import DefaultColumn from './index-dumb';

type Props = {
	id: ColumnId;
};

export default connect(
	(state: State, { id }: Props) => ({
		title: getColumnTitle(state, id),
		HeaderComponent: getColumnHeaderComponent(state, id),
		tooltip: getColumnTooltip(state, id),
		isChild: isChildColumn(state, id),
		isParent: isParentColumn(state, id),
		parentId: getParentId(state, id),
		Column,
	}),
	{},
)(DefaultColumn);
