import React, { type KeyboardEvent, type MouseEvent, useCallback, useMemo } from 'react';
import {
	EnvironmentLozenge,
	nameWithCustomEnvironment,
} from '@atlassian/jira-forge-ui-extension-title/src/ui/environment-lozenge/index.tsx';
import {
	ExpandableMenuItem,
	ExpandableMenuItemTrigger,
	ExpandableMenuItemContent,
} from '@atlassian/jira-navigation-system/src/v4';
import { useRouter } from '@atlassian/jira-router';
import type { ForgeItem as ForgeItemProps } from '../../types';
import { ForgeIcon } from './forge-icon';
import { ForgeItemExpandable } from './forge-item-expandable';
import { ForgeItemSimple } from './forge-item-simple';

export const ForgeItem = (props: ForgeItemProps) => {
	const [{ location }] = useRouter();
	const { url, showIcon, iconUrl, environmentType, environmentKey, name, sections, overrides } =
		props;

	const shouldExpandAsDefault = useMemo(() => location.pathname.includes(url), [url, location]);

	const onNestedMenuItemClick = useCallback(
		(event: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => {
			if (overrides?.ParentMenuItem?.onClick) {
				overrides.ParentMenuItem.onClick(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					event as MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
				);
			}
			return () => null;
		},
		[overrides],
	);

	if (!sections?.length) {
		return <ForgeItemSimple {...props} />;
	}

	if (overrides?.showSelectedAppMenuOnly) {
		return <ForgeItemExpandable {...props} />;
	}

	return (
		<ExpandableMenuItem isDefaultExpanded={shouldExpandAsDefault}>
			<ExpandableMenuItemTrigger
				elemBefore={showIcon ? <ForgeIcon url={iconUrl} /> : undefined}
				elemAfter={<EnvironmentLozenge environmentType={environmentType} />}
				onClick={onNestedMenuItemClick}
			>
				{nameWithCustomEnvironment(name, environmentType, environmentKey)}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<ForgeItemExpandable {...props} />
			</ExpandableMenuItemContent>
		</ExpandableMenuItem>
	);
};
