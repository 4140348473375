import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { CellComponentProps } from '@atlassian/jira-virtual-table/src/model/cell-component';
import { selectRowAction } from '../../../state/actions/bulk-selection';
import { connect } from '../../../state/store';
import CheckboxWrapper from './view';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any, { rowId }: CellComponentProps) => ({
	// @ts-expect-error - TS7031 - Binding element 'isShiftKeyDown' implicitly has an 'any' type. | TS7031 - Binding element 'selection' implicitly has an 'any' type.
	onChange: ({ isShiftKeyDown, isSelected: selection }, analyticsEvent: UIAnalyticsEvent) =>
		dispatch(selectRowAction({ rowId, isShiftKeyDown, selection }, analyticsEvent)),
});

export default connect(null, mapDispatchToProps)(CheckboxWrapper);
