import { connect } from '../../../../common/table-redux';
import { getResizingColumnId } from '../../../../read-ops/columns';
import { getTableContentWidth } from '../../../../read-ops/size';
import type { State } from '../../../../state/types';
import ResizeColumnOverlay from './index-dumb';

export default connect(
	(state: State) => ({
		width: getTableContentWidth(state),
		isVisible: !!getResizingColumnId(state),
	}),
	{},
)(ResizeColumnOverlay);
