import type { AppPropsState } from '@atlassian/jira-servicedesk-common/src/model/types';
import type { Queue, QueueId, Issue } from '@atlassian/jira-servicedesk-queues-common/src/model';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons';
import type { ManualSorting } from '../state/reducers/persisted/queue/types';

export type SortedQueue = Queue & {
	manualSorting?: ManualSorting;
};

export type SortedQueues = {
	[key: string]: SortedQueue;
};

// Source of LOAD_ISSUES action : Page (includes - load, pagination, sorting, queue change, filtering)
export const LOAD_ISSUES_ACTION_SOURCE_PAGE = 'PAGE' as const;
// Source of LOAD_ISSUES action : Change visibility (to and fro issue details)
export const LOAD_ISSUES_ACTION_SOURCE_VIEW = 'VIEW' as const;
// Source of LOAD_ISSUES action : Polling
export const LOAD_ISSUES_ACTION_SOURCE_POLL = 'POLL' as const;
// Source of LOAD_ISSUES action : Infinite scrolling
export const LOAD_ISSUES_ACTION_SOURCE_SCROLL = 'SCROLL' as const;
// Source of LOAD_ISSUES action : Change sort order
export const LOAD_ISSUES_ACTION_SOURCE_SORT = 'SORT' as const;
// Source of LOAD_ISSUES action : Force the queue to update
export const LOAD_ISSUES_ACTION_SOURCE_FORCED_UPDATE = 'FORCE_UPDATE' as const;
// Source of LOAD_ISSUES action : Change filter query
export const LOAD_ISSUES_ACTION_SOURCE_FILTER = 'FILTER' as const;
// Source of LOAD_ISSUES action : Sidebar
export const LOAD_ISSUES_ACTION_SOURCE_SIDEBAR = 'SIDEBAR' as const;

export type LoadIssuesActionSource =
	| typeof LOAD_ISSUES_ACTION_SOURCE_PAGE
	| typeof LOAD_ISSUES_ACTION_SOURCE_VIEW
	| typeof LOAD_ISSUES_ACTION_SOURCE_POLL
	| typeof LOAD_ISSUES_ACTION_SOURCE_SCROLL
	| typeof LOAD_ISSUES_ACTION_SOURCE_SORT
	| typeof LOAD_ISSUES_ACTION_SOURCE_FORCED_UPDATE
	| typeof LOAD_ISSUES_ACTION_SOURCE_FILTER
	| typeof LOAD_ISSUES_ACTION_SOURCE_SIDEBAR;

export type CollectionItem = Issue | undefined;

export type AppRef = {
	readonly reloadAllQueues: (arg1: QueueId) => void;
	readonly setPageVisibility: (arg1: boolean) => void;
};

export type OnAtLeastOneIssueLoadedCallback = () => void;
export type OnQueueIssueCountUpdatedCallback = (arg1: QueueId, arg2: number) => void;
export type OnQueueRenderFailureCallback = (arg1: string | Error) => void;
export type OnProjectStateHashUpdateCallback = (arg1: string | undefined) => void;

export type Props = {
	isQueueVisible: boolean;
	appProps: Partial<AppPropsState>;
	queueId: number;
	push: QueuesPush;
	onAtLeastOneIssueLoaded: OnAtLeastOneIssueLoadedCallback;
	onQueueRenderFailure: OnQueueRenderFailureCallback | undefined;
};

export type QueuesWithSorting = {
	queues: SortedQueues;
	manualSorting?: ManualSorting;
};

// Scroll direction
export const DIRECTION_UP = 'UP';
export const DIRECTION_DOWN = 'DOWN';
export const DIRECTION_UNCHANGED = 'UNCHANGED' as const;

export type ScrollDirection =
	| typeof DIRECTION_UP
	| typeof DIRECTION_DOWN
	| typeof DIRECTION_UNCHANGED;

export type VerticalPosition = {
	offsetFromTop: number;
	offsetFromBottom: number;
	direction: ScrollDirection;
};
