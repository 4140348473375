// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md
// brandfolder asset id = q7cns859w734758qv5wmgpm
// brandfolder url = https://brandfolder.com/atlassian-brand-creative-library/atlassian-public-asset-library/#!asset/q7cns859w734758qv5wmgpm
import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/target-single-darkmode.svg';
import srcLight from '../../assets/target-single.svg';

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 223, 189];
export { srcLight, srcDark, viewBox };
