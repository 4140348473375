import isEmpty from 'lodash/isEmpty';
import {
	type QueueId,
	fromQueueIdString,
} from '@atlassian/jira-servicedesk-queues-common/src/model';
import {
	getFromLocalStorage,
	setInLocalStorage,
} from '@atlassian/jira-servicedesk-queues-common/src/services/local-storage/local-storage';
import {
	type ItsmPractice,
	NO_CATEGORY,
	isValidItsmPractice,
} from '@atlassian/jira-servicedesk-work-category';

const LAST_VISITED_QUEUE_KEY_PREFIX = 'last_visited_queue';
const LAST_VISITED_CATEGORY_KEY_PREFIX = 'last_visited_category';

export const getLastVisitedQueueIdAndCategory = (
	projectKey?: string | null,
	category: ItsmPractice = NO_CATEGORY,
): [string | null, ItsmPractice | null] => {
	if (isEmpty(projectKey)) {
		return [null, null];
	}

	try {
		if (category === NO_CATEGORY) {
			// indicates a redirect from /queues endpoint
			const categoryToRedirectTo = getFromLocalStorage<string>(
				`${LAST_VISITED_CATEGORY_KEY_PREFIX}_${projectKey}`,
			);
			const queueId = getFromLocalStorage<string>(
				`${LAST_VISITED_QUEUE_KEY_PREFIX}_${projectKey}_${categoryToRedirectTo}`,
			);
			return [
				queueId,
				...(isValidItsmPractice(categoryToRedirectTo)
					? ([categoryToRedirectTo] as const)
					: ([NO_CATEGORY] as const)),
			];
		}
		return [
			getFromLocalStorage(`${LAST_VISITED_QUEUE_KEY_PREFIX}_${projectKey}_${category}`),
			category,
		];
	} catch (error: unknown) {
		return [null, null];
	}
};

export const setLastVisitedQueueIdAndCategory = (
	queueId: QueueId,
	projectKey: string,
	category: ItsmPractice = NO_CATEGORY,
) => {
	let lastVisitedQueueId: number | null;
	try {
		if (isEmpty(projectKey)) throw new Error('project key is empty');
		lastVisitedQueueId = getFromLocalStorage(
			`${LAST_VISITED_QUEUE_KEY_PREFIX}_${projectKey}_${category}`,
		);
	} catch (error: unknown) {
		lastVisitedQueueId = null;
	}

	if (!isEmpty(projectKey) && lastVisitedQueueId !== queueId) {
		try {
			setInLocalStorage(
				`${LAST_VISITED_QUEUE_KEY_PREFIX}_${projectKey}_${category}`,
				fromQueueIdString(queueId),
			);
			setInLocalStorage(`${LAST_VISITED_CATEGORY_KEY_PREFIX}_${projectKey}`, category);
		} catch (error: unknown) {
			// Don't care if it fails. Default behavior in QueuesRedirect will take users to first available queue.
		}
	}
};
