import type { MiddlewareAPI } from 'redux';
import uuid from 'uuid';
import { SPA_SSR_RENDERED_MARK } from '@atlassian/jira-common-constants/src/analytics-marks';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources';
import { getMark, setMark } from '@atlassian/jira-common-performance';
import { log } from '@atlassian/jira-common-util-logging';
import {
	startLowPriorityEventDelay,
	stopLowPriorityEventDelay,
} from '@atlassian/jira-event-delay-controller';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service';
import * as marks from '@atlassian/jira-issue-view-common-constants/src/mark-types';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { getFetchIssueNetworkTimes } from '@atlassian/jira-issue-view-common-utils';
import {
	issueViewLoad,
	embeddedIssueViewLoad,
	issueViewOnPageLoad,
} from '@atlassian/jira-issue-view-common-utils/src/utils/performance-analytics';
import { isBM3EmitOnRafTopExperiencesEnabled } from '@atlassian/jira-issue-view-feature-flags';
import { serverTimingSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/server-side-timing-selector';
import type { DataProvider } from '@atlassian/jira-providers-issue';
import type { BM3Metric } from '@atlassian/jira-providers-spa-apdex-analytics';
import { afterPaintEmit } from '@atlassian/jira-providers-spa-apdex-analytics/src/after-paint-emit';
import type { Route } from '@atlassian/jira-router';
import {
	PRODUCT_START_MARK,
	stopInitialPageLoadTimingFromPerformanceMarkStart,
} from '@atlassian/jira-spa-performance-breakdown';
import { isAutomaticExposureCollectionEnabled } from '@atlassian/jira-track-all-changes-sampling';
import { startUimTti, markUimTtiIssueViewLoaded } from '@atlassian/jira-ui-modifications-analytics';
import { addUFOCustomData } from '@atlassian/react-ufo/custom-data';
import { addBM3TimingsToUFO } from '@atlassian/react-ufo/custom-timings';
import { allFeatureFlagsAccessed } from '@atlassian/react-ufo/feature-flags-accessed';
import { addApdexToAll, type CustomData } from '@atlassian/react-ufo/interaction-metrics';
import {
	getBackendBlockingTime,
	type IssueViewLoadMetric,
} from './reporters/backend-blocking-time-reporter';
import { issueScaleabilityReporterFactory } from './reporters/issue-scaleability-reporter';
import StateManager from './state';
import { metricsStateSelector } from './state/metrics-selector';

const LOG_LOCATION = 'issue.metrics';

let state: StateManager;

const getSsrMark = (): number | null => {
	const ssrMark = getMark(SPA_SSR_RENDERED_MARK);
	if (ssrMark != null) {
		return Math.floor(ssrMark.startTime);
	}
	return null;
};

const setMarkWithError = (markName: string) => {
	try {
		setMark(markName);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(LOG_LOCATION, 'Setting performance mark failed', error);
	}
};

export const markFragmentReady = (): void => {
	setMarkWithError(marks.ISSUE_FRAGMENT_READY_MARK);
};

export type StartRenderingOptions = {
	analyticsSource: AnalyticsSource;
	issueContextActions?: IssueContextServiceActions;
	isLoadedWithPage: boolean;
	isSPA: boolean;
	isRecentIssue: boolean;
	recentIssuesCount: number;
	dataProvider?: DataProvider;
	store: MiddlewareAPI<State>;
	route?: Route | null;
};

export const initialiseMetrics = (options: StartRenderingOptions): void => {
	try {
		const {
			issueContextActions,
			isLoadedWithPage,
			isSPA,
			isRecentIssue,
			recentIssuesCount,
			dataProvider,
			analyticsSource,
			store,
			route,
		} = options;
		state = new StateManager({
			isLoadedWithPage,
			isSPA,
			isRecentIssue,
			analyticsSource,
			recentIssuesCount,
			correlationId: uuid.v4(),
			// @ts-expect-error - TS2322 - Type '{ analyticsKey: ApplicationKey | undefined; viewMode: any; issueId: string | undefined; }' is not assignable to type 'MetricsProps'.
			lazyMetricsProps: () => metricsStateSelector(store.getState()),
			timingDataReporter: () => ({
				...serverTimingSelector(store.getState()),
				...getFetchIssueNetworkTimes(),
			}),
			issueScaleabilityReporter: issueScaleabilityReporterFactory(store, issueContextActions),
			dataProvider,
			route,
		});

		// Marking init of bm3
		issueViewLoad.mark('metrics_init');
		issueViewOnPageLoad.mark('metrics_init');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(LOG_LOCATION, 'Failed to initialise issue metrics', error);
	}
};

export const markIssueStartRendering = (): void => {
	setMarkWithError(marks.ISSUE_START_RENDERING_MARK);
	issueViewLoad.mark(marks.ISSUE_START_RENDERING_MARK);
};

export const markIssueRender = (): void => {
	setMarkWithError(marks.ISSUE_RENDERED);
	issueViewOnPageLoad.mark(marks.ISSUE_RENDERED);
};

export const markIssueLegacyInteractive = (): void => {
	setMarkWithError(marks.ISSUE_LEGACY_INTERACTIVE);
	issueViewLoad.mark(marks.ISSUE_LEGACY_INTERACTIVE);
};

export const markEachLCPCandidate = (markTime: number): void => {
	issueViewOnPageLoad.mark(marks.LCP_CANDIDATE, markTime);
};

export const markSPAReadyEndOfCriticalPath = (): void => {
	setMarkWithError(marks.ISSUE_SPA_MARKED_READY_END_OF_CRITICAL_PATH);
	issueViewLoad.mark(marks.ISSUE_SPA_MARKED_READY_END_OF_CRITICAL_PATH);
};

const getSharedCustomData = () => ({
	...state.getIssueScaleabilityReporter()(),
	...getBackendBlockingTime({
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		issueViewLoad: issueViewLoad as IssueViewLoadMetric,
	}),
	...{
		isBentoRecentIssue: state.getIsRecentIssue(),
		bentoRecentIssuesCount: state.getRecentIssuesCount(),
		correlationId: state.getCorrelationId(),
	},
	...(fg('enable-metric-full-page-bundle-load-count')
		? {
				initialLoadFullPageBundleLoadCount: state.getIsInitial()
					? performance
							.getEntriesByType('resource')
							.filter((x) => x.name.includes('async-issue-app--full-page')).length
					: 0,
			}
		: {}),
});

export const markIssueInteractive = ({
	// TODO JIV-14563 Fixup or remove embeddedIssueTTI in BM3 metrics due to broken lastTransitionStartTime
	// @ts-expect-error - this has never been wired up correctly, I only just discovered it by adding types for the data that is actually passed through.
	lastTransitionTime,
}: {
	lastTransitionStartTime: number | undefined;
}): void => {
	const isFullIssue = state.getIsFullIssue();
	state.setInteractiveEnd(performance.now());
	if (state) {
		const startTime = lastTransitionTime || state.getStartTime();
		if (state.getIsFullIssue()) {
			stopInitialPageLoadTimingFromPerformanceMarkStart('product', PRODUCT_START_MARK, true);
		}

		const embeddedCustomData = !isFullIssue
			? {
					embeddedIssueFMP: Math.floor(state.getFMP()),
					embeddedIssueTTI: Math.floor(state.getInteractiveEnd() - startTime),
					analyticsSource: state.getAnalyticsSource(),
					featureFlags: Object.fromEntries(allFeatureFlagsAccessed),
				}
			: {};

		const perfConfig = {
			stopTime: state.getInteractiveEnd(),
			customData: {
				...getSharedCustomData(),
				...embeddedCustomData,
			},
		};

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		addUFOCustomData(perfConfig.customData as CustomData);

		const ufoMetricKey = isFullIssue ? 'issue-view' : 'issue-embed';
		addApdexToAll({
			key: ufoMetricKey,
			startTime: isFullIssue ? undefined : embeddedIssueViewLoad.getData()?.start ?? undefined,
			stopTime: perfConfig.stopTime,
		});

		if (isFullIssue) {
			addBM3TimingsToUFO(issueViewLoad.getData()?.marks, issueViewLoad.getData()?.config.timings);
		}
		if (isFullIssue && isBM3EmitOnRafTopExperiencesEnabled()) {
			afterPaintEmit(
				() => {
					// @ts-expect-error payload type mismatch with optional values
					issueViewLoad.stop(perfConfig);
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				issueViewLoad as unknown as BM3Metric,
			);
		} else {
			// @ts-expect-error payload type mismatch with optional values
			issueViewLoad.stop(perfConfig);
		}

		markUimTtiIssueViewLoaded();

		if (!isFullIssue) {
			// See markStartIssue for more information.
			if (isAutomaticExposureCollectionEnabled()) {
				stopLowPriorityEventDelay();
			}
		}
	}
};

const getBrowserNavigationMetrics = () => {
	const entries = performance.getEntriesByType('navigation');
	if (entries.length === 0 || !(entries[0] instanceof PerformanceNavigationTiming)) {
		return null;
	}

	const navigation = entries[0];

	return {
		connectEnd: Math.round(navigation.connectEnd),
		connectStart: Math.round(navigation.connectStart),
		domComplete: Math.round(navigation.domComplete),
		domContentLoadedEventEnd: Math.round(navigation.domContentLoadedEventEnd),
		domContentLoadedEventStart: Math.round(navigation.domContentLoadedEventStart),
		domInteractive: Math.round(navigation.domInteractive),
		domainLookupEnd: Math.round(navigation.domainLookupEnd),
		domainLookupStart: Math.round(navigation.domainLookupStart),
		fetchStart: Math.round(navigation.fetchStart),
		loadEventStart: Math.round(navigation.loadEventStart),
		requestStart: Math.round(navigation.requestStart),
		responseEnd: Math.round(navigation.responseEnd),
		responseStart: Math.round(navigation.responseStart),
		unloadEventEnd: Math.round(navigation.unloadEventEnd),
		unloadEventStart: Math.round(navigation.unloadEventStart),
		redirectStart: Math.round(navigation.redirectStart),
		redirectEnd: Math.round(navigation.redirectEnd),
		secureConnectionStart: Math.round(navigation.secureConnectionStart),
		redirectCount: navigation.redirectCount,
		type: navigation.type,
	};
};

export const markIssueOnPageLoadEvent = (): void => {
	const isFullIssue = state.getIsFullIssue();
	if (!isFullIssue) {
		return;
	}
	if (state) {
		try {
			new PerformanceObserver((entryList: PerformanceObserverEntryList) => {
				entryList.getEntries().forEach((entry) => markEachLCPCandidate(entry.startTime));
			}).observe({ type: 'largest-contentful-paint', buffered: true });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// do nothing
		}

		const customData = {
			...getSharedCustomData(),
			browserNavigationMetrics: getBrowserNavigationMetrics(),
		};

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		issueViewOnPageLoad.stop(customData as CustomData);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		addUFOCustomData(customData as CustomData);
	}
};

export const markStartIssue = (isFullPage = false): void => {
	if (ff('fix-issueviewload-start-timing-for-new-browser-metric-version_ahdhr')) {
		issueViewLoad.start();
	}
	if (!isFullPage) {
		embeddedIssueViewLoad.start({ startTime: performance.now() });

		/**
		 * All SPA full page loads already start the event delay through the
		 * usePageLoadedSubscriber hook in @atlassian/jira-event-delay-controller.
		 * The embedded views need to start this critical section explicitly here because
		 * there is no equivalent hook that is generically applicable to all experiences.
		 *
		 * The only events that currently leverage the delay mechanism are the automatic
		 * exposure events, so we can avoid the overhead of starting this critical section
		 * if it won't be necessary.
		 */
		if (isAutomaticExposureCollectionEnabled()) {
			startLowPriorityEventDelay();
		}
	}
	if (isFullPage) {
		issueViewOnPageLoad.start({ startTime: 0 });
	}

	if (!ff('fix-issueviewload-start-timing-for-new-browser-metric-version_ahdhr')) {
		issueViewLoad.start();
	}

	startUimTti();
};

export type PreviewMetricArgs = {
	isInitialRender: boolean;
	lastTransitionStartTime: number;
};

export const markIssuePreviewRendered = ({
	isInitialRender,
	lastTransitionStartTime,
}: PreviewMetricArgs): void => {
	const ssrMark = getSsrMark();
	const previewTime = performance.now();
	const startTime = state.getStartTime();
	let fmp = 0;
	if (state.getIsFullIssue()) {
		if (isInitialRender) {
			// @ts-expect-error - TS2322 - Type 'number | null' is not assignable to type 'number'.
			fmp = ssrMark;
		} else {
			fmp = previewTime - (lastTransitionStartTime || startTime);
		}
	} else {
		fmp = previewTime - startTime;
	}
	issueViewLoad.mark(marks.ISSUE_PREVIEW_RENDERED);
	issueViewOnPageLoad.mark(marks.ISSUE_PREVIEW_RENDERED);
	state.setFMP(fmp);
	state.setPreviewEnd(previewTime);
	setMarkWithError(marks.ISSUE_PREVIEW_RENDERED);
};

export const setIsInitialRender = (isInitialRender: boolean) => {
	if (state) {
		state.setIsLoadedWithPage(isInitialRender);
	}
};

export const isFullIssueView = () => state && state.getIsFullIssue();
