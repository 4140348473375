import type { ComponentType } from 'react';
import memoizeOne from 'memoize-one';
import isShallowEqual from '@atlassian/jira-common-util-is-shallow-equal';
import type { TableStateConnectorProps as OwnProps } from '../../../../model/cell';
import { lockFieldAction, unlockFieldAction } from '../../../../state/actions/field/lock';
import { setEditingFieldPositionAction } from '../../../../state/actions/ui';
import type { State } from '../../../../state/reducers/types';
import { isCellValid } from '../../../../state/selectors/cells';
import { isLastColumn, getColumnTitle } from '../../../../state/selectors/columns';
import { getIsFieldLocked } from '../../../../state/selectors/field-lock';
import { getIssueKeyPositionIndex } from '../../../../state/selectors/issues';
import { isTableDisabled, getOnIssueViewTransitionCallback } from '../../../../state/selectors/ui';
import { connect } from '../../../../state/store';
import TableStateConnector, { type ConnectProps } from './view';

const mapStateToPropsFactory = () => {
	const onIssueViewTransitionFactory = memoizeOne((state: State, issueKey: OwnProps['issueKey']) =>
		getOnIssueViewTransitionCallback(state, issueKey),
	);
	return (state: State, props: OwnProps) => ({
		isCellValid: isCellValid(state, props.fieldDescriptor.fieldId, props.issueKey),
		isFieldLocked: getIsFieldLocked(state, props),
		isLastColumn: isLastColumn(state, props.fieldDescriptor.fieldId),
		positionIndex: getIssueKeyPositionIndex(state, props.issueKey),
		fieldName: getColumnTitle(state, props.fieldDescriptor.fieldId),
		isTableDisabled: isTableDisabled(state),
		onIssueViewTransition: onIssueViewTransitionFactory(state, props.issueKey),
	});
};

type StateProps = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any, props: OwnProps) => ({
	onFieldLock: (positionIndex: number) =>
		dispatch(lockFieldAction(props.issueKey, props.fieldDescriptor.fieldId, positionIndex)),
	onFieldUnlock: () => dispatch(unlockFieldAction(props.issueKey, props.fieldDescriptor.fieldId)),
	onSetEditingFieldPosition: (
		horizontalOffset: number,
		verticalOffset: number,
		fieldWidth: number,
		tableWidth: number,
		tableHeight: number,
	) =>
		dispatch(
			setEditingFieldPositionAction(
				horizontalOffset,
				verticalOffset,
				fieldWidth,
				tableWidth,
				tableHeight,
			),
		),
});

const issueFieldLockControlsFactory = memoizeOne(
	(
		positionIndex: number,
		onFieldLock: (positionIndex: number) => void,
		onFieldUnlock: () => void,
	) => ({
		onFieldUnlock,
		onFieldLock: () => onFieldLock(positionIndex),
	}),
);

const mergeProps = (
	stateProps: StateProps,
	dispatchProps: ReturnType<typeof mapDispatchToProps>,
	ownProps: OwnProps,
): ConnectProps => {
	const { positionIndex, isCellValid: _, ...componentStateProps } = stateProps;

	return {
		...ownProps,
		...componentStateProps,
		issueFieldLockControls: issueFieldLockControlsFactory(
			stateProps.positionIndex,
			dispatchProps.onFieldLock,
			dispatchProps.onFieldUnlock,
		),
		onSetEditingFieldPosition: dispatchProps.onSetEditingFieldPosition,
	};
};

const ConnectedTableStateConnector: ComponentType<ConnectProps> = connect(
	mapStateToPropsFactory,
	mapDispatchToProps,
	mergeProps,
	{
		// @ts-expect-error - TS7006 - Parameter 'nextProps' implicitly has an 'any' type. | TS7006 - Parameter 'prevProps' implicitly has an 'any' type.
		areStatePropsEqual: (nextProps, prevProps) => {
			// Stop rendering immediately if the cell isn't valid.
			if (!nextProps.isCellValid) {
				return true;
			}

			return isShallowEqual(nextProps, prevProps);
		},
	},
)(TableStateConnector);

export default ConnectedTableStateConnector;
