import React, { useRef, type ReactNode } from 'react';
import type { UIEventPayload } from '@atlassiansox/analytics-web-client';
import {
	AnalyticsListener,
	type CreateUIAnalyticsEvent,
	type UIAnalyticsEvent,
} from '@atlaskit/analytics-next';
import { NOT_ENROLLED } from '@atlassian/jira-common-util-get-experiment-group';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import {
	getEvent,
	fireUIAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { getSourceByInviteFlow } from '../../common/utils';
import type { InviteFlow, InviteAssignModelContext } from '../types';

type Props = {
	children: ReactNode;
};

const getInviteFromAssignCohort = () => getFeatureFlagValue('invite.from.assign', NOT_ENROLLED);

const useAnalyticsClient = (): {
	sendEvent: (arg1: UIAnalyticsEvent) => void;
} => {
	const analyticsWebClientRef = useRef(getAnalyticsWebClientPromise());
	return {
		sendEvent: (analyticsEvent: UIAnalyticsEvent) => {
			analyticsWebClientRef.current.then((webClient) =>
				webClient.sendEvent(getEvent(analyticsEvent)),
			);
		},
	};
};

export const AnalyticsProvider = ({ children }: Props) => {
	const { sendEvent } = useAnalyticsClient();
	return (
		<AnalyticsListener channel="peopleAndTeams" onEvent={sendEvent}>
			{children}
		</AnalyticsListener>
	);
};

const sendAnalyticsClientUIEvent = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
	analyticsPayload: UIEventPayload,
) => {
	if (createAnalyticsEvent) {
		fireUIAnalytics(
			createAnalyticsEvent({}),
			// @ts-expect-error Argument of type '[string, string, Attributes | undefined]' is not assignable to parameter of type 'RestArguments'.
			`${analyticsPayload.actionSubject} ${analyticsPayload.action}`,
			analyticsPayload?.actionSubjectId || '',
			analyticsPayload.attributes,
		);
	} else {
		const webAnalyticsClient = await getAnalyticsWebClientPromise();
		const analyticsClient = webAnalyticsClient.getInstance();

		// SSR doesn't have an analytics client
		if (analyticsClient) {
			analyticsClient.sendUIEvent(analyticsPayload);
		}
	}
};

export const fireDrawerTriggeredAnalytics = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
	inviteFlow: InviteFlow,
	source?: string | null,
) => {
	const analyticsPayload = {
		action: 'triggered',
		actionSubject: 'drawer',
		actionSubjectId: 'invitePeopleDrawer',
		attributes: {
			source: getSourceByInviteFlow(inviteFlow),
			inviteFromAssignCohort: getInviteFromAssignCohort(),
		},
		source: source != null && source !== '' ? source : 'unknown',
	};
	sendAnalyticsClientUIEvent(createAnalyticsEvent, analyticsPayload);
};

export const fireModalTriggeredAnalytics = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
	inviteFlow: InviteFlow,
	inviteAssignModelContext: InviteAssignModelContext,
	source?: string | null,
) => {
	const analyticsPayload = {
		action: 'triggered',
		actionSubject: 'modal',
		actionSubjectId: 'invitePeopleModal',
		attributes: {
			source: getSourceByInviteFlow(inviteFlow),
			inviteFromAssignCohort: getInviteFromAssignCohort(),
			inviteFromAssignModalCohort: inviteAssignModelContext.inviteFromAssignModalCohort,
		},
		source: source != null && source !== '' ? source : 'unknown',
	};
	sendAnalyticsClientUIEvent(createAnalyticsEvent, analyticsPayload);
};

export const fireInviteFromAssignModalExposedAnalytics = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
	inviteFromAssignModalCohort: string,
	resultCohort: string,
	ineligibilityReasons: String[],
	source?: string | null,
) => {
	const analyticsPayload = {
		action: 'exposed',
		actionSubject: 'feature',
		product: 'jira',
		attributes: {
			flagKey: 'invite.from.assign.modal',
			value: inviteFromAssignModalCohort,
			cohort: resultCohort,
			growthInitiative: 'virality',
			ineligibilityReasons,
		},
		source: source != null && source !== '' ? source : 'unknown',
	};
	if (createAnalyticsEvent) {
		fireTrackAnalytics(
			createAnalyticsEvent({}),
			`${analyticsPayload.actionSubject} ${analyticsPayload.action}`,
			analyticsPayload.attributes,
		);
	} else {
		const webAnalyticsClient = await getAnalyticsWebClientPromise();
		const analyticsClient = webAnalyticsClient.getInstance();

		// SSR doesn't have an analytics client
		if (analyticsClient) {
			analyticsClient.sendTrackEvent(analyticsPayload);
		}
	}
};

export const fireAddTeammatetoJiraAnalytics = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
	resultCohort: string,
	source?: string | null,
) => {
	const analyticsPayload = {
		action: 'clicked',
		actionSubject: 'addTeammatetoJira',
		product: 'jira',
		attributes: {
			inviteFromAssignModalCohort: resultCohort,
		},
		source: source != null && source !== '' ? source : 'unknown',
	};
	sendAnalyticsClientUIEvent(createAnalyticsEvent, analyticsPayload);
};
