import { connect } from '../../common/table-redux';
import { getDetailsPanelComponent } from '../../read-ops/details';
import { getActiveRowId } from '../../read-ops/rows';
import type { State } from '../../state/types';
import DetailsPanel from './index-dumb';

export default connect(
	(state: State) => ({
		activeRowId: getActiveRowId(state),
		DetailsContent: getDetailsPanelComponent(state),
	}),
	{},
)(DetailsPanel);
