import type { AnError } from '@atlassian/jira-servicedesk-common/src/model/error/anerror';
import { FETCH_BATCH_SIZE } from '@atlassian/jira-servicedesk-queues-common/src/constants';
import type { QueueId } from '@atlassian/jira-servicedesk-queues-common/src/model';
import type { LoadedIssueList } from '@atlassian/jira-servicedesk-queues-common/src/services/issue/transform/types';
import { MAX_ISSUES_PER_PAGE } from '../../../common/constants';
import { LOAD_ISSUES_ACTION_SOURCE_PAGE, type LoadIssuesActionSource } from '../../../model';

// OPEN_ISSUE
export const OPEN_ISSUE = 'state.actions.issue.OPEN_ISSUE' as const;

export type OpenIssueAction = {
	type: typeof OPEN_ISSUE;
};

export const openIssueAction = (): OpenIssueAction => ({
	type: OPEN_ISSUE,
});

// LOAD_ISSUES

export const LOAD_ISSUES = 'state.actions.issue.LOAD_ISSUES' as const;

export type LoadIssuesAction = {
	type: typeof LOAD_ISSUES;
	payload: {
		startIndex: number;
		endIndex: number;
		bypassCache: boolean;
		source?: LoadIssuesActionSource;
	};
};

export const loadIssuesAction = (
	startIndex: number,
	source: LoadIssuesActionSource,
	bypassCache = false,
): LoadIssuesAction => {
	const endIndex = startIndex + FETCH_BATCH_SIZE - 1;
	return {
		type: LOAD_ISSUES,
		payload: {
			startIndex,
			endIndex,
			source,
			bypassCache,
		},
	};
};

export const loadIssuesActionForPage = (currentPage: number): LoadIssuesAction => {
	const startIndex = (currentPage - 1) * MAX_ISSUES_PER_PAGE;
	return loadIssuesAction(startIndex, LOAD_ISSUES_ACTION_SOURCE_PAGE);
};

// LOAD_ISSUES_INTERNAL

export const LOAD_ISSUES_INTERNAL = 'state.actions.issue.LOAD_ISSUES_INTERNAL' as const;

export type LoadIssuesInternalAction = {
	type: typeof LOAD_ISSUES_INTERNAL;
	payload: {
		startIndex: number;
		endIndex: number;
		bypassCache: boolean;
		source: LoadIssuesActionSource;
	};
};

export const loadIssuesInternalAction = (
	startIndex: number,
	endIndex: number,
	source: LoadIssuesActionSource,
	bypassCache = false,
): LoadIssuesInternalAction => ({
	type: LOAD_ISSUES_INTERNAL,
	payload: {
		startIndex,
		endIndex,
		source,
		bypassCache,
	},
});

// LOAD_ISSUES_SUCCESS

export const LOAD_ISSUES_SUCCESS = 'state.actions.issue.LOAD_ISSUES_SUCCESS' as const;

export type LoadIssuesSuccessAction = {
	type: typeof LOAD_ISSUES_SUCCESS;
	payload: {
		loadedIssuesResponse: LoadedIssueList;
		queueId: QueueId;
		startIndex: number;
		source: LoadIssuesActionSource;
	};
};

export const loadIssuesSuccessAction = (
	loadedIssuesResponse: LoadedIssueList,
	queueId: QueueId,
	startIndex: number,
	source: LoadIssuesActionSource,
): LoadIssuesSuccessAction => ({
	type: LOAD_ISSUES_SUCCESS,
	payload: {
		loadedIssuesResponse,
		queueId,
		startIndex,
		source,
	},
});

// LOAD_ISSUES_FAILURE

export const LOAD_ISSUES_FAILURE = 'state.actions.issue.LOAD_ISSUES_FAILURE' as const;

export type LoadIssuesFailureAction = {
	type: typeof LOAD_ISSUES_FAILURE;
	payload: {
		source: LoadIssuesActionSource;
		error: AnError;
		statusCode: number | undefined;
		isQueueEmpty: boolean | undefined;
		traceId?: string;
	};
};

export const loadIssuesFailureAction = (
	source: LoadIssuesActionSource,
	error: AnError,
	statusCode?: number,
	isQueueEmpty?: boolean,
	traceId?: string,
): LoadIssuesFailureAction => ({
	type: LOAD_ISSUES_FAILURE,
	payload: {
		source,
		error,
		statusCode,
		isQueueEmpty,
		traceId,
	},
});

export type Action =
	| OpenIssueAction
	| LoadIssuesAction
	| LoadIssuesInternalAction
	| LoadIssuesSuccessAction
	| LoadIssuesFailureAction;
