import { assoc } from 'icepick';
import {
	SET_ASSIGNEE_INITIALIZATION_STATE,
	type SetAssigneeInitializationStateAction,
} from '../../actions/field/initialization';
import type { FieldInitialization } from './types';

type Actions = SetAssigneeInitializationStateAction;

const DEFAULT_FIELD_INITIALIZATION: FieldInitialization = {
	assignee: {},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: FieldInitialization = DEFAULT_FIELD_INITIALIZATION, action: Actions) => {
	switch (action.type) {
		case SET_ASSIGNEE_INITIALIZATION_STATE: {
			const { avatarUrl, displayName, emailAddress } = action.payload;
			return assoc(state, 'assignee', { avatarUrl, displayName, emailAddress });
		}
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
