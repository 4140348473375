import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { LOAD_ISSUES_ACTION_SOURCE_SORT } from '../../../model';
import { loadIssuesAction } from '../../../state/actions/issue';
import { SORT_ORDER_CHANGED, sortOrderOptimisticUpdateAction } from '../../../state/actions/table';
import type { Action } from '../../../state/actions/types';
import type { State } from '../../../state/reducers/types';
import { getQueueId } from '../../../state/selectors/queue';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(SORT_ORDER_CHANGED).switchMap((action) => {
		const state = store.getState();
		const queueId = getQueueId(state);
		const { sortedBy, sortOrder } = action.payload;
		return Observable.of(
			sortOrderOptimisticUpdateAction(queueId, sortedBy, sortOrder),
			// @ts-expect-error - TS2769 - No overload matches this call.
			loadIssuesAction(0, LOAD_ISSUES_ACTION_SOURCE_SORT),
		);
	});
