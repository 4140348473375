import type { NavigateRow } from '../../../model/navigation';

export const NAVIGATE_TO_ROW = 'ops.rows.navigate.NAVIGATE_TO_ROW' as const;

export type NavigateToRowAction = {
	type: typeof NAVIGATE_TO_ROW;
	payload: NavigateRow;
};

export const navigateToRow = (payload: NavigateRow): NavigateToRowAction => ({
	type: NAVIGATE_TO_ROW,
	payload,
});
