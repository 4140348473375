import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import type { Issue, Column } from '@atlassian/jira-servicedesk-queues-common/src/model';
import {
	createContainer,
	createHook,
	createStore,
	createSubscriber,
	type Action,
} from '@atlassian/react-sweet-state';

type CollectionItem = Issue | undefined;

type FieldIds = string[];

type IssueKeyToIdMap = {
	[key: string]: number;
};

export type State = {
	issueKeyToIdMap: IssueKeyToIdMap; // used for mapping selectedKeys to ids for analytics,
	totalIssueCount: number;
	fieldIds: FieldIds;
	issueCollection: CollectionItem[];
	queueId: string;
	projectKey: string;
	projectId: number;
	columns: Column[];
	jql: string;
};

export type SetLoadedIssuesProps = {
	issueCollection: CollectionItem[];
	totalIssueCount: number;
	queueId: string;
	projectKey: string;
	projectId: number;
	columns: Column[];
	jql: string;
};

const getFieldIds = (columns: Column[]): FieldIds => columns.map((col) => col.id);

const convertIssuesToKeyMap = (loadedIssues: CollectionItem[]): IssueKeyToIdMap =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	loadedIssues.reduce<Record<string, any>>((map: IssueKeyToIdMap, issue: CollectionItem) => {
		if (issue !== undefined && issue !== null) {
			const issueKeyToIdMap = map;
			issueKeyToIdMap[issue.key] = issue.id;
		}
		return map;
	}, {});

export const setLoadedIssues =
	(): Action<State, SetLoadedIssuesProps> =>
	(
		{ getState, setState },
		{ issueCollection, totalIssueCount, projectKey, projectId, queueId, columns, jql },
	) => {
		const { issueKeyToIdMap: prevIssueKeyToIdMap, totalIssueCount: prevTotalIssueCount } =
			getState();
		if (!issueCollection || !totalIssueCount) return;

		const issueKeyToIdMap = convertIssuesToKeyMap(issueCollection);
		if (
			totalIssueCount !== prevTotalIssueCount ||
			!isEmpty(xor(Object.keys(issueKeyToIdMap), Object.keys(prevIssueKeyToIdMap)))
		) {
			const fieldIds = getFieldIds(columns);
			const noUndefinedIssueCollection = issueCollection.filter((item) => item);
			setState({
				issueKeyToIdMap,
				totalIssueCount,
				fieldIds,
				issueCollection: noUndefinedIssueCollection,
				projectKey,
				projectId,
				queueId,
				columns,
				jql,
			});
		}
	};

const smartActions = {
	setLoadedIssues,
} as const;

export type Actions = typeof smartActions;

export const initialState: State = {
	issueKeyToIdMap: {},
	totalIssueCount: 0,
	fieldIds: [],
	issueCollection: [],
	queueId: '',
	projectKey: '',
	projectId: 0,
	columns: [],
	jql: '',
};

const Store = createStore<State, Actions>({
	name: 'loaded-queue',
	initialState,
	actions: smartActions,
});

export const LoadedIssuesContainer = createContainer<State, Actions, SetLoadedIssuesProps>(Store, {
	onInit: setLoadedIssues,
	onUpdate: setLoadedIssues,
});

export const LoadedIssuesSubscriber = createSubscriber<State, Actions>(Store, {
	displayName: 'LoadedIssuesSubscriber',
});

export const useLoadedIssues = createHook(Store);

export default Store;
