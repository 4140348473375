import { connect } from '../common/table-redux';
import { setTableSize } from '../ops/table-size';
import { getTableWidth, getTableHeight, isAutoHeight } from '../read-ops/size';
import type { State } from '../state/types';
import DetailsPanel from './details';
import Table from './index-dumb';
import TableMain from './table';

export default connect(
	(state: State) => ({
		isAutoHeight: isAutoHeight(state),
		width: getTableWidth(state),
		height: getTableHeight(state),
		TableMain,
		DetailsPanel,
	}),
	{ setTableSize },
)(Table);
