import React, { useMemo } from 'react';
import withInjectedProps from '@atlassian/jira-common-util-with-injected-props';
import { ff } from '@atlassian/jira-feature-flagging';
import { useRouter } from '@atlassian/jira-router';
import { JsdGettingStartedPanelShouldRender } from '@atlassian/jira-servicedesk-getting-started-panel/src/async';
import QueuesApiDataProviderDI from '@atlassian/jira-servicedesk-queues-available-queues/src/services/queues-api-data-provider/main.tsx';
import QueueProviderDI from '@atlassian/jira-servicedesk-queues-available-queues/src/ui/queue-provider/index.tsx';
import { useCategorizedQueues } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import { ServiceDeskDocumentTitle } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/document-title/index.tsx';
import { usePageContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-context/main.tsx';
import { SpaPageWrapper } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-wrapper/index.tsx';
import QueuesPushProviderDI from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/queues-push-provider/index.tsx';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { useOnFavoritesChanged } from './controllers/favoriting';
import type { Props } from './types';
import { pageToQueues } from './utils/data-transformer';

const QueuesAppBase = (props: Props) => {
	const { QueuesPushProvider, pageId } = props;

	const [{ match }] = useRouter();

	const [{ data: pageContextData, error }] = usePageContext();

	const { tenantContext, projectContext, serviceDeskContext } = pageContextData || {};
	const { baseUrl = '' } = tenantContext || {};
	const { projectName } = projectContext || {};

	const pageContextProps = useMemo(
		() => ({ ...tenantContext, ...projectContext, ...serviceDeskContext }),
		[projectContext, serviceDeskContext, tenantContext],
	);

	const projectKey = toProjectKey(match.params.projectKey || '');
	const practice = toItsmPractice(match.params.practiceType);

	const [categorizedQueues, categorizedQueuesLoading, categorizedError, methods] =
		useCategorizedQueues(baseUrl, projectKey, practice);

	let selectedQueueName: string | undefined;

	if (categorizedQueues && match.params.queueId) {
		const selectedQueue = categorizedQueues.find(
			(queue) => queue.id === Number(match.params.queueId),
		);
		selectedQueueName = selectedQueue ? selectedQueue.name : undefined;
	}

	useOnFavoritesChanged(projectKey, practice, categorizedQueues, methods.editItemFavorite);

	return (
		<>
			<MarkProductStart />
			<SpaPageWrapper
				pageId={pageId}
				baseUrl={baseUrl}
				currentUrl={match.url}
				// @ts-expect-error - TS2322 - Type '{ projectId: number; projectKey: string; projectName: string; projectType: ProjectType; isAdmin: boolean; isProjectSimplified: boolean; } | undefined' is not assignable to type 'ProjectContext | null | undefined'.
				projectContext={projectContext}
				isLoading={(!pageContextData && !error) || categorizedQueuesLoading}
				error={error}
			>
				<QueuesPushProvider projectKey={projectKey}>
					{({ push, replace: providerReplace }) => (
						<>
							{ff('a11y-fission-queues-related-issues_giuuk') ? (
								<ServiceDeskDocumentTitle
									// @ts-expect-error - TS2322 - Type '{ projectName: string | undefined; section: string; }' is not assignable to type 'IntrinsicAttributes & Diff<(Diff<RefAttributes<any> | (RefAttributes<any> & { children?: ReactNode; }), Partial<RefAttributes<...> | (RefAttributes<...> & { ...; })>> & RefAttributes<...>) | (Diff<...> & ... 1 more ... & { ...; }), InjectIntlVoidProps> & { ...; }'.
									projectName={projectName}
									section="queues"
									subsection={selectedQueueName}
								/>
							) : (
								<ServiceDeskDocumentTitle
									// @ts-expect-error - TS2322 - Type '{ projectName: string | undefined; section: string; }' is not assignable to type 'IntrinsicAttributes & Diff<(Diff<RefAttributes<any> | (RefAttributes<any> & { children?: ReactNode; }), Partial<RefAttributes<...> | (RefAttributes<...> & { ...; })>> & RefAttributes<...>) | (Diff<...> & ... 1 more ... & { ...; }), InjectIntlVoidProps> & { ...; }'.
									projectName={projectName}
									section="queues"
								/>
							)}
							<>
								{props.children({
									push,
									match,
									replace: providerReplace,
									queuesData: {
										data: pageToQueues(categorizedQueues || []),
										error: categorizedError ? categorizedError.message : undefined,
										loading: categorizedQueuesLoading,
									},
									category: practice,
									// @ts-expect-error - TS2322 - Type '{ portalId?: number | undefined; canSeeNewIssueView?: boolean | undefined; userRole?: string | undefined; serviceDeskId?: number | undefined; projectId?: number | undefined; projectKey?: string | undefined; ... 11 more ...; isSiteAdmin?: boolean | undefined; }' is not assignable to type 'PageProps'.
									pageContext: pageContextProps,
								})}
							</>
						</>
					)}
				</QueuesPushProvider>
			</SpaPageWrapper>
			<JsdGettingStartedPanelShouldRender />
		</>
	);
};

export default withInjectedProps(QueuesAppBase, {
	QueueProvider: QueueProviderDI,
	QueuesApiDataProvider: QueuesApiDataProviderDI,
	QueuesPushProvider: QueuesPushProviderDI,
});
