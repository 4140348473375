import { Component } from 'react';
import { ComponentWithAnalytics } from '@atlassian/jira-analytics-web-react/src';

type Props = {
	onUpdate: () => void;
};

// eslint-disable-next-line jira/react/no-class-components
class AnalyticsWrapper extends Component<Props> {
	componentDidUpdate() {
		this.props.onUpdate();
	}

	render() {
		return null;
	}
}

export default ComponentWithAnalytics('UsageSampler', {
	onUpdate: 'sample',
})(AnalyticsWrapper);
