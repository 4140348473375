import { connect } from '../../../../../common/table-redux';
import { setHorizontalScrollOffset } from '../../../../../ops/scrolling/offset';
import {
	getVisibleCoreColumnsWidth,
	getVisibleAdditionalColumnsWidth,
} from '../../../../../read-ops/columns';
import {
	getHorizontalScrollOffset,
	getHorizontalScrollWidth,
} from '../../../../../read-ops/scrolling';
import { getTableRowMargin } from '../../../../../state/internal/selectors';
import type { State } from '../../../../../state/types';
import HorizontalScrollbar from './index-dumb';

export default connect(
	(state: State) => ({
		left: getVisibleCoreColumnsWidth(state),
		viewportWidth: getHorizontalScrollWidth(state),
		contentWidth: getVisibleAdditionalColumnsWidth(state) + getTableRowMargin(state),
		scrollLeft: getHorizontalScrollOffset(state),
	}),
	{ setHorizontalScrollOffset },
)(HorizontalScrollbar);
