import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type FetchError from '@atlassian/jira-fetch/src/utils';
import type { FieldType } from '../../../../model/fields/types';
import { updateFieldValue$ } from '../../../../services/field/update';
import {
	type UpdateFieldAction,
	FIELD_UPDATE_SUBMIT,
	updateFieldFailureErrorResponseAction,
	updateFieldSuccessAction,
} from '../../../../state/actions/field/update';
import type { State } from '../../../../state/reducers/types';
import { getColumnTitle } from '../../../../state/selectors/columns';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(FIELD_UPDATE_SUBMIT)
		.switchMap(<T extends FieldType>(action: UpdateFieldAction<T>) => {
			const state: State = store.getState();
			const { issueKey, fieldId, fieldValue, onSuccess, onFailure } = action.payload;
			return updateFieldValue$(issueKey, fieldId, fieldValue)
				.mergeMap(() => {
					onSuccess(fieldValue);
					return Observable.of(updateFieldSuccessAction(issueKey, fieldId));
				})
				.catch((error: FetchError) => {
					onFailure();
					return Observable.of(
						updateFieldFailureErrorResponseAction(
							issueKey,
							fieldId,
							fieldValue.fieldType,
							getColumnTitle(state, fieldId),
							error,
						),
					);
				});
		});
