import React, { Component, type ComponentType, type ReactElement } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

export const MAX_AUTOMATION_RULES_PER_SD = 'max_automation_rules_per_sd' as const;
export const MAX_AUTOMATION_BRANCHES_PER_RULE = 'max_automation_branches_per_rule' as const;
export const MAX_QUEUES_PER_SD = 'max_queues_per_sd' as const;
export const MAX_SLA_GOALS_PER_SD = 'max_sla_goals_per_sd' as const;
export const MAX_CMDB_OBJECTS_PER_FIELD = 'max_cmdb_objects_per_field' as const;
export const MAX_NUM_TEAM_PRIORITY_QUEUES_PER_SD = 'max_num_team_priority_queues_per_sd' as const;

export type LimitProperty =
	| typeof MAX_AUTOMATION_RULES_PER_SD
	| typeof MAX_AUTOMATION_BRANCHES_PER_RULE
	| typeof MAX_QUEUES_PER_SD
	| typeof MAX_SLA_GOALS_PER_SD
	| typeof MAX_CMDB_OBJECTS_PER_FIELD
	| typeof MAX_NUM_TEAM_PRIORITY_QUEUES_PER_SD;

// Duplicate of data from LimitProperty in the JSD Cloud repo
export const LIMIT_DEFAULTS: Record<LimitProperty, number> = {
	[MAX_AUTOMATION_RULES_PER_SD]: 30,
	[MAX_AUTOMATION_BRANCHES_PER_RULE]: 30,
	[MAX_QUEUES_PER_SD]: 300,
	[MAX_SLA_GOALS_PER_SD]: 30,
	[MAX_CMDB_OBJECTS_PER_FIELD]: 20,
	[MAX_NUM_TEAM_PRIORITY_QUEUES_PER_SD]: 50,
};

export type Props = {
	limitProperty: LimitProperty;
	children: (limit: number) => ReactElement;
};

type State = {
	limit: number;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const limitCache: Record<LimitProperty, Promise<number>> = {} as Record<
	LimitProperty,
	Promise<number>
>;

// Exporting in case non cached values needed. Current used by test.js
export const clearCachedLimit = (limitProperty: LimitProperty) => {
	delete limitCache[limitProperty];
};

export const getLimit = (limitProperty: LimitProperty): Promise<number | null> => {
	if (!limitCache[limitProperty]) {
		limitCache[limitProperty] = fetchJson(
			`/rest/servicedesk/latest/limit/${limitProperty}?_=${Date.now()}`,
		).then(
			({ limit }) => limit,
			(error) => {
				// Will allow us to try again to fetch limit
				clearCachedLimit(limitProperty);
				log.safeWarnWithoutCustomerData(
					'servicedesk.common.view.components.limit-data',
					`Failed to fetch limit for ${limitProperty}`,
				);
				throw error;
			},
		);
	}

	return limitCache[limitProperty];
};

// Not pure, because the render prop often isn't a pure function
// eslint-disable-next-line jira/react/no-class-components
export class LimitData extends Component<Props, State> {
	state = {
		// Current consumers of LimitData expect typeof number so this cannot be an empty value.
		// This also voids limit messages showing until we have a reliable limit,
		// instead of showing then changing the value
		limit: Infinity,
	};

	componentDidMount() {
		const { limitProperty } = this.props;
		const defaultLimit = LIMIT_DEFAULTS[limitProperty];

		getLimit(limitProperty)
			.then((limit) => {
				this.updateLimit(limit || defaultLimit);
			})
			.catch(() => {
				this.updateLimit(defaultLimit);
			});
	}

	updateLimit(newLimit: number) {
		this.setState(({ limit: oldLimit }) =>
			oldLimit !== newLimit ? { limit: newLimit } : undefined,
		);
	}

	render() {
		return this.props.children(this.state.limit);
	}
}

export const withLimitData =
	<TProps extends object>(
		limitProperty: LimitProperty,
		WrappedComponent: ComponentType<
			{
				limit: number;
			} & TProps
		>,
	): ComponentType<TProps> =>
	(props: TProps) => (
		<LimitData limitProperty={limitProperty}>
			{(limit) => <WrappedComponent limit={limit} {...props} />}
		</LimitData>
	);
