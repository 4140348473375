import { useRef, useLayoutEffect } from 'react';
import noop from 'lodash/noop';
import { usePracticeList } from '@atlassian/jira-servicedesk-queues-categorized-store';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category';

type Props = {
	projectKey: string;
	onUpdatePractices: (arg1: ItsmPractice[]) => void;
};

const ItsmContextListener = ({ projectKey, onUpdatePractices }: Props) => {
	const practicesBelongsToRef = useRef<string>('');
	const [practices, loading, error] = usePracticeList();

	useLayoutEffect(() => {
		const canUpdatePractices = !loading && !error && practicesBelongsToRef.current !== projectKey;
		if (canUpdatePractices) {
			practicesBelongsToRef.current = projectKey;
			onUpdatePractices(practices || []);
		}
	}, [projectKey, loading, error, onUpdatePractices, practices]);

	return null;
};

ItsmContextListener.defaultProps = {
	projectKey: '',
	onUpdatePractices: noop,
};

export default ItsmContextListener;
