import React, { type Dispatch } from 'react';
import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import { ViewTracker } from '@atlassian/jira-analytics-web-react/src';
import { ff } from '@atlassian/jira-feature-flagging';
import { UIControllerSubscriber as NavUIControllerSubscriber } from '@atlassian/jira-navigation-next-bridge';
import { NavigationUISubscriber } from '@atlassian/jira-navigation-ui-controller/src';
import { connect } from '@atlassian/jira-react-redux';
import { UpFlowPersistentUpgradeBanner } from '@atlassian/jira-up-flow-persistent-upgrade-banner/src/async';
import { LOAD_ISSUES_ACTION_SOURCE_SIDEBAR, type SortedQueue } from '../../model';
import {
	filterQueryChangedAction,
	filterQuerySubmittedAction,
	isNewFilterQueryPendingAction,
} from '../../state/actions/filter';
import { loadIssuesAction } from '../../state/actions/issue';
import { shutdownAction } from '../../state/actions/lifecycle';
import { changeVisibilityAction, displayQueuesErrorHeaderAction } from '../../state/actions/page';
import { queueChangedAction } from '../../state/actions/queue';
import {
	onboardingResolvedAction,
	setNavCollapsedAction,
	setSidebarIssueKey,
} from '../../state/actions/ui';
import { restartPollAction, stopPollAction } from '../../state/actions/update-metadata';
import type { State } from '../../state/reducers/types';
import { getCreateAnalyticsEvent } from '../../state/selectors/analytic';
import { getAppProps } from '../../state/selectors/common';
import { getIsCurrentDataFiltered } from '../../state/selectors/filter';
import { getCollection, getIssueCount } from '../../state/selectors/issues';
import { getEnabledPractices, isItsmProject } from '../../state/selectors/practices';
import { getCategory, getColumns, getJql } from '../../state/selectors/queue';
import {
	getOnboardingResolved,
	isInitialized,
	isLoadingIssues,
	isQueueEmpty,
	showIssueFailureError,
	shouldShowBadFilterQueryError,
	getSidebarIssueKey,
	getSidebarReactKey,
	isQueuesErrorHeaderDisplayed,
} from '../../state/selectors/ui';
import EmptyFilteredQueue from './empty-filtered-queue';
import EmptyQueue from './empty-queue';
import ErrorState from './error-state';
import Header from './header';
import IssueContextListener from './issue-context-listener';
import ItsmContextListener from './itsm-context-listener';
import NavUpdater from './nav-updater';
import QueuesBreadcrumb from './queues-breadcrumb';
import Table from './table';
import UsageSampler from './usage-sampler';
import Layout, { type DispatchProps, type OwnProps, type Props, type StateProps } from './view';

const getColumnsMemoized = () => createSelector(getColumns, (res) => res);
const EMPTY_LIST: ReturnType<typeof getEnabledPractices> = [];
const mapStateToPropsFactory = () => {
	const getColumnsInstance = getColumnsMemoized();
	return (state: State): StateProps => ({
		Header,
		UsageSampler,
		EmptyQueue,
		ErrorState,
		isQueueEmpty: isQueueEmpty(state),
		showIssueFailureError: showIssueFailureError(state),
		isLoadingIssues: isLoadingIssues(state),
		issueCount: getIssueCount(state),
		onboardingResolved: getOnboardingResolved(state),
		isCurrentDataFiltered: getIsCurrentDataFiltered(state),
		Table,
		EmptyFilteredQueue,
		NavUpdater,
		ViewTracker,
		QueuesBreadcrumb,
		IssueContextListener,
		ItsmContextListener,
		UpFlowPersistentUpgradeBanner,
		baseUrl: '',
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		projectKey: getAppProps(state).projectKey!,
		isInitialized: isInitialized(state),
		category: getCategory(state),
		itsmPractices: isItsmProject(state) ? getEnabledPractices(state) : EMPTY_LIST,
		issueCollection: getCollection(state),
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		projectId: getAppProps(state).projectId!,
		columns: getColumnsInstance(state),
		jql: getJql(state),
		shouldShowBadFilterQueryError: shouldShowBadFilterQueryError(state),
		createAnalyticsEvent: getCreateAnalyticsEvent(state),
		sidebarIssueKey: getSidebarIssueKey(state),
		sidebarReactKey: ff('jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff', false)
			? getSidebarReactKey(state)
			: null,
		isQueuesErrorHeaderDisplayed: isQueuesErrorHeaderDisplayed(state),
	});
};

const mapDispatchToProps = (
	dispatch: Dispatch<{
		type: unknown;
	}>,
) => ({
	onPageVisibilityChanged: (isQueueVisible: boolean) => {
		dispatch(changeVisibilityAction(isQueueVisible));

		if (!isQueueVisible) {
			dispatch(stopPollAction());
		}
	},
	onUnmount: () => {
		dispatch(shutdownAction());
	},
	onQueueChanged: (queue: SortedQueue) => {
		dispatch(queueChangedAction(queue));
	},
	onOnboardingResolved: (resolved: boolean) => dispatch(onboardingResolvedAction(resolved)),
	onNavCollapseChange: (isNavCollapsed: boolean) => {
		dispatch(setNavCollapsedAction(isNavCollapsed));
	},
	onWindowFocus: () => {
		dispatch(restartPollAction());
	},
	onFilterClear: () => {
		dispatch(filterQueryChangedAction(null));
		dispatch(filterQuerySubmittedAction(null));
	},
	onIssueChange: () => {
		dispatch(isNewFilterQueryPendingAction(true));
		dispatch(loadIssuesAction(0, LOAD_ISSUES_ACTION_SOURCE_SIDEBAR));
	},
	onSetIssueKey: (key: string | null) => {
		dispatch(setSidebarIssueKey(key));
	},
	onNonPremiumSLAColumnError: () => displayQueuesErrorHeaderAction,
});

const getSidebarApiProps = memoizeOne((sidebarApiProps) => sidebarApiProps);

const LayoutWithSidebarApi = (props: Props) => (
	<NavUIControllerSubscriber>
		{({ state: sidebarState }) => (
			<NavigationUISubscriber>
				{(_, navActions) => (
					<Layout
						{...props}
						sidebarApi={getSidebarApiProps({
							navActions,
							isCollapsed: sidebarState.isCollapsed,
						})}
					/>
				)}
			</NavigationUISubscriber>
		)}
	</NavUIControllerSubscriber>
);

const ConnectedLayout = connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToPropsFactory,
	mapDispatchToProps,
)(LayoutWithSidebarApi);

export default ConnectedLayout;
