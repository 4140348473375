import type { ComponentType } from 'react';
import type { FieldDataSelectorProps } from '@atlassian/jira-issue-table/src';
import { connect } from '@atlassian/jira-react-redux';
import { changeIssueFieldValueAction } from '@atlassian/jira-servicedesk-queues-common/src/state/actions/issue/field';
import ReadyForUser from '@atlassian/jira-servicedesk-queues-common/src/view/queues-ready-for-user';
import type { State } from '../../../../state/reducers/types';
import {
	getFieldPropsSelector,
	isFirstFieldInTable,
	isFieldToExperienceTrack,
} from '../../../../state/selectors/issues';
import { getQueueId } from '../../../../state/selectors/queue';
import { isQueueVisible } from '../../../../state/selectors/ui';
import Field from './view';

type OwnProps = FieldDataSelectorProps;

const mapStateToProps = (state: State, ownProps: OwnProps) => {
	const {
		issueKey,
		fieldDescriptor: { fieldId },
	} = ownProps;
	const isCellFirstFieldInTable = isFirstFieldInTable(state, issueKey, fieldId);
	const queueVisible = isQueueVisible(state);
	return {
		queueId: getQueueId(state),
		onFieldDataSelection: getFieldPropsSelector(state),
		isFirstFieldInTable: isCellFirstFieldInTable,
		isFieldToExperienceTrack: isFieldToExperienceTrack(state, issueKey, fieldId),
		isQueueVisible: queueVisible,
		ReadyForUser,
	};
};

const FieldDataSelector: ComponentType<FieldDataSelectorProps> = connect(mapStateToProps, {
	onFieldChange: changeIssueFieldValueAction,
})(Field);

export default FieldDataSelector;
