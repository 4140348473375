import { connect } from '../../../../../../common/table-redux';
import type { ColumnId } from '../../../../../../model/columns';
import { isFirstColumn, getColumnWidth } from '../../../../../../read-ops/columns';
import type { State } from '../../../../../../state/types';
import DefaultColumn from './default-column';
import ColumnWrapper from './index-dumb';

export default connect(
	(state: State, { id }: { id: ColumnId }) => ({
		width: getColumnWidth(state, id),
		isFirstColumn: isFirstColumn(state, id),
		DefaultColumn,
	}),
	{},
)(ColumnWrapper);
