import type { ComponentType } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import type { State } from '../../state/reducers/types';
import { mergeIssueKeysWithLockedIssues } from '../../state/selectors/issues';
import { transformProps } from '../../state/selectors/table-props';
import { getRefreshSidebar, getRenderSidebarIcon } from '../../state/selectors/ui';
import { connect } from '../../state/store';
import Table, { type Props } from './view';

const mapStateToProps = (state: State) => ({
	issueKeys: mergeIssueKeysWithLockedIssues(state),
	transformProps,
	renderSidebarIcon: getRenderSidebarIcon(state),
	refreshSidebar: ff('jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff', false)
		? getRefreshSidebar(state)
		: undefined,
});

// @ts-expect-error - TS2322 - Type 'ComponentClass<Omit<DispatchProp<any>, "dispatch">> & { WrappedComponent: Component<DispatchProp<any>>; }' is not assignable to type 'ComponentType<Props>'. | TS2554 - Expected 3-4 arguments, but got 1. | TS2345 - Argument of type 'typeof Table' is not assignable to parameter of type 'Component<DispatchProp<any>>'.
const ConnectedIssueTable: ComponentType<Props> = connect(mapStateToProps)(Table);

export default ConnectedIssueTable;
