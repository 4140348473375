import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import type { SERVICE_ENTITY_CUSTOM_FIELD_TYPE } from '../../../model/fields/json-fields/custom-fields/types';
import type { JsonComponentProps } from '../../../model/fields/types';
import type { AffectedServices } from './index';

const LazyAffectedServices = lazyForPaint<typeof AffectedServices>(() =>
	import(/* webpackChunkName: "async-affected-services" */ './index').then(
		(module) => module.AffectedServices,
	),
);

export const AsyncAffectedServices = (
	props: JsonComponentProps<typeof SERVICE_ENTITY_CUSTOM_FIELD_TYPE>,
) => (
	<JSErrorBoundary
		id="affectedServicesField"
		packageName="jiraIssueTable"
		teamName="jsd-shield"
		fallback="flag"
	>
		<Placeholder name="async-affected-services" fallback={null}>
			<LazyAffectedServices {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
