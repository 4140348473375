import { connect } from '../../../../../common/table-redux';
import { navigateToRow } from '../../../../../ops/rows/navigate';
import { setHorizontalScrollOffset } from '../../../../../ops/scrolling/offset';
import { setScrollbarSize } from '../../../../../ops/scrolling/size';
import {
	getHorizontalScrollOffset,
	getMaxHorizontalScrollOffset,
} from '../../../../../read-ops/scrolling';
import {
	getOnRowListFocusFunctionChangedCallback,
	isAutoHeight,
	getOnReactPortalContainerRefChangedCallback,
} from '../../../../../read-ops/size';
import { getNavigationMode } from '../../../../../state/consumer/selectors';
import { clearActiveItem } from '../../../../../state/internal/actions';
import type { State } from '../../../../../state/types';
import AutoHeightList from './auto-height';
import RowList from './index-dumb';
import VirtualList from './virtual';

export default connect(
	(state: State) => ({
		isAutoHeight: isAutoHeight(state),
		maxHorizontalScrollOffset: getMaxHorizontalScrollOffset(state),
		onRowListFocusFunctionChanged: getOnRowListFocusFunctionChangedCallback(state),
		onReactPortalContainerRefChanged: getOnReactPortalContainerRefChangedCallback(state),
		horizontalScrollOffset: getHorizontalScrollOffset(state),
		navigationMode: getNavigationMode(state),
		AutoHeightList,
		VirtualList,
	}),
	{
		setHorizontalScrollOffset,
		setScrollbarSize,
		navigateToRow,
		clearActiveItem,
	},
)(RowList);
