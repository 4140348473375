import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import type { BatchAction } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import {
	getQueueIssueFragment,
	navigate,
} from '@atlassian/jira-servicedesk-common/src/navigation/queues';
import { fromQueueId } from '@atlassian/jira-servicedesk-queues-common/src/model';
import { getProjectKey } from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons';
import { OPEN_ISSUE } from '../../../state/actions/issue';
import type { Action } from '../../../state/actions/types';
import type { State } from '../../../state/reducers/types';
import { getQueueId, getCategory } from '../../../state/selectors/queue';
import { getIssueKeyContext } from '../../../state/selectors/ui';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (push: QueuesPush) =>
	(
		action$: ActionsObservable<Action | BatchAction>,
		store: MiddlewareAPI<State>,
	): Observable<Action> =>
		action$.ofType(OPEN_ISSUE).switchMap(() => {
			const state: State = store.getState();
			const projectKey = getProjectKey(state);
			const queueId = getQueueId(state);
			const issueKey = getIssueKeyContext(state);
			const category = getCategory(state);

			if (issueKey != null && issueKey !== '') {
				if (push) {
					return push(getQueueIssueFragment(fromQueueId(queueId), issueKey, category));
				}

				// Fallback to push state navigator.
				return navigate(
					// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
					projectKey,
					getQueueIssueFragment(fromQueueId(queueId), issueKey, category),
				);
			}
			return Observable.empty<never>();
		});
