import { UP, DOWN, LEFT, RIGHT } from '../../../model/navigation-directions';
import { getVisibleColumnIds } from '../../../state/consumer/selectors';
import { getVisibleRowIds, getVisibleLoadingRowIds } from '../../../state/selectors';
import type { State } from '../../../state/types';
import { defineTransaction } from '../../common/transactions';
import { setActiveCell } from '../../items/active/set';
import { NAVIGATE_TO_CELL, type NavigateToCellAction } from './action';
import { navigateDown, navigateUp } from './utils';

export default defineTransaction(NAVIGATE_TO_CELL, (action: NavigateToCellAction, state: State) => {
	const { direction, columnId, rowId } = action.payload;

	const visibleColumnIds = getVisibleColumnIds(state);
	const loadingRowIdsSet = new Set(getVisibleLoadingRowIds(state));
	const visibleRowIds = getVisibleRowIds(state);

	/* activatedFrom is the opposite of direction, as it is the
	 * relative orientation of the cell that navigated to it, and not
	 * the user interaction that caused the navigation.
	 */

	switch (direction) {
		case UP:
			return setActiveCell({
				columnId,
				rowId: navigateUp(visibleRowIds, rowId, loadingRowIdsSet),
				activatedFrom: DOWN,
			});
		case DOWN:
			return setActiveCell({
				columnId,
				rowId: navigateDown(visibleRowIds, rowId, loadingRowIdsSet),
				activatedFrom: UP,
			});
		case LEFT:
			return setActiveCell({
				columnId: navigateUp(visibleColumnIds, columnId, new Set()),
				rowId,
				activatedFrom: RIGHT,
			});

		case RIGHT:
			return setActiveCell({
				columnId: navigateDown(visibleColumnIds, columnId, new Set()),
				rowId,
				activatedFrom: LEFT,
			});

		default:
			throw new Error('illegal value of the direction property.');
	}
});
