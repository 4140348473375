import type { AggTransformerFunction } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/transformer-type.tsx';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type TEXT_GQL_FIELD,
	ISSUE_FIELD_STRING_CF_TYPE,
	ISSUE_FIELD_ANY_CF_TYPE,
	SUMMARY_TYPE,
	TEXT_CF_TYPE,
	EPIC_NAME_TYPE,
	RANK_CF_TYPE,
	READ_ONLY_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	SERVICEDESK_PRACTICES_TYPE,
	USER_PROPERTY_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

const defaultSchema = {
	configuration: null,
	items: null,
	renderer: PLAIN_TEXT,
} as const;

export const transformAggSingleLineTextToLegacyField: AggTransformerFunction<
	typeof TEXT_GQL_FIELD
> = (node) => {
	const defaultTransformedField = {
		...transformAggNodeToLegacyField(node),
		value: node.text,
	};

	switch (node.type) {
		case EPIC_NAME_TYPE:
		case MESSAGE_EDIT_CF_TYPE:
		case MESSAGE_VIEW_CF_TYPE:
		case READ_ONLY_CF_TYPE:
		case TEXT_CF_TYPE:
		case USER_PROPERTY_CF_TYPE:
			return {
				...defaultTransformedField,
				schema: {
					...defaultSchema,
					custom: node.type,
					system: null,
					type: ISSUE_FIELD_STRING_CF_TYPE,
				},
			};
		case RANK_CF_TYPE:
			return {
				...defaultTransformedField,
				schema: {
					...defaultSchema,
					custom: node.type,
					system: null,
					type: ISSUE_FIELD_ANY_CF_TYPE,
				},
			};
		case SUMMARY_TYPE:
			return {
				...defaultTransformedField,
				schema: {
					...defaultSchema,
					type: ISSUE_FIELD_STRING_CF_TYPE,
					custom: null,
					system: node.type,
				},
			};
		case SERVICEDESK_PRACTICES_TYPE:
			// Transform field only if the practice has a value
			return node.text != null
				? {
						...transformAggNodeToLegacyField(node),
						value: [node.text],
						schema: {
							...defaultSchema,
							renderer: null,
							custom: null,
							system: node.type,
							type: SERVICEDESK_PRACTICES_TYPE,
						},
					}
				: undefined;
		default:
			return undefined;
	}
};
