import { type UserForEditing, type FieldContext, ASSIGNEE_CONTEXT } from '../model';
import assigneeSearch from './search-assignee';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	issueKey: string,
	query: string | null | undefined,
	fieldContext: FieldContext,
): Promise<UserForEditing[]> => {
	if (fieldContext === ASSIGNEE_CONTEXT) {
		return assigneeSearch(issueKey, query ?? '');
	}
	throw new Error('Not implemented');
};
