import type { Optional } from '../../../model/optional';
import { sortedRowIdsHashRootId, type RowId, type RowIdHash } from '../../../model/rows';

const traverseRows = (
	rowIds: RowId[],
	sortedRowIdsHash: Record<RowId, RowId[]>,
	// FSN-4037 Remove draggingRowId related logic during FF cleanup
	draggingRowId: Optional<RowId>,
	result: RowId[],
	expandedRowIdsHash: RowIdHash,
) => {
	if (rowIds === undefined || rowIds.length === 0) {
		return;
	}

	rowIds.forEach((rowId) => {
		// if an item is being dragged, its' children should be hidden
		// otherwise check if it is the list of consumer expanded rows
		const childrenIds =
			draggingRowId === rowId ? [] : (expandedRowIdsHash[rowId] && sortedRowIdsHash[rowId]) || [];

		result.push(rowId);
		traverseRows(childrenIds, sortedRowIdsHash, draggingRowId, result, expandedRowIdsHash);
	});
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	sortedRowIdsHash: Record<RowId, RowId[]>,
	draggingRowId: Optional<RowId>,
	expandedRowIdsHash: RowIdHash,
): RowId[] => {
	const result: RowId[] = [];

	traverseRows(
		sortedRowIdsHash[sortedRowIdsHashRootId],
		sortedRowIdsHash,
		draggingRowId,
		result,
		expandedRowIdsHash,
	);

	return result;
};
