import type { Observable } from 'rxjs/Observable';
import transform from '@atlassian/jira-servicedesk-queues-common/src/services/issue';
import {
	getBaseUrl,
	getProjectKey,
} from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';
import get from '../../rest/poll';
import type { PollResponse } from '../../rest/poll/types';
import type { State } from '../../state/reducers/types';
import { getProjectStateHash } from '../../state/selectors/update-metadata';

const transformFunction = (pollResponse: PollResponse): PollResponse => pollResponse;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State): Observable<PollResponse> =>
	transform(
		// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
		get(getProjectKey(state), getBaseUrl(state), getProjectStateHash(state)),
		transformFunction,
	);
