import type { CloudId } from '@atlassian/jira-shared-types';
import type { State } from '../../reducers/types';
import { getInitialAppProps } from '../common';

export const getAtlassianAccountId = (state: State): string | undefined =>
	getInitialAppProps(state).atlassianAccountId;

export const getCloudId = (state: State): CloudId | undefined => getInitialAppProps(state).cloudId;

export const getFabricPfDirUrl = (state: State): string | undefined =>
	getInitialAppProps(state).fabricPfDirUrl;
