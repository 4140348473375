import React from 'react';
import { ROUTE_NAMES_ARCHIVED_ISSUES_DIRECTORY } from '@atlassian/jira-common-constants/src/spa-routes';
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common';
import { MenuSection, MenuSectionHeading } from '@atlassian/jira-navigation-system/src/v4';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context';
import { useAlwaysVisibleNavItems } from '@atlassian/jira-servicedesk-queues-categorized-store';
import { QueueListSection } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/navigation-category';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category';
import { QueueList } from '../queue-list';
import { messages } from './messages';

type AlwaysVisibleSectionProps = {
	projectKey: string;
	practice: ItsmPractice;
};

export const AlwaysVisibleSection = ({ projectKey, practice }: AlwaysVisibleSectionProps) => {
	const { formatMessage } = useIntl();
	// TODO: This baseUrl has a dependency on @atlassian/jira-servicedesk-queues-categorized-store
	// The above items need to be updated prior to complete baseUrl removal from this file.
	const baseUrl = '';
	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const queuesDefault = useAlwaysVisibleNavItems(baseUrl, projectKey, practice, queueCustomContext);

	const [queues] = queuesDefault;

	const archive = () => (
		<MenuItem
			analytics={{ itemId: 'archives' }}
			selectedOn={[ROUTE_NAMES_ARCHIVED_ISSUES_DIRECTORY]}
			href="/jira/issues/archive"
			data-testid="servicedesk-queues-nav4.common.ui.practice-queues.always-visible-section.archives-link"
		>
			{formatMessage(messages.archive)}
		</MenuItem>
	);

	if (!queues || queues.length === 0) {
		return null;
	}

	return (
		<MenuSection>
			<MenuSectionHeading>{formatMessage(messages.teamPriority)}</MenuSectionHeading>
			<QueueList queues={queues} section={QueueListSection.PRIORITY_GROUP} />
			{ff('issue-unarchival_wdpb9') && archive()}
		</MenuSection>
	);
};
