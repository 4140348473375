/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { forwardRef, type ReactNode, useCallback, useRef, useState } from 'react';

import { cssMap, cx, jsx } from '@compiled/react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import useControlled from '@atlaskit/ds-lib/use-controlled';
import type { IconProps } from '@atlaskit/icon';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { List } from '../../components/list';

import {
	IsChildSelectedContext,
	IsExpandedContext,
	LevelContext,
	OnExpansionToggleContext,
	SetIsExpandedContext,
	useIsChildSelected,
	useIsExpanded,
	useLevel,
	useOnExpansionToggle,
	useSetIsExpanded,
} from './expandable-menu-item-context';
import { MenuItemBase } from './menu-item';
import { MenuListItem } from './menu-list-item';
import type { CommonMenuItemOnClick } from './types';

const styles = cssMap({
	content: {
		// This specific padding value is used to achieve alignment of content
		// when nesting expandable menu items.
		paddingInlineStart: `calc(${token('space.300')} + ${token('space.025')})`,
	},
	collapsedContent: {
		display: 'none',
	},
});

export type ExpandableMenuItemProps = {
	isExpanded?: boolean;
	isDefaultExpanded?: boolean;
	isChildSelected?: boolean;
	onExpansionToggle?: (isExpanded: boolean) => void;
	children: ReactNode;
};

/**
 * __ExpandableMenuItem__
 *
 * A composable component for nested menu items that can be revealed and hidden by interacting witih the parent menu item.
 *
 * Follows the [disclosure pattern](https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/).
 *
 * Should be used with `ExpandableMenuItemTrigger` as the parent menu item, and children menu items should be wrapped in a `ExpandableMenuItemContent`.
 *
 * Usage example:
 * ```jsx
 * <ExpandableMenuItem>
 *   <ExpandableMenuItemTrigger>Parent menu item</ExpandableMenuItemTrigger>
 *   <ExpandableMenuItemContent>
 *     <MenuButtonItem>Item 1</MenuButtonItem>
 *     <MenuButtonItem>Item 2</MenuButtonItem>
 *   </ExpandableMenuItemContent>
 * </ExpandableMenuItem>
 * ```
 */
export const ExpandableMenuItem = forwardRef<HTMLDivElement, ExpandableMenuItemProps>(
	(
		{
			isExpanded: isExpandedControlled,
			isDefaultExpanded = false,
			isChildSelected = false,
			onExpansionToggle,
			children,
		},
		forwardedRef,
	) => {
		const [isExpanded, setIsExpanded] = useControlled(
			isExpandedControlled,
			() => isDefaultExpanded,
		);
		return (
			<IsExpandedContext.Provider value={isExpanded}>
				<SetIsExpandedContext.Provider value={setIsExpanded}>
					<IsChildSelectedContext.Provider value={isChildSelected}>
						<OnExpansionToggleContext.Provider value={onExpansionToggle ?? null}>
							{/* Wrapping in a `li` to group all the composable elements together, as part of the disclosure pattern */}
							<MenuListItem ref={forwardedRef}>{children}</MenuListItem>
						</OnExpansionToggleContext.Provider>
					</IsChildSelectedContext.Provider>
				</SetIsExpandedContext.Provider>
			</IsExpandedContext.Provider>
		);
	},
);

export type ExpandableMenuItemContentProps = {
	children: ReactNode;
};

/**
 * __ExpandableMenuItemContent__
 *
 * The expandable and collapsable section of the expandable menu item. It should contain the nested menu items.
 */
export const ExpandableMenuItemContent = forwardRef<HTMLDivElement, ExpandableMenuItemContentProps>(
	({ children }, forwardedRef) => {
		const isExpanded = useIsExpanded();
		const level = useLevel();
		const hasExpanded = useRef(false);

		if (!isExpanded && !hasExpanded.current) {
			return null;
		}

		hasExpanded.current = true;

		return (
			<LevelContext.Provider value={level + 1}>
				<List ref={forwardedRef} xcss={cx(styles.content, !isExpanded && styles.collapsedContent)}>
					{children}
				</List>
			</LevelContext.Provider>
		);
	},
);

type ExpandableMenuItemIconProps = {
	isExpanded: boolean;
	isHovering: boolean;
	isSelected?: boolean;
	/**
	 * The element to display when the user is not hovering over the icon. If not provided, the chevron icon will be used
	 */
	defaultStateElem?: JSX.Element;
	iconProps?: IconProps;
};

const ExpandableMenuItemIcon = ({
	iconProps,
	isExpanded,
	isHovering,
	isSelected,
	defaultStateElem,
}: ExpandableMenuItemIconProps): JSX.Element => {
	const isChildSelected = useIsChildSelected();
	const ChevronIcon = isExpanded ? ChevronDownIcon : ChevronRightIcon;

	const chevronElem = (
		<ChevronIcon
			{...iconProps}
			label=""
			color={isSelected || isChildSelected ? token('color.icon.selected') : undefined}
		/>
	);

	return isHovering ? chevronElem : defaultStateElem ?? chevronElem;
};

export type ExpandableMenuItemTriggerProps = {
	actions?: ReactNode;
	isSelected?: boolean;
	href?: string;
	elemBefore?: JSX.Element;
	elemAfter?: ReactNode;
	actionsOnHover?: ReactNode;
	onClick?: CommonMenuItemOnClick;
	children: ReactNode;
	testId?: string;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
};

/**
 * __ExpandableMenuItemTrigger__
 *
 * The trigger component for an `ExpandableMenuItem`. Interacting with it will expand or collapse the expandable.
 */
export const ExpandableMenuItemTrigger = forwardRef<
	HTMLButtonElement | HTMLAnchorElement,
	ExpandableMenuItemTriggerProps
>(
	(
		{
			actions,
			isSelected,
			href,
			elemBefore,
			elemAfter,
			actionsOnHover,
			onClick,
			children,
			testId,
			interactionName,
		},
		forwardedRef,
	) => {
		const onExpansionToggle = useOnExpansionToggle();
		const isExpanded = useIsExpanded();
		const setIsExpanded = useSetIsExpanded();

		const [isHovering, setIsHovering] = useState(false);
		const handleMouseEnter = useCallback(() => setIsHovering(true), []);
		const handleMouseLeave = useCallback(() => setIsHovering(false), []);

		const handleIconClick = useCallback(() => {
			onExpansionToggle?.(!isExpanded);
			setIsExpanded(!isExpanded);
		}, [isExpanded, onExpansionToggle, setIsExpanded]);

		const handleMenuContentClick = useCallback(
			(
				event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
				analyticsEvent: UIAnalyticsEvent,
			) => {
				onClick?.(event, analyticsEvent);
				onExpansionToggle?.(!isExpanded);
				setIsExpanded(!isExpanded);
			},
			[onClick, onExpansionToggle, isExpanded, setIsExpanded],
		);

		const isSelectable = typeof href !== 'undefined';

		const expandableElemBefore = isSelectable ? (
			<IconButton
				icon={(iconProps) => (
					<ExpandableMenuItemIcon
						iconProps={iconProps}
						isHovering={isHovering}
						isExpanded={isExpanded}
						isSelected={isSelected}
						defaultStateElem={elemBefore}
					/>
				)}
				aria-expanded={isExpanded}
				label={isExpanded ? 'Collapse' : 'Expand'}
				appearance="subtle"
				spacing="compact"
				onClick={handleIconClick}
				interactionName={interactionName}
			/>
		) : (
			<ExpandableMenuItemIcon
				isHovering={isHovering}
				isExpanded={isExpanded}
				isSelected={isSelected}
				defaultStateElem={elemBefore}
			/>
		);

		return (
			// For expandable menu items, we shouldn't wrap in a `li` here. The `li` is instead at a higher level (`ExpandableMenuItem`), grouping the expandable menu item trigger and its content
			// Wrapping in a `Box` so we can add the mouse event handlers, as `Flex` does not support those props
			<Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
				<MenuItemBase
					actions={actions}
					actionsOnHover={actionsOnHover}
					elemBefore={expandableElemBefore}
					ariaExpanded={isExpanded}
					elemAfter={elemAfter}
					href={href}
					isSelected={isSelected}
					onClick={handleMenuContentClick}
					ref={forwardedRef}
					testId={testId}
					interactionName={interactionName}
				>
					{children}
				</MenuItemBase>
			</Box>
		);
	},
);
