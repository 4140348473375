import type { AggTransformerFunction } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/transformer-type.tsx';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { SecurityLevel } from '@atlassian/jira-issue-shared-types/src/common/types/security-level-type.tsx';
import {
	type SECURITY_GQL_FIELD,
	SECURITY_LEVEL_TYPE,
	SECURITY_LEVEL_TYPE_OVERRIDE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { transformAggNodeToLegacyField, transformAggConnection } from './default-node-transformer';

const transformJiraSecurityLevel = (
	securityLevel:
		| {
				readonly description: string | null | undefined;
				readonly name: string | null | undefined;
				readonly securityId: string;
		  }
		| null
		| undefined,
): SecurityLevel | null => {
	if (securityLevel == null) return null;

	return {
		id: securityLevel.securityId,
		description: securityLevel.description || '',
		name: securityLevel.name || '',
	};
};

export const transformAggSecurityLevelToLegacyField: AggTransformerFunction<
	typeof SECURITY_GQL_FIELD
> = (node) => ({
	...transformAggNodeToLegacyField(node),
	value: transformJiraSecurityLevel(node.securityLevel),
	allowedValues: transformAggConnection(node.securityLevels, transformJiraSecurityLevel),
	schema: {
		configuration: null,
		type: SECURITY_LEVEL_TYPE_OVERRIDE,
		renderer: PLAIN_TEXT,
		custom: null,
		system: SECURITY_LEVEL_TYPE,
		items: null,
	},
});
