import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ISSUE_EDIT_STARTED } from '../../../state/actions/issues';
import type { State } from '../../../state/reducers/types';
import { getLockedIssueCountAssumingIssueKeyWillBeLocked } from '../../../state/selectors/field-lock';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(ISSUE_EDIT_STARTED).switchMap((action) => {
		const { fieldType, issueKey } = action.payload;
		const { analyticsEvent } = action.meta;
		const state = store.getState();
		fireOperationalAnalytics(analyticsEvent, 'tableRow locked', {
			fieldType,
			lockedIssuesCount: getLockedIssueCountAssumingIssueKeyWillBeLocked(state, issueKey),
		});
		return Observable.empty<never>();
	});
