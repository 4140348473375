import React, { type ComponentType } from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import { StartExperiencesOnce } from '@atlassian/jira-servicedesk-itsm-onboarding-common';
import { sampleSpaceTourFirstStepExperience } from '@atlassian/jira-servicedesk-itsm-sample-project';
import { projectTourFirstStepExperience } from '../../experiences';
import { IfNavTargetsAreShowing } from './utils';
import type { Props } from './index';

const preloadComponent = () =>
	import(/* webpackChunkName: "itsm-project-tour" */ './index').then(
		(exportedModule) => exportedModule.ProjectTour,
	);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ProjectTourInternal = lazy(() => preloadComponent());

export const ProjectTour: ComponentType<Props> = (props: Props) => (
	<Placeholder name="itsm-project-tour" fallback={<></>}>
		<IfNavTargetsAreShowing>
			{/* We start measuring the experiences from the moment that lazy suspense allows the tours to start loading, and nav targets are visible */}
			<StartExperiencesOnce
				experiences={[projectTourFirstStepExperience, sampleSpaceTourFirstStepExperience]}
			/>
			<ProjectTourInternal {...props} />
		</IfNavTargetsAreShowing>
	</Placeholder>
);
