import { metrics } from '@atlassian/browser-metrics';
import * as marks from '@atlassian/jira-issue-view-common-constants/src/mark-types';

export const issueViewLoad = metrics.custom({
	key: 'issue-view-load',
	virtual: true,
	timings: [
		{
			key: 'legacy-tti-2021',
			startMark: 'metrics_init',
			endMark: marks.ISSUE_LEGACY_INTERACTIVE,
		},
		{
			key: 'timeBlockedOnBackend',
			startMark: marks.ISSUE_READY_FOR_DATA,
			endMark: marks.ISSUE_DATA_AVAILABLE,
		},
	],
});

export const issueViewOnPageLoad = metrics.custom({
	key: 'issue-view-on-page-load',
	timings: [
		{ key: 'issue-mounted', endMark: marks.ISSUE_MOUNTED },
		{ key: 'latest-lcp', endMark: marks.LCP_CANDIDATE },
	],
});

export const embeddedIssueViewLoad = metrics.custom({
	key: 'embedded-issue-view-load',
	until: issueViewLoad,
	include: [issueViewLoad],
});

export const JSMIssueViewLoad = metrics.pageLoad({
	key: 'service-management.queues.issue',
});

export const JSMReportIssueViewLoads = metrics.pageLoad({
	key: 'service-management.reports.issue',
});

export const epicDropdownOpenInteraction = metrics.interaction({
	key: 'issue-epic-dropdown-menu-open',
});

export const epicModalOpenInteraction = metrics.interaction({
	key: 'issue-epic-modal-open',
});
