import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

type PopoutPanelNav4Props = {
	isPoppedOut: boolean;
	popoutComponent: React.ReactNode;
	children: React.ReactNode;
};

const PopoutPanelNav4 = ({ isPoppedOut, popoutComponent, children }: PopoutPanelNav4Props) => (
	<Box xcss={rootContainerStyles}>
		{isPoppedOut && <Box xcss={popoutPanelContainerStyles}>{popoutComponent}</Box>}
		<Box xcss={mainContentContainerStyles}>{children}</Box>
	</Box>
);

export default PopoutPanelNav4;

// Styles
const rootContainerStyles = xcss({
	display: 'flex',
	height: '100%',
	width: '100%',
});

const popoutPanelContainerStyles = xcss({
	borderRightWidth: token('border.width', '1px'),
	borderRightColor: 'color.border',
	borderRightStyle: 'solid',
	paddingRight: 'space.150',
	marginRight: 'space.150',
	minWidth: '280px',
	width: '280px',
	overflowY: 'auto',
});

const mainContentContainerStyles = xcss({
	flexDirection: 'column',
	display: 'flex',
	height: '100%',
	width: '100%',
	overflowX: 'hidden',
});
