import memoizeOne from 'memoize-one';
import queryString from 'query-string';
import { WORKLOG, COMMENTS, PermalinkType } from './constants';

type PermalinkTypeAndId = {
	permalinkType: string;
	permalinkId: string;
};
type PermalinkParameterType = (typeof PermalinkType)[keyof typeof PermalinkType];

export const memoizedPermalinkTypeAndId = memoizeOne(
	(searchString: string): PermalinkTypeAndId | null => {
		const params = queryString.parseUrl(searchString).query;
		if (params[PermalinkType.COMMENTS]) {
			return { permalinkType: COMMENTS, permalinkId: params[PermalinkType.COMMENTS] };
		}
		if (params[PermalinkType.WORKLOG]) {
			return { permalinkType: WORKLOG, permalinkId: params[PermalinkType.WORKLOG] };
		}

		return null;
	},
);

export const getPermalinkTypeAndId = () => memoizedPermalinkTypeAndId(window.location.search);

export const getPermalinkStatus = (targetPermalinkType: string) => {
	const { permalinkId, permalinkType } = getPermalinkTypeAndId() || {};
	const hasPermalink = permalinkType === targetPermalinkType && permalinkId != null;

	return {
		hasPermalink,
		permalinkId: hasPermalink ? permalinkId : '',
	};
};

export const getUrlPermalinkParameter = (id: string, permalinkType: PermalinkParameterType) => {
	const pathName = window.location.pathname;
	let urlQueryParam = `?${permalinkType}=${id}`;

	// in backlogs/modal view, next-gen board view, or jsd queues view
	if (
		/\/jira\/software\/c\/projects\/[^/]*\/boards\/.*/.test(pathName) ||
		/\/jira\/people\/.*\/boards\/.*/.test(pathName) ||
		pathName === '/secure/RapidBoard.jspa' ||
		(pathName.startsWith('/jira/software/projects/') && pathName.split('/')[5] === 'boards') ||
		(pathName.startsWith('/jira/servicedesk/projects/') && pathName.split('/')[5] === 'queues')
	) {
		const url = new URL(window.location.href);

		// delete the opposite to avoid doubling up param in url
		url.searchParams.delete(
			permalinkType === PermalinkType.WORKLOG ? PermalinkType.COMMENTS : PermalinkType.WORKLOG,
		);
		url.searchParams.set(permalinkType, id);
		urlQueryParam = url.search;
	}

	return urlQueryParam;
};

export { WORKLOG, COMMENTS, PermalinkType };
