import React, { useCallback } from 'react';
import Button from '@atlaskit/button/new';
import InlineMessage from '@atlaskit/inline-message';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	columnWidth: number;
};

export const ErrorInlineMessage = ({ columnWidth }: Props) => {
	const onClick = useCallback(() => window.location.reload(), []);
	const { formatMessage } = useIntl();
	return (
		<Box
			paddingBlockStart="space.100"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				width: `${columnWidth}px`,
			})}
		>
			<InlineMessage appearance="error" iconLabel={formatMessage(messages.errorFlagText)}>
				<Stack space="space.100" alignInline="start">
					<Box paddingInlineStart="space.150">
						<FormattedMessage {...messages.errorFlagText} />
					</Box>

					<Button appearance="subtle" onClick={onClick}>
						<Box
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							xcss={xcss({
								color: 'color.text.accent.blue',
							})}
						>
							<FormattedMessage {...messages.errorFlagLinkText} />
						</Box>
					</Button>
				</Stack>
			</InlineMessage>
		</Box>
	);
};
