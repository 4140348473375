import React, { useEffect, type FC } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { PACKAGE_NAME, PACKAGE_TEAM_NAME } from '../common/constants';
import { useSurveyModalController } from '../controllers/use-survey-modal-controller';
import { ImportIssuesButton } from './import-issues-button';
import { SurveyModal } from './survey-modal';

type JSMImporterSurveyProps = {
	isFromEmptyState?: boolean;
};

export const JSMImporterSurvey: FC<JSMImporterSurveyProps> = ({ isFromEmptyState = false }) => {
	const { showModal, onImport, onClose, onSubmit } = useSurveyModalController({
		isFromEmptyState,
	});
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'jsmImporterSurveyButton Shown', {
			isFromEmptyState,
		});
	}, [createAnalyticsEvent, isFromEmptyState]);
	return (
		<JSErrorBoundary id="JSMImporterSurvey" packageName={PACKAGE_NAME} teamName={PACKAGE_TEAM_NAME}>
			<ImportIssuesButton onImport={onImport} />
			<ModalTransition>
				{showModal && <SurveyModal onClose={onClose} onSubmit={onSubmit} />}
			</ModalTransition>
		</JSErrorBoundary>
	);
};
