import type { DataSelectorHtmlProps } from '@atlassian/jira-issue-table/src';
import type { HtmlFieldResponse } from '../../../../../rest/issue/types';

export const htmlTransformer = (storedValue: HtmlFieldResponse): DataSelectorHtmlProps => {
	const { fieldAsHtml: fieldRenderedValue, fieldCssClass } = storedValue;
	return {
		fieldRenderedValue,
		fieldCssClass,
	};
};
