import type { BlockingSpan, BlockingSpanEntry } from '../types';

/**
 * Utility that eliminates overlapping time from a list of possibly overlapping timestamp-spans
 * The returned spans will combine all overlapping times.
 * The input spans must come in pre-sorted by their startTime, or this utility will not work
 */
export function mergeOverlappingBlockingEntries(
	blockingTimestampEntriesSortedByStartTime: BlockingSpanEntry[],
): BlockingSpan[] {
	return blockingTimestampEntriesSortedByStartTime.reduce<BlockingSpan[]>(
		(nonOverlappingSpans, [, { blockingStart, blockingEnd }]) => {
			const lastSpan = nonOverlappingSpans[nonOverlappingSpans.length - 1];
			if (!lastSpan || blockingStart > lastSpan.blockingEnd) {
				nonOverlappingSpans.push({ blockingStart, blockingEnd }); // add the span as-is to the known non-overlapping spans
			} else if (lastSpan.blockingEnd < blockingEnd) {
				lastSpan.blockingEnd = blockingEnd;
			}

			return nonOverlappingSpans;
		},
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		[] as BlockingSpan[],
	);
}
