import forEach from 'lodash/forEach';
import type { Optional } from '../../../../model/optional';
import type { RowId, RowTree, AnchorPosition } from '../../../../model/rows';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <
	T extends {
		anchorId?: RowId;
		position: AnchorPosition;
	},
>(
	parentRowId: RowId,
	baseRowIds: RowId[],
	rowTree: RowTree,
	anchoredRows: Optional<Record<RowId, T>>,
): RowId[] => {
	if (!anchoredRows) {
		return baseRowIds;
	}

	const result = [...baseRowIds];

	// for each added row, insert based on the anchor
	forEach(anchoredRows, (anchorRow: T, anchoredRowId: RowId) => {
		const { anchorId, position } = anchorRow;

		if (position === 'FIRST') {
			result.unshift(anchoredRowId);
		} else if (position === 'LAST') {
			result.push(anchoredRowId);
		} else if (anchorId) {
			const anchorRowLocation = result.indexOf(anchorId);
			// the anchor row may not be in the set of currently visible things
			if (anchorRowLocation !== -1) {
				if (position === 'BEFORE') {
					result.splice(anchorRowLocation, 0, anchoredRowId);
				} else if (position === 'AFTER') {
					const spliceOffset = 1;
					result.splice(anchorRowLocation + spliceOffset, 0, anchoredRowId);
				}
			}
			if (position === 'INSIDE' && anchorId === parentRowId) {
				result.splice(anchorRowLocation + 1, 0, anchoredRowId);
			}
		}
	});

	return result;
};
