export const IMPORT_URL = '/secure/admin/ExternalImport1.jspa';

export const LOCAL_STORAGE_ID = 'jsm-importer-survey';
export const LOCAL_STORAGE_KEY = 'modal-response-added';
// add the values here also if you are adding more options to the dropdown question "What are you importing?"
export const IMPORT_OPTIONS_VALUES = [
	'issues',
	'users',
	'customerOrganizations',
	'customFields',
	'other',
];
