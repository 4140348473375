import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { SET_CONSUMER_STATE } from '../../../state/consumer/actions';
import { getVerticalScrollOffsetChangedCallback } from '../../../state/consumer/selectors';
import { SET_VERTICAL_SCROLL_OFFSET, SET_TABLE_SIZE } from '../../../state/internal/actions';
import {
	getVirtualBoundaries,
	getOffsetFromBottom,
	getOffsetFromTop,
} from '../../../state/selectors';
import type { State } from '../../../state/types';
import { withUnpackedBatchActions } from '../../common/batch';
import { errorHandlerFactory } from '../../common/errors';

const DEBOUNCE_MILLIS = 16;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) => {
	const errorHandler = errorHandlerFactory();
	return (
		withUnpackedBatchActions(action$)
			// @ts-expect-error - TS2339 - Property 'ofType' does not exist on type 'Observable<any>'.
			.ofType(SET_VERTICAL_SCROLL_OFFSET, SET_TABLE_SIZE, SET_CONSUMER_STATE)
			.debounceTime(DEBOUNCE_MILLIS)
			.mergeMap(() => {
				const state = store.getState();
				const onVerticalScrollOffsetChanged = getVerticalScrollOffsetChangedCallback(state);

				if (onVerticalScrollOffsetChanged) {
					const virtualBoundaries = getVirtualBoundaries(state);
					onVerticalScrollOffsetChanged({
						...virtualBoundaries,
						offsetFromTop: getOffsetFromTop(state),
						offsetFromBottom: getOffsetFromBottom(state),
					});
				}

				return Observable.empty<never>();
			})
			.catch(errorHandler)
	);
};
