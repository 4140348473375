import type { ProjectKey } from '@atlassian/jira-shared-types';
import type { StoreApi, Permissions } from './types';

export const setProjectPermissions =
	(projectKey: ProjectKey, permissions: Permissions) =>
	({ setState }: StoreApi) => {
		setState({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[projectKey as string]: permissions,
		});
	};
