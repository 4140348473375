import { setConsumerState } from '../../../state/consumer/actions';
import { getAddedRows, getRowTree } from '../../../state/consumer/selectors/rows';
import {
	setSortedRowIdsHash,
	setColumnSortOrder,
	setScrollbarSize,
} from '../../../state/internal/actions';
import {
	getSortedRowIdsHash,
	getIsSortOverrideEnabled,
	getActiveSortedColumnConfiguration,
} from '../../../state/internal/selectors';
import getSortedInternalState from '../../common/sorting';
import { defineTransaction } from '../../common/transactions';
import { setHeaderHeight } from '../../header/size';
import { setRowBorderWidth } from '../../rows/row-border';
import { SET_STATE_FROM_PROPS, type SetStateFromPropsAction } from './action';

const getScrollbarSize = (): number => {
	if (document.body) {
		const { body } = document;
		const scrollbarContainer = document.createElement('div');
		scrollbarContainer.setAttribute(
			'style',
			`position: absolute;
             width: 100px;
             height: 100px;
             overflow: scroll;
             top: -9999px;`,
		);

		body.appendChild(scrollbarContainer);
		// Pick arbitrary axis, both should have the same bar size
		const scrollbarSize = scrollbarContainer.offsetWidth - scrollbarContainer.clientWidth;
		body.removeChild(scrollbarContainer);

		return scrollbarSize;
	}

	return 0;
};

export default defineTransaction(SET_STATE_FROM_PROPS, (action: SetStateFromPropsAction, state) => {
	const { apiState, theme } = action.payload;
	const { defaultSortedColumn, ...consumerState } = apiState;

	const sortedRowIdsHash = getSortedInternalState(
		getAddedRows(state),
		getRowTree(state),
		getActiveSortedColumnConfiguration(state),

		getIsSortOverrideEnabled(state),
		defaultSortedColumn,
		getSortedRowIdsHash(state),

		consumerState.addedRows,
		consumerState.rowTree,
		consumerState.columnComparators,
		consumerState.defaultComparator,
	);

	const setActions = [
		setConsumerState(consumerState),
		setSortedRowIdsHash(sortedRowIdsHash),
		setHeaderHeight(theme.header.height),
		setRowBorderWidth(theme.row.borderWidth),
	];

	if (consumerState.autoRowHeight) {
		// @ts-expect-error - TS2345 - Argument of type 'SetScrollbarSizeAction' is not assignable to parameter of type 'SetSortedRowIdsHashAction | SetHeaderHeightAction | SetConsumerStateAction | SetRowBorderWidthAction'.
		setActions.push(setScrollbarSize(getScrollbarSize()));
	}

	if (defaultSortedColumn) {
		// @ts-expect-error - TS2345 - Argument of type 'SetColumnSortOrderAction' is not assignable to parameter of type 'SetSortedRowIdsHashAction | SetHeaderHeightAction | SetConsumerStateAction | SetRowBorderWidthAction'.
		setActions.push(setColumnSortOrder(defaultSortedColumn));
	}

	consumerState.callbacks.onSortedRowIdsChanged &&
		consumerState.callbacks.onSortedRowIdsChanged(sortedRowIdsHash);

	return setActions;
});
