import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category';
import { SET_ENABLED_PRACTICES, type SetEnabledPracticesAction } from '../../../actions/practices';

export type PracticesState = ItsmPractice[];

const defaultPracticesState: PracticesState = [];

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: PracticesState = defaultPracticesState,
	action: SetEnabledPracticesAction,
) => {
	switch (action.type) {
		case SET_ENABLED_PRACTICES: {
			return action.payload.practices;
		}
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
