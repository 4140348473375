// CHANGE_ISSUE_FIELD_VALUE

import type { JsonFieldResponse } from '../../../../rest/issue/fields/types';

export const CHANGE_ISSUE_FIELD_VALUE =
	'state.actions.issue.field.CHANGE_ISSUE_FIELD_VALUE' as const;

export type ChangeIssueFieldValueAction = {
	type: typeof CHANGE_ISSUE_FIELD_VALUE;
	payload: {
		issueKey: string;
		columnId: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		newValue: Partial<JsonFieldResponse<any>>;
	};
};

export const changeIssueFieldValueAction = (
	issueKey: string,
	columnId: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	newValue: Partial<JsonFieldResponse<any>>,
): ChangeIssueFieldValueAction => ({
	type: CHANGE_ISSUE_FIELD_VALUE,
	payload: {
		issueKey,
		columnId,
		newValue,
	},
});

export type ChangeIssueFieldActionDispatcher = (
	issueKey: string,
	columnId: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	newValue: Partial<JsonFieldResponse<any>>,
) => void;
