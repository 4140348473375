// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ReactNode } from 'react';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics';
import { virtualTableScrollReporter } from '@atlassian/jira-common-long-task-metrics/src/reporters/virtual-table-scroll';
import { withTheme } from '../../../../../app/context/theme-context';
import { scrollbarWidth } from '../../../../../constants';
import type { VirtualBoundaries } from '../../../../../model';
import type { CompiledTheme } from '../../../../../model/themes';
import Scrollbars, { type ScrollValues, type ScrollbarsRef } from '../common/scrollbars';

type Props = {
	viewportHeight: number;
	scrollTop: number;
	defaultRowHeight: number;
	rowCount: number;
	visibleColumnCount: number;
	virtualBoundaries: VirtualBoundaries;
	facade: string;
	isVirtualised: boolean;
	setVerticalScrollOffset: (offset: number) => void;
	children: ReactNode;
	isHorizontalScrollVisible: boolean;
	showBottomDecal: boolean;
	showTooltipOnScroll: boolean;
	theme: CompiledTheme;
};

// eslint-disable-next-line jira/react/no-class-components
class VerticalScrollbar extends PureComponent<Props> {
	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { scrollRef } = this;
		// Changing scrollTop will prevent continuation of the scrolling
		// animation in some browsers, so make sure we're not already in a
		// scroll event before setting it.
		if (scrollRef && !this.isScrolling) {
			scrollRef.scrollTop(nextProps.scrollTop);
		}
	}

	onVerticalScroll = ({ scrollTop }: ScrollValues) => {
		const { setVerticalScrollOffset } = this.props;
		setVerticalScrollOffset(scrollTop);
	};

	onScrollStart = () => {
		const {
			isVirtualised,
			facade,
			visibleColumnCount,
			virtualBoundaries: { displayStart, displayEnd },
		} = this.props;
		getLongTasksMetrics('scroll').start('virtual-table', undefined, {
			isVirtualised,
			visibleColumnCount,
			facade,
			displayedRows: displayEnd - displayStart,
		});
		this.isScrolling = true;
	};

	onScrollStop = () => {
		this.isScrolling = false;
		getLongTasksMetrics('scroll').stop(virtualTableScrollReporter);
	};

	setScrollRef = (ref: ScrollbarsRef) => {
		this.scrollRef = ref;
	};

	setTargetRef = (ref?: HTMLDivElement | null) => {
		this.targetRef = ref;
	};

	calculateHeight = () => {
		const { isHorizontalScrollVisible, theme, viewportHeight } = this.props;
		if (theme.scrollbar.computeHeight && isHorizontalScrollVisible) {
			const spaceTakenByHorizontalScrollbar =
				scrollbarWidth + theme.scrollbar.marginTop + theme.scrollbar.marginBottom;

			return viewportHeight - spaceTakenByHorizontalScrollbar;
		}
		return viewportHeight;
	};

	// @ts-expect-error - TS2564 - Property 'scrollRef' has no initializer and is not definitely assigned in the constructor.
	scrollRef: ScrollbarsRef;

	targetRef: HTMLDivElement | undefined | null;

	// @ts-expect-error - TS2564 - Property 'isScrolling' has no initializer and is not definitely assigned in the constructor.
	isScrolling: boolean;

	render() {
		const { children } = this.props;

		return (
			<div
				data-testid="virtual-table.table.content.scrollbars.vertical.virtual-table-scrollbar-wrapper"
				ref={this.setTargetRef}
			>
				<Scrollbars
					onScrollbarsRef={this.setScrollRef}
					height={this.calculateHeight()}
					onScrollStart={this.onScrollStart}
					onScrollStop={this.onScrollStop}
					onScrollFrame={this.onVerticalScroll}
				>
					{children}
				</Scrollbars>
			</div>
		);
	}
}

export default withTheme(VerticalScrollbar);
