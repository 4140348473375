import { connect } from '@atlassian/jira-react-redux';
import type { State } from '../../../../state/reducers/types';
import {
	getExperienceTrackingInitialFetchStatus,
	getInitialFetchErrorEventAttributes,
} from '../../../../state/selectors/experience-tracking';
import Tracker from './view';

const mapStateToProps = (state: State) => ({
	initialFetchStatus: getExperienceTrackingInitialFetchStatus(state),
	initialFetchFailureEventAttributes: getInitialFetchErrorEventAttributes(state),
});

export default connect(mapStateToProps, {})(Tracker);
