import React from 'react';
import type { SENTIMENT_CUSTOM_FIELD_TYPE } from '../../../model/fields/json-fields/custom-fields/types';
import type { JsonComponentProps } from '../../../model/fields/types';
import { UnselectableChildGutterPaddingContainer } from '../common/styled';
import { ReadView } from './view';

type Props = JsonComponentProps<typeof SENTIMENT_CUSTOM_FIELD_TYPE>;

const Sentiment = ({
	dataSelectorProps: { value },
	tableInjectedProps: { isLastColumn },
}: Props) =>
	value ? (
		<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
			<ReadView id={value.id} name={value.name} />
		</UnselectableChildGutterPaddingContainer>
	) : null;

export default Sentiment;
