import { ff } from '@atlassian/jira-feature-flagging';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { AggVersionNode } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/fields-type.tsx';
import type { Version } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import { VersionStatus } from '../constants';

type VersionWithARI = { ari: string } & Version;

export const transformAggVersionToLegacyVersionOld = (
	version: Pick<AggVersionNode, 'versionId' | 'name' | 'description' | 'status'>,
): Version => ({
	id: version.versionId ?? '',
	name: version.name ?? '',
	description: version.description ?? '',
	archived: version.status === VersionStatus.ARCHIVED,
	released: version.status === VersionStatus.RELEASED,
});

export const transformAggVersionToLegacyVersion = (
	version: Pick<AggVersionNode, 'versionId' | 'name' | 'description' | 'status' | 'id'>,
): VersionWithARI => ({
	id: version.versionId ?? '',
	name: version.name ?? '',
	description: version.description ?? '',
	archived: version.status === VersionStatus.ARCHIVED,
	released: version.status === VersionStatus.RELEASED,
	ari: version.id,
});

/**
 * @deprecated this is a test facing helper
 */
export const transformAggVersionToLegacyVersionSelector = functionWithCondition(
	() => ff('issue.details.relay-release-version-field-issue-view-integration'),
	transformAggVersionToLegacyVersion,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	transformAggVersionToLegacyVersionOld as any,
);
