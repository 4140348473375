import isNil from 'lodash/isNil';
import type { Action } from '@atlassian/react-sweet-state';
import { isDemoProject } from '../../../../common/utils';
import { initialIsDemoProjectState } from '../../constants';
import type { ActionProps, IsDemoProjectStoreState } from '../../types';

export const fetchIsDemoProject =
	({ baseUrl, projectId }: ActionProps): Action<IsDemoProjectStoreState> =>
	// @ts-expect-error - TS2322 - Type '({ getState, setState }: StoreActionApi<Partial<Record<string, DataState<boolean>>>>) => () => Promise<void>' is not assignable to type 'Action<Partial<Record<string, DataState<boolean>>>, void, void | Promise<void>>'.
	({ getState, setState }) =>
	async () => {
		const projectState = getState()[projectId] ?? initialIsDemoProjectState;
		if (!isNil(projectState.data) || projectState.loading) {
			return;
		}

		setState({
			...getState(),
			[String(projectId)]: {
				...projectState,
				loading: true,
			},
		});

		try {
			const isDemo = await isDemoProject({ baseUrl, projectId });
			setState({
				...getState(),
				[String(projectId)]: {
					...projectState,
					data: isDemo,
					error: null,
					loading: false,
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...getState(),
				[String(projectId)]: {
					...projectState,
					error,
					loading: false,
				},
			});

			throw error;
		}
	};
