import { useMemo, useRef } from 'react';
import noop from 'lodash/noop';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import {
	useLeftSidebarState,
	useLeftSidebarController,
} from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/left-sidebar-controller/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout/src/common/utils/get-will-show-nav4/index.tsx';
import { useToggleSideNav } from '@atlassian/navigation-system/layout/side-nav';
import type { UIControllerSubscriberProps, UIControllerInterface } from './types';

// Nav3
const UIControllerSubscriberOld = ({ children }: UIControllerSubscriberProps) => {
	const { collapseLeftSidebar, expandLeftSidebar } = useLeftSidebarController();
	const { isCollapsed, width } = useLeftSidebarState();

	const stateRef = useRef<UIControllerInterface>({
		state: {
			isCollapsed,
			isResizing: false,
			isResizeDisabled: false,
			productNavWidth: width,
		},
		collapse: collapseLeftSidebar,
		expand: expandLeftSidebar,
		toggleCollapse: () => (isCollapsed ? expandLeftSidebar() : collapseLeftSidebar()),
	});

	const navigationUIController = useMemo<UIControllerInterface>(
		() => ({
			state: {
				isCollapsed,
				isResizing: false,
				isResizeDisabled: false,
				productNavWidth: width,
			},
			collapse: collapseLeftSidebar,
			expand: expandLeftSidebar,
			toggleCollapse: () => (isCollapsed ? expandLeftSidebar() : collapseLeftSidebar()),
		}),
		[collapseLeftSidebar, expandLeftSidebar, isCollapsed, width],
	);

	stateRef.current = Object.assign(stateRef.current, navigationUIController);

	return children(stateRef.current);
};

const defaultState: UIControllerInterface = {
	state: {
		isCollapsed: false,
		isResizing: false,
		isResizeDisabled: false,
		productNavWidth: 0,
	},
	collapse: noop,
	expand: noop,
	toggleCollapse: noop,
};

// Nav4
const UIControllerSubscriberNew = ({ children }: UIControllerSubscriberProps) => {
	const toggleSideNav = useToggleSideNav();

	const stateRef = useRef<UIControllerInterface>({
		...defaultState,
		toggleCollapse: toggleSideNav,
	});

	const navigationUIController = useMemo<UIControllerInterface>(
		() => ({
			...defaultState,
			toggleCollapse: toggleSideNav,
		}),
		[toggleSideNav],
	);

	stateRef.current = Object.assign(stateRef.current, navigationUIController);

	return children(stateRef.current);
};

export const UIControllerSubscriber = componentWithCondition(
	getWillShowNav4,
	UIControllerSubscriberNew,
	UIControllerSubscriberOld,
);
