import performance, { timeBetweenMarks } from '@atlassian/jira-common-performance';
import {
	fetchCommentsPerf,
	fetchFieldDataPerf,
	fetchIssueLinkPerf,
	fetchIssuePropertiesPerf,
	fetchSubtaskTypesPerf,
	fetchUploadPerf,
	fetchViewContextPerf,
	fetchUserAuthPerf,
} from '@atlassian/jira-issue-view-common-constants';

const fetchIssueNetworkMarks = [
	fetchUploadPerf,
	fetchCommentsPerf,
	fetchIssuePropertiesPerf,
	fetchIssueLinkPerf,
	fetchViewContextPerf,
	fetchSubtaskTypesPerf,
	fetchFieldDataPerf,
	fetchUserAuthPerf,
];

// Data remains the same once called once (for the same issue), so we cache it
// here for future requests for this data due to the calculation wiping the
//  performance marks required to calculate.  It does however need to be reset
// in cases like subtask click throughs when we load a new issue.
let fetchIssueNetworkTimes: Record<string, number> | null;

const calculateFetchIssueNetworkTimes = () => {
	const issueNetworkTimes = fetchIssueNetworkMarks.reduce<Record<string, number>>((obj, perf) => {
		const beginMarkName = `BEGIN_${perf.prefix}_${perf.key}`;
		const endMarkName = `END_${perf.prefix}_${perf.key}`;
		const networkTime = timeBetweenMarks(beginMarkName, endMarkName);
		if (networkTime != null && networkTime > 0) {
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			const aggregated = { ...obj, [`${perf.key}_NETWORK`]: networkTime };

			// This is vital to clear mark after use.  Or next call will failed to add the mark of same name.
			if (performance.clearMarks) {
				performance.clearMarks(beginMarkName);
				performance.clearMarks(endMarkName);
			}
			return aggregated;
		}
		return obj;
	}, {});

	return issueNetworkTimes;
};

/*
 * This clears the current cached value of fetchIssueNetworkTimes, and should be
 * called when a new issue is being loaded inside Bento (without a full page load).
 * One such example is in the issue-navigation-epic, which is called when a user
 * clicks through to an issue's subtask.
 */
export const clearFetchIssueNetworkTimes = (): void => {
	fetchIssueNetworkMarks.forEach((perf) => {
		const beginMarkName = `BEGIN_${perf.prefix}_${perf.key}`;
		const endMarkName = `END_${perf.prefix}_${perf.key}`;
		if (performance.clearMarks) {
			performance.clearMarks(beginMarkName);
			performance.clearMarks(endMarkName);
		}
	});
	fetchIssueNetworkTimes = null;
	window.ISSUE_DATA_AVAILABLE = null;
};

export const getFetchIssueNetworkTimes = () => {
	if (!fetchIssueNetworkTimes) {
		fetchIssueNetworkTimes = calculateFetchIssueNetworkTimes();
	}

	return fetchIssueNetworkTimes;
};
