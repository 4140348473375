import type { AggTransformerFunction } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/transformer-type.tsx';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { MULTI_GROUP_PICKER_GQL_FIELD } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { transformAggConnection, transformAggNodeToLegacyField } from './default-node-transformer';

export const transformAggMultiGroupPickerToLegacyField: AggTransformerFunction<
	typeof MULTI_GROUP_PICKER_GQL_FIELD
> = (node) => {
	const value = transformAggConnection(node.selectedGroupsConnection, (groupNode) => ({
		groupId: groupNode.groupId,
		name: groupNode.name,
	}));

	return {
		...transformAggNodeToLegacyField(node),
		value: value.length > 0 ? value : null,
		schema: {
			type: 'array',
			renderer: PLAIN_TEXT,
			custom: node.type,
			system: null,
			items: 'group',
			configuration: null,
		},
	};
};
