import type { Observable } from 'rxjs/Observable';
import { fetchJson$ } from '@atlassian/jira-servicedesk-common/src/utils/fetch';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types';
import type { QueueUpdate } from '../../services/update-queues/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (projectKey: string, baseUrl: string, payload: QueueUpdate): Observable<Page[]> =>
	fetchJson$(`${baseUrl}/rest/servicedesk/1/servicedesk/${projectKey}/queues`, {
		method: 'PUT',
		body: JSON.stringify(payload),
	});
