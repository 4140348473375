import { createSelector } from 'reselect';
import { fg } from '@atlassian/jira-feature-gating';
import { type ExperienceResult, EXPERIENCE_FAILED } from '../../../common/constants';
import type { ExperienceTrackingState } from '../../reducers/persisted/experience-tracking';
import type { State } from '../../reducers/types';
import { getPersisted } from '../common';

export const getExperienceTracking = (state: State): ExperienceTrackingState =>
	getPersisted(state).experienceTracking;

export const getExperienceTrackingInitialFetchStatus = (state: State): ExperienceResult =>
	getExperienceTracking(state).initialDataLoadStatus;

export const getHasLoadedIssueDataFromCache = (state: State): boolean =>
	getExperienceTracking(state).hasLoadedIssueDataFromCache;

const getErrorStatusCode = (state: State): number | undefined =>
	getExperienceTracking(state).errorStatusCode;

const getErrorMessage = (state: State): { errorMessage: string }[] | undefined =>
	getExperienceTracking(state).errorMessages;

const getErrorKey = (state: State): string | undefined => getExperienceTracking(state).errorKey;

const getTraceId = (state: State): string | undefined => getExperienceTracking(state).traceId;

export type FailureEventAttributes = {
	[key: string]: string | boolean | number;
};

const constructErrorMessage = (
	statusCode?: number,
	errorMessage?: { errorMessage: string }[] | undefined,
	errorKey?: string,
): string =>
	`Initial fetch failed status code: ${statusCode}, error: ${JSON.stringify(errorMessage)}, errorKey: ${errorKey}`;

export const getInitialFetchErrorEventAttributes = createSelector(
	getExperienceTrackingInitialFetchStatus,
	getHasLoadedIssueDataFromCache,
	getErrorStatusCode,
	getErrorMessage,
	getErrorKey,
	getTraceId,
	(
		initialDataLoadStatus,
		hasLoadedIssueDataFromCache,
		errorStatusCode,
		errorMessage,
		errorKey,
		traceId,
	): FailureEventAttributes | undefined => {
		if (initialDataLoadStatus === EXPERIENCE_FAILED) {
			if (fg('adding-more-data-for-viewqueue-and-viewnav-event')) {
				return {
					didFallbackOnIssueCache: hasLoadedIssueDataFromCache,
					errorMessage: constructErrorMessage(errorStatusCode, errorMessage, errorKey),
					traceId: traceId || 'no-trace-id',
				};
			}
			return {
				didFallbackOnIssueCache: hasLoadedIssueDataFromCache,
				errorMessage:
					errorStatusCode !== undefined
						? `Initial fetch failed with status code ${errorStatusCode}`
						: 'Initial fetch failed',
			};
		}
		return undefined;
	},
);
