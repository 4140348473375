import React, { type ReactNode } from 'react';
import { Breadcrumb, BreadcrumbManager, ProjectBreadcrumb } from '@atlassian/jira-breadcrumbs/src';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import { usePracticeList } from '@atlassian/jira-servicedesk-queues-categorized-store';
import type { QueueCategory } from '@atlassian/jira-servicedesk-queues-common/src/model';
import {
	INCIDENTS,
	CHANGES,
	SERVICE_REQUEST,
	PROBLEMS,
	POST_INCIDENT_REVIEWS,
} from '@atlassian/jira-servicedesk-work-category';
import practiceMessages from '@atlassian/jira-servicedesk-work-category/src/common/messages';
import messages from './messages';

type Props = {
	children: ReactNode;
	projectKey: string;
	projectName: string;
	baseUrl: string;
	href: string;
	text: string;
	type: string;
	category: QueueCategory;
	intl: IntlShape;
};

const getBreadcrumbText = (category: QueueCategory) => {
	if (!category) {
		return messages.queues;
	}
	switch (category) {
		case INCIDENTS:
			return practiceMessages.incidents;
		case CHANGES:
			return practiceMessages.changes;
		case SERVICE_REQUEST:
			return practiceMessages.serviceRequests;
		case PROBLEMS:
			return practiceMessages.problems;
		case POST_INCIDENT_REVIEWS:
			return practiceMessages.postIncidentReviews;
		default:
			return practiceMessages.queues;
	}
};

const QueuesBreadcrumbManager = ({
	children,
	projectKey,
	projectName,
	baseUrl,
	href,
	text,
	type,
	category,
	intl: { formatMessage },
}: Props) => {
	const [practiceList] = usePracticeList();
	const hasPractices = Boolean(practiceList && practiceList.length > 1);

	return (
		<BreadcrumbManager>
			<ProjectBreadcrumb baseUrl={baseUrl} projectKey={projectKey} projectName={projectName}>
				<Breadcrumb
					breadcrumb={{
						href,
						text: text || formatMessage(getBreadcrumbText(hasPractices ? category : undefined)),
						type,
					}}
				>
					{children}
				</Breadcrumb>
			</ProjectBreadcrumb>
		</BreadcrumbManager>
	);
};

QueuesBreadcrumbManager.defaultProps = {
	type: 'queues',
	text: '',
	category: undefined,
};

export default QueuesBreadcrumbManager;
