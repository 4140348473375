import type { State } from '../../reducers/types';
import { getPersisted } from '../common';

export const getCurrentFilterQuery = (state: State): string | null =>
	getPersisted(state).filter.filterQuery;

export const getIsCurrentDataFiltered = (state: State): boolean =>
	getPersisted(state).filter.isCurrentDataFiltered;

export const getIsNewFilterQueryPending = (state: State): boolean =>
	getPersisted(state).filter.isNewFilterQueryPending;

export const getIsFilterFocused = (state: State): boolean =>
	getPersisted(state).filter.isFilterFocused;
