import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import invert from 'lodash/invert';
import reject from 'lodash/reject';
import { type SortOrder, ASCENDING, type SortOrderConfiguration } from '../../../model/columns';
import type { Optional } from '../../../model/optional';
import {
	sortedRowIdsHashRootId,
	type RowId,
	type RowTree,
	type PersistedAddedRows,
	type SortedRowIdsHash,
} from '../../../model/rows';
import type { SortConfiguration, Comparator, ComparatorHash } from '../../../model/sorting';
import insertAnchoredRowIds from './utils/insert-anchored-row-ids';

const sort = (
	rowIds: RowId[],
	sortOrder: SortOrder,
	comparator: (rowId1: RowId, rowId2: RowId) => number,
) => [...rowIds].sort(comparator);

const sortRowIds = (
	rowIds: RowId[],
	sortConfiguration: SortConfiguration,
	lastSortedRowIds: RowId[],
) => {
	if (rowIds.length === 0) {
		return rowIds;
	}

	const { consumerComparator, sortOrder, isSortOverrideEnabled } = sortConfiguration;

	const sortAwareConsumerComparator = consumerComparator(sortOrder);

	// either this is the first time sorting, or the user has explicitly sorted the table
	// sort as expressed by the consumer
	if (!lastSortedRowIds || !isSortOverrideEnabled) {
		return sort(rowIds, sortOrder, sortAwareConsumerComparator);
	}

	// 2...Nth time sorting
	// any update that isn't an explicit sort we want to maintain order based on the previous
	const lastRowIdsHash = invert(lastSortedRowIds);

	const internalComparator = (rowId1: RowId, rowId2: RowId) => {
		// both ids were already present, sort in their old older
		if (lastRowIdsHash[rowId1] !== undefined && lastRowIdsHash[rowId2] !== undefined) {
			return Number(lastRowIdsHash[rowId1]) < Number(lastRowIdsHash[rowId2]) ? -1 : 1;
		}
		// one of the ids is new, sort as expected by the consumer (including order)
		return sortAwareConsumerComparator(rowId1, rowId2);
	};

	return [...rowIds].sort(internalComparator);
};

type LookupHash = {
	[key: string]: true;
};

const toLookupHash = (array?: string[]): LookupHash => {
	if (!array) {
		return {};
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const hash: Record<string, any> = {};
	forEach(
		filter(array, (element) => !!element),
		// eslint-disable-next-line no-return-assign
		(element) => (hash[element] = true),
	);
	return hash;
};

const getRowIdsWithoutAddedRows = (
	rowIds: RowId[],
	sortConfiguration: SortConfiguration,
	persistedAddedRows: Optional<PersistedAddedRows>,
	lastSortedRowIds: RowId[],
) => {
	if (sortConfiguration.isSortOverrideEnabled) {
		const lastSortedRowIdsHash = toLookupHash(lastSortedRowIds);
		return reject(
			rowIds,
			(rowId) =>
				persistedAddedRows !== undefined &&
				persistedAddedRows[rowId] !== undefined &&
				lastSortedRowIdsHash[rowId] === undefined,
		);
	}
	return rowIds;
};

const traverseRows = (
	rowTree: RowTree,
	parentRowId: RowId,
	rowIds: RowId[],
	sortConfiguration: SortConfiguration,
	result: Record<RowId, RowId[]>,
	persistedAddedRows: Optional<PersistedAddedRows>,
	sortedRowIdsHash: SortedRowIdsHash,
) => {
	if (rowIds === undefined || rowIds.length === 0) {
		return;
	}

	// filter out added-rows, as they shouldn't be sorted but just inserted afterwards according to their position/anchor
	const sortableRowIds = getRowIdsWithoutAddedRows(
		rowIds,
		sortConfiguration,
		persistedAddedRows,
		sortedRowIdsHash[parentRowId] || [],
	);

	const sortedRowIds: RowId[] = sortRowIds(
		sortableRowIds,
		sortConfiguration,
		sortedRowIdsHash[parentRowId] || [],
	);

	const reSortedRowIds: RowId[] = insertAnchoredRowIds(
		parentRowId,
		sortedRowIds,
		rowTree,
		persistedAddedRows,
	);

	// eslint-disable-next-line no-param-reassign
	result[parentRowId] = reSortedRowIds;

	reSortedRowIds.forEach((rowId) => {
		const { childrenIds } = rowTree.rows[rowId];

		traverseRows(
			rowTree,
			rowId,
			childrenIds,
			sortConfiguration,
			result,
			persistedAddedRows,
			sortedRowIdsHash,
		);
	});
};

export const sortRowTree = (
	rowTree: RowTree,
	sortConfiguration: SortConfiguration,
	persistedAddedRows: Optional<PersistedAddedRows>,
	sortedRowIdsHash: SortedRowIdsHash,
): Record<RowId, RowId[]> => {
	const result: Record<RowId, RowId[]> = {};

	traverseRows(
		rowTree,
		sortedRowIdsHashRootId,
		rowTree.rootIds,
		sortConfiguration,
		result,
		persistedAddedRows,
		sortedRowIdsHash,
	);

	return result;
};

const DEFAULT_SORT_ORDER_FOR_COMPARATORS = ASCENDING;

const getSortConfiguration = (
	columnComparators: ComparatorHash,
	// @ts-expect-error - TS7006 - Parameter 'activeSortedColumnConfiguration' implicitly has an 'any' type.
	activeSortedColumnConfiguration,
	defaultComparator: Comparator,
	isSortOverrideEnabled: boolean,
): SortConfiguration => {
	// are we sorting by a particular comparator
	const activeSortedColumnComparator =
		activeSortedColumnConfiguration && columnComparators
			? columnComparators[activeSortedColumnConfiguration.columnId]
			: undefined;

	// if we are not, use the default comparator
	const consumerComparator = activeSortedColumnComparator || defaultComparator;

	// which of the sort tri-state are we in
	// this is where the tri-state ASC/DESC/UNDEF is reduced to ASC/DESC to
	// keep the tri-state hidden from actual comparator implementations
	const sortOrder = activeSortedColumnConfiguration
		? activeSortedColumnConfiguration.sortOrder
		: DEFAULT_SORT_ORDER_FOR_COMPARATORS;

	return {
		consumerComparator,
		sortOrder,
		isSortOverrideEnabled,
	};
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	rowTree,
	persistedAddedRows,
	columnComparators,
	activeSortedColumnConfiguration,
	isSortOverrideEnabled,
	defaultComparator,
	sortedRowIdsHash,
}: {
	rowTree: RowTree;
	persistedAddedRows: Optional<PersistedAddedRows>;
	columnComparators: ComparatorHash;
	activeSortedColumnConfiguration: Optional<SortOrderConfiguration>;
	isSortOverrideEnabled: boolean;
	defaultComparator: Optional<Comparator>;
	sortedRowIdsHash: SortedRowIdsHash;
}): SortedRowIdsHash => {
	if (!defaultComparator) {
		return {
			[sortedRowIdsHashRootId]: rowTree.rootIds,
		};
	}

	const sortConfiguration = getSortConfiguration(
		columnComparators,
		activeSortedColumnConfiguration,
		defaultComparator,
		isSortOverrideEnabled,
	);
	return sortRowTree(rowTree, sortConfiguration, persistedAddedRows, sortedRowIdsHash);
};
