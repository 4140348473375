import { connect } from '@atlassian/jira-react-redux';
import type { State } from '../../../state/reducers/types';
import { isRetrying } from '../../../state/selectors/ui';
import ErrorState from './view';

const reloadWindow = () => window.location.reload();

const mapStateToProps = (state: State) => ({
	isLoading: isRetrying(state),
});

const mapDispatchToProps = () => ({
	onRetry: reloadWindow,
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorState);
