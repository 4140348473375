import React, { Component, type ReactNode, type ComponentType } from 'react';
import type { Store, Reducer, Middleware } from 'redux';
import { type Epic, combineEpics } from 'redux-observable';
// eslint-disable-next-line jira/restricted/prop-types
import PropTypes from 'prop-types';
import AppBase from '@atlassian/jira-app-base';
import AppStyle from '@atlassian/jira-common-components-app-style';
import type { AppPropsWithProjectKey, BaseState } from '../../../model/types';
import {
	createMonolithNavigationBridge,
	type ServiceDeskNavigationBridge,
} from '../../../navigation/bridge';
import createMonolithNavigationMiddleware from '../../../navigation/bridge/epic';
import { withExtraMiddleware } from '../../../redux/extra-middleware';
import type { AppName } from '../../../utils/app-names';
import { ServiceDeskErrorBoundary } from '../servicedesk-error-boundary';
import { createStore } from './create-store';

export type ServiceDeskSubAppProps = {
	appName: AppName;
	initialState: BaseState<AppPropsWithProjectKey>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rootReducer: Reducer<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rootEpic: Epic<any, any>;
	children: ReactNode | null;
	extraMiddleware: Middleware[];
	onErrorHandler: (arg1: string | Error) => undefined | undefined;
	ErrorView?: ComponentType<{}>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	resetProjectContextAction?: (projectContext: AppPropsWithProjectKey) => any;
};

// eslint-disable-next-line jira/react/no-class-components
class ServiceDeskSubApp extends Component<ServiceDeskSubAppProps> {
	// go/jfe-eslint
	// eslint-disable-next-line react/sort-comp
	static defaultProps = {
		children: null,
		onErrorHandler: null,
	};

	static childContextTypes = {
		monolithNavigationBridge: PropTypes.object.isRequired,
	};

	constructor(props: ServiceDeskSubAppProps) {
		super(props);

		this.monolithNavigationBridge = createMonolithNavigationBridge();
		const navigationBridgeEpic = createMonolithNavigationMiddleware(
			this.onNavigationConfirm,
			this.onNavigationReject,
		);

		this.store = createStore(
			this.props.appName,
			this.props.initialState,
			this.props.rootReducer,
			this.props.extraMiddleware,
			combineEpics(navigationBridgeEpic, this.props.rootEpic),
		);
	}

	getChildContext() {
		return {
			monolithNavigationBridge: this.monolithNavigationBridge,
		};
	}

	shouldComponentUpdate(nextProps: ServiceDeskSubAppProps): boolean {
		const { appName, rootReducer, rootEpic, children, extraMiddleware, onErrorHandler } =
			this.props;
		// If any prop other than initialState has changed
		return (
			appName !== nextProps.appName ||
			rootReducer !== nextProps.rootReducer ||
			rootEpic !== nextProps.rootEpic ||
			children !== nextProps.children ||
			extraMiddleware !== nextProps.extraMiddleware ||
			onErrorHandler !== nextProps.onErrorHandler
		);
	}

	// This state update must happens before render() is called to avoid the wrong queue from loading
	UNSAFE_componentWillUpdate(nextProps: Readonly<ServiceDeskSubAppProps>) {
		this.resetAppPropsState(
			this.props.initialState.appProps.projectKey,
			nextProps.initialState.appProps.projectKey,
			nextProps.initialState.appProps,
		);
	}

	componentWillUnmount() {
		this.monolithNavigationBridge && this.monolithNavigationBridge.disable();
	}

	resetAppPropsState(
		currentProjectKey: string,
		nextProjectKey: string,
		appProps: AppPropsWithProjectKey,
	) {
		const { resetProjectContextAction } = this.props;

		if (!resetProjectContextAction) {
			return;
		}

		if (currentProjectKey !== nextProjectKey) {
			this.store.dispatch(resetProjectContextAction(appProps));
		}
	}

	onNavigationReject = () => {
		this.monolithNavigationBridge && this.monolithNavigationBridge.rejectNavigation();
	};

	onNavigationConfirm = () => {
		this.monolithNavigationBridge && this.monolithNavigationBridge.confirmNavigation();
	};

	monolithNavigationBridge: ServiceDeskNavigationBridge;

	store: Store<BaseState>;

	render() {
		const { children, appName, onErrorHandler, ErrorView } = this.props;
		// We put the error boundary below <AppBase>, to intercept errors before the error boundary
		// provided by AppBase
		return (
			<AppBase store={this.store}>
				<AppStyle>
					<ServiceDeskErrorBoundary
						appName={appName}
						onErrorHandler={onErrorHandler}
						ErrorView={ErrorView}
					>
						{children}
					</ServiceDeskErrorBoundary>
				</AppStyle>
			</AppBase>
		);
	}
}

export default withExtraMiddleware(ServiceDeskSubApp);
