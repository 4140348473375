import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { BreadcrumbRenderer } from '@atlassian/jira-breadcrumbs/src';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';

const QueuesBreadcrumbRendererLegacy = () => (
	<Box xcss={wrapperStyles}>
		<BreadcrumbRenderer />
	</Box>
);

const QueuesBreadcrumbRendererNew = () => <BreadcrumbRenderer />;

export default componentWithFG(
	'jsm-fission-decompose-queue-page-header',
	QueuesBreadcrumbRendererNew,
	QueuesBreadcrumbRendererLegacy,
);

const wrapperStyles = xcss({
	/* negates margin added by original component */
	marginLeft: 'space.negative.050',
});
