import type { BatchAction } from 'redux-batched-actions';
import { combineEpics, type Epic } from 'redux-observable';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons';
import type { Action } from '../../state/actions/types';
import type { State } from '../../state/reducers/types';
import getEpic from './get';
import internalEpic from './internal';
import openIssue from './open';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (push: QueuesPush): Epic<Action | BatchAction, State> =>
	combineEpics(internalEpic, getEpic, openIssue(push));
