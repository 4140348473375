import {
	type ActiveCell,
	type ActiveRow,
	ACTIVE_CELL_TYPE,
	ACTIVE_ROW_TYPE,
} from '../../../../model/navigation';

export const SET_ACTIVE_CELL = 'ops.items.active.set.SET_ACTIVE_CELL' as const;
export const SET_ACTIVE_ROW = 'ops.items.active.set.SET_ACTIVE_ROW' as const;

export type SetActiveCellAction = {
	type: typeof SET_ACTIVE_CELL;
	payload: ActiveCell;
};

export type SetActiveRowAction = {
	type: typeof SET_ACTIVE_ROW;
	payload: ActiveRow;
};

export const setActiveCell = (
	payload: Flow.Diff<
		ActiveCell,
		{
			type: typeof ACTIVE_CELL_TYPE;
		}
	>,
): SetActiveCellAction => ({
	type: SET_ACTIVE_CELL,
	payload: {
		type: ACTIVE_CELL_TYPE,
		...payload,
	},
});

export const setActiveRow = (
	payload: Flow.Diff<
		ActiveRow,
		{
			type: typeof ACTIVE_ROW_TYPE;
		}
	>,
): SetActiveRowAction => ({
	type: SET_ACTIVE_ROW,
	payload: { type: ACTIVE_ROW_TYPE, setFocusOnRow: false, ...payload },
});
