import { ff } from '@atlassian/jira-feature-flagging';
import type { AggTransformerFunction } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/transformer-type.tsx';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { CASCADING_SELECT_GQL_FIELD } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { transformAggConnection, transformAggNodeToLegacyField } from './default-node-transformer';

export const transformAggCascadingSelectToLegacyField: AggTransformerFunction<
	typeof CASCADING_SELECT_GQL_FIELD
> = (node) => {
	let value = null;

	if (node.cascadingOption) {
		const { childOptionValue, parentOptionValue } = node.cascadingOption;

		const child = childOptionValue
			? {
					child: {
						id: childOptionValue.optionId,
						value: childOptionValue.value,
					},
				}
			: {};

		value = {
			// assigned in the statement above
			id: parentOptionValue?.optionId,
			value: parentOptionValue?.value,
			...child,
		};
	}
	const allowedValues = ff(
		'issue.details.relay-cascading-select-field-issue-view-layout-integration',
	)
		? []
		: transformAggConnection(node.cascadingOptions, (cascadingOption) => {
				if (!cascadingOption.parentOptionValue) return null;

				const { parentOptionValue, childOptionValues } = cascadingOption;

				const childrenObj = childOptionValues?.length
					? {
							children: childOptionValues.filter(Boolean).map((option) => ({
								id: option.optionId,
								value: option.value ?? null,
								ari: option.id,
							})),
						}
					: {};

				return {
					value: parentOptionValue.value,
					id: parentOptionValue.optionId,
					...childrenObj,
				};
			});

	return {
		...transformAggNodeToLegacyField(node),
		value,
		allowedValues,
		schema: {
			type: 'option-with-child',
			renderer: PLAIN_TEXT,
			custom: node.type,
			system: null,
			items: null,
			configuration: null,
		},
	};
};
