import React, { type FC } from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import type { ButtonClickProps } from '../../common/types';
import messages from './messages';

type ImportIssuesButtonProps = {
	onImport: ButtonClickProps;
};

export const ImportIssuesButton: FC<ImportIssuesButtonProps> = ({ onImport }) => {
	const { formatMessage } = useIntl();
	return <Button onClick={onImport}>{formatMessage(messages.importIssuesButtonLabel)}</Button>;
};
