import flow from 'lodash/flow';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import type { State } from '../../../state/reducers/types';
import { getIsCurrentDataFiltered } from '../../../state/selectors/filter';
import { getIssueCount } from '../../../state/selectors/issues';
import { getProjectStateHash } from '../../../state/selectors/update-metadata';
import NavUpdater from './view';

const mapStateToProps = (state: State) => ({
	issueCount: getIssueCount(state),
	projectStateHash: getProjectStateHash(state),
	isCurrentDataFiltered: getIsCurrentDataFiltered(state),
});

export default flow(
	connect(mapStateToProps, {}),
	AnalyticsEventToProps('navUpdate', {
		onProjectStateHashUpdate: 'queuesCountsRefreshed',
	}),
)(NavUpdater);
