import React from 'react';
import ErrorState from '@atlassian/jira-common-components-error-state';
import { injectIntlV2 as injectIntl, type IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	onRetry?: () => void;
	intl: IntlShape;
};

const onReloadWindow = () => {
	window.location.reload();
};

function Error(props: Props) {
	const {
		onRetry,
		intl,
		intl: { formatMessage },
	} = props;

	return (
		<ErrorState
			// @ts-expect-error - TS2322 - Type '{ intl: IntlShapeV2; onRetry: (() => void) | undefined; header: string; description: string; retryMessage: MessageDescriptorV2; }' is not assignable to type 'IntrinsicAttributes & Omit<Pick<Props, "description" | "intl" | "onRetry" | "imageWidth" | "maxImageHeight" | "errorImage"> & Partial<Pick<Props, "header" | ... 3 more ... | "isSubtle">> & Partial<...>, "intl"> & { ...; } & { ...; }'. Caused by 2nd party lib
			intl={intl}
			onRetry={onRetry}
			header={formatMessage(messages.errorHeader)}
			description={formatMessage(messages.errorMessage)}
			retryMessage={messages.retry}
		/>
	);
}

Error.defaultProps = {
	onRetry: onReloadWindow,
};

export const QueuesErrorView = injectIntl(Error);
