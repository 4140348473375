import { connect } from '../../../../../../common/table-redux';
import { getVisibleRowIds } from '../../../../../../read-ops/rows';
import type { State } from '../../../../../../state/types';
import List from '../common/base-list';
import AutoHeightList from './index-dumb';

export default connect(
	(state: State) => ({
		rowIds: getVisibleRowIds(state),
		List,
	}),
	{},
)(AutoHeightList);
