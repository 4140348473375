import { useContext, useRef } from 'react';
import find from 'lodash/find';
import values from 'lodash/values';
import {
	FavoriteChangeContext,
	type ItemMap,
	QUEUES_ITEM_TYPE,
} from '@atlassian/jira-favourite-change-provider';
import { ff } from '@atlassian/jira-feature-flagging';
import type { NavItem } from '@atlassian/jira-servicedesk-queues-categorized-store/src/types';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category';

type EditItemFavoriteCallback = (id: string, value: boolean) => void;
type RefreshIssueCountForItemsCallback = (ids: string[]) => void;
type WatchProps = {
	projectKey: string;
	category: ItsmPractice;
	changedItems: ItemMap | undefined;
};

const updateQueueUsingCallback = (
	queues: NavItem[] | null,
	queueItemChanges: ItemMap,
	editItemFavoriteCallback: EditItemFavoriteCallback,
	refreshIssueCountForItemsCallback: RefreshIssueCountForItemsCallback,
) => {
	if (queues) {
		const itemsToRefresh: string[] = [];
		values(queueItemChanges).forEach(({ id, value }) => {
			const queue = find(queues, { id });

			if (queue && queue.starred !== value) {
				const isHiddenQueueStarred = queue.canBeHidden === true && value === true;
				editItemFavoriteCallback(id, value);

				if (isHiddenQueueStarred) {
					itemsToRefresh.push(id);
				}
			}
		});
		if (!ff('jsm-queues-nav4-refresh-m2-dogfooding_rh43y')) {
			if (itemsToRefresh && itemsToRefresh.length > 0) {
				refreshIssueCountForItemsCallback(itemsToRefresh);
			}
		}
	}
};

export const useOnFavoritesChanged = (
	projectKey: string,
	category: ItsmPractice,
	queues: NavItem[] | null,
	editItemFavoriteCallback: EditItemFavoriteCallback,
	refreshIssueCountForItemsCallback: RefreshIssueCountForItemsCallback,
) => {
	const { items } = useContext(FavoriteChangeContext);
	const queueItemChanges = items[QUEUES_ITEM_TYPE];
	const previousChangesRef = useRef<WatchProps>({
		projectKey,
		category,
		changedItems: undefined,
	});
	const { current: previousChanges } = previousChangesRef;
	const hasPotentialChanges =
		previousChanges.projectKey !== projectKey ||
		previousChanges.category !== category ||
		previousChanges.changedItems !== queueItemChanges;

	if (hasPotentialChanges) {
		previousChangesRef.current = {
			projectKey,
			category,
			changedItems: queueItemChanges,
		};
		updateQueueUsingCallback(
			queues,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			queueItemChanges as ItemMap,
			editItemFavoriteCallback,
			refreshIssueCountForItemsCallback,
		);
	}
};
