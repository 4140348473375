import { useIssueKey } from '@atlassian/jira-issue-context-service';
import { useFieldValue } from '@atlassian/jira-issue-field-base';
import { SERVICEDESK_PRACTICES_TYPE } from '@atlassian/jira-platform-field-config';
import {
	useServiceDeskContext,
	USER_JSM_PROJECT_ROLE,
} from '@atlassian/jira-servicedesk-spa-commons';
import {
	DEVELOPER_ESCALATION,
	INCIDENTS,
	POST_INCIDENT_REVIEWS,
} from '@atlassian/jira-servicedesk-work-category';
import type { IssueKey } from '@atlassian/jira-shared-types';

// A more granularly DI-able useFieldValue for injecting just practices values.
export const usePracticesFieldValue = ({ issueKey }: { issueKey: IssueKey }) =>
	useFieldValue({ issueKey, fieldKey: SERVICEDESK_PRACTICES_TYPE });

export const useIsAgentOrProjectAdmin = () => {
	const [{ data, error }] = useServiceDeskContext();
	if (error) return false;
	return (
		data?.userRole === USER_JSM_PROJECT_ROLE.AGENT_ROLE ||
		data?.userRole === USER_JSM_PROJECT_ROLE.AGENT_PROJECT_ADMIN_ROLE
	);
};

export const useIsIssueOfIncidentsPractice = () => {
	const issueKey = useIssueKey();
	const [practices] = usePracticesFieldValue({ issueKey });
	return Array.isArray(practices) && practices?.includes(INCIDENTS);
};

export const useIsIssueOfPostIncidentReviewPractice = () => {
	const issueKey = useIssueKey();
	const [practices] = usePracticesFieldValue({ issueKey });
	return Array.isArray(practices) && practices?.includes(POST_INCIDENT_REVIEWS);
};

export const useIsIssueOfDeveloperEscalationPractice = () => {
	const issueKey = useIssueKey();
	const [practices] = usePracticesFieldValue({ issueKey });
	return Array.isArray(practices) && practices?.includes(DEVELOPER_ESCALATION);
};
