import React from 'react';
import {
	IssueStatusFieldReadonly,
	type StatusValue,
} from '@atlassian/jira-issue-field-status/async';
import type { Props } from '../types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ value }: Props) => {
	const status: StatusValue = {
		id: value.id,
		name: value.name,
		statusCategory: value.statusCategory,
	};
	return <IssueStatusFieldReadonly value={status} />;
};
