import type { BlockedOnBackendEntryLog } from '../../common/entry-log';
import { mergeOverlappingBlockingEntries } from '../../common/merge-overlapping-entries';

/**
 * From the known times spent blocking on backend data, generate a simplified report of a start and duration
 */
export const getSimplifiedBlockingTime = (
	entryLog: BlockedOnBackendEntryLog,
): {
	/**
	 * This will take the earliest blocking start time of the entries in the log
	 */
	blockingStart: number;
	/**
	 * This will sum together all of the known spans (eliminating overlap time)
	 */
	totalBlockingDuration: number;
} => {
	const blockingTimestampEntries = entryLog.getBlockedOnBackendEntries({ sorted: true });
	const blockingSpans = mergeOverlappingBlockingEntries(blockingTimestampEntries);
	const blockingStartTimes = blockingSpans.map(({ blockingStart }) => blockingStart ?? 0);
	const blockingDurations = blockingSpans.map(
		({ blockingEnd, blockingStart }) => blockingEnd - blockingStart,
	);

	const blockingStart = Math.min(...(blockingStartTimes.length ? blockingStartTimes : [0]));
	const totalBlockingDuration = blockingDurations.reduce((a, b) => a + b, 0);
	return { blockingStart, totalBlockingDuration };
};
