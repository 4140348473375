import type { ComponentType, ReactNode } from 'react';
import type { CellComponentType } from '../cell-component';
import type { Identifier } from '../identifiers';
import type { Optional } from '../optional';
import type { TreeNode } from '../tree';

export type ColumnId = Identifier;

export type HiddenColumns = ColumnId[];

export type ColumnTitleType = ReactNode;

export type HeaderComponentProps = {
	columnId: ColumnId;
	title: ColumnTitleType;
	width: number;
};

export type HeaderComponentType = ComponentType<HeaderComponentProps>;

export const DYNAMIC_RESIZE = 'DYNAMIC_RESIZE' as const;
export const DRAGGABLE_RESIZE = 'DRAGGABLE_RESIZE' as const;

export const SUMMARY = 'summary';

// FSN-4987 Remove resize strategy related logic during cleanup
export type ResizeStrategy = typeof DYNAMIC_RESIZE | typeof DRAGGABLE_RESIZE;

export type Column = TreeNode<ColumnId> & {
	id: ColumnId;
	childrenIds: Optional<ColumnId[]>;
	CellComponent: CellComponentType;
	HeaderComponent: HeaderComponentType | undefined;
	defaultWidth: number;
	minWidth: number;
	maxWidth: number;
	title: ColumnTitleType;
	tooltip: Optional<string | Element>;
	color: Optional<string>;
	resizeStrategy: ResizeStrategy;
	isHideable: boolean;
	isDraggable: boolean;
	isMarkup: boolean;
	isDefaultAdd: boolean;
	canBeMultiLine: boolean;
};

export type ColumnTree = {
	columns: Record<ColumnId, Column>;
	rootCoreIds: ColumnId[];
	rootAdditionalIds: ColumnId[];
};

export type ColumnConfiguration = {
	id: ColumnId;
	title: ColumnTitleType;
	resizeStrategy?: ResizeStrategy;
	isHidden: boolean;
	isDisabled: boolean;
	parentId: Optional<ColumnId>;
	isParentHidden: Optional<boolean>;
	depth: number;
};

export type ColumnIdHash = Record<ColumnId, boolean>;

export const ASCENDING = 'ASCENDING' as const;
export const DESCENDING = 'DESCENDING' as const;

export type SortOrder = typeof ASCENDING | typeof DESCENDING;

export type SortOrderConfiguration = {
	columnId: ColumnId;
	sortOrder: SortOrder;
};

export type ExpandConfiguration = {
	columnId: ColumnId;
	shouldExpand: boolean;
};
