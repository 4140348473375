// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import type { DispatchExternalAction, ExternalAction } from './external-actions';

type ModifyIssueViewPayload = {
	issueKey: string;
	externalAction: ExternalAction;
};

type EventsModule = {
	bind: <T>(key: string, callback: (event: unknown, payload: T) => void) => void;
};

export const listenToExternalActionFromLegacy = (
	getDispatcherAndModifiedIssue: () => {
		dispatchExternalAction: DispatchExternalAction | null | undefined;
		modifiedIssueIssueKey: string | null;
	},
) => {
	// eslint-disable-next-line jira/wrm/no-load-bridge
	loadBridge<EventsModule>({
		name: 'jira/util/events',
		wrmKeys: 'wrc!jira.webresources:jira-events',
	}).then((Events) => {
		Events.bind<ModifyIssueViewPayload>('modify-issue-view-via-external-action', (e, payload) => {
			const { issueKey, externalAction } = payload;
			const { dispatchExternalAction, modifiedIssueIssueKey } = getDispatcherAndModifiedIssue();
			if (issueKey === modifiedIssueIssueKey && externalAction && dispatchExternalAction) {
				dispatchExternalAction(externalAction);
			}
		});
	});
};
