import createRequestService from '../create-request';
import getCreatedRequestsService from '../get-created-requests';
import getNextRequestAliasService from '../get-next-request-alias';
import type { CreateNextRequestPayload, CreateNextRequestService } from './types';

const service: CreateNextRequestService = async ({
	baseUrl,
	projectId,
	analyticsEvent,
}: CreateNextRequestPayload) => {
	const createdAliases = await getCreatedRequestsService({ baseUrl, projectId });
	const requestAlias = getNextRequestAliasService({ createdAliases });

	if (!requestAlias) {
		return;
	}

	await createRequestService({
		baseUrl,
		projectId,
		requestAlias,
		analyticsEvent,
	});
};

export default service;
