import { connect } from '@atlassian/jira-react-redux';
import AsyncPagination from './async-pagination';
import Pagination from './view';

export default connect(
	() => ({
		Pagination: AsyncPagination,
	}),
	{},
)(Pagination);
