import { combineEpics } from 'redux-observable';
import addAnchor from './add-anchor';
import analytics from './analytics/epic';
import navigateToRow from './navigate/epic';
import setRowBorderWidth from './row-border/epic';
import setCalculatedRowHeight from './row-height/epic';

export default combineEpics(
	addAnchor,
	navigateToRow,
	setCalculatedRowHeight,
	setRowBorderWidth,
	analytics,
);
