import React from 'react';
import { MAJOR_INCIDENT, MajorIncidentLozenge } from '@atlassian/jira-major-incident';
import type { MAJOR_INCIDENT_CUSTOM_FIELD_TYPE } from '../../../model/fields/json-fields/custom-fields/types';
import type { JsonComponentProps } from '../../../model/fields/types';

type Props = JsonComponentProps<typeof MAJOR_INCIDENT_CUSTOM_FIELD_TYPE>;

const MajorIncident = ({ dataSelectorProps: { value } }: Props) =>
	value === MAJOR_INCIDENT ? <MajorIncidentLozenge /> : null;

export default MajorIncident;
