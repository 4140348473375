import type { TreeNode } from '../../model/tree';
import getTreePath from './get-tree-path';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <T extends TreeNode<string>>(
	tree: {
		[key: string]: T;
	},
	id: string,
): number => getTreePath(tree, id).length - 1;
