import type {
	AggUserNode,
	AggUserNodeType,
} from '@atlassian/jira-issue-agg-field-transformers/src/common/types/fields-type.tsx';
import type { ServerRestUser } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import {
	type AccountType,
	type AvatarUrls,
	APP_USER,
	ATLASSIAN_ACCOUNT_USER,
	CUSTOMER_USER,
	UNKNOWN_USER,
} from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';

type OptionalKeysMap = Partial<Record<keyof ServerRestUser, keyof NonNullable<AggUserNode>>>;

type ServerRestUserType = Partial<ServerRestUser> & {
	accountId: string;
	displayName: string;
	active: boolean;
	accountType: AccountType;
};

const getAccountType = (objectType?: string): AccountType => {
	switch (objectType) {
		case 'AtlassianAccountUser':
			return ATLASSIAN_ACCOUNT_USER;
		case 'CustomerUser':
			return CUSTOMER_USER;
		case 'AppUser':
			return APP_USER;
		default:
			return UNKNOWN_USER;
	}
};

const generateAvatarUrls = (url: string): AvatarUrls => ({
	'16x16': url,
	'24x24': url,
	'32x32': url,
	'48x48': url,
});

export const transformJiraUserToLegacyServerUser = (
	user?: AggUserNodeType,
): ServerRestUserType | null => {
	if (!user) return null;

	const userOptionalKeysMap: OptionalKeysMap = {
		emailAddress: 'email',
		locale: 'locale',
		timeZone: 'zoneinfo',
	};

	const optionalValues: Partial<ServerRestUser> = {};

	Object.entries(userOptionalKeysMap).forEach(([key, aggKey]) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const aggValue = user[aggKey as keyof AggUserNode];

		if (aggValue !== undefined && aggValue !== null) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			optionalValues[key as keyof ServerRestUser] = aggValue;
		}
	});

	const avatars =
		user.picture != null
			? {
					avatarUrl: user.picture,
					avatarUrls: generateAvatarUrls(user.picture),
				}
			: {};

	return {
		...avatars,
		...optionalValues,
		accountId: user.accountId,
		accountType: getAccountType(user.__typename),
		active: user.accountStatus === 'active',
		displayName: user.name,
	};
};
