import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import Lozenge from '@atlaskit/lozenge';
import Tooltip from '@atlaskit/tooltip';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useJsmMimEntitlementResource } from '@atlassian/jira-router-resources-jsm-mim-entitlement';
import { useCloudId } from '@atlassian/jira-tenant-context-controller';
import { MAJOR_INCIDENT } from '../../common/constants';
import { useMajorIncidentField } from '../../services/use-major-incident';
import messages from './messages';

export const MajorIncidentLozenge = () => {
	const { formatMessage } = useIntl();
	return <Lozenge appearance="removed">{formatMessage(messages.lozengeLabel)}</Lozenge>;
};

export const MajorIncidentLozengeWithTooltip = () => {
	const { formatMessage } = useIntl();
	const { isEntitled: isMIMEntitled } = useJsmMimEntitlementResource(useCloudId());
	const issueKey = useIssueKey();
	const [{ value }] = useMajorIncidentField({ issueKey });
	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const shouldRenderLozenge = ff('im_feature_enablement_gating')
		? isMIMEntitled && isIssueOfIncidentsPractice && value === MAJOR_INCIDENT
		: isIssueOfIncidentsPractice && value === MAJOR_INCIDENT;

	useEffect(() => {
		if (shouldRenderLozenge) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'majorIncidentLozenge viewed', {
				action: 'viewed',
				actionSubjectId: 'majorIncidentLozenge',
				teamName: 'jsm-getting-started',
			});
		}
	}, [createAnalyticsEvent, shouldRenderLozenge]);

	return shouldRenderLozenge ? (
		<LozengeWrapper>
			<Tooltip content={formatMessage(messages.tootltipContent)}>
				<MajorIncidentLozenge />
			</Tooltip>
		</LozengeWrapper>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.div({
	display: 'flex',
});
