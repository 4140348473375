import { colors } from '@atlaskit/theme';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { createTheme } from '../../view/themes';

export default createTheme({
	list: {
		paddingTop: gridSize / 2,
	},
	row: {
		height: 0, // 0 will render 'auto'
		minHeight: gridSize * 5,
		borderColor: 'transparent',
		borderRadius: gridSize / 2,
		borderWidth: 0,
		maxDepthChildrenOperationIndicatorWidth: 0,
		activeColor: colors.N20,
		hoveredColor: colors.N20,
		useCssHover: true,
	},
	header: {
		height: 0,
		borderColor: colors.N50,
		borderWidth: 2,
		paddingBottom: gridSize / 2,
		paddingTop: gridSize,
		sideMargin: gridSize,
		hasShadow: true,
	},
	scrollbar: {
		offset: gridSize * 4,
		marginTop: gridSize + gridSize / 2,
		marginBottom: gridSize,
		paddingTop: gridSize,
		paddingBottom: gridSize / 2,
		computeHeight: true,
	},
	shadow: {
		softenShadow: true,
		right: {
			display: 'block',
		},
		left: {
			borderWidth: 0,
			computeHeight: true,
		},
		bottom: {
			display: 'block',
		},
	},
	decal: {
		borderColor: colors.N50,
		borderWidth: 2,
	},
});
