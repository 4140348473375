import React, { type ComponentType } from 'react';
import type {
	ColumnId,
	ColumnTitleType,
	HeaderComponentType,
} from '../../../../../../../model/columns';
import type { Optional } from '../../../../../../../model/optional';

export default function DefaultColumn({
	Column,
	id,
	title,
	HeaderComponent,
	tooltip,
	width,
	isChild,
	isParent,
	parentId,
	isFirstColumn,
}: {
	id: string;
	title: ColumnTitleType;
	HeaderComponent: HeaderComponentType | undefined;
	tooltip: Optional<string | Element>;
	width: number;
	isChild: boolean;
	isParent: boolean;
	parentId: Optional<ColumnId>;
	isFirstColumn: boolean;
	Column: ComponentType<{
		id: ColumnId;
		title: ColumnTitleType;
		HeaderComponent: HeaderComponentType | undefined;
		tooltip: Optional<string | Element>;
		width: number;
		isChild: boolean;
		isParent: boolean;
		parentId: Optional<ColumnId>;
		isFirstColumn: boolean;
	}>;
}) {
	return (
		<Column
			id={id}
			title={title}
			HeaderComponent={HeaderComponent}
			tooltip={tooltip}
			width={width}
			isChild={isChild}
			isParent={isParent}
			parentId={parentId}
			isFirstColumn={isFirstColumn}
		/>
	);
}
