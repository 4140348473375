import { connect } from '@atlassian/jira-react-redux';
import type { State } from '../../../../state/reducers/types';
import { getQueueId } from '../../../../state/selectors/queue';
import { isQueueVisible } from '../../../../state/selectors/ui';
import Provider from './view';

const mapStateToProps = (state: State) => ({
	queueId: getQueueId(state),
	isQueueVisible: isQueueVisible(state),
});

export default connect(mapStateToProps, {})(Provider);
