import {
	CHANGES,
	INCIDENTS,
	PROBLEMS,
	SERVICE_REQUEST,
	POST_INCIDENT_REVIEWS,
	NO_CATEGORY,
} from '@atlassian/jira-servicedesk-work-category';
import { PRACTICE_DATA } from '../../constants';
import commonMessages from '../messages';
import messages from './messages';

export { MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH } from '../../constants';

export const EMPTY_STATE_DATA = {
	[SERVICE_REQUEST]: {
		headerMessage: messages.serviceRequestsHeader,
		descriptionMessage: messages.serviceRequestsDescription,
		imageUrl: PRACTICE_DATA[SERVICE_REQUEST].imageUrl,
		getImageUrl: PRACTICE_DATA[SERVICE_REQUEST].getImageUrl,
		learnMoreUrl: PRACTICE_DATA[SERVICE_REQUEST].learnMoreUrl,
		learnMoreText: commonMessages.serviceRequestsLearnMore,
	},
	[INCIDENTS]: {
		headerMessage: messages.incidentsHeader,
		descriptionMessage: messages.incidentsDescription,
		imageUrl: PRACTICE_DATA[INCIDENTS].imageUrl,
		getImageUrl: PRACTICE_DATA[INCIDENTS].getImageUrl,
		learnMoreUrl: PRACTICE_DATA[INCIDENTS].learnMoreUrl,
		learnMoreText: commonMessages.incidentsLearnMore,
	},
	[PROBLEMS]: {
		headerMessage: messages.problemsHeader,
		descriptionMessage: messages.problemsDescription,
		imageUrl: PRACTICE_DATA[PROBLEMS].imageUrl,
		getImageUrl: PRACTICE_DATA[PROBLEMS].getImageUrl,
		learnMoreUrl: PRACTICE_DATA[PROBLEMS].learnMoreUrl,
		learnMoreText: commonMessages.problemsLearnMore,
	},
	[CHANGES]: {
		headerMessage: messages.changesHeader,
		descriptionMessage: messages.changesDescription,
		imageUrl: PRACTICE_DATA[CHANGES].imageUrl,
		getImageUrl: PRACTICE_DATA[CHANGES].getImageUrl,
		learnMoreUrl: PRACTICE_DATA[CHANGES].learnMoreUrl,
		learnMoreText: commonMessages.changesLearnMore,
	},
	[POST_INCIDENT_REVIEWS]: {
		headerMessage: messages.postIncidentReviewsHeader,
		descriptionMessage: messages.postIncidentReviewsDescription,
		imageUrl: PRACTICE_DATA[POST_INCIDENT_REVIEWS].imageUrl,
		getImageUrl: PRACTICE_DATA[POST_INCIDENT_REVIEWS].getImageUrl,
		learnMoreUrl: PRACTICE_DATA[POST_INCIDENT_REVIEWS].learnMoreUrl,
		learnMoreText: commonMessages.postIncidentReviewsLearnMore,
	},
	// NO_CATEGORY will never be a valid case in case of unconfigured queue, need to do this to keep Flow happy
	[NO_CATEGORY]: {
		...PRACTICE_DATA[NO_CATEGORY],
		learnMoreText: undefined,
	},
} as const;
