/**
 * Opt into the new issue view modal key. To be cleaned up with issue.details.opt-back-in
 * This key is stored in the API and should not be renamed.
 * @see https://hello.atlassian.net/wiki/spaces/JIE/pages/920124493/How+to+re-set+new+Bento+Change+Boarding#Re-set-Opt-In-Back-Modal
 */
export const OPT_IN_BACK_REMINDING_DATE_USER_PROPERTIES_KEY =
	'jira.details.opt-in-back.next-reminding-date';

export const SHOULD_SHOW_WELCOME_MESSAGE_USER_PROPERTIES_KEY =
	'jira.user.issue.should.show.welcome.message';

export const HAS_DISMISSED_OPT_IN_MODAL_KEY = 'jira.user.issue.opt.in.back.modal.has.dismissed';
export const HAS_DISMISSED_OPT_IN_BANNER_KEY = 'jira.user.issue.opt.in.back.banner.has.dismissed';

export const MESSAGE_ID = 'issue-details-changeboarding';

export const DEFAULT_REMINDING_DAY_NUM = 30;

// Change boarding steps
export const SOFTWARE_ISSUE_STATUS = 'SOFTWARE_ISSUE_STATUS' as const;
export const SERVICEDESK_ISSUE_STATUS = 'SERVICEDESK_ISSUE_STATUS' as const;
/** The big collapsible JSM panel that includes the description field */
export const SERVICEDESK_REQUEST_PANEL = 'SERVICEDESK_REQUEST_PANEL' as const;
/** The "Add internal note / Reply to customer" buttons */
export const SERVICEDESK_CREATE_COMMENT_ACTIONS = 'SERVICEDESK_CREATE_COMMENT_ACTIONS' as const;
export const SERVICEDESK_PLACEHOLDER_CREATE_COMMENT_ACTIONS =
	'SERVICEDESK_PLACEHOLDER_CREATE_COMMENT_ACTIONS' as const;
export const ISSUE_STATUS = 'status-spotlight' as const;
export const SHOW_MORE = 'SHOW_MORE' as const;
export const PIN_FIELD = 'PIN_FIELD' as const;
export const EDIT_FIELD = 'EDIT_FIELD' as const;
export const PERMALINK = 'PERMALINK' as const;
export const BOARD_SIDEBAR = 'BOARD_SIDEBAR' as const;
/** "Details" panel in the sidebar (assignee/labels/reporter etc) */
export const DETAILS_PANEL =
	'issue-view-layout-templates-views.ui.context.visible-hidden.ui.context-group.container.details-group';
/** The attach/add child/link issue bar just under the issue title */
export const QUICK_ADDS = 'QUICK_ADDS' as const;
/** The toggle buttons above the comments section */
export const JSW_ACTIVITY_FEED_BUTTONS = 'JSW_ACTIVITY_FEED_BUTTONS' as const;
/** "SLA" panel in sidebar specific to JSM */
export const SERVICEDESK_SLA_PANEL =
	'issue-view-layout-templates-views.ui.context.visible-hidden.ui.context-group.container.sla-group';
/**
 * The dismissable "pin field" promotional banner.
 *
 * This placeholder is always in the DOM when no fields have been pinned yet, however the banner can
 * be dismissed by the user. So you must mock the spotlight target to be sure.
 */
export const PIN_FIELD_PLACEHOLDER_BANNER = 'PIN_FIELD_PLACEHOLDER_BANNER' as const;
/** The "pin field" context panel. This only exists when there are pinned fields */
export const PINNED_FIELDS_PANEL = 'PINNED_FIELDS_PANEL' as const;
