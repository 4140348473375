import React from 'react';
import { usePathParam } from '@atlassian/jira-router';
import { PopoutPanelNav4 } from '@atlassian/jira-servicedesk-popout-panel-nav4';
import {
	PracticeQueues,
	getPoppedOutStateKey,
	usePoppedOutState,
} from '@atlassian/jira-servicedesk-queues-nav4';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category';

interface QueuesPopoutPanelNav4Props {
	children: React.ReactNode;
}

const QueuesPopoutPanelNav4 = ({ children }: QueuesPopoutPanelNav4Props) => {
	const [projectKeyFromPath] = usePathParam('projectKey');
	const [practiceFromPath] = usePathParam('practiceType');
	const categoryFromPath = toItsmPractice(practiceFromPath);

	// TODO: Updated to a dynamic hook value in [FSN-5149]
	const [isPoppedOut] = usePoppedOutState(
		getPoppedOutStateKey(projectKeyFromPath || '', categoryFromPath),
	);

	const practiceQueues = (
		<PracticeQueues projectKey={projectKeyFromPath || ''} practice={categoryFromPath} />
	);

	return (
		<PopoutPanelNav4 isPoppedOut={isPoppedOut} popoutComponent={practiceQueues}>
			{children}
		</PopoutPanelNav4>
	);
};

export default QueuesPopoutPanelNav4;
