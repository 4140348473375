import findIndex from 'lodash/findIndex';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types';

export const addItem = (source: Page[], item: Page): Page[] => [...source, item];

export const deleteItem = (source: Page[], id: string | number): Page[] => {
	const result = source.slice();
	const index = findIndex(result, { id: Number(id) });

	if (index >= 0) {
		result.splice(index, 1);
	}

	return result;
};

export const editItem = (source: Page[], updatedItem: Partial<Page>): Page[] => {
	const result = source.slice();
	const index = findIndex(result, { id: updatedItem.id });

	if (index >= 0) {
		result[index] = { ...result[index], ...updatedItem };
	}

	return result;
};

export const editItems = (source: Page[], updatedItems: Partial<Page>[]): Page[] => {
	const result = source.slice();
	updatedItems.forEach((item) => {
		const index = findIndex(result, { id: item.id });
		if (index >= 0) {
			result[index] = { ...result[index], ...item };
		}
	});
	return result;
};

export const moveItem = (source: Page[], from: number, to: number) => {
	const result: Page[] = source.slice();
	const item = result[from];

	if (from !== to && from >= 0) {
		result.splice(from, 1);
		result.splice(to, 0, item);
	}

	return result.map<Page>((page, order) => ({ ...page, order }));
};

export const moveItemBelow = (source: Page[], sourceId: number, targetId: number): Page[] => {
	const result: Page[] = source.slice();

	if (sourceId !== targetId) {
		const sourceIndex = findIndex(result, { id: sourceId });
		if (sourceIndex >= 0) {
			const sourceQueue = result[sourceIndex];

			result.splice(sourceIndex, 1);
			const targetIndex = findIndex(result, { id: targetId });
			if (targetIndex >= 0) {
				result.splice(targetIndex + 1, 0, sourceQueue);

				return result.map<Page>((page, order) => ({ ...page, order }));
			}
		}
	}
	return source;
};

export const moveItemAbove = (source: Page[], sourceId: number, targetId: number): Page[] => {
	const result: Page[] = source.slice();

	if (sourceId !== targetId) {
		const sourceIndex = findIndex(result, { id: sourceId });
		if (sourceIndex >= 0) {
			const sourceQueue = result[sourceIndex];

			result.splice(sourceIndex, 1);
			const targetIndex = findIndex(result, { id: targetId });
			if (targetIndex >= 0) {
				result.splice(targetIndex, 0, sourceQueue);
				return result.map<Page>((page, order) => ({ ...page, order }));
			}
		}
	}
	return source;
};

export const moveToTop = (source: Page[], sourceId: number): Page[] => {
	const result: Page[] = source.slice();
	if (result.length === 0) {
		return source;
	}

	const index = findIndex(result, { id: sourceId });
	if (index > 0) {
		const sourceQueue = result[index];

		result.splice(index, 1);
		result.splice(0, 0, sourceQueue);
		return result.map<Page>((page, order) => ({ ...page, order }));
	}

	return source;
};

export const enableSelectedQueuesVisibility = (selectedQueueIds: number[], queues: Page[]) =>
	queues.map((queue) => {
		if (selectedQueueIds.some((selectedQueueId) => selectedQueueId === queue.id)) {
			if (!queue.canBeHidden) {
				return queue;
			}

			return { ...queue, canBeHidden: false };
		}

		if (queue.canBeHidden) {
			return queue;
		}

		return { ...queue, canBeHidden: true };
	});
