import { connect } from '../../../../../common/table-redux';
import { getVisibleAdditionalColumnsWidth } from '../../../../../read-ops/columns';
import { isAddLinkShown, getAddBarOverflow } from '../../../../../read-ops/rows';
import { getTableRowsHeight, getTableContentHeight } from '../../../../../read-ops/size';
import type { State } from '../../../../../state/types';
import AdditionalColumnsContainer from '../../../common/additional-columns-container';
import ScrollArea from './index-dumb';

export default connect(
	(state: State) => ({
		rowsHeight: getTableRowsHeight(state),
		contentHeight: getTableContentHeight(state),
		contentWidth: getVisibleAdditionalColumnsWidth(state),
		isAddLinkShown: isAddLinkShown(state),
		addBarOverflow: getAddBarOverflow(state),
		AdditionalColumnsContainer,
	}),
	{},
)(ScrollArea);
