import { connect } from '@atlassian/jira-react-redux';
import { displayQueuesErrorHeaderAction } from '../../../state/actions/page';
import type { State } from '../../../state/reducers/types';
import { isQueuesErrorHeaderDisplayed } from '../../../state/selectors/ui';
import IssueCount from './issue-count';
import IssueSearch from './issue-search';
import QueuesDetails from './view';

const mapStateToProps = (state: State) => ({
	IssueCount,
	IssueSearch,
	isQueuesErrorHeaderDisplayed: isQueuesErrorHeaderDisplayed(state),
});

const mapDispatchToProps = {
	renderDisplayError: displayQueuesErrorHeaderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(QueuesDetails);
