import type { AggTransformerFunction } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/transformer-type.tsx';
import isFieldMissingRenderer from '@atlassian/jira-issue-agg-field-transformers/src/common/utils/helpers/is-field-missing-renderer.tsx';
import {
	type FORGE_OBJECT_GQL_FIELD,
	ISSUE_FIELD_OBJECT_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer';

export const transformAggForgeObjectToLegacyField: AggTransformerFunction<
	typeof FORGE_OBJECT_GQL_FIELD
> = (node) => {
	if (isFieldMissingRenderer(node)) {
		return undefined;
	}

	const value = (() => {
		if (node.object == null) return null;
		try {
			return JSON.parse(node.object);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			throw new Error(
				'Failed to transform forge object field: node.object was invalid JSON object',
			);
		}
	})();

	return {
		...transformAggNodeToLegacyField(node),
		value,
		schema: {
			configuration: null,
			items: null,
			system: null,
			custom: node.type,
			renderer: node.renderer,
			type: ISSUE_FIELD_OBJECT_CF_TYPE,
		},
	};
};
