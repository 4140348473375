import React, { type ComponentType, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import range from 'lodash/range';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { injectIntlV2 as injectIntl, type IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import messages from './messages';

export type Props = {
	currentPage: number;
	totalPages: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Pagination: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onPageChange: (arg1: SyntheticEvent<any>, arg2: number, arg3: UIAnalyticsEvent) => void;
	intl: IntlShape;
};

function PaginationComponent(props: Props) {
	const {
		currentPage,
		totalPages,
		Pagination,
		intl: { formatMessage },
	} = props;
	return (
		<PaginationContainer>
			<AlignmentContainer>
				<Pagination
					selectedIndex={currentPage - 1}
					onChange={props.onPageChange}
					i18n={{
						prev: formatMessage(messages.previousButton),
						next: formatMessage(messages.nextButton),
					}}
					pages={range(1, totalPages + 1)}
				/>
			</AlignmentContainer>
		</PaginationContainer>
	);
}

export default injectIntl(PaginationComponent);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PaginationContainer = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	marginRight: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AlignmentContainer = styled.div({
	display: 'flex',
	paddingBottom: token('space.100', '8px'),
	paddingTop: token('space.100', '8px'),
});
