import React, { type ReactNode } from 'react';
import { FullPageIssueContainer } from '@atlassian/jira-spa-apps-issue/src/common/styles';

export type Props = {
	children: ReactNode;
};

export const ViewInner = (props: Props) => (
	<FullPageIssueContainer>{props.children}</FullPageIssueContainer>
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ ...rest }: { children: ReactNode }) => <ViewInner {...rest} />;
