import type { RowHeightMapping, RowId } from '../../../model/rows';

// BATCH UPDATE ROW HEIGHT MAPPING

export const BATCH_UPDATE_ROW_HEIGHT_MAPPING =
	'ops.rows.row-height.BATCH_UPDATE_ROW_HEIGHT_MAPPING' as const;

export type BatchUpdateRowHeightMappingAction = {
	type: typeof BATCH_UPDATE_ROW_HEIGHT_MAPPING;
	payload: {
		rowIds: RowId[];
		rowHeightMapping: RowHeightMapping;
	};
};

export const batchUpdateRowHeightMapping = (
	rowIds: RowId[],
	rowHeightMapping: RowHeightMapping,
): BatchUpdateRowHeightMappingAction => ({
	type: BATCH_UPDATE_ROW_HEIGHT_MAPPING,
	payload: {
		rowIds,
		rowHeightMapping,
	},
});
