import React from 'react';
import type { JsonComponentProps } from '../../../model/fields/types';
import Tags from '../common/tags';

type Props = JsonComponentProps<'labels'>;

const Labels = ({ tableInjectedProps: { isLastColumn }, dataSelectorProps: { value } }: Props) => (
	// @ts-expect-error - TS2322 - Type 'LabelsProps' is not assignable to type 'TagData[]'.
	<Tags tags={value} isLastColumn={isLastColumn} />
);

export default Labels;
