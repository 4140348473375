import { assoc } from 'icepick';
import { SEND_ANALYTIC_CREATOR, type SendAnalyticCreatorAction } from '../../../actions/analytic';
import { initialState } from './constants';
import type { AnalyticState } from './types';

export type Actions = SendAnalyticCreatorAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: AnalyticState = initialState, action: Actions) => {
	switch (action.type) {
		case SEND_ANALYTIC_CREATOR: {
			const { createAnalyticsEvent } = action.payload;

			return assoc(state, 'createAnalyticsEvent', createAnalyticsEvent);
		}
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
