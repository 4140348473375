import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	modalHeading: {
		id: 'importer-survey.survey-modal.modal-heading',
		defaultMessage: 'Before you start',
		description: 'Heading for the JSM importer survey modal',
	},
	modalTitle: {
		id: 'importer-survey.survey-modal.modal-title',
		defaultMessage:
			"Can you answer a few optional questions? We'll use your answers to improve the importer.",
		description: 'Title for the JSM importer survey modal',
	},
	dropDownQuestionWhereAreYouImportingFrom: {
		id: 'importer-survey.survey-modal.drop-down-question-where-are-you-importing-from',
		defaultMessage: 'Where are you importing from?',
		description: 'Question label for survey modal dropdown',
	},
	toolsOption1: {
		id: 'importer-survey.survey-modal.tools-option-1',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'ServiceNow',
		description:
			'This is the company name and the 1st option for the question Where are you importing from?',
	},
	toolsOption2: {
		id: 'importer-survey.survey-modal.tools-option-2',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'BMC Helix',
		description:
			'This is the company name and 2nd option for the question Where are you importing from?',
	},
	toolsOption3: {
		id: 'importer-survey.survey-modal.tools-option-3',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Freshworks',
		description:
			'This is the company name and 3rd option for the question Where are you importing from?',
	},
	toolsOption4: {
		id: 'importer-survey.survey-modal.tools-option-4',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'PagerDuty',
		description:
			'This is the company name and 4th option for the question Where are you importing from?',
	},
	toolsOption5: {
		id: 'importer-survey.survey-modal.tools-option-5',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Cherwell',
		description:
			'This is the company name and 5th option for the question Where are you importing from?',
	},
	toolsOption6: {
		id: 'importer-survey.survey-modal.tools-option-6',
		defaultMessage: 'Jira',
		description:
			'This is the company name and 6th option for the question Where are you importing from?',
	},
	toolsOption7: {
		id: 'importer-survey.survey-modal.tools-option-7',
		defaultMessage: 'Other',
		description: 'This is the 7th option for the question Where are you importing from?',
	},
	toolsOption8: {
		id: 'importer-survey.survey-modal.tools-option-8',
		defaultMessage: 'I’m just exploring',
		description: 'This is the 8th option for the question Where are you importing from?',
	},
	toolsOption9: {
		id: 'importer-survey.survey-modal.tools-option-9',
		defaultMessage: 'Zendesk',
		description:
			'This is the company name and 9th option for the question Where are you importing from?',
	},
	dropDownQuestionWhatAreYouImporting: {
		id: 'importer-survey.survey-modal.drop-down-question-what-are-you-importing',
		defaultMessage: 'What are you importing?',
		description: 'Question label for survey modal dropdown',
	},
	importOption1: {
		id: 'importer-survey.survey-modal.import-option-1',
		defaultMessage: 'Issues',
		description: '1st option for the question What are you importing?',
	},
	importOption2: {
		id: 'importer-survey.survey-modal.import-option-2',
		defaultMessage: 'Users',
		description: '2nd option for the question What are you importing?',
	},
	importOption3: {
		id: 'importer-survey.survey-modal.import-option-3',
		defaultMessage: 'Customer organizations',
		description: '3rd option for the question What are you importing?',
	},
	importOption4: {
		id: 'importer-survey.survey-modal.import-option-4',
		defaultMessage: 'Custom fields',
		description: '4th option for the question What are you importing?',
	},
	importOption5: {
		id: 'importer-survey.survey-modal.import-option-5',
		defaultMessage: 'Other',
		description: '5th option for the question What are you importing?',
	},
	cancelButtonLabel: {
		id: 'importer-survey.survey-modal.cancel-button-label',
		defaultMessage: 'Cancel',
		description: 'Cancel button label for the JSM importer survey modal',
	},
	continueToImporterButtonLabel: {
		id: 'importer-survey.survey-modal.continue-to-importer-button-label',
		defaultMessage: 'Continue to importer',
		description:
			'This is the label for Go to Jira Importer button which is shown on the survey modal',
	},
	placeholderLabel: {
		id: 'importer-survey.survey-modal.placeholder-label',
		defaultMessage: 'Select',
		description: 'This is the placeholder text for Select dropdown in the survey modal',
	},
});
