import { connect } from '../../../../common/table-redux';
import { setHorizontalScrollOffset } from '../../../../ops/scrolling/offset';
import { getVisibleAdditionalColumnsWidth } from '../../../../read-ops/columns';
import { getVisibleRowIds } from '../../../../read-ops/rows';
import { getHorizontalScrollOffset } from '../../../../read-ops/scrolling';
import type { State } from '../../../../state/types';
import AdditionalColumnsContainer from './index-dumb';

export default connect(
	(state: State) => ({
		scrollLeft: getHorizontalScrollOffset(state),
		/* This prop is not used by the component other than to force an update when the rows change.
		 * It has been done to fix the behaviour of row scroll being reset during table actions that
		 * would have previously only reconciled the elements, rather than reconstructing them.
		 * This behaviour is inherent to React and the browser, where React re-inserting nodes cause
		 * the browser to reset specific transient properties (such as scrolling or video play).
		 * Here we are forcing the re-rendering so the scroll position can be correctly
		 * inferred directly from the store.
		 * For more info see https://github.com/facebook/react/issues/13042 and the associated links.
		 */
		rowIds: getVisibleRowIds(state),
		additionalColumnsWidth: getVisibleAdditionalColumnsWidth(state),
	}),
	{
		setHorizontalScrollOffset,
	},
)(AdditionalColumnsContainer);
