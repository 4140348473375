import React, { type ComponentType } from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import { withShouldRenderWithinItsmSampleProject } from '@atlassian/jira-servicedesk-itsm-sample-project/src/ui/with-should-render-within-itsm-sample-project/index.tsx';
import type { ChangeManagementTourIssueProps } from './ui/change-management-tour-issue/types';
import type { ChangeManagementTourQueuesProps } from './ui/change-management-tour-queues/types';

const ChangeManagementTourIssueComponent = () =>
	import(
		/* webpackChunkName: "change-management-tour-issue" */ './ui/change-management-tour-issue'
	);

const ChangeManagementTourQueuesComponent = () =>
	import(
		/* webpackChunkName: "change-management-tour-queues" */ './ui/change-management-tour-queues'
	);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ChangeManagementTourIssue = lazy(() => ChangeManagementTourIssueComponent());

export const AsyncChangeManagementTourIssueNew = (props: ChangeManagementTourIssueProps) => (
	<Placeholder name="change-management-tour-issue" fallback={<></>}>
		<ChangeManagementTourIssue {...props} />
	</Placeholder>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ChangeManagementTourQueues = lazy(() => ChangeManagementTourQueuesComponent());

export const AsyncChangeManagementTourQueuesNew = (props: ChangeManagementTourQueuesProps) => (
	<Placeholder name="change-management-tour-queues" fallback={<></>}>
		<ChangeManagementTourQueues {...props} />
	</Placeholder>
);

export const AsyncChangeManagementTourIssue: ComponentType<ChangeManagementTourIssueProps> =
	withShouldRenderWithinItsmSampleProject(AsyncChangeManagementTourIssueNew, false);

export const AsyncChangeManagementTourQueues: ComponentType<ChangeManagementTourQueuesProps> =
	withShouldRenderWithinItsmSampleProject(AsyncChangeManagementTourQueuesNew, false);
