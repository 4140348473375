import React, { useRef, type ReactElement } from 'react';
import { getIn } from 'icepick';
import ErrorState from '@atlassian/jira-common-components-error-state';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import PageSpinner from '@atlassian/jira-servicedesk-common/src/ui/components/page-spinner';
import type { Queue } from '@atlassian/jira-servicedesk-queues-common/src/model';
import { setLastVisitedQueueIdAndCategory } from '../../services/last-visited-queue-helper';
import type { QueuesDataSource } from '../../services/queues-api-data-provider';
import messages from './messages';

type Props = {
	queueId: string;
	queuesDataSource: QueuesDataSource;
	projectKey: string;
	baseUrl: string;
	children: (arg1: Queue) => ReactElement;
	intl: IntlShape;
};

const findQueue = (source: QueuesDataSource, id: string): Queue | undefined =>
	getIn(source, ['data', id]);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	queueId,
	queuesDataSource,
	baseUrl,
	projectKey,
	children,
	intl: { formatMessage },
}: Props) => {
	const lastUnfoundQueue = useRef<string>('');

	if (queuesDataSource.loading) {
		return <PageSpinner />;
	}

	const queue = findQueue(queuesDataSource, queueId);

	if (queue) {
		lastUnfoundQueue.current = '';

		setLastVisitedQueueIdAndCategory(queue.id, projectKey, queue.category);
		return children(queue);
	}

	if (lastUnfoundQueue.current === queueId) {
		return (
			<ErrorState
				header={formatMessage(messages.notFoundHeader)}
				description={formatMessage(messages.notFoundDescription)}
				onRetry={() => {
					// If data source errors out let user retry the current page they are on.
					// Otherwise queue doesn't exist so go back to /queues
					if (queuesDataSource.error) {
						window.location.reload();
					} else {
						window.location.assign(`${baseUrl}/jira/servicedesk/projects/${projectKey}/queues`);
					}
				}}
			/>
		);
	}

	lastUnfoundQueue.current = queueId;
	return <PageSpinner />;
};
