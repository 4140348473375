import { connect } from '../../../../common/table-redux';
import DynamicColumnComponent from './dynamic';
import Column from './index-dumb';

export default connect(
	() => ({
		DynamicColumnComponent,
	}),
	{},
)(Column);
