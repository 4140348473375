import { useEffect, useMemo, useState } from 'react';
import type { FeaturesData } from '@atlassian/jira-router-resources-service-project-features';
import useServiceProjectFeatures from '@atlassian/jira-servicedesk-features-store';
import {
	getBoardIssueCount,
	getCreateSharedObjectsPermission,
} from '../../../services/board-promotion';

export const useBoardPromotion = (isAdmin: boolean | undefined, projectKey: string | undefined) => {
	const [doesHaveSharedObjectsPermissionEnabled, setDoesHaveSharedObjectsPermissionEnabled] =
		useState<boolean>();
	const [issueCount, setIssueCount] = useState<number>();
	const { data: features } = useServiceProjectFeatures();

	useEffect(() => {
		if (isAdmin !== undefined && projectKey) {
			getCreateSharedObjectsPermission().then((data) => {
				setDoesHaveSharedObjectsPermissionEnabled(data);
			});

			getBoardIssueCount(projectKey).then((data) => {
				setIssueCount(data);
			});
		}
	}, [isAdmin, projectKey]);

	const shouldRenderBoardPromotion = useMemo(() => {
		if (features && doesHaveSharedObjectsPermissionEnabled && issueCount !== undefined) {
			const isWithinMaxIssueCountLimit = issueCount < 4000;
			const hasEnabledBoards = hasUserEnabledBoardsFeature(features);

			if (isAdmin) {
				const permissionEnabled =
					doesHaveSharedObjectsPermissionEnabled !== undefined &&
					doesHaveSharedObjectsPermissionEnabled === true;
				return isWithinMaxIssueCountLimit && permissionEnabled && !hasEnabledBoards;
			}
			// agent
			return isWithinMaxIssueCountLimit && !hasEnabledBoards;
		}
		return false;
	}, [features, doesHaveSharedObjectsPermissionEnabled, isAdmin, issueCount]);

	return {
		shouldRenderBoardPromotion,
	};
};

const hasUserEnabledBoardsFeature = (features: FeaturesData | null): boolean => {
	if (features) {
		return features['jsm-board']?.status === 'ENABLED';
	}

	return false;
};
