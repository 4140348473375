// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md
// brandfolder asset id = hrv73kh63rncntxr5mpzh
// brandfolder url = https://brandfolder.com/atlassian-brand-creative-library/atlassian-public-asset-library/#!asset/hrv73kh63rncntxr5mpzh
import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/ticket-dispensor.svg'; // eslint-disable-line import/no-duplicates, jira/import/no-duplicates
import srcLight from '../../assets/ticket-dispensor.svg'; // eslint-disable-line import/no-duplicates

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 233, 235];
export { srcLight, srcDark, viewBox };
