import { connect } from '../../../../../../../../../common/table-redux';
import type { ColumnId } from '../../../../../../../../../model/columns';
import { clearSortingColumn } from '../../../../../../../../../ops/columns/sorting/clear';
import { setSortingColumn } from '../../../../../../../../../ops/columns/sorting/set';
import {
	isColumnSortable,
	getColumnSortOrder,
	getDefaultComparator,
	getSortedColumnChangedCallback,
} from '../../../../../../../../../read-ops/columns';
import {
	isAnyRowBeingAdded,
	getClearAddedRowsCallback,
} from '../../../../../../../../../read-ops/rows';
import type { State } from '../../../../../../../../../state/types';
import ColumnSortOperationIndicator from './index-dumb';

type Props = {
	columnId: ColumnId;
};

export default connect(
	(state: State, { columnId }: Props) => ({
		sortOrder: getColumnSortOrder(state, columnId),
		isColumnSortable: isColumnSortable(state, columnId),
		isSortEnabled: !isAnyRowBeingAdded(state),
		isDefaultSortColumn: getDefaultComparator(state) === columnId,
		onClearAddedRowsRequested: getClearAddedRowsCallback(state),
		onSortedColumnChanged: getSortedColumnChangedCallback(state),
	}),
	{
		setColumnSortOrder: setSortingColumn,
		clearColumnSortOrder: clearSortingColumn,
	},
)(ColumnSortOperationIndicator);
