import { connect } from '../../../../../common/table-redux';
import { sortedRowIdsHashRootId } from '../../../../../model/rows';
import {
	getAddLinkCaption,
	isAddLinkShown,
	getRowAddCallback,
	getAddBarOverflow,
} from '../../../../../read-ops/rows';
import { getSortedRowIdsHash } from '../../../../../state/internal/selectors';
import type { State } from '../../../../../state/types';
import AddLink from './index-dumb';

export default connect((state: State) => {
	const sortedRowIds = getSortedRowIdsHash(state)[sortedRowIdsHashRootId];
	return {
		addLinkCaption: getAddLinkCaption(state),
		lastRowId: sortedRowIds && sortedRowIds[sortedRowIds.length - 1],
		isVisible: isAddLinkShown(state),
		onRowAddRequested: getRowAddCallback(state),
		addBarOverflow: getAddBarOverflow(state),
	};
}, {})(AddLink);
