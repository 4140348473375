import type { Observable } from 'rxjs/Observable';
import { fetchJson$ } from '@atlassian/jira-servicedesk-common/src/utils/fetch';
import { type QueueId, fromQueueId } from '@atlassian/jira-servicedesk-queues-common/src/model';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: string, projectKey: string, queueId: QueueId): Observable<void> =>
	fetchJson$(
		`${baseUrl}/rest/servicedesk/1/servicedesk/${projectKey}/queues-history?queueId=${fromQueueId(
			queueId,
		)}`,
		{ method: 'POST' },
	);
