import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import union from 'lodash/union';
import { colors } from '@atlaskit/theme';
import type {
	ColumnConfiguration as ConfiguredColumn,
	HiddenColumns,
} from '../../../../../model/columns';

export const getHiddenParentColumns = (
	column: ConfiguredColumn,
	columnConfiguration: ConfiguredColumn[],
): HiddenColumns => {
	if (!column.parentId) {
		return [];
	}

	// @ts-expect-error - TS2322 - Type 'ColumnConfiguration | undefined' is not assignable to type 'ColumnConfiguration'.
	const columnParent: ConfiguredColumn = find(
		columnConfiguration,
		(configItem) => configItem.id === column.parentId,
	);

	const addToList = columnParent.isHidden ? [columnParent.id] : [];

	return union(addToList, getHiddenParentColumns(columnParent, columnConfiguration));
};

export const getAllHiddenColumns = (columnConfigurations: ConfiguredColumn[]): HiddenColumns =>
	reduce(
		columnConfigurations,
		// @ts-expect-error - TS2769 - No overload matches this call.
		(list, columnConfig: ConfiguredColumn) => {
			if (columnConfig.isHidden) {
				return [...list, columnConfig.id];
			}
			return list;
		},
		[],
	);

export const determineItemColor = (
	column: ConfiguredColumn,
	hiddenParentColumns: HiddenColumns,
): string => {
	const { depth, isHidden, isDisabled } = column;
	const isActiveChild = depth > 0 && !isHidden;
	const hasInactiveParent = !isEmpty(hiddenParentColumns);
	return (isActiveChild && hasInactiveParent) || isDisabled ? colors.N80 : 'inherit';
};
